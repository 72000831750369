import * as React from 'react';
import { InputView } from '../inputView/InputView';
import AddressInputViewProps from './AddressInputViewProps';
import AddressInputViewStyle from './AddressInputViewStyle';
import PlacesAutocomplete, {geocodeByAddress,getLatLng} from 'react-places-autocomplete';

export default class AddressInputView extends React.Component<AddressInputViewProps> 
{
    /* ATTRIBUTES */
    style = new AddressInputViewStyle();

    /* CONSTRUCTOR */
    constructor(props: any) 
    {
        super(props);

        // Initial state
        this.state = {focused: false}

        // Bindings
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    /* HANDLERS */
    handleChange(address:any) 
    {
        this.props.handleInputChanged(this.props.target, address);
    }

    handleSelect(address:any)
    {
        geocodeByAddress(address)
            .then((results:any) => getLatLng(results[0]))
            .then((latLng:any) => 
            {
                this.props.handleInputChanged(this.props.target, address);
 
                if(this.props.noCoordinates === undefined)
                {
                    this.props.handleInputChanged('longitude', latLng.lng);
                    this.props.handleInputChanged('latitude', latLng.lat);
                }
            })
    };

    /* RENDERING */
    render() {
        return (
            <PlacesAutocomplete value={this.props.value} onChange={this.handleChange} onSelect={this.handleSelect}>
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => 
                    {
                        return (
                            <>
                                <InputView updateSync={this.props.updateSync} {...getInputProps({className:"form-control"})} error={this.props.error} placeholder={this.props.placeholder} disabled={this.props.disabled} />

                                {
                                    <div className="autocomplete-dropdown-container" style={{...this.style.autoCompleteContainer, display: suggestions.length > 0 ? 'inherit' : 'none'}}>
                                
                                        {/* BUSY INDICATOR WHILE FETCHING DATA FROM GOOGLE */}
                                        {
                                            loading
                                            && 
                                            (
                                                <div>Loading...</div>
                                            )
                                        }

                                        {/* LIST OF SUGGESTIONS */}
                                        {
                                            suggestions?.map((suggestion:any) => 
                                                {
                                                    return (
                                                        <div {...getSuggestionItemProps(suggestion)} className={suggestion.active ? 'suggestion-item--active': 'suggestion-item'} style={suggestion.active ? this.style.suggestionFocused : this.style.suggestionUnFocused} >
                                                            <span>
                                                                {suggestion.description}
                                                            </span>
                                                        </div>
                                                    );
                                                }
                                            )
                                        }

                                    </div>
                                }
                            </>
                        )
                    }
                }
            </PlacesAutocomplete>
        );
    }
}