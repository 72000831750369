import React from 'react';
import './style.css'

const CalendarEnd = ({ className, children }) => {
    return (
        <div className='dashboard-filter-date-calendar-end-container'>
            <div className={className}>
                <div style={{ position: "relative" }}>{children}</div>
            </div>
        </div>
    )
}

export default CalendarEnd;