import axios from 'axios';
import config from '../config';

async function post(refresh_token: string) {
	var details: any = {
		grant_type: 'refresh_token',
		refresh_token: refresh_token,
	};

	let formBody: any = [];

	for (let property in details) {
		let encodedKey = encodeURIComponent(property);
		let encodedValue = encodeURIComponent(details[property]);
		formBody.push(encodedKey + '=' + encodedValue);
	}

	formBody = formBody.join('&');

	try {
		return await axios
			.post(
				`https://securetoken.googleapis.com/v1/token?key=${config.firebaseApiKey}`,
				formBody,
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			)
			.then((elt) => elt.data);
	} catch (e) {
		return undefined;
	}
}

export default { post };
