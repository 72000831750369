import Service from './Service';
import Axios from 'axios';
import ServiceDTO from '../models/ServiceDTO';

export default class CompanyServiceService extends Service
{
    /* METHODS */
    get(id:string)
    {
        return this.execute(Axios.get(`${this.url}/companies/${id}/services`, this.config()));
    }

    post(companyId: string, body: ServiceDTO)
    {
        return this.execute(Axios.post(`${this.url}/companies/${companyId}/services`, body, this.config()));
    }

    put(companyId: string, body: ServiceDTO)
    {        
        return this.execute(Axios.put(`${this.url}/companies/${companyId}/services`, body, this.config()));
    }
}