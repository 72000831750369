import * as React from 'react';
import { Spring } from 'react-spring';
import Lottie from 'lottie-react-web';
import ButtonViewState from './ButtonViewState';
import ButtonViewProps from './ButtonViewProps';
import ButtonViewStyle from './ButtonViewStyle';
import * as loader from './assets/loading.json';
import TranslationView from '../translationView/TranslationView';

export default class DoubleClickButtonView extends React.Component<ButtonViewProps, ButtonViewState>
{
    style = new ButtonViewStyle();

    /* CONSTRUCTOR */
    constructor(props: ButtonViewProps) {
        super(props);

        // State
        this.state = {
            isClicked: false,
            isHovered: false
        };

        // Binding
        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleOnMouseIn = this.handleOnMouseIn.bind(this);
        this.handleOnMouseOut = this.handleOnMouseOut.bind(this);
        this.retrieveButtonStyle = this.retrieveButtonStyle.bind(this);
    }

    /* HANDLERS */
    handleOnClick() {
        const { disabled, onClick } = this.props;

        if (!disabled) {
            this.setState({ isClicked: true });
            if (onClick) {
                setTimeout(() => {
                    (onClick as () => void)();
                    this.setState({ isClicked: false });
                }, 300);
            }
            else {
                setTimeout(() => this.setState({ isClicked: false }), 300);
            }
        }
    }

    handleOnMouseIn() {
        if (!this.props.disabled && !this.props.isBusy && !this.state.isClicked) {
            this.setState({ isHovered: true });
        }
    }

    handleOnMouseOut() {
        this.setState({ isHovered: false });
    }

    /* METHODS */
    retrieveButtonStyle() {
        const { isClicked, isHovered } = this.state;
        const { disabled, previous, width } = this.props;


        let style = {};

        if (width) {
            style = { width: width };
        }

        if (disabled) {
            style = { ...style, ...this.style.disabledButton };
        }
        else if (previous) {
            style = { ...style, ...this.style.previousButton };
        }
        else {
            style = { ...style, ...this.style.button };
        }

        if (isHovered) {
            return { ...style, ...this.style.hoveredButton };
        }

        if (isClicked) {
            return { ...style, transform: 'scale(1.05)' };
        }

        return style;
    }

    /* RENDERING */
    render() {
        const { isHovered } = this.state;
        const { image, isBusy, children, disabled } = this.props;

        return (
            <Spring from={this.retrieveButtonStyle()} to={this.retrieveButtonStyle()}>
                {(style: any) =>
                    <div onDoubleClick={this.handleOnClick} onMouseOver={this.handleOnMouseIn} onMouseLeave={this.handleOnMouseOut} style={disabled ? { ...this.style.button, ...style } : { ...this.style.disabledButton, ...style, maxHeight: "40px" }}>
                        {isBusy == true ?
                            (
                                <>
                                    <Lottie options={{ animationData: loader }} />
                                </>
                            )
                            :
                            (
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: 5.5, paddingRight: 5.5, width: '100%' }}>
                                    {image && <img style={isHovered ? this.style.imageHovered : this.style.image} src={`/assets/${image}`} />}
                                    <span style={{ fontWeight: 600 }}>
                                        <TranslationView children={children} />
                                    </span>
                                </div>
                            )
                        }
                    </div>
                }
            </Spring>
        );
    }
}