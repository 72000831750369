import Service from './Service';
import Axios from 'axios';
import CompanyContactPersonDTO from '../models/CompanyContactPersonDTO';

export default class CompanyContactPersonService extends Service
{
    get(companyId: String)
    {
        return this.execute(Axios.get(`${this.url}/companies/${companyId}/contactpeople`, this.config()));
    }

    post(companyId:string, body: CompanyContactPersonDTO[])
    {
        return this.execute(Axios.post(`${this.url}/companies/${companyId}/contactpeople`, body, this.config()));
    }

    put(companyId:string, body: CompanyContactPersonDTO[])
    {
        return this.execute(Axios.put(`${this.url}/companies/${companyId}/contactpeople`, body, this.config()));
    }

    delete(companyId: string, contactPersonId: string)
    {
        return this.execute(Axios.delete(`${this.url}/companies/${companyId}/contactpeople/${contactPersonId}`, this.config()));
    }
}