import Service from './Service';
import Axios from 'axios';

export default class CheckScheduleService extends Service
{
    post(checkId: string, check: any)
    {
        return this.execute(Axios.post(`${this.url}/checks/${checkId}/schedules`, check, this.config()));
    }

    postWithCommunications(checkId: string, check: any)
    {
        return this.execute(Axios.post(`${this.url}/checks/${checkId}/schedulecommunications`, check, this.config()));
    }

    put(checkId: string, check: any)
    {
        return this.execute(Axios.put(`${this.url}/checks/${checkId}/schedules`, check, this.config()))
    }
}