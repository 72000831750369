import React from 'react';
import './style.css';

const InputLeft = ({ children }) => {
    return (
        <div className="settings-detail-input-left-container">
            {children}
        </div>
    )
}

export default InputLeft;