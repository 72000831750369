import * as React from "react";
import SeparatorView from "../components/SeparatorView";
import TranslationView from "src/components/translationView/TranslationView";

const SystemLogView = ({ log }) => {
	if ((log.type !== "0000000" && log.type !== "0000001" && log.type !== "0000002" && log.type !== "0000003" && log.type !== "0000041" && log.type !== "0000013" && log.type !== "0000012" && log.type !== "0000074" && log.type !== "0000073" && log.type !== "0000102" && log.type !== "0000014" && log.type !== "0000011" && log.type !== "0000015" && log.type !== "0000016" && log.type !== "0000017") || (!log.payLoad && !log.system)) {
		return <div />;
	}

	function getPayLoad() {
		switch (log.type) {
			case "0000000":
			case "0000003":
				return log.system.split("\n")?.map((elt) => <div>{elt}</div>);
			case "0000073":
			case "0000074":
				return <TranslationView>{log.system}</TranslationView>;
			default:
				return log.payLoad ? log.payLoad : log.system.split("\n")?.map((elt) => <div>{elt}</div>);
		}
	}

	return (
		<>
			<SeparatorView visible />
			<div style={{ WebkitUserSelect: "text" }}>{getPayLoad()}</div>
		</>
	);
};

export default SystemLogView;
