export default class CompanyDoctorDTO
{
    id: string;

    type: number;

    name: string;

    companyId: string;
    doctorId: string;
}