import React, { useEffect, useReducer, useState } from 'react';
import HeaderView from 'src/modules/dashboard/components/headerView/HeaderView';
import CompanyNavView from '../../components/companyNavView';
import { App } from '../../../../../../app';
import MyCertificateGeneral from './components/general';
import { mockCertif } from './mock';
import { actions, reducer } from './reducer';
import MyCertificateUpload from './components/upload';
import { useQuery } from 'react-query';
import CompanyConfigurationService from 'src/services/CompanyConfigurationService';
import BusyIndicator from 'src/modules/dashboard/components/busyIndicator';
import AlertHelper from 'src/helpers/AlertHelper';
import './style.css';
import SaveButtonView from 'src/modules/dashboard/components/saveButtonView/SaveButtonView';
import MyCertificateCheckLaunch from './components/check-launch';
import MyCertificateRandomChecksSetup from './components/random-checks-setup';

const CompanyMyCertificate = () => {
  // Attributes
  const query = useQuery<any, any>(
    'company-id-configuration',
    refreshConfiguration,
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );
  const [myCertificate, dispatch] = useReducer(reducer, mockCertif);
  const [isBusy, setIsBusy] = useState(false);

  // Effects
  useEffect(() => {
    if (query.data != undefined) {
      dispatch({ type: actions.serverChanged, payload: query.data });
    }
  }, [query.data]);

  // Functions
  async function refreshConfiguration() {
    return (
      await CompanyConfigurationService.get(
        App.history.location.state.company.id
      )
    ).data;
  }

  async function handleSaveClicked() {
    setIsBusy(true);

    await CompanyConfigurationService.put(
      App.history.location.state.company.id,
      myCertificate.company
    );
    AlertHelper.success('Modifications saved.');
    setIsBusy(false);
  }

  return (
    <BusyIndicator query={query}>
      <img
        className='my-certificate-illustration'
        src='/assets/illu_mycertif.png'
      />
      <div className='col' style={{ maxWidth: 1077 }}>
        <HeaderView
          title={`${App.history.location.state.company.name} MyCertificate setup`}
          history={[
            { name: 'Companies', path: '/dashboard/company' },
            {
              name: App.history.location.state.company.name,
              path: '/dashboard/company/detail',
              state: App.history.location.state,
            },
            {
              name: `${App.history.location.state.company.name} MyCertificate setup`,
              path: '/dashboard/company/mycertificate',
              state: App.history.location.state,
            },
          ]}
        >
          <CompanyNavView
            type={'mycertificate'}
            company={App.history.location.state.company}
          />
        </HeaderView>
        <MyCertificateGeneral
          myCertificate={myCertificate}
          dispatch={dispatch}
        />
        <MyCertificateCheckLaunch
          myCertificate={myCertificate}
          dispatch={dispatch}
        />
        <MyCertificateRandomChecksSetup
          myCertificate={myCertificate}
          dispatch={dispatch}
        />
        {/* <MyCertificateTable myCertificate={myCertificate} dispatch={dispatch} /> */}
        <MyCertificateUpload />
        <SaveButtonView isBusy={isBusy} handleSaved={handleSaveClicked}>
          Save
        </SaveButtonView>
      </div>
    </BusyIndicator>
  );
};

export default CompanyMyCertificate;
