import * as React from "react";
import { App } from "../../../../../../app";
import CompanyDetailViewState from "./CompanyDetailViewState";
import CompanyDetailViewProps from "./CompanyDetailViewProps";
import HeaderView from "../../../../components/headerView/HeaderView";
import CompanyDTO from "../../../../../../models/CompanyDTO";
import CompanyService from "../../../../../../services/CompanyService";
import DetailComponent from "../../../../abstracts/detailComponent/DetailComponent";
import SaveButtonView from "../../../../components/saveButtonView/SaveButtonView";
import CompanyDetailViewStyle from "./CompanyDetailViewStyle";
import DeleteButtonView from "../../../../components/deleteButtonView/DeleteButtonView";
import CompanyDoctorDTO from "../../../../../../models/CompanyDoctorDTO";
import CompanyDoctorService from "../../../../../../services/CompanyDoctorService";
import DoctorService from "../../../../../../services/DoctorService";
import CompanyContactPersonService from "../../../../../../services/CompanyContactPersonService";
import CompanyContactPersonDTO from "../../../../../../models/CompanyContactPersonDTO";
import DoctorDTO from "../../../../../../models/DoctorDTO";
import DetailView from "../../../../components/detailView/DetailView";
import CompanyNavView from "../../components/companyNavView/index";
import CompanyContractView from "./modules/contract/CompanyContractView";
import CompanyPreferenceView from "./modules/preference/CompanyPreferenceView";
import CompanyContactView from "./modules/contact/CompanyContactView";
import CompanyReportingView from "./modules/reporting/CompanyReportingView";
import CompanyGeneralView from "./modules/general/CompanyGeneralView";
import { LocalizationContext } from "src/components/localizationContext/LocalizationContext";
import CompanyCommunicationView from "./modules/communication/CompanyCommunicationView";
import UserContext from "src/components/userContext/UserContext";
import CompanyRecipientView from "./modules/recipient";
import BusyIndicator from "src/modules/dashboard/components/busyIndicator";

export default class CompanyDetailView extends DetailComponent<
  CompanyDetailViewProps,
  CompanyDetailViewState
> {
  /* ATTRIBUTES */
  service = new CompanyService();
  doctorService = new DoctorService();
  style = new CompanyDetailViewStyle();
  companyDoctorService = new CompanyDoctorService();
  companyContactPersonService = new CompanyContactPersonService();
  requiredFields = ["name", "address", "language"];
  emailFields = [
    "companyDoctorEmail",
    "contactEmail",
    "personOfTrustEmail",
    "preventionAdvisorEmail",
  ];
  phoneFields = [
    "companyDoctorPhoneNumber",
    "contactPhoneNumber",
    "personOfTrustPhoneNumber",
    "preventionAdvisorPhoneNumber",
  ];

  /* CONSTRUCTOR */
  constructor(props: CompanyDetailViewProps) {
    super(props);

    let element = new CompanyDTO();

    if (App.history.location.state) {
      element = App.history.location.state.company;
    }

    // Initial State
    this.state = {
      element: element,
      errors: {},
      companyAlwaysDoctors: [],
      companyNeverDoctors: [],
      doctors: [],
      isBusy: false,
      fields: [],
      isLoading: true,
    };

    // Handlers
    this.handleSaved = this.handleSaved.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleInputChanged = this.handleInputChanged.bind(this);
    this.mapCompanyNeverDoctor = this.mapCompanyNeverDoctor.bind(this);
    this.mapCompanyAlwaysDoctor = this.mapCompanyAlwaysDoctor.bind(this);
    this.mapCompanyDoctorOptions = this.mapCompanyDoctorOptions.bind(this);
    this.handleCompanyNeverDoctor = this.handleCompanyNeverDoctor.bind(this);
    this.handleCompanyAlwaysDoctor = this.handleCompanyAlwaysDoctor.bind(this);
    this.handleCompanyContactPeopleReceived =
      this.handleCompanyContactPeopleReceived.bind(this);
  }

  /* LIFE CYCLE EVENTS */
  async componentDidMount() {
    const doctors = await this.execute<DoctorDTO[]>(this.doctorService.get());

    if (App.history.location.state != undefined) {
      const company = await this.execute<CompanyDTO>(
        this.service.get(App.history.location.state.company.id)
      );
      const companyContactPeople = await this.execute<
        CompanyContactPersonDTO[]
      >(this.companyContactPersonService.get(company?.id));
      const companyDoctors = await this.execute<CompanyDoctorDTO[]>(
        this.companyDoctorService.get(company?.id)
      );

      this.setState(
        {
          doctors,
          element: company,
          companyAlwaysDoctors: companyDoctors?.filter((elt) => elt.type == 0),
          companyNeverDoctors: companyDoctors?.filter((elt) => elt.type == 1),
          isLoading: false,
        },
        () => this.handleCompanyContactPeopleReceived(companyContactPeople)
      );
    } else {
      this.setState({ doctors, isLoading: false });
    }
  }

  /* HANDLERS */
  handleCompanyContactPeopleReceived(
    companyContactPeople: CompanyContactPersonDTO[]
  ) {
    this.setState((previousState) => {
      previousState.element.emailResults = companyContactPeople
        .filter((elt) => elt.type == 0)
        ?.map((elt) => elt.email)
        .join(",");
      previousState.element.emailReports = companyContactPeople
        .filter((elt) => elt.type == 1)
        ?.map((elt) => elt.email)
        .join(",");
      previousState.element.emailInvoices = companyContactPeople
        .filter((elt) => elt.type == 2)
        ?.map((elt) => elt.email)
        .join(",");
      previousState.element.emailHR = companyContactPeople
        .filter((elt) => elt.type == 3)
        ?.map((elt) => elt.email)
        .join(",");
      return previousState;
    });
  }

  async handleDelete(company: CompanyDTO) {
    await this.execute(this.service.delete(company.id));
    this.success(`Company ${company.name} deleted.`);
    App.history.push("/dashboard/company");
  }

  async handleSaved() {
    // Deconstruct
    const { element, errors } = this.state;

    /* REQUIRED FIELD CHECKS */
    this.requiredFields.forEach((field) => this.checkRequired(field));
    if (
      this.requiredFields.filter((target) => {
        if (errors[target]) return target;
        return;
      }).length > 0
    ) {
      this.setState(this.state, () =>
        this.danger("Required fields are missing")
      );
      return;
    }

    /* PHONE CHECKS */
    this.phoneFields.forEach((phone) => this.checkPhone(phone));
    if (
      this.phoneFields.filter((target) => {
        if (errors[target]) return target;
        return;
      }).length > 0
    ) {
      this.setState(this.state, () =>
        this.danger("Some fields have wrong formatting.")
      );
      return;
    }

    /* EMAIL CHECKS */
    this.emailFields.forEach((email) => this.checkEmail(email));
    if (
      this.emailFields.filter((target) => {
        if (errors[target]) return target;
        return;
      }).length > 0
    ) {
      this.setState(this.state, () =>
        this.danger("Some fields have wrong formatting.")
      );
      return;
    }

    /* SERVER CALL */
    const companyContactPeople = this.mapContactPeople(element);
    const companyDoctors: CompanyDoctorDTO[] =
      this.state.companyNeverDoctors?.concat(this.state.companyAlwaysDoctors);

    if (element.distanceMax !== null) {
      element.distanceMax = (element.distanceMax + "")
        .replace("_", "")
        .replace("_", "")
        .replace(" km", "");
    }

    if (element.distanceMin !== null) {
      element.distanceMin = (element.distanceMin + "")
        .replace("_", "")
        .replace("_", "")
        .replace(" km", "");
    }

    if (element.id) {
      companyDoctors?.forEach((companyDoctor) => {
        companyDoctor.companyId = element.id;
      });
      companyContactPeople.forEach((companyContactPerson) => {
        companyContactPerson.companyId = element.id;
      });

      await this.execute(
        this.companyContactPersonService.put(element.id, companyContactPeople)
      );
      await this.execute(
        this.companyDoctorService.put(
          element.id,
          companyDoctors?.length > 0 ? companyDoctors : []
        )
      );
      await this.execute(this.service.put(element));
      this.success(`Company ${element.name} updated`);
      App.history.push("/dashboard/company");
    } else {
      const company = await this.execute<CompanyDTO>(
        this.service.post(element)
      );

      companyDoctors?.forEach((companyDoctor) => {
        companyDoctor.companyId = company.id;
      });
      companyContactPeople?.forEach((companyContactPerson) => {
        companyContactPerson.companyId = company.id;
      });

      await this.execute(
        this.companyContactPersonService.post(company.id, companyContactPeople)
      );
      await this.execute(
        this.companyDoctorService.post(company.id, companyDoctors)
      );
      this.success(`Company ${company.name} created.`);
      App.history.push("/dashboard/company");
    }
  }

  handleCompanyAlwaysDoctor(event: any) {
    this.setState({
      companyAlwaysDoctors:
        event.target.value?.map(this.mapCompanyAlwaysDoctor) ?? [],
    });
  }

  handleCompanyNeverDoctor(event: any) {
    this.setState({
      companyNeverDoctors:
        event.target.value?.map(this.mapCompanyNeverDoctor) ?? [],
    });
  }

  /* METHOD */
  mapCompanyDoctorOptions() {
    return this.state.doctors?.map((elt) => {
      return (
        <option value={elt.id}>{`${elt.lastName} ${elt.firstName}`}</option>
      );
    });
  }

  mapCompanyDoctorValues(doctors: any) {
    return doctors?.map((elt: any) => {
      return elt.doctorId;
    });
  }

  mapCompanyDoctor(doctorId: string) {
    const doctor = this.state.doctors?.find((doctor) => doctor.id == doctorId);

    if (doctor != null) {
      const companyDoctor = new CompanyDoctorDTO();
      companyDoctor.doctorId = doctorId;
      companyDoctor.companyId = this.state.element.id;
      companyDoctor.name = `${doctor.lastName} ${doctor.firstName}`;
      return companyDoctor;
    }

    return undefined;
  }

  mapCompanyAlwaysDoctor(doctorId: string) {
    const companyDoctor = this.mapCompanyDoctor(doctorId);

    if (companyDoctor != undefined) {
      companyDoctor.type = 0;
      return companyDoctor;
    }

    return undefined;
  }

  mapCompanyNeverDoctor(doctorId: string) {
    const companyDoctor = this.mapCompanyDoctor(doctorId);

    if (companyDoctor != undefined) {
      companyDoctor.type = 1;
      return companyDoctor;
    }

    return undefined;
  }

  mapContactPeople(element: CompanyDTO) {
    let companyResultsContactPeople;
    if (element.emailResults.includes(",")) {
      companyResultsContactPeople = element.emailResults
        .split(",")
        ?.map((email) => {
          return { email: email, type: 0, companyId: "" };
        });
    } else {
      companyResultsContactPeople = [
        { email: element.emailResults, type: 0, companyId: "" },
      ];
    }

    let companyReportsContactPeople;
    if (element.emailReports.includes(",")) {
      companyReportsContactPeople = element.emailReports
        .split(",")
        ?.map((email) => {
          return { email: email, type: 1, companyId: "" };
        });
    } else {
      companyReportsContactPeople = [
        { email: element.emailReports, type: 1, companyId: "" },
      ];
    }

    let companyInvoicesContactPeople;
    if (element.emailInvoices.includes(",")) {
      companyInvoicesContactPeople = element.emailInvoices
        .split(",")
        ?.map((email) => {
          return { email: email, type: 2, companyId: "" };
        });
    } else {
      companyInvoicesContactPeople = [
        { email: element.emailInvoices, type: 2, companyId: "" },
      ];
    }

    let companyHRContactPeople;
    if (element.emailHR.includes(",")) {
      companyHRContactPeople = element.emailHR.split(",")?.map((email) => {
        return { email: email, type: 3, companyId: "" };
      });
    } else {
      companyHRContactPeople = [
        { email: element.emailHR, type: 3, companyId: "" },
      ];
    }

    return companyInvoicesContactPeople
      .concat(companyResultsContactPeople)
      .concat(companyReportsContactPeople)
      .concat(companyHRContactPeople);
  }

  /* RENDERING */
  render() {
    // Deconstruct
    const { location } = App.history;
    const { element, errors } = this.state;

    // History and title
    let title = "New Company";
    let history = [
      { name: "Companies", path: "/dashboard/company" },
      {
        name: "New Company",
        path: "/dashboard/company/detail",
        state: undefined,
      },
    ];

    if (location.state != undefined) {
      title = element?.name;
      history = [
        { name: "Companies", path: "/dashboard/company" },
        {
          name: title,
          path: "/dashboard/company/detail",
          state: location.state,
        },
      ];
    }

    if (this.state.isLoading) {
      return <BusyIndicator query={{ status: "loading" }} />;
    }

    // Rendering
    return (
      <LocalizationContext.Consumer>
        {({ translate }) => (
          <UserContext.Consumer>
            {({ isInRole }) => (
              <DetailView
                fields={this.state.fields}
                handleSaved={this.handleSaved}
                className="col"
                style={{
                  ...this.style.container,
                  marginTop: 0,
                }}
              >
                {/* PAGE ILLUSTRATION */}
                {location.state != undefined ? (
                  <img
                    src="/assets/illu_company_edit.png"
                    style={this.style.illustration}
                  />
                ) : (
                  <img
                    src="/assets/illu_company_new.png"
                    style={this.style.illustration}
                  />
                )}

                {/* PAGE HEADER */}
                <div style={this.style.headerContainer}>
                  <HeaderView title={title} history={history}>
                    {App.history.location.state &&
                      App.history.location.state.company && (
                        <CompanyNavView
                          type={"detail"}
                          company={App.history.location.state.company}
                        />
                      )}
                  </HeaderView>
                </div>

                {/* PAGE CONTENT */}
                <div>
                  {/* GENERAL INFORMATION */}
                  <CompanyGeneralView
                    element={element}
                    errors={errors}
                    handleInputChanged={this.handleInputChanged}
                  />

                  {/* COMPANY CONTRACTS */}
                  <CompanyContractView
                    element={element}
                    handleInputChanged={this.handleInputChanged}
                  />

                  {/* REPORTING */}
                  <CompanyReportingView
                    element={element}
                    handleInputChanged={this.handleInputChanged}
                  />

                  {/* COMM PREFERENCES */}
                  <CompanyRecipientView
                    element={element}
                    handleInputChanged={this.handleInputChanged}
                  />

                  {/* COMMUNICATION */}
                  {(isInRole("admin") || isInRole("sales")) && location.state !== undefined && (
                    <CompanyCommunicationView element={element} />
                  )}

                  {/* CONTACTS FOR PSYCHOSOCIAL PROBLEMS */}
                  <CompanyContactView
                    element={element}
                    errors={errors}
                    handleInputChanged={this.handleInputChanged}
                  />

                  {/* COMPANY PREFERENCES */}
                  <CompanyPreferenceView
                    element={element}
                    handleInputChanged={this.handleInputChanged}
                    companyAlwaysDoctors={this.state.companyAlwaysDoctors}
                    companyNeverDoctors={this.state.companyNeverDoctors}
                    handleCompanyAlwaysDoctor={this.handleCompanyAlwaysDoctor}
                    handleCompanyNeverDoctor={this.handleCompanyNeverDoctor}
                    mapCompanyDoctorOptions={this.mapCompanyDoctorOptions}
                    mapCompanyDoctorValues={this.mapCompanyDoctorValues}
                  />

                  {/* BUTTON CONTAINER */}
                  <div style={this.style.container}>
                    <SaveButtonView
                      isBusy={this.state.isBusy}
                      handleSaved={this.handleSaved}
                    />
                  </div>
                </div>
              </DetailView>
            )}
          </UserContext.Consumer>
        )}
      </LocalizationContext.Consumer>
    );
  }
}
