import * as React from 'react';
import { App } from '../../../../app';
import { motion } from 'framer-motion';
import MenuImageViewStyle from './MenuImageViewStyle';

export default class MenuImageView extends React.Component<any, any>
{
    /* ATTRIBUTES */
    style = new MenuImageViewStyle();

    /* CONSTRUCTOR */
    constructor(props: {}) {
        super(props);

        // BINDINGS
        this.renderSrc = this.renderSrc.bind(this);
    }

    /* RENDERING */
    render() {
        if (this.props.disabled === true) {
            return (
                <div style={{ ...this.style.menuImage, cursor: 'not-allowed' }}>
                    <img src={this.renderSrc()} style={this.style.image} />
                </div>
            );
        }

        return (
            <motion.div whileHover={{ scale: 1.1 }} onClick={() => App.navigate(this.props.route, undefined)} onMouseOver={(e) => { (e.currentTarget.children[0] as HTMLImageElement).src = `/assets/${this.props.src}_clicked.${this.props.extension}` }} onMouseOut={(e) => { (e.currentTarget.children[0] as HTMLImageElement).src = this.renderSrc() }} style={this.style.menuImage}>
                <img src={this.renderSrc()} style={this.style.image} />
            </motion.div>
        );
    }

    renderSrc() {
        if (App.history.location.pathname.includes("/dashboard/detail")) {
            if (this.props.src === "dashboard_icon") {
                return `/assets/${this.props.src}_clicked.${this.props.extension}`;
            }
        }

        if (App.history.location.pathname.includes((this.props.route))) {
            return `/assets/${this.props.src}_clicked.${this.props.extension}`;
        }

        return `/assets/${this.props.src}.${this.props.extension}`;
    }
}

