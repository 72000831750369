import React from "react";
import { actions } from "../../../../reducer";
import { MyCertificateRandomChecksSetupProps } from "../../props";
import MyCertificateInput from "../input";
import TranslationView from "src/components/translationView/TranslationView";
import "./style.css";
import { TypeOfEntry } from "src/dtos/EntryType";

const LeftGroup = ({
  dispatch,
  myCertificate,
}: MyCertificateRandomChecksSetupProps) => {
  //Attributes
  const typeOfEntrySelectOptions = [
    { label: "Absence declaration", value: TypeOfEntry.ABSENCE_DECLARATION },
    { label: "Medical certificate", value: TypeOfEntry.MEDICAL_CERTIFICATE },
  ];

  //Functions
  const handleOnChangeEntryTypes = (event: any) => {
    dispatch({
      type: actions.configChanged,
      payload: {
        target: "entryTypes",
        value: myCertificate.company.configuration.entryTypes?.map((elt) => {
          if (event.target.value?.includes(elt.type)) {
            elt.isRandom = true;
          } else {
            elt.isRandom = false;
          }

          return elt;
        }),
      },
    });
  };

  const handleOnBlurNumberOfRandomCheck = () => {
    if (!myCertificate.company.configuration.numberOfRandomCheck) {
      dispatch({
        type: actions.configChanged,
        payload: {
          target: "numberOfRandomCheck",
          value: -1,
        },
      });
    }
  };
  
  //Render
  return (
    <div className="company-mycertificate-leftgroup-container">
      <MyCertificateInput
        target="entryTypes"
        label="randomCheckEntryTypesLabel"
        type="multi-select"
        value={myCertificate.company.configuration?.entryTypes
          ?.filter((elt) => elt.isRandom)
          ?.map((elt) => elt.type)}
        handleOnChange={(event) => handleOnChangeEntryTypes(event)}
      >
        {typeOfEntrySelectOptions?.map((option) => {
          return (
            <option value={option.value}>
              <TranslationView>{option.label}</TranslationView>
            </option>
          );
        })}
      </MyCertificateInput>
      <div className="company-mycertificate-rightgroup-row">
        <div style={{ width: 200, marginRight: 30 }}>
          <MyCertificateInput
            onBlur={() => handleOnBlurNumberOfRandomCheck()}
            disabled={
              myCertificate.company.configuration.entryTypes === undefined
            }
            target="numberOfRandomCheck"
            label="numberOfRandomCheckLabel"
            type="text"
            value={myCertificate.company.configuration.numberOfRandomCheck}
            handleOnChange={(event) =>
              dispatch({
                type: actions.configChanged,
                payload: {
                  target: "numberOfRandomCheck",
                  value: event.target.value,
                },
              })
            }
          />
        </div>
        <div style={{ width: 200 }} />
      </div>
    </div>
  );
};

export default LeftGroup;
