import Axios from "axios";
import { ReportingType } from "src/enums/reporting";
import { ReportingOptionDTO } from "src/models/ReportingOptionDTO";
import Service from "./Service";

async function getAllByCompanyIdAndReportingType(companyId: string, reportingType: ReportingType) {
	const service = new Service();
	return Axios.get(`${service.getUrl("v1")}/companies/${companyId}/reporting-options?reportingType=${reportingType}`, service.config());
}

async function put(companyId: string, reportingOptionId: string, reportingOptionDTO: ReportingOptionDTO) {
	const service = new Service();
	return Axios.put(`${service.getUrl("v1")}/companies/${companyId}/reporting-options/${reportingOptionId}`, reportingOptionDTO, service.config());
}

const reportingOptionService = {
	getAllByCompanyIdAndReportingType,
	put,
};

export default reportingOptionService;
