export interface EntryType {
  configurationId: string;
  type: TypeOfEntry;
  isActive: boolean;
  isRandom: boolean;
}

export enum TypeOfEntry {
  ABSENCE_DECLARATION,
  MEDICAL_CERTIFICATE,
}
