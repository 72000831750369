export default class SuperiorDTO {
    id: string;

    firstName: string;
    lastName: string;

    fullName: string = "";

    email: string;
    phoneNumber: string;
    language: string;

    isInterface: boolean;

    companyId: string;

    requestForm: number;

    constructor() {
        this.fullName = this.firstName + " " + this.lastName;
    }
}