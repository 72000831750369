import React, { useContext, useEffect } from 'react';
import { CheckContext } from 'src/modules/dashboard/components/checkContext/CheckContext';
import AccordeonView from '../../../../../../components/accordeonView/AccordeonView';
import CheckResultStyle from './CheckResultStyle';
import FileButtonView from '../../../../components/FileButtonView/FileButtonView';
import StarView from '../../../../components/StarView/StarView';
import { InputView } from '../../../../../../../../components/inputView/InputView';
import { useMounted } from 'src/functions/useMounted';
import { CheckStatus } from 'src/constants/CheckStatus';
import { isCheckStatusPending } from 'src/helpers/CheckStatusHelper';

enum prolongationLikelyHood {
  excluded = 0,
  probable = 1,
  possible = 2,
  idk = -1,
}

const CheckResultView = ({
  handleInputChanged,
  handleDateChanged,
  handleResultClicked,
  handleDeleteResultClicked,
}) => {
  // Attributes
  const isMounted = useMounted();
  const { check, handleUpdateCheck, handleUpdateRequest } =
    useContext(CheckContext);
  const { request } = check;
  const archived = check.state === 0;
  const style = new CheckResultStyle();

  // Effects
  useEffect(() => {
    if (isMounted) {
      handleUpdateCheck();
    }
  }, [check.doctorEfficiency]);

  // Methods
  function getShouldShowProlongation() {
    const newIncapacityEndDate = new Date(check.newIncapacityEndDate);
    const incapacityEndDate = new Date(request.endDate);

    return (
      newIncapacityEndDate.getFullYear() === incapacityEndDate.getFullYear() &&
      newIncapacityEndDate.getDate() === incapacityEndDate.getDate() &&
      newIncapacityEndDate.getMonth() === incapacityEndDate.getMonth()
    );
  }

  function getShouldShowDisagreement() {
    const newIncapacityEndDate = new Date(check.newIncapacityEndDate);
    const incapacityEndDate = new Date(request.endDate);

    return !(
      newIncapacityEndDate.getFullYear() === incapacityEndDate.getFullYear() &&
      newIncapacityEndDate.getDate() === incapacityEndDate.getDate() &&
      newIncapacityEndDate.getMonth() === incapacityEndDate.getMonth()
    );
  }

  function getShouldShowNonOperable() {
    return check.checkOccurence === 1;
  }

  function getShouldShowResultSentBy() {
    return check.checkOccurence > 1;
  }

  function getShouldShowNewIncapacityDate() {
    return (
      check.checkOccurence === 4 ||
      check.checkOccurence === 3 ||
      check.checkOccurence === 5
    );
  }

  // Options
  const basicCheckOccurenceOptions = [
    <option key={-1} value={-1}>
      {' '}
    </option>,
    <option key={0} value={0}>
      No (Cancelled)
    </option>,
    <option key={1} value={1}>
      No (Check non-operable)
    </option>,
    <option key={2} value={2}>
      No show
    </option>,
    request.location === 0 ? (
      <option key={4} value={4}>
        Yes (patient's home)
      </option>
    ) : (
      <option key={3} value={3}>
        Yes
      </option>
    ),
    <option key={5} value={5}>
      Yes (second check)
    </option>,
  ];

  const certifCheckOccurenceOptions = [
    <option key={6} value={6}>
      No certif confirmed
    </option>,
    <option key={7} value={7}>
      No certif denied
    </option>,
  ];

  const checkOccurenceOptions = [
    ...basicCheckOccurenceOptions,
    ...certifCheckOccurenceOptions,
  ];

  // Render
  return (
    <AccordeonView
      isOpenByDefault={
        archived ||
        isCheckStatusPending(check) ||
        check.state === CheckStatus.FINISHED
      }
      title='Check Result'
      header={
        <FileButtonView
          disabled={archived}
          type='RESULT'
          fileName={check.resultLink}
          checkId={check.id}
          onClick={handleResultClicked}
          onDeleteClicked={handleDeleteResultClicked}
        />
      }
    >
      {/* RESULT LINK */}
      <div style={style.inputDouble}>
        <div style={style.inputTitle}>Result Link</div>
        <InputView
          disabled
          placeholder='Link to result'
          type='text'
          value={check.resultLink}
          onChange={() => {}}
        />
      </div>

      <div className='row'>
        {/* CHECK OCCURENCE */}
        <div style={style.inputDouble}>
          <div style={style.inputTitle}>Check Occurence*</div>
          <InputView
            disabled={archived}
            updateSync={handleUpdateCheck}
            placeholder='Select an option'
            type='select'
            value={check.checkOccurence}
            onChange={(event: any) => {
              handleInputChanged('checkOccurence', event.target.value);
            }}
          >
            {checkOccurenceOptions}
          </InputView>
        </div>
      </div>

      {getShouldShowNewIncapacityDate() && (
        /* PROLONGATION */
        <div className='row'>
          {/* NEW END DATE OF INCAPACITY */}
          <div style={style.inputLeft}>
            <div style={style.inputTitle}>New End Date of Incapacity</div>
            <InputView
              updateSync={handleUpdateCheck}
              disabled={archived}
              minDate={request.startDate && new Date(request.startDate)}
              maxDate={request.endDate && new Date(request.endDate)}
              placeholder='01/01/2020'
              type='date'
              value={check.newIncapacityEndDate}
              onChange={(event: any) => {
                handleDateChanged('newIncapacityEndDate', event.target.value);
              }}
            />
          </div>

          {!getShouldShowProlongation() && !getShouldShowDisagreement() && (
            <div style={style.inputRight} />
          )}

          {getShouldShowProlongation() && (
            <div style={{ ...style.inputRight }}>
              {/* PROLONGATION */}
              <div style={style.inputTitle}>Prolongation</div>
              <InputView
                disabled={archived}
                updateSync={handleUpdateRequest}
                placeholder='Select an option'
                type='select'
                value={request.workAccidentInformation.plausibility}
                onChange={(event: any) =>
                  handleInputChanged(
                    'request.workAccidentInformation.plausibility',
                    event.target.value
                  )
                }
              >
                <option key='' value={-1}></option>
                <option key='EXCLUDED' value={prolongationLikelyHood.excluded}>
                  Excluded
                </option>
                <option key='PROBABLE' value={prolongationLikelyHood.probable}>
                  Probable
                </option>
                <option key='POSSIBLE' value={prolongationLikelyHood.possible}>
                  I don't know
                </option>
              </InputView>
            </div>
          )}

          {/* WORKER DISAGREEMENT */}
          {getShouldShowDisagreement() && (
            <div style={{ ...style.inputRight }}>
              {/* WORKER DISAGREEMENT */}
              <div style={style.inputTitle}>Worker disagreement</div>
              <InputView
                disabled={archived}
                updateSync={handleUpdateCheck}
                placeholder='Select an option'
                type='select'
                value={`${check.workerDisagreement}`}
                onChange={(event: any) =>
                  handleInputChanged('workerDisagreement', event.target.value)
                }
              >
                <option key='YES' value='true'>
                  Yes
                </option>
                <option key='NO' value='false'>
                  No
                </option>
              </InputView>
            </div>
          )}
        </div>
      )}

      {/* NON-OPERABLE REASON */}
      {getShouldShowNonOperable() && (
        <div className='row'>
          <div style={style.inputDouble}>
            <div style={style.inputTitle}>Non-operable reason</div>
            <InputView
              disabled={archived}
              updateSync={handleUpdateCheck}
              placeholder='Select an option'
              type='select'
              value={check.nonOperableReason}
              onChange={(event: any) =>
                handleInputChanged('nonOperableReason', event.target.value)
              }
            >
              <option key={0} value={0}></option>
              <option key={1} value={1}>
                The company sent the request too late to organize the Check
              </option>
              <option key={2} value={2}>
                The company sent us incorrect/incomplete Check information
              </option>
              <option key={4} value={4}>
                The worker sent us incorrect/incomplete Check information
              </option>
              <option key={5} value={5}>
                The doctor did not have time to do the Check
              </option>
              <option key={6} value={6}>
                The doctor did not remember to do the Check
              </option>
              <option key={7} value={7}>
                The doctor couldn’t find the house of the Worker
              </option>
              <option key={8} value={8}>
                The doctor couldn’t find the mailbox of the Worker
              </option>
              <option key={9} value={9}>
                The doctor couldn’t do the Check for personal reasons
              </option>
              <option key={10} value={10}>
                The doctor could not do the Check independently
              </option>
              <option key={11} value={11}>
                We couldn’t respect the company's distance preference
              </option>
              <option key={12} value={12}>
                We couldn’t respect the company's timing preference
              </option>
              <option key={13} value={13}>
                We couldn’t find an available doctor (during the week)
              </option>
              <option key={14} value={14}>
                We couldn’t find an available doctor (during the weekend)
              </option>
              <option key={15} value={15}>
                We made a mistake in the organization of the Check
              </option>

              <option key={16} value={16}>
                The worker is in quarantine (covid)
              </option>
              <option key={17} value={17}>
                Send to ghosttown
              </option>
              <option key={18} value={18}>
                Check denied by worker
              </option>
            </InputView>
          </div>
        </div>
      )}

      {getShouldShowResultSentBy() && (
        <div className='row'>
          {/* RESULT SENT BY */}
          <div style={style.inputLeft}>
            <div style={style.inputTitle}>Result Sent By</div>
            <InputView
              disabled={archived}
              updateSync={handleUpdateCheck}
              placeholder='Select an option'
              type='select'
              value={check.resultSentBy}
              onChange={(event: any) =>
                handleInputChanged('resultSentBy', event.target.value)
              }
            >
              <option key={-1} value={-1}></option>
              <option key={0} value={0}>
                Doctor App
              </option>
              <option key={1} value={1}>
                E-mail / Fax
              </option>
              <option key={2} value={2}>
                Never sent
              </option>
            </InputView>
          </div>

          {/* DOCTOR EFFICIENCY */}
          <div style={style.inputRight}>
            <div style={style.inputTitle}>Doctor Efficiency</div>
            <StarView
              disabled={archived}
              stars={check.doctorEfficiency}
              onChange={(event: any) =>
                handleInputChanged('doctorEfficiency', event.target.value)
              }
            />
          </div>
        </div>
      )}
    </AccordeonView>
  );
};

export default CheckResultView;
