import React from 'react';
import { App } from 'src/app';
import UserDTO from 'src/models/UserDTO';
import SettingViewStyle from '../../SettingViewStyle';

interface UserRowProps {
	user: UserDTO;
	index: number;
}

const UserRow = ({ user, index }: UserRowProps) => {
	// Attributes
	const style = new SettingViewStyle();

	// Methods
	function handleEdit(user: UserDTO) {
		App.history.push('/dashboard/setting/detail', { user: user });
	}

	function handleCopied() {
		success('Copied to clipboard.');
	}

	function success(message: string) {
		alert({ type: 'success', message: message });
	}

	return (
		<tr key={index}>
			<td style={style.td} onCopy={handleCopied}>
				{`${user?.firstName} ${user?.lastName}`}
			</td>
			<td style={style.td} onCopy={handleCopied}>
				{user?.email}
			</td>
			<td style={style.td} onCopy={handleCopied}>
				{user?.roles?.map((role) => role.name).join(', ')}
			</td>
			<td>
				<span onClick={() => handleEdit(user)} style={style.editLink}>
					<img src='/assets/icon_edit.svg' style={style.editIcon} />
				</span>
			</td>
		</tr>
	);
};

export default UserRow;
