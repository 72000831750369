import Axios from 'axios';
import Service from './Service';

export default class CompanyCommunicationService extends Service {
    get(companyId: string) {
        return this.execute(Axios.get(`${this.getUrl("v2")}/companies/${companyId}/communications`, this.config()));
    }

    put(companyId: string, companyCommunications: any[]) {
        return this.execute(Axios.put(`${this.getUrl("v2")}/companies/${companyId}/communications`, companyCommunications, this.config()));
    }
}