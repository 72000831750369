import DetailComponentState from './DetailComponentState';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import BaseComponent from '../../../../abstracts/BaseComponent';

export default abstract class DetailComponent<
  Props = {},
  State extends DetailComponentState = {
    element: {};
    errors: {};
    isBusy: false;
    fields: string[];
  }
> extends BaseComponent<Props, State> {
  /* ATTRIBUTES */
  emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  /* CONSTRUCTOR */
  constructor(props: Props) {
    super(props);

    // Bindings
    this.checkPhone = this.checkPhone.bind(this);
    this.handleInputChanged = this.handleInputChanged.bind(this);
  }

  /* HANDLERS */
  handleInputChanged(targets: string, value: string) {
    this.setState((previousState) => {
      let element = previousState.element;
      let newTarget = targets;

      if (newTarget.includes('.')) {
        const targetArray = newTarget.split('.');
        targetArray.forEach((target, index) => {
          if (index < targetArray.length - 1) {
            element = element[target];
          } else {
            newTarget = target;
          }
        });
      }

      previousState.errors[targets] = false;
      element[newTarget] = value;
      previousState.fields.push(targets);
      return previousState;
    });
  }

  /* METHODS */
  checkRequired(target: string) {
    let element = this.state.element;
    let newTarget = target;

    if (newTarget.includes('.')) {
      const targetArray = newTarget.split('.');
      targetArray.forEach((elt, index) => {
        if (index < targetArray.length - 1) {
          element = element[elt];
        } else {
          newTarget = elt;
        }
      });
    }

    if (element[newTarget] === '' || element[newTarget] === undefined) {
      this.state.errors[target] = true;
    }

    if (element[newTarget] !== undefined) {
      if (Array.isArray(element[newTarget])) {
        if (element[newTarget].length == 0) {
          this.state.errors[target] = true;
        }
      }
    }
  }

  checkEmail(target: string) {
    const { element, errors } = this.state;
    if (
      element[target] != undefined &&
      element[target] != '' &&
      !this.emailRegex.test(String(element[target]).toLowerCase())
    ) {
      errors[target] = true;
    }
  }

  checkPhone(target: string) {
    const { errors } = this.state;
    errors[target] = false;

    /*
        if(element[target])
        {
            const phoneNumber = parsePhoneNumberFromString(element[target]);
            if(phoneNumber)
            {
                errors[target] = !phoneNumber.isValid();
            }
            else
            {
                errors[target] = true;
            }
        }
        */
  }
}
