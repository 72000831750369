import React from "react";
import { App } from "src/app";
import AlertHelper from "src/helpers/AlertHelper";
import { useCheck } from "src/modules/dashboard/components/checkContext/CheckContext";
import CheckDoctorService from "src/services/CheckDoctorService";
import ScheduleViewStyle from "../../ScheduleViewStyle";

const DeleteCard = ({ isDelete }) => {
	// Attributes
	const { check } = useCheck();
	const style = new ScheduleViewStyle();

	// Functions
	async function handleCancel() {
		await new CheckDoctorService().delete(check.id);
		App.navigate("/dashboard/request", { check: check });
		AlertHelper.success("Appointment has been cancelled.");
	}

	if (!isDelete) {
		return <React.Fragment />;
	}

	return (
		<div style={{ width: 1047 }}>
			<button
				onClick={handleCancel}
				type="button"
				className="btn btn-base btn-base-danger"
				style={{
					...style.button,
					float: "right",
					width: 120,
				}}
			>
				<div style={style.buttonContent}>
					<img className="img-base-primary" src="/assets/icon_calendar.svg" style={style.buttonImage} />
					<div style={style.buttonText}>Cancel</div>
				</div>
			</button>
		</div>
	);
};

export default DeleteCard;
