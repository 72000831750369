import * as React from 'react';
import LogViewStyle from '../LogViewStyle';

const BadgeView = ({ log, internal }) => {
    const style = new LogViewStyle();

    if (log) {

        let user = undefined;

        if(log.user)
        {
            user = log.user;
        }
        else if (log.type === "0000081") {
            user = { firstName: 'Valou', lastName: 'System' };
        }
       
        if(user)
        {
            return (
                <div style={{ ...style.badgeContainer, marginRight: internal ? 0 : 20, marginLeft: internal ? 20 : 0 }}>
                    <span style={style.badgeText}>
                        {`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}
                    </span>
                </div>
            );
        }
    }

    return (
        <div style={{ ...style.badgeContainer, marginRight: internal ? 0 : 20, marginLeft: internal ? 20 : 0 }}>
            <img src="/assets/icon_user.svg" style={style.userIcon} />
        </div>
    );
}

export default BadgeView;