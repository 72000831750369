import React from "react";
import SearchInput from "./components/search-input";
import SearchViewStyle from "../../SearchViewStyle";
import DownloadButton from "./components/download-button";
import NewButton from "./components/new-button";
import SearchViewProps from "../../SearchViewProps";

interface SearchBarProps {
	isBusy?: boolean;
	search: string;
	handleNew?: any;
	handleRefresh?: any;
	cardHeaderChildren?: any;
	handleSearchChanged: any;
	handleSecondaryAction?: any;
	secondaryActionChildren?: any;
}

const SearchBar = ({ cardHeaderChildren, search, handleSearchChanged, secondaryActionChildren, handleSecondaryAction, handleNew }: SearchBarProps) => {
	// Attributes
	const style = new SearchViewStyle();
	return (
		<div className="card-header border-0" style={style.cardHeaderContainer}>
			<SearchInput cardHeaderChildren={cardHeaderChildren} search={search} handleSearchChanged={handleSearchChanged} />
			{secondaryActionChildren && secondaryActionChildren()}
			{handleSecondaryAction && <DownloadButton handleSecondaryAction={handleSecondaryAction} />}
			{handleNew && <NewButton handleNew={handleNew} />}
		</div>
	);
};

export default SearchBar;
