import React from "react";
import { App } from "src/app";
import CheckDoctorDTO from "src/models/CheckDoctorDTO";
import CheckDTO from "src/models/CheckDTO";
import DoctorDTO, { mapAppointmentMethodsToEnum } from "src/models/DoctorDTO";
import ScheduleViewStyle from "../../../../ScheduleViewStyle";

interface DoctorRowProps {
  elt: CheckDoctorDTO;
  check: CheckDTO;
  index: number;
  handleDoctorSelected: (doctor: CheckDoctorDTO) => void;
  handleDoctorOpened: (doctor: CheckDoctorDTO) => void;
}

const DoctorRow = ({
  elt,
  check,
  index,
  handleDoctorSelected,
  handleDoctorOpened,
}: DoctorRowProps) => {
  // Attributes
  const greyListed = elt.inhouseAppreciation === 1;
  const languageConstraint = !check.request.worker.languages.includes(
    elt.mainLanguage
  );
  const distanceColor = mapDistance(elt);

  let doctorAlwaysConstraint = false;
  if (check.request.company.doctorAlwaysUse.length > 0) {
    doctorAlwaysConstraint = true;
    if (
      check.request.company.doctorAlwaysUse.find(
        (doctor: DoctorDTO) => doctor.id === elt.id
      )
    ) {
      doctorAlwaysConstraint = false;
    }
  }

  let doctorNeverConstraint = false;
  if (check.request.company.doctorNeverUse.length > 0) {
    if (
      check.request.company.doctorNeverUse.find(
        (doctor: DoctorDTO) => doctor.id === elt.id
      )
    ) {
      doctorNeverConstraint = true;
    }
  }

  // Functions
  function mapDistance(elt: any) {
    const { company } = check.request;

    if (company.distanceMax) {
      if (elt.distance > company.distanceMax) {
        return "#FDB56D";
      }
    } else if (elt.distance > 30) {
      return "#FDB56D";
    }

    if (company.distanceMin) {
      if (elt.distance < company.distanceMin) {
        return "#FDB56D";
      }
    }

    return "inherit";
  }

  return (
    <>
      <tr key={index}>
        <td
          style={{
            ...style.doctorSelector,
            color: elt.selected ? "white" : "black",
            backgroundColor: elt.selected ? "#85D8C8" : "inherit",
          }}
          onClick={() => handleDoctorSelected(elt)}
        >
          <img
            src={`/assets/icon_pokeball${elt.selected ? "_selected" : ""}.svg`}
            style={style.doctorSelectorImage}
          />
        </td>
        <td
          style={
            doctorNeverConstraint || doctorAlwaysConstraint
              ? { color: "#fdcfa0" }
              : { color: "inherit" }
          }
        >
          {elt.firstName} {elt.lastName}
        </td>
        <td style={{ color: distanceColor }}>{elt.distance}km</td>
        <td>
          <span
            style={
              languageConstraint ? { color: "#fdcfa0" } : { color: "inherit" }
            }
          >
            {elt.mainLanguage}
          </span>
          {elt.otherLanguages &&
            elt.otherLanguages.split(",")?.map((lang) => {
              const languageConstraint =
                !check.request.worker.languages.includes(lang);

              return (
                <>
                  ,
                  <span
                    style={
                      languageConstraint
                        ? { color: "#fdcfa0" }
                        : { color: "inherit" }
                    }
                  >{` ${lang}`}</span>
                </>
              );
            })}
        </td>
        <td>
          {elt.hasOfficeService === true && (
            <img
              src={`/assets/icon_office${
                check?.request?.location == 0 ? "_orange" : ""
              }.svg`}
              style={style.doctorServiceImage}
            />
          )}
          {elt.hasHomeService === true && (
            <img
              src={`/assets/icon_home${
                check?.request?.location == 0 ? "" : "_orange"
              }.svg`}
              style={style.doctorServiceImage}
            />
          )}
        </td>
        <td style={greyListed ? { color: "#FDB56D" } : { color: "inherit" }}>
          {elt.inhouseAppreciation === 0 ? "white" : "grey"}
        </td>
        <td>{elt.reactivity ? elt.reactivity : "- - -"}</td>
        <td>{elt.efficiency ? elt.efficiency : "- - -"}</td>
        <td
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <span
            onClick={() =>
              App.history.push("/dashboard/doctor/detail", {
                ...App.history.location.state,
                doctor: elt,
              })
            }
            style={{
              transform: "translateY(-2.5px)",
              width: 15,
              height: 15,
              fill: "white",
              color: "white",
              margin: 5,
              cursor: "pointer",
            }}
          >
            <img
              src="/assets/icon_edit.svg"
              style={{
                width: 15,
                height: 15,
                fill: "white",
                color: "white",
                cursor: "pointer",
                filter:
                  "invert(83%) sepia(10%) saturate(107%) hue-rotate(169deg) brightness(100%) contrast(82%)",
              }}
            />
          </span>
          <div
            style={style.doctorOpenButton}
            onClick={() => handleDoctorOpened(elt)}
          >
            {elt.open ? "-" : "+"}
          </div>
        </td>
      </tr>
      {elt.open && (
        <tr>
          <td colSpan={9}>
            {/* DOCTOR STATS */}
            <div style={style.doctorInfoContainer}>
              {/* NUMBER OF CHECKS */}
              <div>
                <span style={style.doctorInfoTitle}>{`#checks : `}</span>
                <span>{elt.numberOfChecks}</span>
              </div>

              {/* SALES REPRESENTATIVE */}
              <div>
                <span style={style.doctorInfoTitle}>{`Sales Rep : `}</span>
                <span>{elt.salesRepresentative}</span>
              </div>

              {/* SIGNED DATE */}
              <div>
                <span style={style.doctorInfoTitle}>{`Signed Date : `}</span>
                <span>
                  {elt.signedDate ? elt.signedDate.split("T")[0] : "-"}
                </span>
              </div>

              {/* EARLY RETURNS */}
              <div>
                <span style={style.doctorInfoTitle}>{`Early Returns : `}</span>
                <span>{elt.statER}</span>
              </div>

              {/* APP USAGE */}
              <div>
                <span style={style.doctorInfoTitle}>{`App Usage : `}</span>
                <span>{elt.appUsage}</span>
              </div>

              {/* LAST CHECK DATE */}
              <div>
                <span
                  style={style.doctorInfoTitle}
                >{`Last check date : `}</span>
                <span>
                  {new Date(elt.lastCheckDate).toISOString().split("T")[0]}
                </span>
              </div>
            </div>

            {/* SCHEDULE DESCRIPTION */}
            <div style={style.doctorPreferencesContainer}>
              <div style={style.doctorInfoTitle}>Schedule Description :</div>
              <div style={style.doctorPreferences}>
                {elt.weeklyScheduleDescription}
              </div>
            </div>

            {/* HOLIDAYS */}
            <div style={style.doctorPreferencesContainer}>
              <div style={style.doctorInfoTitle}>Holidays :</div>
              <div style={style.doctorPreferences}>{elt.holidays}</div>
            </div>

            {/* HOW TO REQUEST APPOINTMENT */}
            <div style={style.doctorPreferencesContainer}>
              <div style={style.doctorInfoTitle}>
                How To Request Appointment :
              </div>
              <div style={style.doctorPreferences}>
                <strong>Preferred method: </strong>
                {mapAppointmentMethodsToEnum(elt.appointmentMethods).join(", ")}
                <br />
                {elt.comments}
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const style = new ScheduleViewStyle();

export default DoctorRow;
