import Lottie from 'lottie-react-web';
import React from 'react';
import { Modal } from 'reactstrap';
import TranslationView from '../translationView/TranslationView';

interface CustomModalProps {
	name?: string;
	description: string;
	imagePath: string;
	confirmButtonText: string;
	cancelButtonText: string;
	confirmButtonIconPath?: string;
	cancelButtonIconPath?: string;
	confirmButtonColor?: string;
	nameTextColor?: string;
	onConfirm?: any;
	onCancel?: any;
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isLoading?: boolean;
}

export default function CustomModal({
	name,
	description,
	imagePath,
	confirmButtonText,
	cancelButtonText = 'oopsNoGoBack',
	confirmButtonIconPath,
	cancelButtonIconPath,
	confirmButtonColor = '#FDB56D',
	nameTextColor = '#FDB56D',
	onCancel,
	onConfirm,
	isOpen,
	setIsOpen,
	isLoading = false,
}: CustomModalProps) {
	// Render
	return (
		<Modal
			className='modal-dialog-centered'
			isOpen={isOpen}
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: '350px',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-between',
					height: '100%',
					width: '100%',
					minHeight: '489px',
					borderRadius: '10px',
					padding: '22px',
					boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.25)',
				}}
			>
				{/* HEADER */}
				<div
					style={{
						display: 'flex',
						flexDirection: 'row-reverse',
						width: '100%',
						cursor: 'pointer',
					}}
					onClick={() => setIsOpen(false)}
				>
					<img src='/assets/icon_cross.png' style={{ height: 25, width: 25 }} />
				</div>

				{/* BODY */}
				<div
					style={{
						width: '100%',
						height: '410px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<div style={{ display: 'flex' }}>
						{/* IMAGE */}
						<img src={imagePath} style={{ width: '200px', height: '200px' }} />
					</div>

					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							width: '100%',
							height: '71px',
						}}
					>
						{/* NAME */}
						<span
							style={{
								fontFamily: 'Roboto',
								fontWeight: 600,
								fontSize: '14px',
								lineHeight: '22px',
								textAlign: 'center',
								marginBottom: '5px',
								color: nameTextColor,
							}}
						>
							{name}
						</span>

						{/* DESCRIPTION */}
						<div
							style={{
								fontFamily: 'Roboto',
								fontWeight: 300,
								fontSize: '14px',
								lineHeight: '22px',
								textAlign: 'center',
							}}
						>
							<TranslationView>{description}</TranslationView>
						</div>
					</div>

					{/* BUTTONS */}
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							alignItems: 'center',
							width: '100%',
							height: '73px',
						}}
					>
						<button
							style={{
								width: '179px',
								height: '41px',
								borderRadius: '8px',
								backgroundColor: confirmButtonColor,
								border: 'none',
								cursor: isLoading ? 'progress' : 'pointer',
							}}
							disabled={isLoading}
							onClick={() => {
								onConfirm();
							}}
						>
							<div
								style={{
									alignItems: 'center',
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								{isLoading && (
									<Lottie
										height={40}
										width={150}
										options={{
											animationData: require('./assets/loading.json'),
										}}
									/>
								)}
								{!isLoading && (
									<span>
										{confirmButtonIconPath && (
											<img
												src={confirmButtonIconPath}
												style={{ marginRight: '20px' }}
											/>
										)}
										<span
											style={{
												color: '#fff',
												fontFamily: 'Roboto',
												fontWeight: 600,
												fontSize: '14px',
												lineHeight: '22px',
											}}
										>
											<TranslationView>{confirmButtonText}</TranslationView>
										</span>
									</span>
								)}
							</div>
						</button>
						<button
							style={{
								width: '179px',
								height: '22px',
								borderRadius: '8px',
								alignItems: 'center',
								textAlign: 'center',
								backgroundColor: 'transparent',
								border: 'none',
								cursor: 'pointer',
							}}
							onClick={() => {
								onCancel();
							}}
						>
							{cancelButtonIconPath && (
								<img src={cancelButtonIconPath} style={{}} />
							)}
							<span
								style={{
									color: '#90A7B8',
									fontFamily: 'Roboto',
									fontWeight: 300,
									fontSize: '14px',
									lineHeight: '22px',
									textDecoration: 'underline',
								}}
							>
								<TranslationView>{cancelButtonText}</TranslationView>
							</span>
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
}
