import * as React from 'react';
import ArchiveViewStyle from './ArchiveViewStyle';
import CompanyArchiveService from '../../../../services/CompanyArchiveService';
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';
import { motion } from 'framer-motion';
import SearchArchivesSection from './modules/search';
import HeaderView from '../../components/headerView/HeaderView';
import NetworkHelper from 'src/helpers/NetworkHelper';
import ListArchivesSection from './modules/list';


const COMPANY = "ARCHIVES_COMPANY_STATE";
const SEARCH = "ARCHIVES_SEARCH_STATE";
const PAGING = "ARCHIVES_PAGING_STATE";

const ArchiveView = () => {
    /* ATTRIBUTES */
    const style = new ArchiveViewStyle();

    const [isBusy, setIsBusy] = React.useState(false);
    const [elements, setElements] = React.useState([]);
    const [search, setSearch] = React.useState("");
    const [company, setCompany] = React.useState<string>();
    const [page, setPage] = React.useState(0);

    // EFFECTS
    React.useEffect(() => {
        if (company !== undefined && search && (search.length > 2 || search === "/")) {
            refreshChecksForCompany();
        }
    }, [company, search]);

    React.useEffect(() => {
        if (localStorage.getItem(COMPANY) && localStorage.getItem(SEARCH) || localStorage.getItem(PAGING)) {
            handleStorageNotEmpty();
        }
    }, []);

    /* HANDLERS */
    function handleStorageNotEmpty() {
        const company = localStorage.getItem(COMPANY);
        const search = localStorage.getItem(SEARCH);
        const paging = localStorage.getItem(PAGING);

        setCompany(company);
        setSearch(search);
        setPage(parseInt(paging));

        localStorage.removeItem(COMPANY);
        localStorage.removeItem(SEARCH);
        localStorage.removeItem(PAGING);
    }

    function handleNavigateToDetail(callableFunction: any) {
        localStorage.setItem(COMPANY, company);
        localStorage.setItem(SEARCH, search);
        localStorage.setItem(PAGING, `${page}`);
        callableFunction();
    }

    /* METHODS */
    async function refreshChecksForCompany() {
        setIsBusy(true);
        setElements(await NetworkHelper.execute(await new CompanyArchiveService().get(company, search)));
        setIsBusy(false);
    }

    /* RENDERING */
    return (
        <LocalizationContext.Consumer>

            {({ translate }) => (
                <div className="col">

                    {/* PAGE HEADER */}
                    <motion.div initial={{ y: -20 }} animate={{ y: 0 }}>
                        <HeaderView title={translate('archives')} history={[{ name: translate('archives'), path: "/dashboard/archive" }]}>
                            <div />
                        </HeaderView>
                    </motion.div>

                    {/* SEARCH SECTION FOR THE ARCHIVES */}
                    <SearchArchivesSection search={search} setSearch={setSearch} company={company} setCompany={setCompany} />

                    {/* CHECKS RECEIVED FOR THE */}
                    {(company !== undefined) && (search !== undefined) && ((search.length > 2) || search === "/") && (
                        <ListArchivesSection page={page} handlePageChanged={(page) => setPage(page)} handleNavigateToDetail={handleNavigateToDetail} isBusy={isBusy} refreshChecksForCompany={refreshChecksForCompany} elements={elements} />
                    )}

                </div>
            )}

        </LocalizationContext.Consumer>
    );
}

export default ArchiveView;