import * as React from 'react';
import { hot } from 'react-hot-loader/root';
import LoginView from './modules/login/LoginView';
import { History, createMemoryHistory, createBrowserHistory } from 'history';
import { Router, Route, Redirect, Switch } from 'react-router';
import DashboardShellView from './modules/dashboard/DashboardShellView';
import { AlertableView } from './components/alertableView/AlertableView';
import UserContextProvider from './components/userContext/UserContextProvider';
import LocalizationContextProvider from './components/localizationContext/LocalizationContextProvider';
import config from './config';
import InactivityTracker from './components/inactivityTracker/InactivityTracker';

class App extends React.Component<{}, {}> {
	/* ATTRIBUTES */
	static history: History<any>;
	static navigate: (path: string, state: any, handleSaved?: Function) => void;
	static mainUrl: string = config.azure.resource.url;
	static newApiUrl: string = config.azure.newResource.url;
	/* CONSTRUCTOR */
	constructor(props: {}) {
		super(props);

		// History
		if (config.isWebInterface == true) {
			App.history = createBrowserHistory();
		} else {
			App.history = createMemoryHistory();
		}

		// Basic navigation at first
		App.navigate = (path: string, state: any) => App.history.push(path, state);
	}

	async componentDidMount() {
		try {
			eval(`
			const remote = require('electron').remote;
			const { Menu, MenuItem } = remote;

			const menu = remote.Menu.getApplicationMenu();
			menu.items[0].submenu.insert(1, new MenuItem({label: 'Version : ${process.env.VERCEL_GITHUB_COMMIT_SHA}', enabled: false}));
						
			Menu.setApplicationMenu(menu);
		`);
		} catch {
			console.warn('This is not a desktop application.');
		}
        App.navigate('/login', {});
	}

	/* RENDER */
	render() {
		return (
			<Router history={App.history}>
				<InactivityTracker />
				<LocalizationContextProvider>
					<UserContextProvider>
						<AlertableView>
							<Switch>
								<Route path='/login' render={() => <LoginView />} />
								<Route
									path='/dashboard'
									render={() => <DashboardShellView />}
								/>
								<Route render={() => <Redirect to='/login' />} />
							</Switch>
						</AlertableView>
					</UserContextProvider>
				</LocalizationContextProvider>
			</Router>
		);
	}
}

const HottedApp = hot(App);

export { App, HottedApp };
