import * as React from 'react';
import CompanyViewState from './CompanyViewState';
import CompanyService from '../../../../../../services/CompanyService';
import CompanyViewProps from './CompanyViewProps';
import { App } from '../../../../../../app';
import CompanyDTO from '../../../../../../models/CompanyDTO';
import SearchView from '../../../../components/searchView/SearchView';
import ListView from '../../../../components/listView/ListView';
import CompanyViewStyle from './CompanyViewStyle';
import SearchableComponent from '../../../../abstracts/searchableComponent/SearchableComponent';
import { exportCompanies } from '../../../../../../helpers/ExportHelper';
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';
import UserContext from 'src/components/userContext/UserContext';

export default class CompanyView extends SearchableComponent<
	CompanyViewProps,
	CompanyViewState
> {
	/* ATTRIBUTES */
	searchableValues = ['name'];
	service = new CompanyService();
	style = new CompanyViewStyle();

	/* CONSTRUCTOR */
	constructor(props: CompanyViewProps) {
		super(props);

		// Initial State
		this.state = {
			currentCompany: '',
			elements: [],
			search: '',
			page: 0,
			isBusy: false,
			settingsHovered: false,
			superiorHovered: false,
			editHovered: false,
			myCertificateHovered: false,
		};

		// Handlers
		this.setState = this.setState.bind(this);
		this.mapHeaders = this.mapHeaders.bind(this);
		this.mapCompanies = this.mapCompanies.bind(this);
		this.handleSuperiorsHovered = this.handleSuperiorsHovered.bind(this);
		this.handleEditHovered = this.handleEditHovered.bind(this);
		this.handleSettingsHovered = this.handleSettingsHovered.bind(this);
		this.handleSearchChanged = this.handleSearchChanged.bind(this);
		this.handleMyCertificateHovered =
			this.handleMyCertificateHovered.bind(this);
	}

	/* LIFE CYCLE EVENTS */
	async componentDidMount() {
		const companies = (
			await this.execute<CompanyDTO[]>(this.service.get())
		)?.sort(this.sortTable);
		this.elements = companies?.filter((elt) => elt.name !== 'XXXXXXX');
		this.setState({ elements: this.elements });
	}

	/* HANDLERS */
	handleCurrentCompanyHovered(company: CompanyDTO) {
		this.setState({ currentCompany: company.id });
	}

	handleSuperiorsHovered() {
		this.setState({ superiorHovered: !this.state.superiorHovered });
	}

	handleSettingsHovered() {
		this.setState({ settingsHovered: !this.state.settingsHovered });
	}

	handleEditHovered() {
		this.setState({ editHovered: !this.state.editHovered });
	}

	handleMyCertificateHovered() {
		this.setState({ myCertificateHovered: !this.state.myCertificateHovered });
	}

	/* METHODS */
	sortTable(a: CompanyDTO, b: CompanyDTO) {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name === b.name) {
			return 0;
		}
		return 1;
	}

	mapHeaders() {
		return (
			<LocalizationContext.Consumer>
				{({ translate }) => (
					<tr>
						<th scope='col' style={{ width: '20%', overflowX: 'hidden' }}>
							{translate('companyName', 'en')}
						</th>
						<th scope='col' style={{ width: '40%', overflowX: 'hidden' }}>
							{translate('companyAddress', 'en')}
						</th>
						<th scope='col' style={{ width: '20%', overflowX: 'hidden' }}>
							{translate('companySalesRep', 'en')}
						</th>
						<th scope='col' style={{ width: '15%', overflowX: 'hidden' }}>
							{translate('companyNumber', 'en')}
						</th>
						<th scope='col' style={{ width: '5%' }}></th>
					</tr>
				)}
			</LocalizationContext.Consumer>
		);
	}

	mapCompanies(company: CompanyDTO, index: number) {
		return (
			<tr
				onMouseEnter={() => this.handleCurrentCompanyHovered(company)}
				key={index}
			>
				<td
					style={{ ...this.style.td, width: '20%', overflowX: 'hidden' }}
					onCopy={this.handleCopied}
				>
					{company.name}
				</td>
				<td
					style={{ ...this.style.td, width: '40%', overflowX: 'hidden' }}
					onCopy={this.handleCopied}
				>
					{company.address}
				</td>
				<td
					style={{ ...this.style.td, width: '20%', overflowX: 'hidden' }}
					onCopy={this.handleCopied}
				>
					{company.salesRepresentative}
				</td>
				<td
					style={{ ...this.style.td, width: '15%', overflowX: 'hidden' }}
					onCopy={this.handleCopied}
				>
					{`${company.companyNumber}`}
				</td>
				<td style={{ width: '5%', textAlign: 'right' }}>
					<span
						onMouseEnter={this.handleEditHovered}
						onMouseLeave={this.handleEditHovered}
						onClick={() =>
							App.history.push('/dashboard/company/detail', {
								company: company,
							})
						}
						style={this.style.button}
					>
						<img
							src={`/assets/edit_company${
								this.state.editHovered &&
								this.state.currentCompany === company.id
									? '_selected'
									: ''
							}.svg`}
							style={this.style.buttonImage}
						/>
					</span>
					<span
						onMouseEnter={this.handleSuperiorsHovered}
						onMouseLeave={this.handleSuperiorsHovered}
						onClick={() =>
							App.history.push('/dashboard/company/superior', {
								company: company,
							})
						}
						style={this.style.button}
					>
						<img
							src={`/assets/edit_superior${
								this.state.superiorHovered &&
								this.state.currentCompany === company.id
									? '_selected'
									: ''
							}.svg`}
							style={this.style.buttonImage}
						/>
					</span>
					<span
						onMouseEnter={this.handleSettingsHovered}
						onMouseLeave={this.handleSettingsHovered}
						onClick={() =>
							App.history.push('/dashboard/company/service', {
								company: company,
							})
						}
						style={this.style.button}
					>
						<img
							src={`/assets/edit_credits${
								this.state.settingsHovered &&
								this.state.currentCompany === company.id
									? '_selected'
									: ''
							}.svg`}
							style={this.style.buttonImage}
						/>
					</span>
					{company.hasMyCertificate && (
						<span
							onMouseEnter={this.handleMyCertificateHovered}
							onMouseLeave={this.handleMyCertificateHovered}
							onClick={() =>
								App.history.push('/dashboard/company/mycertificate', {
									company: company,
								})
							}
							style={this.style.button}
						>
							<img
								src={`/assets/edit_mycertificate${
									this.state.myCertificateHovered &&
									this.state.currentCompany === company.id
										? '_selected'
										: ''
								}.svg`}
								style={this.style.buttonImage}
							/>
						</span>
					)}
				</td>
			</tr>
		);
	}

	/* RENDERING */
	render() {
		return (
			<LocalizationContext.Consumer>
				{({ translate }) => (
					<UserContext.Consumer>
						{({ isInRole }) => (
							<SearchView
								title={translate('company')}
								history={[
									{ name: translate('companies'), path: '/dashboard/company' },
								]}
								handleSearchChanged={this.handleSearchChanged}
								handleNew={() => App.history.push('/dashboard/company/detail')}
								handleSecondaryAction={isInRole('admin') && exportCompanies}
								search={this.state.search}
							>
								<ListView
									isBusy={this.state.isBusy}
									elements={this.state.elements}
									mapElements={this.mapCompanies}
									mapHeaders={this.mapHeaders}
								/>
							</SearchView>
						)}
					</UserContext.Consumer>
				)}
			</LocalizationContext.Consumer>
		);
	}
}
