import Service from './Service';
import Axios from 'axios';
import UserDTO from '../models/UserDTO';
import UserCompanyDTO from 'src/models/UserCompanyDTO';

export default class UserCompanyService extends Service {
    get(userId: string) {
        return this.execute(Axios.get(`${this.getUrl("v2")}/users/${userId}/companies`, this.config()), "Unable to retrieve user companies.");
    }

    put(userId: string, userCompanies: UserCompanyDTO[]) {
        return this.execute(Axios.put(`${this.getUrl("v2")}/users/${userId}/companies`, userCompanies, this.config()), "User companies can't be updated.");
    }
}