import React, { useContext, useEffect, useState } from "react";

import { CheckContext } from "src/modules/dashboard/components/checkContext/CheckContext";

import AccordeonView from "../../../../../../components/accordeonView/AccordeonView";
import CheckResultStyle from "./WorkAccidentInformationStyle";

import { App } from "../../../../../../../../app";
import { InputView } from "../../../../../../../../components/inputView/InputView";
import ValidationHelper from 'src/helpers/ValidationHelper';
import { CheckStatus } from "src/constants/CheckStatus";

const WorkAccidentInformationView = ({
    handleInputChanged,
    handleDateChanged
}) => {
    const { check, handleUpdateRequest } = useContext(CheckContext);
    const { workAccidentInformation, worker } = check.request;
    const archived = App.history.location.pathname.includes('archive');
    const style = new CheckResultStyle();
    const isCheck = ValidationHelper.isCheck(check);


    return (
        <AccordeonView isOpenByDefault={check.state !== CheckStatus.PROBLEM && check.state !== CheckStatus.UPDATED && isCheck || archived} title="Work Accident Information">
            <div className="row">
                {/* NISS */}
                <div style={style.inputDouble}>
                    <div style={style.inputTitle}>
                        NISS*
                    </div>
                    <InputView
                        mask="99.99.99-999.99"
                        disabled={archived}
                        updateSync={handleUpdateRequest}
                        placeholder="11.11.11"
                        type="text"
                        value={check.request.workerNationalNumber}
                        onChange={(event) => handleInputChanged("request.workerNationalNumber", event.target.value)}
                    />
                </div>
            </div>

            <div className="row">
                {/* ACCIDENT DATE */}
                <div style={{ ...style.inputLeft }}>
                    <div style={style.inputTitle}>Accident date</div>
                    <InputView
                        updateSync={handleUpdateRequest}
                        disabled={archived}
                        placeholder="24/10/2020"
                        type="date"
                        value={workAccidentInformation.accidentDate}
                        onChange={(event: any) => {
                            handleDateChanged("request.workAccidentInformation.accidentDate", event.target.value);
                        }}
                    />
                </div>
                {/* ACCIDENT TIME */}
                <div style={{ ...style.inputRight }}>
                    <div style={style.inputTitle}>Accident time</div>
                    <InputView disabled={archived} updateSync={handleUpdateRequest} placeholder="8:00:00" mask="h9:m9:s9" type="text" value={workAccidentInformation.accidentTime} onChange={(event) => handleInputChanged("request.workAccidentInformation.accidentTime", event.target.value)} />
                </div>
            </div>

            {/* ACCIDENT DESCRIPTION */}
            <div className="row">
                <div style={style.inputDouble}>
                    <div style={style.inputTitle}>Accident description</div>
                    <InputView
                        disabled={archived}
                        updateSync={handleUpdateRequest}
                        type="textarea"
                        maxLength={110}
                        placeholder=""
                        value={workAccidentInformation.accidentDescription}
                        onChange={event =>
                            handleInputChanged("request.workAccidentInformation.accidentDescription", event.target.value)
                        }
                    />
                </div>
            </div>

            {/* INJURY DESCRIPTION */}
            <div className="row">
                <div style={style.inputDouble}>
                    <div style={style.inputTitle}>Injury description</div>
                    <InputView
                        disabled={archived}
                        updateSync={handleUpdateRequest}
                        type="textarea"
                        maxLength={57}
                        placeholder=""
                        value={workAccidentInformation.injuryDescription}
                        onChange={event =>
                            handleInputChanged("request.workAccidentInformation.injuryDescription", event.target.value)
                        }
                    />
                </div>
            </div>

            <div className="row">
                {/* RESULT SENT BY */}
                <div style={{ ...style.inputLeft, marginLeft: "40px" }}>
                    <div style={style.inputTitle}>Differences in injury</div>
                    <InputView
                        disabled={archived}
                        updateSync={handleUpdateRequest}
                        placeholder="Select an option"
                        type="select"
                        value={workAccidentInformation.injuryDifference ? 1 : 0}
                        onChange={(event: any) =>
                            handleInputChanged("request.workAccidentInformation.injuryDifference", event.target.value === 1)
                        }
                    >
                        <option key={-1} value={-1}></option>
                        <option key={0} value={0}>
                            No
                        </option>
                        <option key={1} value={1}>
                            Yes
                        </option>
                    </InputView>
                </div>
            </div>

            {workAccidentInformation.injuryDifference && (
                <div className="row">
                    <div style={style.inputDouble}>
                        <div style={style.inputTitle}>Initial injury description</div>
                        <InputView
                            disabled={archived}
                            updateSync={handleUpdateRequest}
                            type="textarea"
                            maxLength={57}
                            placeholder=""
                            value={workAccidentInformation.initialInjuryDescription}
                            onChange={event =>
                                handleInputChanged("request.workAccidentInformation.initialInjuryDescription", event.target.value)
                            }
                        />
                    </div>
                </div>
            )}
        </AccordeonView>
    );
};

export default WorkAccidentInformationView;
