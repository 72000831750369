export default class SwitchViewStyle
{
    switchContainer: React.CSSProperties =
    {
        cursor:'pointer', 
        alignItems:'center', 
        display:'flex', 
        backgroundColor:'white', 
        width:60, 
        height:30, 
        marginLeft:'auto', 
        borderRadius:20
    }

    selectedSpan: React.CSSProperties =
    {
        marginRight:'auto', 
        marginLeft:10, 
        color:'#009DC8', 
        fontWeight:500
    }

    selectedBall: React.CSSProperties =
    {
        height:20, 
        width:20, 
        borderRadius:10, 
        backgroundColor:'#009DC8', 
        marginLeft:'auto', 
        marginRight:5
    }

    unselectedBall: React.CSSProperties =
    {
        height:20, 
        width:20, 
        borderRadius:10, 
        backgroundColor:'#EBEDF0', 
        marginRight:'auto', 
        marginLeft:5
    }

    unselectedSpan: React.CSSProperties =
    {
        marginLeft:'auto', 
        marginRight:5, 
        color:'#EBEDF0', 
        fontWeight:500
    }
}