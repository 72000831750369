import style from './style';
import { motion } from 'framer-motion';
import React, { useState, useEffect } from 'react';
import CompanyDTO from 'src/models/CompanyDTO';
import SearchArchivesSectionProps from './props';
import { InputView } from 'src/components/inputView/InputView';
import TranslationView from 'src/components/translationView/TranslationView';
import NetworkHelper from 'src/helpers/NetworkHelper';
import CompanyService from 'src/services/CompanyService';

const SearchArchivesSection = (props: SearchArchivesSectionProps) => {

    // Attributes
    const { company, search, setCompany, setSearch } = props;
    const [companies, setCompanies] = useState([]);

    // Effects
    useEffect(() => {
        refreshCompanies();
    }, []);

    // Methods
    async function refreshCompanies() {
        setCompanies(await NetworkHelper.execute(await new CompanyService().get()));
    }

    // Rendering
    return (
        <div className="card shadow" style={style.container}>
            <div style={style.title}>
                Search for an Archived Check
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: 600, display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center' }}>
                    <div style={style.inputTitle}>
                        <TranslationView>
                            PleaseSelectACompany
                        </TranslationView>
                    </div>
                    <div style={{ width: 300, marginLeft: 40 }}>
                        <InputView placeholder="Select an option" type="select" value={company} onChange={(event: any) => setCompany(event.target.value)}>
                            {companies?.map((company: CompanyDTO) => <option value={company.id}>{company.name}</option>)}
                        </InputView>
                    </div>
                </div>
                <div style={{ marginLeft: 200, width: 700, display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center' }}>
                    <div style={style.inputTitle}>
                        <TranslationView>
                            PleaseEnterWorkerLastNameOrNISS
                        </TranslationView>
                    </div>
                    <div style={{ width: 300, marginLeft: 40 }}>
                        <InputView placeholder="Select an option" type="text" value={search} onChange={(event: any) => setSearch(event.target.value)} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchArchivesSection;