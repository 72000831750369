

function gender(gender: number)
{
    switch(gender)
    {
        case 0:
            return "Man";
        case 1:
            return "Woman";
        default:
            return "Unknown";
    }
}

const EnumHelper = {
    gender
};

export default EnumHelper;