import LogConstants from '../../../../../../constants/LogConstants'

export default class LogViewStyle {
    badgeText: React.CSSProperties =
        {
            fontSize: 14,
            fontWeight: 600,
            color: 'white'
        }

    logText: React.CSSProperties =
        {
            fontWeight: 300,
            fontSize: 12,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            color: 'white',
            marginTop: 1,
            padding: 16,
            marginLeft: 2
        }

    logTitleText: React.CSSProperties =
        {
            fontSize: 14,
            fontWeight: 400
        }

    logBodyText: React.CSSProperties =
        {
            backgroundColor: 'white',
            padding: 8,
            fontSize: 14,
            borderRadius: 6,
            color: '#68737E',
            marginBottom: 10,
            marginTop: 1,
            WebkitUserSelect: 'text'
        }

    logNoBorderBodyText: React.CSSProperties =
        {
            marginBottom: 10,
            marginTop: 1,
            fontSize: 14,
            fontWeight: 300,
            WebkitUserSelect: 'text'
        }

    userIcon: React.CSSProperties =
        {
            height: 16,
            width: 16,
            filter: "invert(99%) sepia(7%) saturate(0%) hue-rotate(351deg) brightness(109%) contrast(100%)"
        }

    basicLog: React.CSSProperties =
        {
            width: '100%'
        }

    launchLog: React.CSSProperties =
        {
            ...this.basicLog,
            marginLeft: 20
        }

    badgeContainer: React.CSSProperties =
        {
            height: 40,
            minWidth: 40,
            borderRadius: 20,
            backgroundColor: '#90A7B8',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            padding: 10,
            marginLeft: 20,
            marginTop: 5
        }

    launchLogContainer: React.CSSProperties =
        {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 15
        }

    commentLog: React.CSSProperties =
        {
            ...this.basicLog,
            marginLeft: 20
        }

    commentLogContainer: React.CSSProperties =
        {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 15
        }

    archiveCommentLog: React.CSSProperties =
        {
            ...this.basicLog,
            marginLeft: 0
        }

    archiveCommentLogContainer: React.CSSProperties =
        {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 15
        }

    basicStamp: React.CSSProperties =
        {
            color: '#C8C8C8',
            fontWeight: 300,
            fontSize: 13
        }

    basicStampRight: React.CSSProperties =
        {
            ...this.basicStamp,
            marginLeft: 'auto'
        }

    basicLogContainer: React.CSSProperties =
        {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 15
        }

    log: React.CSSProperties =
        {
            fontWeight: 500,
            fontSize: 13,
            marginLeft: 2,
            padding: 10,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            color: 'white'
        }

    commentLogText: React.CSSProperties =
        {
            ...this.log,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        }

    mapColors = ({ type, payLoad }) => {

        if (type === "0000081") {
            return { backgroundColor: '#f5365c' }
        }

        if (type === "0000063" || type === "0000062") {
            return { backgroundColor: '#85D8C8' }
        }

        if ((type === "0" || type === "1") && payLoad) {
            const targetType = JSON.parse(payLoad).targetType;

            // WORKER
            if (targetType === 0) {
                return { backgroundColor: '#FDB56D' };
            }

            // DOCTOR
            return { backgroundColor: '#85D8C8' };
        }

        // DOCTOR (APP)
        if (type === LogConstants.RESULT_RECEIVED || type === LogConstants.SECOND_CHECK_RECEIVED || type === "0000061" || type === "0000041" || type === "0000071" || type === "0000072") {
            return { backgroundColor: '#85D8C8' };
        }

        // COMPANY
        return { backgroundColor: '#9FD6E5' };
    }
}