import * as React from 'react';
import AccordeonViewStyle from './AccordeonViewStyle';
import BaseComponent from '../../../../abstracts/BaseComponent';
import AccordeonViewProps from './AccordeonViewProps';
import AccordeonViewState from './AccordeonViewState';
import { Spring } from 'react-spring';
import { motion, useAnimation } from 'framer-motion';

const AccordeonView = (props: AccordeonViewProps) => {
    /* ATTRIBUTES */
    const style = new AccordeonViewStyle();
    const { isOpenByDefault } = props;
    const [open, setOpen] = React.useState(isOpenByDefault);
    const accordeonControls = useAnimation();

    /* HANDLERS */
    function handleClicked() {
        setOpen(!open);
    }

    /* EFFETS */
    React.useEffect(() => {
        if (open) {
            accordeonControls.start({ height: 'auto', transition: { duration: 0.3 } });
        }
        else {
            accordeonControls.start({ height: 0, transition: { duration: 0.3 } });
        }
    }, [open]);

    /* METHODS */

    /* RENDER */
    return (
        <div className="card shadow" style={style.card}>

            {/* TITLE */}
            <div className="card-header border-1">

                <div style={style.cardTitle}>
                    {props.title}
                </div>

                <div onClick={handleClicked} style={style.button}>
                    {open ? '-' : '+'}
                </div>

                <div style={style.header}>
                    {props.header}
                </div>
            </div>


            <motion.div initial={{ height: 0 }} animate={accordeonControls} style={{ ...style.accordeonContent }}>
                {open && (
                    <motion.div initial={{ opacity: 0 }} transition={{ delay: 0.3 }} animate={{ opacity: 1 }} className="card-body" style={style.cardBody}>
                        {props.children}
                    </motion.div>
                )}
            </motion.div>
        </div>
    )
}

export default AccordeonView;