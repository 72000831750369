import { useEffect } from "react";

interface Props {
  timeoutDuration: number;
  onInactive: () => void;
}

export const useInactivityTracker = ({
  timeoutDuration,
  onInactive,
}: Props) => {
  let timeout: ReturnType<typeof setTimeout>;

  const resetTimer = () => {
    clearTimeout(timeout);
    startTimer();
  };

  const startTimer = () => {
    timeout = setTimeout(() => {
      onInactive();
    }, timeoutDuration);
  };

  useEffect(() => {
    startTimer();
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);

    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
    };
  }, []);
};
