import * as React from 'react';
import BaseComponent from '../../../../../../abstracts/BaseComponent';
import FileButtonViewProps from './FileButtonViewProps';
import FileButtonViewState from './FileButtonViewState';
import CheckCertificateFileService from '../../../../../../services/CheckCertificateFileService';
import CheckConvocationFileService from '../../../../../../services/CheckConvocationFileService';
import CheckResultFileService from '../../../../../../services/CheckResultFileService';

export default class FileButtonView extends BaseComponent<FileButtonViewProps, FileButtonViewState>
{
    /* ATTRIBUTE S*/
    service: any;

    /* CONSTRUCTOR */
    constructor(props: FileButtonViewProps) {
        super(props);

        this.state = { isBusy: false, clicked: false, hovered: false, deleteHovered: false, file: undefined, open: false };

        // Bindings
        this.mapImage = this.mapImage.bind(this);
        this.getBase64 = this.getBase64.bind(this);
        this.handleClicked = this.handleClicked.bind(this);
        this.handleFileChanged = this.handleFileChanged.bind(this);
        this.handleDeleteClicked = this.handleDeleteClicked.bind(this);
        this.handleDownloadClicked = this.handleDownloadClicked.bind(this);
    }

    /* LIFE CYCLE */
    async componentDidMount() {
        const { checkId, type } = this.props
        switch (type) {
            case "CONVOCATION":
                this.service = new CheckConvocationFileService();
                break;
            case "MEDICAL_CERTIFICATE":
                this.service = new CheckCertificateFileService();
                break;
            case "RESULT":
                this.service = new CheckResultFileService();
                break;
        }

        if (checkId) {
            this.setState({ file: await this.execute<string>(this.service.get(checkId)) })
        }
        else {
            this.setState({ file: '' });
        }
    }

    /* HANDLERS */
    handleClicked() {
        if (!this.props.disabled) {
            (this.refs.fileUploader as any).click();
        }
        else {
            this.danger('This check is archived.');
        }
    }

    handleFileChanged(file: any) {
        this.getBase64(file.target.files[0]);
    }

    async handleDeleteClicked() {
        if (!this.props.disabled) {
            this.props.onDeleteClicked(this.props.checkId);
            this.setState({ file: await this.execute<string>(this.service.get(this.props.checkId)) })
        }
        else {
            this.danger('This check is archived.');
        }
    }

    async handleDownloadClicked() {
        const link = document.createElement('a');
        link.download = `${this.props.fileName}${this.guessFileType(this.state.file)}`;
        link.href = `data:application/octet-stream;base64,${this.state.file}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    /* METHODS */
    getBase64(file: File) {
        const self = this;

        return new Promise((_resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                let base64 = reader.result as string;
                await self.props.onClick({ fileData: base64, fileName: file.name, fileExtension: file.name.split('.').pop() });
                self.setState({ file: await this.execute<string>(this.service.get(this.props.checkId)), hovered: false, open: false })
            }
            reader.onerror = error => reject(error);
        });
    }

    guessFileType(base64: string) {
        var data = base64.substring(0, 5);

        switch (data.toUpperCase()) {
            case "IVBOR":
                return ".png";
            case "/9J/4":
                return ".jpg";
            case "AAAAF":
                return ".mp4";
            case "JVBER":
                return ".pdf";
            case "AAABA":
                return ".ico";
            case "UMFYI":
                return ".rar";
            case "E1XYD":
                return ".rtf";
            case "U1PKC":
                return ".txt";
            case "MQOWM":
            case "77U/M":
                return ".srt";
            default:
                return "";
        }
    }

    mapImage() {
        switch (this.props.type) {
            case "MEDICAL_CERTIFICATE":
                return "/assets/button_download_certif.png";

            case "CONVOCATION":
                return "/assets/button_download_convocation.png";

            case "RESULT":
                return "/assets/button_download_result.png";
        }
    }

    mapEmptyImage() {
        if (this.state.hovered) {
            return "/assets/button_upload_hovered.png";
        }

        switch (this.props.type) {
            case "MEDICAL_CERTIFICATE":
                return "/assets/button_upload_certif.png";

            case "CONVOCATION":
                return "/assets/button_upload_convocation.png";

            case "RESULT":
                return "/assets/button_upload_result.png";
        }
    }

    /* RENDERING */
    render() {
        return (
            <>
                {this.state.file !== undefined
                    ?
                    this.state.file !== ''
                        ?
                        (
                            <div onMouseLeave={() => this.setState({ open: false })} style={{ display: 'flex', flexDirection: 'row', width: this.state.open ? 75 : 40 }}>
                                {this.state.open
                                    ?
                                    (
                                        <>
                                            {/* DELETE */}
                                            <div onMouseOver={() => this.setState({ deleteHovered: true })} onMouseOut={() => this.setState({ deleteHovered: false })} onClick={this.handleDeleteClicked} style={{ height: 30, width: 30, borderRadius: 15, marginRight: 5, cursor: 'pointer' }}>
                                                <img style={{ height: 30, width: 30, borderRadius: 15 }} src={this.state.deleteHovered ? '/assets/button_delete_certif_hovered.png' : '/assets/button_delete_certif.png'} />
                                            </div>

                                            {/* DOWNLOAD */}
                                            <div onClick={this.handleDownloadClicked} onMouseOver={() => this.setState({ hovered: true })} onMouseOut={() => this.setState({ hovered: false })} style={{ height: 30, width: 30, borderRadius: 15, marginRight: 10, cursor: 'pointer' }}>
                                                <img style={{ height: 30, width: 30, borderRadius: 15 }} src={this.state.hovered ? '/assets/button_download_certif_hovered.png' : '/assets/button_download_normal.png'} />
                                            </div>
                                        </>
                                    )
                                    :
                                    (
                                        <div onMouseOver={() => this.setState({ open: true })} style={{ height: 30, width: 30, borderRadius: 15, marginRight: 10, cursor: 'pointer' }}>
                                            <img style={{ height: 30, width: 30, borderRadius: 15 }} src={this.mapImage()} />
                                        </div>
                                    )
                                }

                            </div>
                        )
                        :
                        (
                            <div onMouseOver={() => this.setState({ hovered: true })} onMouseOut={() => this.setState({ hovered: false })} onClick={this.handleClicked} style={{ height: 30, width: 30, borderRadius: 15, marginRight: 10, cursor: 'pointer' }}>
                                <img style={{ height: 30, width: 30, borderRadius: 15 }} src={this.mapEmptyImage()} />
                            </div>
                        )
                    :
                    (
                        <div />
                    )
                }
                <input type="file" id="file" ref="fileUploader" onChange={this.handleFileChanged} style={{ display: "none" }} />
            </>
        );
    }
}