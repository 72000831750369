import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import SettingView from './modules/list/index';
import SettingDetailView from './modules/detail/index';


export default class SettingShellView extends React.Component {
	/* RENDERING */
	render() {
		return (
			<Switch>
				<Route exact path="/dashboard/setting" render={() => <SettingView />} />
				<Route path="/dashboard/setting/detail" render={() => <SettingDetailView />} />
				<Route path="/dashboard/setting" render={() => <Redirect to="/dashboard/setting" />} />
			</Switch>
		);
	}
}

