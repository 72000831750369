import React, { useEffect, useState } from 'react';
import { App } from 'src/app';
import NetworkHelper from 'src/helpers/NetworkHelper';
import UserDTO from 'src/models/UserDTO';
import CompanyService from 'src/services/CompanyService';
import UserCompanyService from 'src/services/UserCompanyService'
import SaveButton from './components/save-button';
import Form from './components/form';
import DetailView from 'src/modules/dashboard/components/detailView/DetailView';
import Header from './components/header';
import { handleSaved } from './utils';
import UserService from 'src/services/UserService';


const Detail = () => {

    // Attributes
    const [user, setUser] = useState<UserDTO>(new UserDTO())
    const [errors, setErrors] = useState({})
    const [userCompanies, setUserCompanies] = useState([])
    const [companies, setCompanies] = useState([])
    const [fields, setFields] = useState([])
    const userService = new UserService()
    const userCompanyService = new UserCompanyService()

    // Effect
    useEffect(() => {

        fetchCompanies()

        if (App.history.location.state != undefined) {
            setUser(App.history.location.state.user);
            fetchUserCompanies()
        }
    }, [])


    // Methods
    function handleInputChanged(attribute: string, value: string) {
        setErrors({ [attribute]: false })
        setUser(previous => {
            return {
                ...previous,
                [attribute]: value
            }
        })
        setFields(previous => [...previous, attribute])
    }

    async function fetchUserCompanies() {
        const userCompanies = await NetworkHelper.execute(new UserCompanyService().get(App.history.location.state.user.id))
        setUserCompanies(userCompanies)
    }

    async function fetchCompanies() {
        const companies = await NetworkHelper.execute(new CompanyService().get())
        setCompanies(companies)
    }

    return (
        <DetailView handleSaved={() => handleSaved({ user, userCompanies, errors, setErrors, userCompanyService, userService })} fields={fields} className="col" style={{ width: 1047, marginTop: 0 }}>
            <Header user={user} />
            <div>
                <Form
                    user={user}
                    errors={errors}
                    companies={companies}
                    handleInputChanged={handleInputChanged}
                    userCompanies={userCompanies}
                    setUserCompanies={setUserCompanies}
                />
                <SaveButton
                    user={user}
                    setErrors={setErrors}
                    userCompanies={userCompanies}
                    errors={errors}
                />
            </div>
        </DetailView>

    )
}

export default Detail