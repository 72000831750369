import * as React from "react";
import CompanyServiceViewState from "./CompanyServiceViewState";
import CompanyServiceService from "../../../../../../services/CompanyServiceService";
import { App } from "../../../../../../app";
import { Modal } from "reactstrap";
import ListView from "../../../../components/listView/ListView";
import ServiceDTO from "../../../../../../models/ServiceDTO";
import { InputView } from "../../../../../../components/inputView/InputView";
import CompanyServiceViewStyle from "./CompanyServiceViewStyle";
import ServiceService from "../../../../../../services/ServiceService";
import HeaderView from "../../../../components/headerView/HeaderView";
import SaveButtonView from "../../../../components/saveButtonView/SaveButtonView";
import CompanyCreditService from "../../../../../../services/CompanyCreditService";
import CompanyCreditDTO from "../../../../../../models/CompanyCreditDTO";
import DetailComponent from "../../../../abstracts/detailComponent/DetailComponent";
import JsonToCsvExportHelper from "./helpers/JsonToCsvExportHelper";
import DetailView from "../../../../components/detailView/DetailView";
import TranslationView from "../../../../../../components/translationView/TranslationView";
import CompanyNavView from "../../components/companyNavView/index";
import CompanyWalletService from "../../../../../../services/CompanyWalletService";
import { LocalizationContext } from "src/components/localizationContext/LocalizationContext";
import UserContext from "src/components/userContext/UserContext";
import format from "date-fns/format";
import ToggleView from "src/modules/dashboard/components/toggleView/ToggleView";

export default class CompanyServiceView extends DetailComponent<
  {},
  CompanyServiceViewState
> {
  /* ATTRIBUTES */
  style = new CompanyServiceViewStyle();
  service = new ServiceService();
  companyCreditsService = new CompanyCreditService();
  companyServiceService = new CompanyServiceService();

  /* CONSTRUCTOR */
  constructor(props: {}) {
    super(props);

    // Initial State
    this.state = {
      element: new CompanyCreditDTO(),
      elements: [],
      credits: 0,
      errors: {},
      services: [],
      isBusy: false,
      modal: false,
      fields: [],
      previousMonthTransactionsAmount: 0,
    };
    this.setState({ element: { ...this.state.element, amountOfCredit: 0 } });

    // Handlers
    this.handleSaved = this.handleSaved.bind(this);
    this.mapServices = this.mapServices.bind(this);
    this.mapCompanyCredits = this.mapCompanyCredits.bind(this);
    this.mapServiceHeaders = this.mapServiceHeaders.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleConfirmCredits = this.handleConfirmCredits.bind(this);
    this.handleAmountInputChanged = this.handleAmountInputChanged.bind(this);
    this.handleConfirmCreditsClicked =
      this.handleConfirmCreditsClicked.bind(this);
    this.handleDownloadCreditHistory =
      this.handleDownloadCreditHistory.bind(this);
  }

  /* LIFE CYCLE EVENTS */
  componentDidMount() {
    this.handleRefresh();
  }

  /* HANDLERS */
  async handleRefresh() {
    // Destructuring
    const { company } = App.history.location.state;

    // Server calls
    let services = await this.execute<ServiceDTO[]>(this.service.get());
    const companyServices = await this.execute(
      this.companyServiceService.get(company.id)
    );
    let companyCredits = await this.execute<CompanyCreditDTO[]>(
      this.companyCreditsService.get(company.id)
    );
    const companyWallet = await this.execute(
      new CompanyWalletService().get(company.id)
    );

    services = services
      .filter((elt: ServiceDTO) => elt.type == 0)
      ?.map((service: ServiceDTO, index: number) => {
        service.serviceId = service.id;
        service.companyId = company.id;
        service.id = `${index}`;
        service.existing = false;

        const companyService = companyServices.find(
          (elt: any) => elt.serviceId === service.serviceId
        );
        if (companyService !== undefined) {
          service.isActive = companyService.isActive;
          service.price = companyService.price;
          service.percentage = companyService.percentage;
          service.id = companyService.id;
          service.existing = true;
        }
        service.price = this.mapPrice(service.price);
        service.percentage = this.mapPercentage(service.percentage);
        return service;
      });

    companyCredits = companyCredits.sort(
      (eltA, eltB) =>
        new Date(eltB.creationDate).getTime() -
        new Date(eltA.creationDate).getTime()
    );

    this.setState({
      services,
      elements: companyCredits,
      credits: companyWallet.amount,
      previousMonthTransactionsAmount: companyWallet.previousMonthAmount,
    });
  }

  handleInputChanged(target: string, value: string) {
    switch (target) {
      case "packType":
        this.setState({ errors: {} }, () =>
          super.handleInputChanged(target, value)
        );
        break;
      case "amountOfCredit":
        super.handleInputChanged(target, value.toString().replace(",", "."));
        break;
      default:
        super.handleInputChanged(target, value);
        break;
    }
  }

  handleDownloadCreditHistory() {
    const { company } = App.history.location.state;

    JsonToCsvExportHelper.download(
      { 0: "Date", 1: "Credit", 2: "Justification", 3: "Total Credits" },
      this.state.elements?.map(this.mapCompanyCredits).reverse(),
      company.name
    );

    this.success("Download succesfull");
  }

  mapPercentage(percentage: string): string {
    if (!percentage) {
      return "00.00%";
    }

    let floatPercentage = parseFloat(percentage);
    if (isNaN(floatPercentage)) {
      return "00.00%";
    }
    // Format to two decimal places and ensure two digits in the integer part
    const formattedPercentage = floatPercentage.toFixed(2).padStart(5, "0");
    return `${formattedPercentage}%`;
  }

  mapCompanyCredits(element: CompanyCreditDTO) {
    let justification = "";
    let justificationText = "";
    const totalCredits = this.state.elements
      .filter(
        (elt) => new Date(elt.creationDate) <= new Date(element.creationDate)
      )
      ?.map((elt) => elt.amountOfCredit)
      .reduce((total, elt) => total + elt);

    switch (element.packType) {
      case 0:
        justification = `New Pack (PO# : ${element.purchaseOrderNumber})`;
        break;

      case 1:
        justificationText = "";

        switch (element.justification) {
          case 4:
            justificationText = "Expired credits";
            break;

          case 5:
            justificationText = "Commercial gesture on expired credits";
            break;

          case 6:
            justificationText = "MyCertificate fees";
            break;

          case 7:
            justificationText = "Interface & other setup fees";
            break;

          case 8:
            justificationText = "Correction of an encoding error";
            break;
        }

        justification = `${justificationText} (${element.additionalDetails})`;
        break;

      default:
        justification = `${element.additionalDetails}`;
        break;
    }

    return {
      0: format(new Date(element.creationDate), "dd-MM-yyyy (HH:mm:SS)"),
      1: element.amountOfCredit.toFixed(2).replace(".", ","),
      2: justification,
      3: totalCredits.toFixed(2).replace(".", ","),
    };
  }

  handleConfirmCreditsClicked() {
    // Destructuring
    const { element } = this.state;
    const errors = {};

    switch (element.packType) {
      case 0:
        if (element.amountOfCredit === undefined) {
          errors["amountOfCredit"] = true;
        }

        if (element.startDate === undefined) {
          errors["startDate"] = true;
        }

        if (element.endDate === undefined) {
          errors["endDate"] = true;
        }

        if (
          element.purchaseOrderNumber === undefined ||
          element.purchaseOrderNumber === ""
        ) {
          errors["purchaseOrderNumber"] = true;
        }
        break;

      case 1:
        if (element.amountOfCredit === undefined) {
          errors["amountOfCredit"] = true;
        }

        if (element.justification === undefined) {
          errors["justification"] = true;
        }

        if (
          element.additionalDetails === undefined ||
          element.additionalDetails === ""
        ) {
          errors["additionalDetails"] = true;
        }
        break;

      case 2:
        if (element.amountOfCredit === undefined) {
          errors["amountOfCredit"] = true;
        }

        if (element.justification === undefined) {
          errors["justification"] = true;
        }

        if (
          element.additionalDetails === undefined ||
          element.additionalDetails === ""
        ) {
          errors["additionalDetails"] = true;
        }
        break;

      default:
        this.danger("Choose a pack type.");
        return;
    }

    if (Object.keys(errors).filter((elt) => errors[elt] === true).length > 0) {
      this.setState({ errors: errors });
    } else {
      this.setState({ modal: true });
    }
  }

  async handleConfirmCredits() {
    // Destructuring
    const { company } = App.history.location.state;
    const { element } = this.state;
    this.setState({ isBusy: true });

    // Pack type === withdrawal
    if (element.packType === 2) {
      element.amountOfCredit = -element.amountOfCredit;
    }

    await this.execute(this.companyCreditsService.post(company.id, element));
    this.success(`${element.amountOfCredit} credits added.`);

    const newCompanyCreditDTO: CompanyCreditDTO = {
      amountOfCredit: 0,
      packType: 0,
      startDate: "",
      endDate: "",
      purchaseOrderNumber: "",
      justification: undefined,
      additionalDetails: "",
      companyId: "",
      creationDate: "",
    };

    const companyCredits = await this.execute<CompanyCreditDTO[]>(
      this.companyCreditsService.get(company.id)
    );
    this.setState({
      elements: companyCredits,
      element: newCompanyCreditDTO,
      modal: false,
      fields: [],
      isBusy: false,
    });
    this.handleRefresh();
  }

  async handleSaved() {
    // Destructuring
    const { company } = App.history.location.state;

    // Server calls
    const companyServicePromises = this.state.services?.map(
      (service: ServiceDTO) => {
        if (service.price) {
          service.price = service.price.replace(" €", "");
          service.price = service.price.replace(/_/g, "");
        }
        if (service.percentage) {
          service.percentage = `${service.percentage}`;
          service.percentage = service.percentage
            .replace("%", "")
            .replace(/_/g, "");
        }

        if (!service.existing) {
          return this.companyServiceService.post(company.id, service);
        }

        return this.companyServiceService.put(company.id, service);
      }
    );
    await this.execute(Promise.all(companyServicePromises));
    await this.handleRefresh();
    this.setState({ fields: [] }, () =>
      this.success("Company Services updated.")
    );
  }

  handleServiceActiveChanged(element: ServiceDTO, isActive: string) {
    this.setState((previousState) => {
      const service = previousState.services.find(
        (service) => service.id === element.id
      );
      if (service != undefined) {
        service.isActive = isActive === "true" ? true : false;
      }

      previousState.fields.push(element.id);

      return previousState;
    });
  }

  handleServicePriceChanged(element: ServiceDTO, value: string) {
    this.setState((previousState) => {
      const service = previousState.services.find(
        (service) => service.id == element.id
      );
      if (service != undefined) {
        if (
          service.name === "Additional percentage Sunday (ie 33,33%)" ||
          service.name === "Additional percentage Saturday (ie 33,33%)"
        ) {
          service.percentage = value;
        } else {
          service.price = value;
        }
      }

      previousState.fields.push(element.id);

      return previousState;
    });
  }

  handleAmountInputChanged(event: any) {
    this.handleInputChanged("amountOfCredit", event.target.value);
  }

  /* METHODS */
  mapServiceHeaders() {
    return (
      <tr>
        <th>
          <TranslationView>serviceName</TranslationView>
        </th>
        <th>
          <TranslationView>serviceActive</TranslationView>
        </th>
        <th>
          <TranslationView>servicePrice</TranslationView>
        </th>
      </tr>
    );
  }

  mapPrice(price: string) {
    if (!price) {
      return "000.00";
    }

    // Little hack to be certain price is a string
    price = `${price}`;

    if (price && price !== "___.__ €") {
      const splitPrice = price.replace(" €", "").replace(/_/g, "").split(".");

      if (splitPrice && splitPrice.length > 0) {
        for (var i = 0; i < 3 - splitPrice[0].length; i++) {
          price = "0" + price;
        }

        if (splitPrice[1]) {
          for (var i = 0; i < 2 - splitPrice[1].length; i++) {
            price = price + "0";
          }
        } else {
          price = price + ".00";
        }
      } else {
        price = "000.00";
      }
    }

    return price;
  }

  mapServices(service: ServiceDTO, index: number) {
    const isPercentage =
      service.name === "Additional percentage Sunday (ie 33,33%)" ||
      service.name === "Additional percentage Saturday (ie 33,33%)";
    return (
      <tr key={index}>
        <th>
          <span style={this.style.service}>{service.name}</span>
        </th>
        <th>
          <ToggleView
            isToggled={service.isActive}
            handleIsToggled={(event) =>
              this.handleServiceActiveChanged(service, `${event}`)
            }
          />
        </th>
        <th>
          {service.name === "MyCertificate (€/month)" ||
          service.name === "Interface (€/month)" ? (
            <></>
          ) : (
            <InputView
              disabled={this.state.isBusy}
              placeholder={isPercentage ? "33.33%" : "0.00€"}
              type="text"
              mask={isPercentage ? "99.99%" : "999.99 €"}
              value={isPercentage ? service.percentage : service.price}
              onChange={(event) =>
                this.handleServicePriceChanged(service, event?.target?.value)
              }
            />
          )}
        </th>
      </tr>
    );
  }

  /* RENDERING */
  render() {
    const { element, credits, previousMonthTransactionsAmount } = this.state;

    return (
      <LocalizationContext.Consumer>
        {({ translate }) => (
          <UserContext.Consumer>
            {({ user }) => (
              <div className="col">
                {/* PAGE HEADER */}
                <HeaderView
                  title={`${App.history.location.state.company.name} credits & pricing`}
                  history={[
                    { name: "Companies", path: "/dashboard/company" },
                    {
                      name: App.history.location.state.company.name,
                      path: "/dashboard/company/detail",
                      state: App.history.location.state,
                    },
                    {
                      name: `${App.history.location.state.company.name} credits & pricing`,
                      path: "/dashboard/company/service",
                      state: App.history.location.state,
                    },
                  ]}
                >
                  <CompanyNavView
                    type={"services"}
                    company={App.history.location.state.company}
                  />
                </HeaderView>

                {/* CREDITS MANAGEMENT */}
                <div className="card shadow" style={this.style.card}>
                  {/* TITLE */}
                  <div
                    className="card-header border-1"
                    style={this.style.cardHeader}
                  >
                    {/* CARD HEADER TITLE */}
                    <span style={this.style.cardHeaderTitle}>
                      <TranslationView>
                        serviceCreditsManagement
                      </TranslationView>
                    </span>

                    {/* DOWNLOAD CREDIT HISTORY */}
                    <button
                      disabled={this.state.isBusy}
                      onClick={this.handleDownloadCreditHistory}
                      type="button"
                      className="btn btn-base btn-base-primary"
                      style={this.style.creditHistoryButton}
                    >
                      <span>
                        <img
                          className="img-base-primary"
                          src="/assets/icon_credits.svg"
                          style={this.style.buttonImage}
                        />
                        <span style={this.style.buttonText}>
                          <TranslationView>
                            serviceDownloadCreditHistory
                          </TranslationView>
                        </span>
                      </span>
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {/* CREDIT BALANCE */}
                      <div style={this.style.inputLeft}>
                        <div style={this.style.inputTitle}>
                          <TranslationView>
                            serviceCreditBalance
                          </TranslationView>
                        </div>
                        <InputView
                          disabled
                          placeholder="0"
                          type="text"
                          value={credits?.toFixed(2)}
                          onChange={() => {}}
                        />
                      </div>

                      {/* CURRENT MONTHS TOTAL TRANSACTIONS AMOUNT */}
                      <div style={this.style.inputLeft}>
                        <div style={this.style.inputTitle}>
                          <TranslationView>
                            servicePreviousMonthsTransactionsAmount
                          </TranslationView>
                        </div>
                        <InputView
                          disabled
                          placeholder="0"
                          type="text"
                          value={previousMonthTransactionsAmount?.toFixed(2)}
                          onChange={() => {}}
                        />
                      </div>

                      {/* ADD CREDITS */}
                      <div style={this.style.inputDouble}>
                        <div style={this.style.inputTitle}>
                          <TranslationView>serviceAddCredits</TranslationView>
                        </div>
                        <InputView
                          placeholder="Select an option"
                          type="select"
                          value={element.packType}
                          onChange={(event) =>
                            this.handleInputChanged(
                              "packType",
                              event.target.value
                            )
                          }
                        >
                          <option value={0}>
                            {translate("serviceAddCreditsNewPack", "en")}
                          </option>
                          <option value={1}>
                            {translate(
                              "serviceAddCreditsNewExceptionnalCredits",
                              "en"
                            )}
                          </option>
                        </InputView>
                      </div>

                      {/* PLACEHOLDER */}
                      <div style={{ ...this.style.inputLeft }} />
                      <div style={{ ...this.style.inputRight }} />
                    </div>

                    <div className="row">
                      {/* AMOUNT OF CREDITS */}
                      <div style={this.style.inputLeft}>
                        <div style={this.style.inputTitle}>
                          <TranslationView>
                            serviceAmountOfCredits
                          </TranslationView>
                        </div>
                        <InputView
                          error={this.state.errors["amountOfCredit"]}
                          disabled={element.packType === undefined}
                          placeholder="0.00"
                          type="text"
                          value={
                            element.amountOfCredit && element?.amountOfCredit
                          }
                          onChange={this.handleAmountInputChanged}
                        />
                      </div>

                      {/* START DATE */}
                      <div style={this.style.inputRight}>
                        <div style={this.style.inputTitle}>
                          <TranslationView>serviceStartDate</TranslationView>
                        </div>
                        <InputView
                          error={this.state.errors["startDate"]}
                          disabled={
                            element.packType === 1 ||
                            element.packType === 2 ||
                            element.packType === undefined
                          }
                          placeholder="01/01/2020"
                          type="date"
                          value={element.startDate}
                          onChange={(event) =>
                            this.handleInputChanged(
                              "startDate",
                              event.target.value
                            )
                          }
                        />
                      </div>

                      {/* START DATE */}
                      <div style={this.style.inputLeft}>
                        <div style={this.style.inputTitle}>
                          <TranslationView>serviceEndDate</TranslationView>
                        </div>
                        <InputView
                          error={this.state.errors["endDate"]}
                          disabled={
                            element.packType === 1 ||
                            element.packType === 2 ||
                            element.packType === undefined
                          }
                          placeholder="01/01/2020"
                          type="date"
                          value={element.endDate}
                          onChange={(event) =>
                            this.handleInputChanged(
                              "endDate",
                              event.target.value
                            )
                          }
                        />
                      </div>

                      {/* PURCHASE ORDER NUMBER */}
                      <div style={this.style.inputRight}>
                        <div style={this.style.inputTitle}>
                          <TranslationView>
                            servicePurchaseOrderNumber
                          </TranslationView>
                        </div>
                        <InputView
                          error={this.state.errors["purchaseOrderNumber"]}
                          disabled={
                            element.packType === 1 ||
                            element.packType === 2 ||
                            element.packType === undefined
                          }
                          placeholder="0"
                          type="text"
                          value={element.purchaseOrderNumber}
                          onChange={(event) =>
                            this.handleInputChanged(
                              "purchaseOrderNumber",
                              event.target.value
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="row">
                      {/* JUSTIFICATION */}
                      <div style={this.style.inputDouble}>
                        <div style={this.style.inputTitle}>
                          <TranslationView>
                            serviceJustification
                          </TranslationView>
                        </div>
                        <InputView
                          error={this.state.errors["justification"]}
                          disabled={
                            element.packType === 0 ||
                            element.packType === undefined
                          }
                          placeholder="Select an option"
                          type="select"
                          value={element.justification}
                          onChange={(event) =>
                            this.handleInputChanged(
                              "justification",
                              event.target.value
                            )
                          }
                        >
                          <option value={4}>Expired credits</option>
                          <option value={5}>
                            Commercial gesture on expired credits
                          </option>
                          <option value={6}>MyCertificate fees</option>
                          <option value={7}>
                            Interface & other setup fees
                          </option>
                          <option value={8}>
                            Correction of an encoding error
                          </option>
                        </InputView>
                      </div>

                      {/* ADDITIONAL DETAILS */}
                      <div style={this.style.inputDouble}>
                        <div style={this.style.inputTitle}>
                          <TranslationView>
                            serviceAdditionalDetails
                          </TranslationView>
                        </div>
                        <InputView
                          error={this.state.errors["additionalDetails"]}
                          disabled={
                            element.packType === 0 ||
                            element.packType === undefined
                          }
                          placeholder="Give more details"
                          type="text"
                          value={element.additionalDetails}
                          onChange={(event) =>
                            this.handleInputChanged(
                              "additionalDetails",
                              event.target.value
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="row">
                      <button
                        onClick={this.handleConfirmCreditsClicked}
                        type="button"
                        className="btn btn-base btn-base-primary"
                        style={this.style.button}
                      >
                        <span>
                          <img
                            className="img-base-primary"
                            src="/assets/icon_credits.svg"
                            style={this.style.buttonImage}
                          />
                          <span style={this.style.buttonText}>
                            <TranslationView>
                              serviceConfirmButton
                            </TranslationView>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                {/* PRICING INFORMATION */}
                <DetailView
                  fields={this.state.fields}
                  handleSaved={this.handleSaved}
                  className="card shadow"
                  style={this.style.card}
                >
                  {/* TITLE */}
                  <div
                    className="card-header border-1"
                    style={this.style.cardHeader}
                  >
                    <TranslationView>servicePricingInformation</TranslationView>
                  </div>
                  <ListView
                    isBusy={this.state.services.length == 0}
                    overflow="visible"
                    elements={this.state.services}
                    mapElements={this.mapServices}
                    mapHeaders={this.mapServiceHeaders}
                  />
                </DetailView>

                {/* BUTTON CONTAINER */}
                <div style={this.style.container}>
                  <SaveButtonView
                    isBusy={this.state.isBusy}
                    handleSaved={this.handleSaved}
                  />
                </div>

                {/* POP UP */}
                <Modal
                  className="modal-dialog-centered"
                  isOpen={this.state.modal}
                  toggle={() => this.setState({ modal: !this.state.modal })}
                  style={{ height: 535, width: 400, textAlign: "center" }}
                >
                  <div style={{ height: 535, width: 400, padding: 22 }}>
                    {/* CLOSE BUTTON */}
                    <div
                      onClick={() =>
                        this.setState({ modal: !this.state.modal })
                      }
                      style={{
                        cursor: "pointer",
                        height: 25,
                        width: 15,
                        padding: 0,
                        marginLeft: "auto",
                      }}
                    >
                      <img
                        style={{ height: 25, width: 25, marginLeft: -5 }}
                        src="/assets/icon_cross.png"
                      />
                    </div>

                    {/* ILLUSTRATION */}
                    <img
                      className="row"
                      style={{ margin: "auto", height: 250, width: 250 }}
                      src="/assets/illu_credit_confirmation.png"
                    />

                    {/* NAME */}
                    <div
                      className="row"
                      style={{
                        color: "#85D8C8",
                        fontWeight: 500,
                        fontSize: 16,
                        margin: "auto",
                        width: "100%",
                        textAlign: "center",
                        marginTop: 25,
                      }}
                    >
                      <span style={{ margin: "auto" }}>
                        Hey {user?.firstName}
                      </span>
                    </div>

                    {/* VALIDATION */}
                    <div
                      className="row"
                      style={{
                        width: 300,
                        fontSize: 18,
                        fontWeight: 300,
                        color: "#405364",
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      {`Are you sure you want to add ${this.state.element.amountOfCredit} credits for this client?`}
                    </div>

                    {/* SAVE BUTTON */}
                    <div className="row">
                      <button
                        disabled={this.state.isBusy}
                        onClick={this.handleConfirmCredits}
                        type="button"
                        className="btn btn-base btn-base-credit"
                        style={this.style.creditButton}
                      >
                        <span>
                          <img
                            src="/assets/icon_credits.svg"
                            style={this.style.creditButtonImage}
                          />
                          <span
                            className="roboto"
                            style={this.style.buttonText}
                          >
                            <TranslationView>
                              serviceConfirmCreditsPopUp
                            </TranslationView>
                          </span>
                        </span>
                      </button>
                    </div>

                    {/* DON'T SAVE */}
                    <div className="row" style={{ marginTop: 15 }}>
                      <span
                        onClick={() =>
                          this.setState({ modal: !this.state.modal })
                        }
                        style={{
                          margin: "auto",
                          textAlign: "center",
                          color: "#7D91A0",
                          fontWeight: 300,
                          fontSize: 16,
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        <TranslationView>oopsNoGoBack</TranslationView>
                      </span>
                    </div>
                  </div>
                </Modal>
              </div>
            )}
          </UserContext.Consumer>
        )}
      </LocalizationContext.Consumer>
    );
  }
}
