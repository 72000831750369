import Service from './Service';
import Axios from 'axios';

export default class RequestLogService extends Service
{
    get(requestId: string, logId?: string)
    {
        if(logId)
        {
            return this.execute(Axios.get(`${this.url}/requests/${requestId}/logs/${logId}`, this.config()));
        }
        return this.execute(Axios.get(`${this.url}/requests/${requestId}/logs`, this.config()));
    }
}