import Service from './Service';
import Axios from 'axios';
import MessageDTO from 'src/models/MessageDTO';

export default class CheckMessageService extends Service {
    get(checkId: string) {
        return this.execute(Axios.get(`${this.getUrl("v2")}/checks/${checkId}/messages`, this.config()));
    }

    post(checkId: string, message: MessageDTO) {
        return this.execute(Axios.post(`${this.getUrl("v2")}/checks/${checkId}/messages`, message, this.config()));
    }
}