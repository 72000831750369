import React, { useState } from "react";
import { useUser } from "src/components/userContext/UserContext";
import CheckTableRowProps from "./props";
import { useTranslation } from "src/components/localizationContext/LocalizationContext";
import { CheckStatus } from "src/constants/CheckStatus";
import CheckDTO from "src/models/CheckDTO";
import CheckViewStyle from "../../style";
import CheckAssignService from "src/services/CheckAssignService";
import NetworkHelper from "src/helpers/NetworkHelper";
import DateHelper from "src/helpers/DateHelper";

const CheckTableRow = (props: CheckTableRowProps) => {
  // Attributes
  const { check, currentCheck, setCurrentCheck, handleNavigateClicked } = props;
  const style = new CheckViewStyle();
  const { user } = useUser();
  const { translate } = useTranslation();
  const [isAssignHovered, setIsAssignHovered] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const isCurrentCheck = currentCheck === check.id;

  // Methods
  function normalizeDoctorName(check: CheckDTO) {
    const doctor = check.doctor;

    if (doctor) {
      return `${check.doctor.firstName} ${check.doctor.lastName}`;
    }

    return "ERROR";
  }

  async function handleAssignClicked(check: CheckDTO) {
    await NetworkHelper.execute(new CheckAssignService().put(check));
  }

  function getLocationTranslation(location) {
    switch (location) {
      case 0:
        return "checkPatientLocation";
      case 1:
        return "checkDoctorLocation";
      case 2:
        return "checkTelecheckLocation";
      default:
        return "checkPatientLocation";
    }
  }

  return (
    <tr
      onMouseEnter={() => setCurrentCheck(check)}
      style={{
        opacity: user.id === check.userId || !check.userId ? 1 : 0.5,
      }}
    >
      <td>
        {check.state === CheckStatus.CHECK_AUTOMATIC_PAUSED ? (
          <img style={{ height: 13, width: 13 }} src="assets/icon_pause.svg" />
        ) : (
          <div style={style.checkStatus(check.state)} />
        )}
      </td>
      <td
        style={{ ...style.td, color: check.hasDuplicate ? "#fbb56c" : "" }}
        onCopy={() =>
          alert({
            type: "success",
            message: "Copied to Clipboard",
          })
        }
      >
        {`${check.request.worker.firstName} ${check.request.worker.lastName}`}
      </td>
      <td
        style={style.td}
        onCopy={() =>
          alert({
            type: "success",
            message: "Copied to Clipboard",
          })
        }
      >
        {check.request.company.name}
      </td>
      <td
        style={style.td}
        onCopy={() =>
          alert({
            type: "success",
            message: "Copied to Clipboard",
          })
        }
      >
        {normalizeDoctorName(check)}
      </td>
      <td
        style={style.td}
        onCopy={() =>
          alert({
            type: "success",
            message: "Copied to Clipboard",
          })
        }
      >
        {check.secondCheckNeeded && check.secondCheckDate
          ? `${check.secondCheckDate?.split("T")[0]?.split("-")[2]}/${
              check.secondCheckDate?.split("T")[0]?.split("-")[1]
            }/${check.secondCheckDate?.split("T")[0]?.split("-")[0]}`
          : `${check.checkDate.split("T")[0].split("-")[2]}/${
              check.checkDate.split("T")[0].split("-")[1]
            }/${check.checkDate.split("T")[0].split("-")[0]}`}
      </td>
      <td
        style={style.td}
        onCopy={() =>
          alert({
            type: "success",
            message: "Copied to Clipboard",
          })
        }
      >
        {check.secondCheckNeeded
          ? check.secondCheckTime
          : DateHelper.formatHour(check.checkDate)}
      </td>
      <td
        style={style.td}
        onCopy={() =>
          alert({
            type: "success",
            message: "Copied to Clipboard",
          })
        }
      >
        {translate(getLocationTranslation(check.request.location), "en")}
      </td>
      <td
        onMouseEnter={() => {
          setIsAssignHovered(true);
          setCurrentCheck(check.id);
        }}
        onMouseLeave={() => {
          setIsAssignHovered(false);
          setCurrentCheck("");
        }}
        style={{ textAlign: "center" }}
      >
        {check.operatorInitials ? (
          <>
            {isAssignHovered && isCurrentCheck ? (
              <div
                onClick={() => handleAssignClicked(check)}
                style={style.assignButtonContainer}
              >
                <img
                  src={`/assets/icon_assign_to_me${
                    isAssignHovered ? "_selected" : ""
                  }_listing.svg`}
                  style={style.assignButton}
                />
              </div>
            ) : (
              <span style={style.operatorStyle}>{check.operatorInitials}</span>
            )}
          </>
        ) : (
          <div style={style.assignButtonContainer}>
            <img
              src={`/assets/icon_assign_to_me${
                isAssignHovered && isCurrentCheck ? "_selected" : ""
              }_listing.svg`}
              style={style.assignButton}
            />
          </div>
        )}
      </td>
      <td style={{ textAlign: "center" }}>
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => handleNavigateClicked(check)}
          style={style.buttonContainer}
        >
          <img
            src={`/assets/icon_open${
              isHovered && currentCheck === check.id ? "_selected" : ""
            }.svg`}
            style={style.button}
          />
        </div>
      </td>
    </tr>
  );
};

export default CheckTableRow;
