import React from 'react';
import { motion } from 'framer-motion';
import HeaderView from '../../../headerView/HeaderView';

const SearchViewTitle = ({ headerChildren, title, history }: any) => {
    return (
        <motion.div initial={{ y: -20 }} animate={{ y: 0 }}>
            <HeaderView title={title} history={history}>
                {headerChildren && headerChildren}
            </HeaderView>
        </motion.div>
    )
}

export default SearchViewTitle;