import Service from './Service';
import Axios from 'axios';
import ServiceDTO from '../models/ServiceDTO';

export default class DoctorServiceService extends Service
{
    /* METHODS */
    get(id:string)
    {
        return this.execute(Axios.get(`${this.url}/doctors/${id}/services`, this.config()));
    }

    post(doctorId: string, body: ServiceDTO)
    {
        return this.execute(Axios.post(`${this.url}/doctors/${doctorId}/services`, body, this.config()));
    }

    put(doctorId: string, body: ServiceDTO)
    {        
        return this.execute(Axios.put(`${this.url}/doctors/${doctorId}/services`, body, this.config()));
    }
}