import Lottie from 'lottie-react-web';
import React from 'react';

const BusyIndicator = (props: any) => {
  if (
    (props.query && props?.query?.status !== 'success') ||
    !!props.isLoading
  ) {
    return (
      <Lottie
        height={props.height || 150}
        width={props.width || 150}
        options={{
          animationData: require('./assets/loading.json'),
        }}
      />
    );
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default BusyIndicator;
