import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { App } from 'src/app';
import LogConstants from 'src/constants/LogConstants';
import { useCheck } from 'src/modules/dashboard/components/checkContext/CheckContext';
import LogView from '../../../../components/LogView/LogView';
import OnlineLogView from '../../../../components/OnlineLogView/OnlineLogView';
import PhoneLogView from '../../../../components/PhoneLogView/PhoneLogView';
import ScheduleViewStyle from '../../ScheduleViewStyle';
import { AppointmentMethodType, mapAppointmentMethodsToEnum } from 'src/models/DoctorDTO';

const SelectCommunicationCard = ({ selectedDoctor }) => {
  // Attributes
  const { check } = useCheck();
  const style = new ScheduleViewStyle();
  const [communicationType, setCommunicationType] = useState(0);
  const { request } = check;
  const { worker } = request;

  // Effects
  useEffect(() => {
    if (selectedDoctor && selectedDoctor.appointmentMethods) {
      const appointmentMethods = mapAppointmentMethodsToEnum(
        selectedDoctor.appointmentMethods
      );
      const comType = appointmentMethods[0];

      switch (comType) {
        case AppointmentMethodType.SMS:
          setCommunicationType(1);
          break;
        case AppointmentMethodType.Email:
          setCommunicationType(2);
          break;
        case AppointmentMethodType.Phone:
          setCommunicationType(0);
          break;
        case AppointmentMethodType.Online:
          setCommunicationType(3);
          break;
      }
    }
  }, [selectedDoctor]);

  // Functions
  function mapCommunicationImage() {
    switch (communicationType) {
      case 1:
        return '/assets/illu_via_sms.png';
      case 2:
        return '/assets/illu_via_email.png';
      case 3:
        return '/assets/illu_via_website.png';

      default:
        return '/assets/illu_via_phone.png';
    }
  }

  function mapLog() {
    const isModify = App.history.location.state.isModification;
    const isDelete = App.history.location.state.isDelete;

    const SMSLog = {
      type: LogConstants.SEND_DOCTOR_SMS,
      request: check.request,
    };
    const EMAILLog = {
      type: LogConstants.SEND_DOCTOR_MAIL,
      request: check.request,
    };

    switch (communicationType) {
      case 1:
        return (
          <LogView
            isSchedule={true}
            archived={false}
            check={check}
            doctor={selectedDoctor}
            isDelete={isDelete}
            isModify={isModify}
            log={SMSLog}
            delay={0}
          />
        );
      case 2:
        return (
          <LogView
            isSchedule={true}
            archived={false}
            check={check}
            doctor={selectedDoctor}
            isDelete={isDelete}
            isModify={isModify}
            log={EMAILLog}
            delay={0}
          />
        );
      case 3:
        return <OnlineLogView doctor={selectedDoctor} />;

      default:
        return <PhoneLogView doctor={selectedDoctor} />;
    }
  }

  return (
    <div className='card shadow' style={style.extendedCard}>
      <div style={style.takeAppointmentTitle}>
        Select communication method :
      </div>
      <div style={style.selectCommunicationMethod}>
        {/* TELEPHONE COMMUNICATION TYPE */}
        <div
          onClick={() => setCommunicationType(0)}
          style={
            communicationType === 0
              ? style.communicationBoxSelected
              : style.communicationBox
          }
        >
          <div
            style={
              communicationType === 0
                ? style.communicationBoxImageSelected
                : style.communicationBoxImage
            }
          >
            <img
              src='/assets/icon_phone.svg'
              style={style.communicationImage}
            />
          </div>
          <div
            style={
              communicationType === 0
                ? style.communicationBoxTextSelected
                : style.communicationBoxText
            }
          >
            Via PHONE
          </div>
        </div>

        {/* SMS COMMUNICATION TYPE */}
        <div
          onClick={() => setCommunicationType(1)}
          style={
            communicationType === 1
              ? style.communicationBoxSelected
              : style.communicationBox
          }
        >
          <div
            style={
              communicationType === 1
                ? style.communicationBoxImageSelected
                : style.communicationBoxImage
            }
          >
            <img src='/assets/icon_SMS.svg' style={style.communicationImage} />
          </div>
          <div
            style={
              communicationType === 1
                ? style.communicationBoxTextSelected
                : style.communicationBoxText
            }
          >
            Via SMS
          </div>
        </div>

        {/* E-MAIL COMMUNICATION TYPE */}
        <div
          onClick={() => setCommunicationType(2)}
          style={
            communicationType === 2
              ? style.communicationBoxSelected
              : style.communicationBox
          }
        >
          <div
            style={
              communicationType === 2
                ? style.communicationBoxImageSelected
                : style.communicationBoxImage
            }
          >
            <img
              src='/assets/icon_email.svg'
              style={style.communicationImage}
            />
          </div>
          <div
            style={
              communicationType === 2
                ? style.communicationBoxTextSelected
                : style.communicationBoxText
            }
          >
            Via E-MAIL
          </div>
        </div>

        {/* WEBSITE COMMUNICATION TYPE */}
        <div
          onClick={() => setCommunicationType(3)}
          style={
            communicationType === 3
              ? style.communicationBoxSelected
              : style.communicationBox
          }
        >
          <div
            style={
              communicationType === 3
                ? style.communicationBoxImageSelected
                : style.communicationBoxImage
            }
          >
            <img
              src='/assets/icon_website.svg'
              style={style.communicationImage}
            />
          </div>
          <div
            style={
              communicationType === 3
                ? style.communicationBoxTextSelected
                : style.communicationBoxText
            }
          >
            Via WEBSITE
          </div>
        </div>
      </div>

      {/* LOG */}
      <div style={style.logContainer}>
        <div style={style.imageContainer}>
          <img src={mapCommunicationImage()} style={style.logImage} />
        </div>
        <div style={style.logContent}>
          {/* LOG */}
          <div style={style.logBody}>{selectedDoctor && mapLog()}</div>

          {/* WORKER INFORMATION */}
          <div style={style.extendedWorkerInformationBox}>
            <div style={style.workerInformationMemoImageContainer}>
              <img
                src='/assets/icon_memo.svg'
                style={style.workerInformationMemoImage}
              />
            </div>
            <span
              style={style.workerInformationText}
            >{`Worker info : ${worker.firstName} ${worker.lastName}, ${worker.gender}, ${worker.address}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCommunicationCard;
