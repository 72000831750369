import React, { useState } from "react";
import { App } from "src/app";
import TranslationView from "src/components/translationView/TranslationView";
import DoctorDTO from "src/models/DoctorDTO";
import ListView from "src/modules/dashboard/components/listView/ListView";
import DoctorList from "../..";
import DoctorViewStyle from "../../DoctorViewStyle";
import { DoctorListTableProps } from "./props";

const DoctorListTable = ({ doctors }: DoctorListTableProps) => {

    // Attributes
    const style = new DoctorViewStyle();
    const [currentDoctor, setCurrentDoctor] = useState('');
    const [editHovered, setEditHovered] = useState(false);

    // Handlers
    function handleCurrentDoctor(id: string) {
        setCurrentDoctor(id);
    }

    function mapHeaders() {
        return (
            <tr>
                <th scope="col">
                    <TranslationView>
                        doctorName
                    </TranslationView>
                </th>
                <th scope="col">
                    <TranslationView>
                        doctorAddress
                    </TranslationView>
                </th>
                <th scope="col">
                    <TranslationView>
                        doctorLanguage
                    </TranslationView>
                </th>
                <th scope="col">
                    <TranslationView>
                        doctorEmail
                    </TranslationView>
                </th>
                <th scope="col">
                    <TranslationView>
                        doctorGSM
                    </TranslationView>
                </th>
                <th scope="col">
                    <TranslationView>
                        doctorFixNumber
                    </TranslationView>
                </th>
                <th scope="col" style={style.actionHeader}></th>
            </tr>
        )
    }

    function mapDoctors(doctor: DoctorDTO, index: number) {
        return (
            <tr onMouseEnter={() => handleCurrentDoctor(doctor.id)} key={index}>
                <td style={style.td}>
                    {`${doctor.firstName} ${doctor.lastName}`}
                </td>
                <td style={style.td}>
                    {doctor.officeAddress}
                </td>
                <td style={style.td}>
                    {doctor.mainLanguage}
                </td>
                <td style={style.td}>
                    {doctor.email}
                </td>
                <td style={style.td}>
                    {doctor.gsm}
                </td>
                <td style={style.td}>
                    {doctor.fixNumber}
                </td>
                <td>
                    <span onMouseEnter={() => setEditHovered(true)} onMouseLeave={() => setEditHovered(false)} onClick={() => App.history.push('/dashboard/doctor/detail', { doctor: doctor })} style={style.action}>
                        <img src={`/assets/edit_company${editHovered && currentDoctor === doctor.id ? '_selected' : ''}.svg`} style={style.actionIcon} />
                    </span>
                </td>
            </tr>
        )
    }

    function sortTable(a: DoctorDTO, b: DoctorDTO) {
        const firstFullName = `${a.firstName} ${a.lastName}`;
        const secondFullName = `${b.firstName} ${b.lastName}`;

        if (firstFullName < secondFullName) {
            return -1;
        }

        if (firstFullName === secondFullName) {
            return 0;
        }

        return 1;
    }
    return (
        <ListView
            isBusy={false}
            elements={doctors.sort(sortTable)?.map((elt) => { return { ...elt, fullName: `${elt.firstName} ${elt.lastName}` } })}
            mapElements={mapDoctors}
            mapHeaders={mapHeaders}

        />
    )
};

export default DoctorListTable;