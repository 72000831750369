import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { App } from 'src/app';
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';
import UserContext from 'src/components/userContext/UserContext';
import useSearch from 'src/components/useSearch';
import DoctorDTO from 'src/models/DoctorDTO';
import BusyIndicator from 'src/modules/dashboard/components/busyIndicator';
import DoctorService from 'src/services/DoctorService';
import DoctorListTable from './modules/table';
import SearchableView from "../../../../dashboard/components/searchView";
import { exportDoctor } from 'src/helpers/ExportHelper';
import ToggleView from 'src/modules/dashboard/components/toggleView/ToggleView';
import DoctorViewStyle from './DoctorViewStyle';
import DoctorsMap from './modules/table/map';

const DoctorList = () => {

    // Attributes
    const service = new DoctorService();
    const style = new DoctorViewStyle();
    const doctorQuery = useQuery("doctors", refreshDoctors);
    const { search, setSearch, filtered } = useSearch<DoctorDTO>(doctorQuery.data ?? [], ["firstName", "lastName", "fullName", "email"]);
    const [isMap, setIsMap] = useState(false);

    // Handlers
    async function refreshDoctors() {
        return (await service.get())
    }

    const ToggleButton = () => {
        return (
            <div style={style.toggleStyle}>
                <ToggleView yesTitle={"Map"} noTitle={"List"} isToggled={isMap} handleIsToggled={() => setIsMap(previous => !previous)} />
            </div>
        )
    }

    return (
        <LocalizationContext.Consumer>
            {({ translate }) => (
                <UserContext.Consumer>
                    {({ isInRole }) => (
                        <SearchableView
                            title={translate('doctorTitle')}
                            history={[{ name: translate('doctorTitle'), path: "/dashboard/doctor" }]}
                            handleSearchChanged={(event) => setSearch(event.target.value)}
                            search={search}
                            handleNew={() => App.history.push('/dashboard/doctor/detail')}
                            handleSecondaryAction={isInRole('admin') && exportDoctor}
                            secondaryActionChildren={() => <ToggleButton />}

                        >
                            <BusyIndicator query={doctorQuery}>

                                {
                                    isMap
                                        ?

                                        (
                                            <DoctorsMap doctors={filtered} />
                                        )
                                        :
                                        (
                                            <DoctorListTable doctors={filtered} />
                                        )
                                }
                            </BusyIndicator>
                        </SearchableView>
                    )}
                </UserContext.Consumer>
            )}
        </LocalizationContext.Consumer>
    )
};

export default DoctorList;