import React, { useState } from 'react';
import Service from 'src/services/Service';
import ViewTab, { DashboardView } from '../view-tab';

const DashboardDetail = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        height: '100vh',
        marginTop: -50,
        marginLeft: -15,
      }}
    >
      <div
        style={{
          position: 'absolute',
          right: 0,
          marginTop: 50,
          marginRight: 35,
        }}
      ></div>
      <iframe
        title='dashboard-detail'
        onLoad={(e) => setIsLoaded(true)}
        src={`https://apps-73qybaztda-ew.a.run.app/dashboard?showsidebar=false&accessToken=${Service.BEARER_TOKEN}`}
        style={{ width: '100%', height: '100%', opacity: isLoaded ? 1 : 0 }}
      />
    </div>
  );
};

export default DashboardDetail;
