import * as React from 'react';
import CompanyDetailViewStyle from '../../CompanyDetailViewStyle';
import { InputView } from '../../../../../../../../components/inputView/InputView'
import TranslationView from '../../../../../../../../components/translationView/TranslationView'
import AddressInputView from '../../../../../../../../components/addressInputView/AddressInputView';

const style = new CompanyDetailViewStyle();

interface CompanyGeneralProps {
    errors: any;
    element: any;
    handleInputChanged: any;
}

const CompanyGeneralView = (props: CompanyGeneralProps) => {

    const { element, errors, handleInputChanged } = props;

    return (
        <div className="card shadow" style={style.card}>
            {/* TITLE */}
            <div className="card-header border-1" style={style.cardHeader}>
                <TranslationView>
                    companyDetailGeneralInformation
                </TranslationView>
            </div>
            <div className="card-body">
                <div className="row">

                    {/* LEGAL NAME */}
                    <div style={style.inputLeft}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailLegalName
                            </TranslationView>
                        </div>
                        <InputView placeholder="Medicheck SA/NV" type="text" value={element.legalName} onChange={(event) => handleInputChanged("legalName", event.target.value)} />
                    </div>

                    {/* COMMERCIAL NAME */}
                    <div style={style.inputRight}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailCommercialName
                            </TranslationView>
                        </div>
                        <InputView error={errors["name"]} placeholder="Medicheck SA/NV" type="text" value={element.name} onChange={(event) => handleInputChanged("name", event.target.value)} />
                    </div>

                    {/* MAIN LANGUAGE */}
                    <div style={style.inputLeft}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailMainLanguage
                            </TranslationView>
                        </div>
                        <InputView error={errors["language"]} placeholder="Select a language" type="select" value={element.language} onChange={(event) => handleInputChanged("language", event.target.value)}>
                            <option value="FR">FR</option>
                            <option value="NL">NL</option>
                            <option value="EN">EN</option>
                        </InputView>
                    </div>

                    {/* MEDICHECK SALES REPRESENTATIVE */}
                    <div style={style.inputRight}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailMedicheckSalesRepresentative
                            </TranslationView>
                        </div>
                        <InputView placeholder="Xavier" type="text" value={element.salesRepresentative} onChange={(event) => handleInputChanged("salesRepresentative", event.target.value)} />
                    </div>

                </div>
                <div className="row">

                    {/* ADDRESS OF THE OFFICE */}
                    <div style={style.inputDouble}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailAddressOfTheOffice
                            </TranslationView>
                        </div>
                        <AddressInputView
                            error={errors["address"]}
                            placeholder="Parvis Sainte Gudule 5, 1000 Bruxelles"
                            value={element.address}
                            target="address"
                            handleInputChanged={handleInputChanged} />
                    </div>

                    {/* EMAILS FOR INVOICES */}
                    <div style={style.inputDouble}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailEmailsForInvoices
                            </TranslationView>
                        </div>
                        <InputView
                            placeholder="accounting@medicheck.io"
                            type="text" value={element.emailInvoices}
                            onChange={(event) => handleInputChanged("emailInvoices", event.target.value)} />
                    </div>

                </div>
                <div className="row">

                    {/* COMPANY NUMBER */}
                    <div style={style.inputLeft}>
                        <div style={style.input}>
                            <div style={style.inputTitle}>
                                <TranslationView>
                                    companyDetailCompanyNumber
                                </TranslationView>
                            </div>
                            <InputView placeholder="0683.604.629" type="text" value={element.companyNumber} onChange={(event) => handleInputChanged("companyNumber", event.target.value)} />
                        </div>
                    </div>

                    {/* COMPANY NUMBER */}
                    <div style={style.inputLeft}>
                        <div style={style.input}>
                            <div style={style.inputTitle}>
                                <TranslationView>
                                    companyHubspotId
                                </TranslationView>
                            </div>
                            <InputView placeholder="12345678912345678" type="text" value={element.companyHubspotId} onChange={(event) => handleInputChanged("companyHubspotId", event.target.value)} />
                        </div>
                    </div>

                    {/* <div style={style.inputRight} /> */}

                    {/* MAIN CONTACT FIRST NAME */}
                    <div style={style.inputLeft}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailMainContactFirstName
                            </TranslationView>
                        </div>
                        <InputView placeholder="Jean" type="text" value={element.contactFirstName} onChange={(event) => handleInputChanged("contactFirstName", event.target.value)} />
                    </div>

                    {/* MAIN CONTACT LAST NAME */}
                    <div style={style.inputRight}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailMainContactLastName
                            </TranslationView>
                        </div>
                        <InputView placeholder="Rifflart" type="text" value={element.contactLastName} onChange={(event) => handleInputChanged("contactLastName", event.target.value)} />
                    </div>

                </div>
                <div className="row">
                    <div style={style.inputLeft}>
                        <div style={style.input}>
                            <div style={style.inputTitle}>
                                <TranslationView>
                                    companyUniqueID
                                </TranslationView>
                            </div>
                            <InputView disabled placeholder="0123456789" type="text" value={element.id} onChange={(event) => handleInputChanged("id", event.target.value)} />
                        </div>
                    </div>

                    <div style={style.inputRight}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailMedicheckAssignedOperator
                            </TranslationView>
                        </div>
                        <InputView disabled placeholder="Unassigned" type="text" value={element.operatorName} onChange={(event) => handleInputChanged("operatorName", event.target.value)} />
                    </div>

                    {/* MAIN CONTACT EMAIL */}
                    <div style={style.inputLeft}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailMainContactEmail
                            </TranslationView>
                        </div>
                        <InputView
                            error={(errors["contactEmail"] && element.contactEmail) ? true : false}
                            placeholder="jean@medicheck.io"
                            type="text"
                            value={element.contactEmail}
                            onChange={(event) => handleInputChanged("contactEmail", event.target.value)} />
                    </div>

                    {/* MAIN CONTACT PHONE NUMBER */}
                    <div style={style.inputRight}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailMainContactPhone
                            </TranslationView>
                        </div>
                        <InputView error={errors["contactPhoneNumber"]} placeholder="02 897 20 01" type="text" value={element.contactPhoneNumber} onChange={(event) => handleInputChanged("contactPhoneNumber", event.target.value)} />
                    </div>
                </div>

                <div className="row">
                    <div style={style.inputLeft}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailInsurance
                            </TranslationView>
                        </div>
                        <InputView placeholder="" type='select' value={element.type} onChange={(event) => handleInputChanged("type", event.target.value)}>
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>
                        </InputView>
                    </div>
                    <div style={style.inputRight}/>
                    <div style={style.inputDouble} />
                </div>
            </div>
        </div>
    )
}

export default CompanyGeneralView;