export default class DetailViewStyle
{
    button: React.CSSProperties =
    {
        backgroundColor:'#FDB56D', 
        border: 'none',
        margin:'auto',
        boxShadow:'none',
        marginTop: 25 
    }

    buttonIcon: React.CSSProperties = 
    {
        width:20, 
        height:20, 
        marginRight:15, 
        filter:'invert(100%) sepia(0%) saturate(7496%) hue-rotate(272deg) brightness(100%) contrast(103%)'
    }

    buttonText: React.CSSProperties =
    {
        fontWeight:600, 
        marginTop:-20, 
        fontSize:16
    }

    modal: React.CSSProperties =
    {
        height:535, 
        width:400, 
        textAlign:'center'
    }

    modalContent: React.CSSProperties =
    {
        height:535, 
        width:400, 
        padding:22
    }

    closeButtonContainer: React.CSSProperties =
    {
        cursor:'pointer', 
        height:25, 
        width:15, 
        padding: 0, 
        marginLeft:'auto'
    }

    closeButton: React.CSSProperties =
    {
        height:25, 
        width:25, 
        marginLeft:-5
    }

    modalIllustration: React.CSSProperties =
    {
        margin:'auto', 
        height:250, 
        width:250
    }

    nameContainer: React.CSSProperties =
    {
        color:'#FDB56D', 
        fontWeight:500, 
        fontSize:16, 
        margin:'auto', 
        width:'100%', 
        textAlign:'center', 
        marginTop:25
    }

    name: React.CSSProperties =
    {
        margin:'auto'
    }

    validation: React.CSSProperties =
    {
        width:300, 
        fontSize:18, 
        fontWeight:300, 
        color:'#405364', 
        margin:'auto', 
        textAlign:'center'
    }

    noNeedToSaveContainer: React.CSSProperties =
    {
        marginTop:15
    }

    noNeedToSave: React.CSSProperties =
    {
        margin:'auto', 
        textAlign:'center', 
        color:'#7D91A0', 
        fontWeight:300, 
        fontSize:16, 
        textDecoration:'underline', 
        cursor:'pointer'
    }
}