import React from 'react';
import { App } from 'src/app';
import Button from 'src/components/button';
import { downloadCSV } from 'src/helpers/ExportHelper';
import CompanySuperiorService from 'src/services/CompanySuperiorService';

export default function SuperiorDownloadButton() {
	// Handlers
	async function handleDownload() {
		const companyId = App.history.location.state.company.id;

		const superiors = await new CompanySuperiorService().get(companyId);

		const rows = [
			[
				'firstName',
				'lastName',
				'email',
				'phoneNumber',
				'language',
				'requestForm',
			],
		];

		superiors?.map((superior) => {
			const arr = [];
			rows[0].forEach((key) => arr.push(superior[key]));
			rows.push(arr);
		});

		let csvContent = '';

		rows.forEach((rowArray) => {
			let row = rowArray.join(';');
			csvContent += row + '\r\n';
		});

		downloadCSV(csvContent, `${companyId}-superiors`);
	}

	// Render
	return (
		<Button
			iconPath='/assets/icon_download_3.svg'
			customStyle={{ minWidth: '215px', marginRight: '9px' }}
			text='Download superiors'
			onClick={handleDownload}
		/>
	);
}
