import Service from './Service';
import Axios from 'axios';
import CheckDTO from '../models/CheckDTO';

export default class CheckPriceService extends Service
{
    post(checkId: string, check: CheckDTO)
    {
        return this.execute(Axios.post(`${this.url}/checks/${checkId}/prices`, check, this.config()));
    }
}