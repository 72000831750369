import Axios from 'axios';
import Service from './Service';
import IntegrationDTO from 'src/models/IntegrationDTO';

export default class CheckIntegrationService extends Service {
    get(companyId: string) {
        return this.execute(Axios.get(`${this.getUrl("v2")}/companies/${companyId}/integrations`, this.config()));
    }

    post(companyId: string, integrationDTO: IntegrationDTO) {
        return this.execute(Axios.post(`${this.getUrl("v2")}/companies/${companyId}/integrations`, integrationDTO, this.config()));
    }
}