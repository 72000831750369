export default class PinViewStyle
{
    pin: React.CSSProperties =
    {
        height:32, 
        width:25
    }

    card: React.CSSProperties =
    {
        width:400, 
        height:220, 
        marginTop:-220, 
        marginLeft:-190, 
        position:'absolute', 
        zIndex:200
    }

    titleRow: React.CSSProperties =
    {
        fontSize:20, 
        fontWeight:500,
        color: '#405364'
    }

    doctorTitleCol: React.CSSProperties = 
    {
        width: '90%'
    }

    actionTitleCol: React.CSSProperties = 
    {
        textAlign:'right', 
        width:20
    }

    actionTitleButton: React.CSSProperties =
    {
        width:20, 
        height:20, 
        fill:'white', 
        color:'white', 
        cursor:'pointer'
    }

    actionTitleButtonIcon: React.CSSProperties =
    {
        width:20, 
        height:20, 
        fill:'white', 
        color:'white', 
        margin:5, 
        cursor:'pointer', 
        filter:'invert(83%) sepia(10%) saturate(107%) hue-rotate(169deg) brightness(100%) contrast(82%)'
    }

    addressRow: React.CSSProperties =
    {
        fontSize:15, 
        color:'#405364',
        fontWeight:300
    }

    cardBody: React.CSSProperties = 
    {
        color: '#405364'
    }

    cardInfo: React.CSSProperties =
    {
        fontSize: 16,
        color: '#405364',
        fontWeight:400,
        WebkitUserSelect:'text'
    }

    cardInfoTitle: React.CSSProperties =
    {
        fontSize: 14,
        fontWeight: 100,
        color: '#405364'
    }
    
    cardContainer: React.CSSProperties =
    {
        width:100
    }

    doctorInformationContainer: React.CSSProperties =
    {
        display:'flex', flexDirection:'column', alignContent:'space-between', flex:1, width:'100%', height:100, marginTop:30
    }

    doctorCardInformationBody: React.CSSProperties =
    {
        ...this.cardBody, display:'flex', flexDirection:'row', justifyContent:'space-around', flex:1
    }
}