export default class AccordeonViewStyle {
    button: React.CSSProperties =
        {
            float: 'right',
            fontWeight: 700,
            cursor: 'pointer'
        }

    header: React.CSSProperties =
        {
            float: 'right',
            marginRight: 10
        }

    card: React.CSSProperties =
        {
            marginTop: 30
        }

    cardTitle: React.CSSProperties =
        {
            float: 'left'
        }

    accordeonContent: React.CSSProperties =
        {
            padding: 0,
            margin: 0
        }

    cardBody: React.CSSProperties =
        {
            padding: 0,
            paddingTop: 5,
            paddingBottom: 10
        }
}