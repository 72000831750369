import DetailComponentStyle from '../../abstracts/detailComponent/DetailComponentStyle'

export default class ArchiveViewStyle extends DetailComponentStyle {
    buttonContainer: React.CSSProperties =
        {
            width: 20,
            height: 20,
            fill: 'white',
            color: 'white',
            margin: 5,
            cursor: 'pointer'
        }

    button: React.CSSProperties =
        {
            width: 30,
            height: 30,
            fill: 'white',
            color: 'white',
            margin: 5,
            cursor: 'pointer',
        }

    td: React.CSSProperties =
        {
            WebkitUserSelect: 'text'
        }


    extendedCard: React.CSSProperties =
        {
            ...this.card,
            padding: 31
        }
    takeAppointmentTitle: React.CSSProperties =
        {
            height: 28,
            width: 436,
            color: "#405364",
            fontSize: 18,
            lineHeight: "21px"
        }
}