import React, { useRef, useState } from 'react';
import Buttons from './buttons';
import Inputs from './inputs';
import './style.css'

const DateSelect = ({ startDate, endDate, setStartDate, setEndDate }: any) => {

    return (
        <div className='dashboard-filter-date-container'>
            <Buttons setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
            <Inputs setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
        </div>
    )
}

export default DateSelect;