import Service from './Service';
import Axios from 'axios';

export default class SuperiorConfirmationService extends Service
{

    post(id: string)
    {
        return this.execute(Axios.post(`${this.url}/superiors/${id}/confirmations`, undefined, this.config()));
    }
}