import { CheckStatus } from '../../../../constants/CheckStatus';

export default class CheckViewStyle {
    buttonContainer: React.CSSProperties = {
        width: 30,
        height: 30,
        fill: 'white',
        color: 'white',
        margin: 0,
        cursor: 'pointer'
    }

    assignButtonContainer: React.CSSProperties = {
        width: 18,
        height: 20,
        fill: 'white',
        color: 'white',
        margin: 0,
        cursor: 'pointer',
        transform: 'translateY(-1px)'
    }

    button: React.CSSProperties = {
        width: 30,
        height: 30,
        fill: 'white',
        color: 'white',
        margin: 0,
        marginLeft: 5,
        cursor: 'pointer'
    }

    assignButton: React.CSSProperties = {
        width: 18,
        height: 20,
        fill: 'white',
        color: 'white',
        cursor: 'pointer',
        margin: 'auto'
    }

    td: React.CSSProperties = {
        WebkitUserSelect: 'text'
    }

    imageTag: React.CSSProperties = {
        height: 13,
        width: 13
    }

    operatorStyle: React.CSSProperties = {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: '#90A7B8',
        color: 'white',
        fontWeight: 400,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        margin: 'auto'
    }

    checkStatus: (status: number) => React.CSSProperties = (status: number) => {
        let color = '';

        switch (status) {
            case CheckStatus.REQUESTED:
                color = '#BED2DE';
                break;
            case CheckStatus.PAUSED:
                color = '#019BC7';
                break;
            case CheckStatus.PROBLEM:
                color = '#EC5467';
                break;
            case CheckStatus.SCHEDULED:
                color = '#9FD6E5';
                break;
            case CheckStatus.FINISHED:
                color = '#0A728E';
                break;
            case CheckStatus.UPDATED:
                color = '#FDB56D';
                break;
            case CheckStatus.ARCHIVED:
                color = 'black';
                break;
            default:
                color = 'black';
                break;
        }

        return {
            backgroundColor: color,
            height: 13,
            width: 13,
            borderRadius: 6.5
        };
    }
}