import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { InputView } from 'src/components/inputView/InputView';
import { useTranslation } from 'src/components/localizationContext/LocalizationContext';
import NetworkHelper from 'src/helpers/NetworkHelper';
import CompanyDTO from 'src/models/CompanyDTO';
import CompanyService from 'src/services/CompanyService';
import './style.css'

const CompanySelect = ({ companyFilter, setCompanyFilter }) => {

    // Attributes
    const [companies, setCompanies] = useState([]);
    const { translate } = useTranslation()

    // Effects
    useEffect(() => {
        refreshCompanies();
    }, []);

    // Methods
    async function refreshCompanies() {

        const fetchedCompanies = await NetworkHelper.execute(await new CompanyService().get())
        const emptyCompany = [{ id: '', name: `${translate("PleaseSelectACompany")}` }]
        setCompanies(emptyCompany.concat(fetchedCompanies))
    }

    function handleAll() {
        setCompanyFilter('')
    }

    function renderClassName() {
        if (!companyFilter) {
            return 'dashboard-select-all-button-selected'
        } else {
            return 'dashboard-select-all-button'
        }
    }
    return (
        <div className='dashboard-filter-company-container'>
            <div onClick={handleAll} className={renderClassName()}>
                All
            </div>
            <div style={{ width: 300 }}>
                <InputView placeholder={translate("PleaseSelectACompany")} type="select" value={companyFilter} onChange={(event: any) => setCompanyFilter(event.target.value)}>
                    {companies?.map((company: CompanyDTO) => <option value={company.id}>{company.name}</option>)}
                </InputView>
            </div>
        </div>
    )
}

export default CompanySelect;