import Service from './Service';
import Axios from 'axios';
import UserDTO from '../models/UserDTO';

export default class UserService extends Service
{
    get()
    {
        return this.execute(Axios.get(`${this.url}/users?page=0&size=2000`, this.config()), "Unable to retrieve users.");
    }

    post(body: UserDTO)
    {
        return this.execute(Axios.post(`${this.url}/users`, body, this.config()), "User can't be created.");
    }

    put(body: UserDTO)
    {
        return this.execute(Axios.put(`${this.url}/users`, body, this.config()), "User can't be updated.");
    }

    delete(id: string)
    {
        return this.execute(Axios.delete(`${this.url}/users/${id}`, this.config()), "User can't be deleted.");
    }
}