import { MyCertificateDTO } from "src/dtos/MyCertificateDTO";
import { PARSING_TYPE } from "src/dtos/MyCertificateFieldDTO";

const matriculeFields = [
  {
    screenId: "1",
    isActive: true,
    isRequired: false,
    parsingType: PARSING_TYPE.ADDRESS,
    // position: number,
    options: "",
    name: "Matricule",
  },
];
const workerInfoFields = [
  {
    screenId: "2",
    isActive: true,
    isRequired: false,
    parsingType: PARSING_TYPE.ADDRESS,
    // position: number,
    options: "",
    name: "First name",
  },
  {
    screenId: "2",
    isActive: true,
    isRequired: false,
    parsingType: PARSING_TYPE.ADDRESS,
    // position: number,
    options: "",
    name: "Last name",
  },
  {
    screenId: "2",
    isActive: true,
    isRequired: false,
    parsingType: PARSING_TYPE.ADDRESS,
    // position: number,
    options: "",
    name: "Gender",
  },
];

const screens = [
  {
    configurationId: "1",
    isActive: true,
    isRequired: false,
    name: "Matricule",
    nextScreenName: "Worker info",
    fields: matriculeFields,
    order: 1,
  },
  {
    configurationId: "2",
    isActive: true,
    isRequired: false,
    name: "Worker info",
    nextScreenName: "Worker info",
    fields: workerInfoFields,
    order: 1,
  },
];

export const mockCertif: MyCertificateDTO = {
  company: {
    key: "http://url.be",
    medicheckId: "123",
    logoURL: "http://picture.be",
    name: "Medicheck",
    hrEmails: "amelie@medicheck.be",
    configuration: {
      companyId: "123",
      isRequestFromSuperiorsAllowed: true,
      isRequestFromHRAllowed: false,
      isCertificateForSuperiorsAllowed: true,
      isCertificateForHRAllowed: true,
      numberOfDaysBeforeCheck: 5,
      numberOfCertificates: 6,
      numberOfDays: 7,
      matriculeFormat: "string",
      screens: screens,
      matriculeExampleUrl: "https://file.url.com",
      isMatriculeVisible: true,
      certificateTypes: [],
      incapacityTypes: [],
      isFrequencyCriteriaActive: true,
      isNumberOfDayCriteriaActive: true,
      numberOfRandomCheck: null,
    },
  },
};
