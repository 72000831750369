export default class ToolBoxElementViewStyle
{
    container: React.CSSProperties =
    {
        width: 150, 
        height: 100, 
        marginTop: 10, 
        marginBottom: 10, 
        textAlign: 'center'
    }

    imageContainer: React.CSSProperties =
    {
        marginTop: 10, 
        margin: 'auto', 
        height:60, 
        width:60, 
        borderRadius:30, 
        backgroundColor:'white', 
        cursor: 'pointer'
    }

    image: React.CSSProperties =
    {
        margin: 'auto', 
        height:60, 
        width:60, 
        borderRadius:30, 
        backgroundColor:'white', 
        cursor: 'pointer'
    }

    title: React.CSSProperties =
    {
        height:30, 
        width:130, 
        fontSize:10, 
        margin: 'auto', 
        marginTop: 10, 
        overflow:'hidden', 
        wordBreak: 'break-all'
    }
}