import * as React from 'react';
import DoctorScheduleViewProps from './DoctorScheduleViewProps';
import { InputView } from '../../../../../../../components/inputView/InputView';
import TranslationView from '../../../../../../../components/translationView/TranslationView';

const DoctorScheduleView = (props: DoctorScheduleViewProps) => {
    const { style, element, handleInputChanged } = props;

    return (
        <div className="card shadow" style={style.card}>
            {/* TITLE */}
            <div className="card-header border-1" style={style.cardHeader}>
                <TranslationView>
                    doctorDetailSchedule
                </TranslationView>
            </div>
            <div className="card-body">
                <div className="row">

                    {/* WEEKLY SCHEDULE DESCRIPTION */}
                    <div style={style.inputDouble}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                doctorDetailWeeklyScheduleDescription
                            </TranslationView>
                        </div>
                        <InputView type="textarea" placeholder="Monday: Open consultations !-10, Appointments 10-18" value={element.weeklyScheduleDescription} onChange={(event) => handleInputChanged("weeklyScheduleDescription", event.target.value)} />
                    </div>

                    {/* HOLIDAYS */}
                    <div style={style.inputDouble}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                doctorDetailHolidays
                            </TranslationView>
                        </div>
                        <InputView type="textarea" placeholder="01/01/2019 to the 01/02/2019" value={element.holidays} onChange={(event) => handleInputChanged("holidays", event.target.value)} />
                    </div>

                </div>
            </div>
        </div>
    );
}

export default DoctorScheduleView;