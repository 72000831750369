import React, { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { App } from "src/app";
import { InputView } from "src/components/inputView/InputView";
import TranslationView from "src/components/translationView/TranslationView";
import CheckDoctorDTO from "src/models/CheckDoctorDTO";
import DoctorDTO from "src/models/DoctorDTO";
import { useCheck } from "src/modules/dashboard/components/checkContext/CheckContext";
import ListView from "src/modules/dashboard/components/listView/ListView";
import CheckDoctorService from "src/services/CheckDoctorService";
import ScheduleViewStyle from "../../ScheduleViewStyle";
import DoctorRow from "./components/doctor-row";

const DoctorTable = ({ selectedDoctor, setSelectedDoctor }) => {
	// Attributes
	const [doctors, setDoctors] = useState([]);
	const { check } = useCheck();
	const [amountOfDoctors, setAmountOfDoctors] = useState(16);
	const style = new ScheduleViewStyle();
	const { isModification, isDelete } = App.history.location.state;
	const checkDoctorsQuery = useQuery(check.id, refreshDoctors, { refetchOnMount: false, refetchOnWindowFocus: false });

	useEffect(() => {
		if (doctors.length > 0 && selectedDoctor) {
			selectDoctor(doctors);
		}
	}, [selectedDoctor, checkDoctorsQuery.data]);

	// Functions
	function selectDoctor(tmpDoctors: DoctorDTO[]) {
		// Sets the selected doctor if there is any
		tmpDoctors.forEach((doctor: any) => {
			if (doctor.id === selectedDoctor?.id) {
				doctor.selected = true;

				// Opens the doctor is it's a delete / modification
				if (isModification || isDelete) {
					doctor.open = true;
				}
			} else {
				doctor.selected = false;
			}
		});

		setDoctors([].concat(tmpDoctors));
	}

	async function refreshDoctors() {
		let tmpDoctors = [];
		if (!isModification && !isDelete) {
			tmpDoctors = await new CheckDoctorService().getByPages(check.id, 0, amountOfDoctors);
			tmpDoctors = tmpDoctors.filter((elt: DoctorDTO) => elt.inhouseAppreciation !== 2);
		} else {
			tmpDoctors.push(await new CheckDoctorService().getById(check.id, check.doctorId));
		}

		selectDoctor(tmpDoctors);
	}

	function handleDoctorOpened(elt: CheckDoctorDTO) {
		setDoctors((previousState) => {
			for (let i = 0; i < previousState.length; i++) {
				if (previousState[i].id == elt.id) {
					previousState[i].open = true;
				} else {
					previousState[i].open = false;
				}
			}
			return [].concat(previousState);
		});
	}

	function handleDoctorSelected(doctor: CheckDoctorDTO) {
		const { isModification, isDelete } = App.history.location.state;
		if (isModification === false && isDelete === false) {
			setSelectedDoctor(doctors.find((elt) => elt.id === doctor.id));
		}
	}

	function mapDoctorHeaders() {
		return (
			<tr>
				<th style={style.fillerTH} />
				<th>
					<TranslationView>doctorName</TranslationView>
				</th>
				<th>
					<TranslationView>doctorDistance</TranslationView>
				</th>
				<th>
					<TranslationView>doctorScheduleLanguage</TranslationView>
				</th>
				<th>
					<TranslationView>doctorServices</TranslationView>
				</th>
				<th>
					<TranslationView>appreciation</TranslationView>
				</th>
				<th>
					<TranslationView>reactivity</TranslationView>
				</th>

				<th>
					<TranslationView>efficiency</TranslationView>
				</th>
				<th>
					<TranslationView>actions</TranslationView>
				</th>
			</tr>
		);
	}

	return (
		<div className="card shadow" style={style.doctorTableExtendedCard}>
			<div style={style.doctorChooseADoctor}>
				<div>Choose a doctor for the :</div>
				<div
					style={{
						width: 75,
						marginTop: -10,
						marginRight: 75,
					}}
				>
					<InputView updateSync={checkDoctorsQuery.refetch} placeholder="0" type="text" value={amountOfDoctors} onChange={(val) => setAmountOfDoctors(val.target.value)} />
				</div>
			</div>
			<div>
				<ListView hasBusyIndicator elements={doctors} isBusy={checkDoctorsQuery.isFetching} mapElements={(elt, index) => <DoctorRow handleDoctorOpened={handleDoctorOpened} handleDoctorSelected={handleDoctorSelected} elt={elt} index={index} check={check} />} mapHeaders={mapDoctorHeaders} />
			</div>
		</div>
	);
};

export default DoctorTable;
