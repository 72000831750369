import React from 'react';
import './style.css'

const CalendarStart = ({ className, children }) => {
    return (
        <div className='dashboard-filter-date-calendar-start-container'>
            <div className={className}>
                <div style={{ position: "relative" }}>{children}</div>
            </div>
        </div>
    )
}

export default CalendarStart;