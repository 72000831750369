import React, { useState, useEffect, useContext } from 'react';
import TranslationView from 'src/components/translationView/TranslationView';
import CompanyDetailViewStyle from '../../CompanyDetailViewStyle';
import CompanyDTO from 'src/models/CompanyDTO';
import CompanyCommunicationService from 'src/services/CompanyCommunicationService';
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';
import ListView from 'src/modules/dashboard/components/listView/ListView';
import AlertHelper from 'src/helpers/AlertHelper';
import CompanyCommunicationTableRow from './table/CompanyCommunicationTableRowView';
import CompanyTableHeader from './table/CompanyCommunicationTableHeaderView';
import { CommunicationConstants } from 'src/constants/CommunicationConstants';

const style = new CompanyDetailViewStyle();

interface CompanyCommunicationProps {
    element: CompanyDTO;
}

const CompanyCommunicationView: React.FC<CompanyCommunicationProps> = ({ element }) => {

    const [hasBeenModified, setHasBeenModified] = useState(false);
    const [communications, setCommunications] = useState([]);
    const [firstCommunicationsByType, setFirstCommunicationsByType] = useState([]);
    const [companyCommunications, setCompanyCommunications] = useState([]);
    const { translate } = useContext(LocalizationContext);

    // Effects
    useEffect(() => {
        loadCommunications();
    }, []);

    useEffect(() => {
        if (hasBeenModified) {
            updateCommunications();
            setHasBeenModified(false);
        }
    }, [communications]);

    async function loadCommunications() {
        const communicationService = new CompanyCommunicationService();
        const communications = await communicationService.get(element.id);
        
        const importantTitles = [
            translate(CommunicationConstants.SUPERIOR_REQUEST_CONFIRMATION),
            translate(CommunicationConstants.WORKER_2DE_CHECK),
            translate(CommunicationConstants.SUPERIOR_2DE_CHECK),
            translate(CommunicationConstants.SUPERIOR_GSM_PROBLEM),
            translate(CommunicationConstants.TELECHECK_TO_SUPERIOR)
        ];
        
        const filteredCommunications = communications.filter(communication => {
            const translatedTitle = translate(communication.communication.title);
            return (
                [0, 1, 2].includes(communication.communication.type) ||
                importantTitles.includes(translatedTitle)
            );
        });
        
        // Sort communications by type
        filteredCommunications.sort((a, b) => {
            if (a.communication.type === b.communication.type) {
                const titleA = translate(a.communication.title);
                const titleB = translate(b.communication.title);
                return titleA.localeCompare(titleB);
            } else {
                return a.communication.type - b.communication.type;
            }
        });
        
        setCommunications(filteredCommunications);
        
        const firstCommunicationsByType = [];
        const communicationTypeSet = new Set();
        
        for (const communication of filteredCommunications) {
            if (!communicationTypeSet.has(communication.communication.type)) {
                communicationTypeSet.add(communication.communication.type);
                firstCommunicationsByType.push(communication);
            }
        }
        
        setFirstCommunicationsByType(firstCommunicationsByType);
        
        const uniqueCommunications = filteredCommunications.filter((communication, index, self) => {
            const element = self.find(elt => translate(communication.communication.title) === translate(elt.communication.title));
            return self.indexOf(element) === index;
        });
        
        setCompanyCommunications(uniqueCommunications);
    }    

    function handleToggleChanged(element: any, value: any) {
        setHasBeenModified(true);
        setCommunications(communications?.map(elt => {
            if (translate(elt.communication.title) === translate(element.communication.title)) {
                if (value === true) {
                    elt.isSelected = true;
                }
                else {
                    elt.isSelected = false;
                }
            }
            return elt;
        }));
    }

    function handleRecipientChanged(companyCommunication: any, value: any) {
        setHasBeenModified(true);
        setCommunications(communications?.map(elt => {
            if (translate(elt.communication.title) === translate(companyCommunication.communication.title)) {
                elt.recipient = value;
            }
            return elt;
        }));
    }

    async function updateCommunications() {
        if (communications && hasBeenModified) {
            await new CompanyCommunicationService().put(element.id, communications);
            AlertHelper.success("Communications updated.");
            loadCommunications();
        }
    }

    return (
        <LocalizationContext.Consumer>
            {({ translate }) => (
                <div className="card shadow" style={style.card}>
                    {/* TITLE */}
                    <div className="card-header border-1" style={style.cardHeader}>
                        <TranslationView>
                            companyDetailCommunications
                        </TranslationView>
                    </div>
                    <ListView isBusy={false} elements={companyCommunications} mapElements={(element) => <CompanyCommunicationTableRow handleToggleChanged={(value) => handleToggleChanged(element, value)} handleRecipientChanged={(event) => handleRecipientChanged(element, event.target.value)} element={element} displayHeader={firstCommunicationsByType.includes(element)} />} mapHeaders={() => <CompanyTableHeader />} />
                </div>
            )}
        </LocalizationContext.Consumer>
    )
}

export default CompanyCommunicationView;