import React, { useContext, useEffect, useState } from "react";
import { App } from "src/app";
import { InputView } from "src/components/inputView/InputView";
import { useCheck } from "src/modules/dashboard/components/checkContext/CheckContext";
import style from "./style";
import TranslationView from "src/components/translationView/TranslationView";
import CheckScheduleService from "src/services/CheckScheduleService";
import NetworkHelper from "src/helpers/NetworkHelper";
import moment from "moment";
import AppointmentProps from "./props";

const AppointmentCard = (props: AppointmentProps) => {
	// Attributes
	const [isLoading, setIsLoading] = useState(false);
	const { check, handleInputChanged, handleRefreshCheck } = useCheck();
	let suggestedDateConstraint;

	if (check) {
		suggestedDateConstraint = mapCheckDate(check.suggestedCheckDate);
	}

	// Handlers
	async function updateSuggestedCheckDate(date: any) {
		await handleInputChanged("suggestedCheckDate", date);
	}

	async function updateSuggestedCheckTimeOfDay(date: any) {
		await handleInputChanged("suggestedCheckTimeOfDay", date);
	}

	async function updateSuggestedCheckTimeStart(date: any) {
		await handleInputChanged("suggestedCheckTimeStart", date);
	}

	async function updateSuggestedCheckTimeEnd(date: any) {
		await handleInputChanged("suggestedCheckTimeEnd", date);
	}

	// Functions
	async function updateScheduling() {
		setIsLoading(true);
		await new CheckScheduleService().put(check.id, check);
		await handleRefreshCheck();
		setIsLoading(false);
	}

	function mapCheckDate(checkDate) {
		const { incapacityStartDate, incapacityEndDate } = check.request as any;

		const today = new Date();
		today.setHours(0);
		today.setMinutes(0);
		today.setSeconds(0);
		today.setMilliseconds(0);

		if (checkDate < today) {
			return true;
		}

		if (checkDate < incapacityStartDate) {
			return true;
		}

		if (checkDate < incapacityEndDate) {
			return true;
		}

		return false;
	}

	function mapDate(date) {
		try {
			return moment(date).format("DD/MM/YYYY");
		} catch (e) {
			console.warn("Error transforming date.");
			return "ERROR";
		}
	}

	if (!check) {
		return (
			<div className="card shadow" style={style.container}>
				<div style={style.title}>Taking the appointment</div>
			</div>
		);
	}

	// Render
	return (
		<div className="card shadow" style={style.container}>
			<div style={style.title}>Taking the appointment</div>
			<span style={style.description}>
				The worker's incapacity is from the <b>{mapDate(check.request.startDate)}</b> to the <b>{mapDate(check.request.endDate)}</b>.
			</span>
			<span style={style.description}>Here are Valou's suggestions :</span>
			<div style={style.suggestionContainer}>
				<div style={style.inputContainer}>
					<span style={style.inputTitle}>Suggested Check Date:</span>
					<div style={style.input}>
						<InputView
							disabled={props.isDelete || isLoading}
							warning={suggestedDateConstraint}
							placeholder={`${new Date()}`}
							type="date"
							isSundayRuleApplied
							minDate={check.request.startDate ? new Date(check.request.startDate) : new Date()}
							maxDate={check.request.endDate ? new Date(check.request.endDate) : undefined}
							value={check.suggestedCheckDate}
							onChange={(event) => {
								updateSuggestedCheckDate(event.target.value);
							}}
							updateSync={updateScheduling}
						/>
					</div>
				</div>
				<div style={style.inputContainer}>
					<span style={style.inputTitle}>Suggested Check Time Of Day:</span>
					<div style={style.input}>
						<InputView disabled={props.isDelete || isLoading} updateSync={updateScheduling} placeholder="Select an option" type="select" value={check.suggestedCheckTimeOfDay} onChange={(event) => updateSuggestedCheckTimeOfDay(event.target.value)}>
							<option key={"MORNING"} value={0}>
								In the morning
							</option>
							<option key={"AFTER"} value={1}>
								After
							</option>
							<option key={"BETWEEN"} value={2}>
								Between
							</option>
						</InputView>
					</div>
				</div>
				<div style={style.inputContainer}>
					<span style={style.inputTitle}>Suggested Check Time Start:</span>
					<div style={style.input}>
						<InputView updateSync={updateScheduling} disabled={check.suggestedCheckTimeOfDay === 0 || props.isDelete || isLoading} placeholder="--:--:--" mask="h9:m9:s9" type="text" value={check.suggestedCheckTimeStart} onChange={(event) => updateSuggestedCheckTimeStart(event.target.value)} />
					</div>
				</div>
				<div style={style.inputContainer}>
					<span style={style.inputTitle}>Suggested Check Time End:</span>
					<div style={style.input}>
						<InputView updateSync={updateScheduling} disabled={check.suggestedCheckTimeOfDay < 2 || props.isDelete || isLoading} placeholder="--:--:--" mask="h9:m9:s9" type="text" value={check.suggestedCheckTimeEnd} onChange={(event) => updateSuggestedCheckTimeEnd(event.target.value)} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppointmentCard;
