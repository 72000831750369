import Service from 'src/services/Service';

interface IDoctorInvoicesIframeProps {
  doctorId: string;
  
}

const DoctorInvoicesIframe = ({
  doctorId,
}: IDoctorInvoicesIframeProps) => {
  
  // Render
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        height: '100vh',
        width: '100%',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: '85px',
          right: '-2px',
          height: '100%',
          width: '96%',
        }}
      >
        <iframe
          title='doctor-invoices'
          allow='clipboard-read; clipboard-write'
          src={`https://mc-apps-back-office.vercel.app/dashboard/doctors/invoices?accessToken=${Service.BEARER_TOKEN}&doctorId=${doctorId}`}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    </div>
  );
};
export default DoctorInvoicesIframe;
