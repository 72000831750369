import Service from './Service';
import Axios from 'axios';

export default class CompanyExportService extends Service
{
    /* METHODS */
    get()
    {        
        return this.execute(Axios.get(`${this.url}/companies/exports`, this.config()), "Unable to retrieve companies exports.");
    }
}