import React from 'react';
import TranslationView from 'src/components/translationView/TranslationView';
import { actions } from '../../../../reducer';
import { GeneralProps } from '../../props';
import MyCertificateInput from '../input';
import './style.css';

const RightGroup = ({ myCertificate, dispatch }: GeneralProps) => {
  return (
    <div className='company-mycertificate-rightgroup-container'>
      <div className='company-mycertificate-leftgroup-row'>
        <div style={{ width: 200, marginRight: 30 }}>
          <MyCertificateInput
            type='select'
            label='Show matricule screen'
            value={
              myCertificate.company.configuration.screens
                .find((elt) => elt.name === 'matricule')
                ?.fields.find((elt) => elt.name === 'Matricule')?.isActive
            }
            handleOnChange={(event) =>
              dispatch({
                type: actions.fieldChanged,
                payload: {
                  target: 'isActive',
                  screenName: 'matricule',
                  fieldName: 'Matricule',
                  value: event.target.value,
                },
              })
            }
          >
            <option value={true as any}>Yes</option>
            <option value={false as any}>No</option>
          </MyCertificateInput>
        </div>

        <div style={{ width: 200 }}>
          <MyCertificateInput
            type='text'
            target='matriculeFormat'
            label='Matricule Format'
            value={myCertificate.company.configuration.matriculeFormat}
            handleOnChange={(event) =>
              dispatch({
                type: actions.configChanged,
                payload: {
                  target: 'matriculeFormat',
                  value: event.target.value,
                },
              })
            }
          />
        </div>
      </div>
      <MyCertificateInput
        type='text'
        target='matriculeUrl'
        label='Matricule Url'
        value={myCertificate.company.configuration.matriculeExampleUrl}
        handleOnChange={(event) =>
          dispatch({
            type: actions.configChanged,
            payload: {
              target: 'matriculeExampleUrl',
              value: event.target.value,
            },
          })
        }
      />
      <MyCertificateInput
        target='incapacityTypes'
        label='Incapacity type options'
        type='multi-select'
        value={myCertificate.company.configuration.incapacityTypes
          .filter((elt) => elt.isActive)
          ?.map((elt) => elt.type)}
        handleOnChange={(event) =>
          dispatch({
            type: actions.companyChanged,
            payload: {
              target: 'incapacityTypes',
              value: myCertificate.company.configuration.incapacityTypes?.map(
                (elt) => {
                  if (event.target.value?.includes(elt.type)) {
                    elt.isActive = true;
                  } else {
                    elt.isActive = false;
                  }

                  return elt;
                }
              ),
            },
          })
        }
      >
        {myCertificate.company.configuration.incapacityTypes?.map((option) => {
          return (
            <option value={option.type}>
              <TranslationView>{option.name}</TranslationView>
            </option>
          );
        })}
      </MyCertificateInput>
      <MyCertificateInput
        target='certificateTypes'
        label='Certificate type options'
        type='multi-select'
        value={myCertificate.company.configuration.certificateTypes
          .filter((elt) => elt.isActive)
          ?.map((elt) => elt.type)}
        handleOnChange={(event) =>
          dispatch({
            type: actions.companyChanged,
            payload: {
              target: 'certificateTypes',
              value: myCertificate.company.configuration.certificateTypes?.map(
                (elt) => {
                  if (event.target.value?.includes(elt.type)) {
                    elt.isActive = true;
                  } else {
                    elt.isActive = false;
                  }

                  return elt;
                }
              ),
            },
          })
        }
      >
        {myCertificate.company.configuration.certificateTypes?.map((option) => {
          return (
            <option value={option.type}>
              <TranslationView>{option.name}</TranslationView>
            </option>
          );
        })}
      </MyCertificateInput>
    </div>
  );
};

export default RightGroup;
