import React from 'react';
import './style.css'

const DateButton = ({ title, isSelected, onClick }) => {

    // Methods
    function renderClassName() {
        if (isSelected) {
            return 'dashboard-select-date-button-selected'
        } else {
            return 'dashboard-select-date-button'
        }
    }

    return (
        <div className={renderClassName()} onClick={onClick}>
            {title}
        </div>
    )
}

export default DateButton;