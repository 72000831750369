import React from 'react';
import style from './style';
import TranslationView from 'src/components/translationView/TranslationView';
import BoardCardProps from './props';

const BoardCard = (props: BoardCardProps) => {

    // Attributes
    const myStyle: any = style;
    const { title, amount, children } = props;

    return (
        <div style={myStyle.container}>
            <div style={myStyle.titleContainer}>
                <div style={myStyle.title}>
                    <TranslationView>
                        {title}
                    </TranslationView>
                </div>
                <div style={myStyle.amount}>
                    {amount}
                </div>
            </div>
            <div style={myStyle.separator} />
            <div style={myStyle.body}>
                {children}
            </div>
        </div>
    )
}

export default BoardCard;