import * as React from 'react';
import { TagViewProps } from './TagViewInterface';
import { TagViewStyle } from './TagViewStyle';
import { CheckStatus } from '../../../../constants/CheckStatus';
import { motion } from 'framer-motion';

const TagView = (props: TagViewProps) => {

    const { tag, imageTag } = TagViewStyle;
    const tagText = () => {
        switch (type) {
            case CheckStatus.ARCHIVED:
                return "Archived";
            case CheckStatus.REQUESTED:
                return "Untreated";
            case CheckStatus.SCHEDULED:
                return "Pending";
            case CheckStatus.PAUSED:
                return "On hold";
            case CheckStatus.FINISHED:
                return "Finished";
            case CheckStatus.UPDATED:
                return "Modified";
            case CheckStatus.PROBLEM:
                return "Problem";
            case CheckStatus.REQUESTUPDATED:
                return "Modified";
            case CheckStatus.CHECK_AUTOMATIC_PAUSED:
                return "Paused";
            case CheckStatus.REQUEST_AUTOMATIC_PAUSED:
                return "Paused";
            case CheckStatus.REQUEST_PROBLEM:
                return "Problem";

            default:
                return "Untreated";
        }
    }
    const { numberOfItems = 0, type, onChange } = props;

    return (
        <motion.div whileTap={{ scale: 0.9 }} onClick={onChange} style={{ cursor: 'pointer', opacity: props.value ? 1 : 0.5, boxSizing: 'border-box', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', justifyItems: 'space-around', alignItems: 'center', height: 45, width: 130, borderRadius: 6, backgroundColor: 'white', border: '1px solid #E3E3E3', marginLeft: 5, marginRight: 5 }}>

            {(type !== 8 && type !== 9)
                ?
                (
                    <div style={tag(type)} />
                )
                :
                (
                    <img style={imageTag} src="assets/icon_pause.svg" />
                )
            }

            <div>
                {`${tagText()} (${numberOfItems})`}
            </div>
        </motion.div>
    );
}

export default TagView;