import React, { useState } from 'react';
import { App } from '../../../../../app';


export enum DashboardView {
    Global,
    Detailed
}

export interface ViewTabProps {
    currentView?: DashboardView
}

const ViewTab = ({ currentView = DashboardView.Global }: ViewTabProps) => {
    //Attributes
    const [dashboardView, setDashboardView] = useState<DashboardView>(currentView);

    //Functions
    function handleViewClick(view: DashboardView) {
        switch (view) {
            case DashboardView.Detailed:
                setDashboardView(DashboardView.Detailed);
                App.history.push('/dashboard/detail');
                break;
            case DashboardView.Global:
                setDashboardView(DashboardView.Global);
                App.history.push('/dashboard/global');
                break;
            default:
                break;
        }
    }

    //Render
    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div onClick={() => handleViewClick(DashboardView.Global)} style={viewContainerStyle}>
                <img src="/assets/dashboard_global.svg" style={{ ...imageStyle, display: dashboardView === DashboardView.Global ? "none" : "flex" }} />
                <img src="/assets/dashboard_global_active.svg" style={{ ...imageStyle, display: dashboardView === DashboardView.Global ? "flex" : "none" }} />
            </div>
            <div onClick={() => handleViewClick(DashboardView.Detailed)} style={{ ...viewContainerStyle, marginLeft: 10 }}>
                <img src="/assets/dashboard_detailed.svg" style={{ ...imageStyle, display: dashboardView === DashboardView.Detailed ? "none" : "flex" }} />
                <img src="/assets/dashboard_detailed_active.svg" style={{ ...imageStyle, display: dashboardView === DashboardView.Detailed ? "flex" : "none" }} />
            </div>
        </div>
    )
}

const viewContainerStyle = {
    width: 30,
    height: 30,
    overflow: "hidden",
    backgroundColor: "#fff",
    borderRadius: 50,
    alignItems: "center",
    display: 'flex',
    justifyContent: "center",
    cursor: "pointer",
}

const imageStyle = {
    width: 30,
    height: 30,
}

export default ViewTab;