export default class AlertableViewStyle
{
    alertStyle: React.CSSProperties = 
    {
        width:300, 
        float:'right', 
        position:'absolute', 
        marginRight:20,
        right:20
    }
}