export default class SearchViewStyle {
	searchContainer: React.CSSProperties = {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
	};

	searchInput: React.CSSProperties = {
		width: 250,
		marginRight: 5,
	};

	card: React.CSSProperties = {
		marginTop: 30,
	};

	cardBody: React.CSSProperties = {
		width: '100%',
		padding: 0,
	};

	cardHeaderContainer: React.CSSProperties = {
		display: 'flex',
		flexDirection: 'row',
		width: 'fill',
	};

	searchIcon: React.CSSProperties = {
		width: 20,
		height: 20,
		filter:
			'invert(83%) sepia(10%) saturate(107%) hue-rotate(169deg) brightness(100%) contrast(82%)',
	};

	newContainer: React.CSSProperties = {
		marginLeft: 20,
	};

	newIcon: React.CSSProperties = {
		width: 20,
		height: 20,
		marginRight: 15,
		filter:
			'invert(100%) sepia(0%) saturate(7496%) hue-rotate(272deg) brightness(100%) contrast(103%)',
	};

	downloadIcon: React.CSSProperties = {
		width: 20,
		height: 20,
		marginRight: 15,
	};

	newText: React.CSSProperties = {
		fontWeight: 600,
		marginTop: -20,
		fontSize: 16,
	};
}
