import * as React from 'react';
import CompanyDetailViewStyle from '../../CompanyDetailViewStyle';
import { InputView } from '../../../../../../../../components/inputView/InputView'
import TranslationView from '../../../../../../../../components/translationView/TranslationView'

const style = new CompanyDetailViewStyle();

interface CompanyContractProps {
    element: any;
    handleInputChanged: any;
}

const CompanyContractView = (props: CompanyContractProps) => {

    const { element, handleInputChanged } = props;

    return (
        <div className="card shadow" style={style.card} >

            {/* TITLE */}
            <div className="card-header border-1" style={style.cardHeader} >
                <TranslationView>
                    companyContractInformation
                </TranslationView>
            </div >

            <div className="card-body">
                <div className="row">

                    {/* SIGNED DATE */}
                    <div style={style.inputLeft}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                signedDate
                            </TranslationView>
                        </div>
                        <InputView placeholder="01/01/2020" type="date" value={element.signedDate} onChange={(event) => handleInputChanged("signedDate", event.target.value)} />
                    </div>

                    {/* PAYMENT SCHEME */}
                    <div style={style.inputRight}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                paymentScheme
                            </TranslationView>
                        </div>
                        <InputView placeholder="" type='select' value={element.paymentScheme} onChange={(event) => handleInputChanged("paymentScheme", event.target.value)}>
                            <option value={-1}></option>
                            <option value={0}>Prepaid</option>
                            <option value={1}>Postpaid</option>
                        </InputView>
                    </div>

                    {/* AUTOMATIC RENEWAL TIME */}
                    <div style={style.inputLeft}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                automaticRenewalTime
                            </TranslationView>
                        </div>
                        <InputView placeholder="" type='select' value={element.automaticRenewalTime} onChange={(event) => handleInputChanged("automaticRenewalTime", event.target.value)}>
                            <option value={-1}></option>
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>
                        </InputView>
                    </div>

                    {/* NOTICE PERIOD */}
                    <div style={style.inputRight}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                noticePeriod
                            </TranslationView>
                        </div>
                        <InputView placeholder="0" type="text" value={element.noticePeriod} onChange={(event) => handleInputChanged("noticePeriod", event.target.value)} />
                    </div>

                </div>
                <div className="row">

                    {/* PAYMENT FREQUENCY */}
                    <div style={style.inputLeft}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                paymentFrequency
                            </TranslationView>
                        </div>
                        <InputView placeholder="" type='select' value={element.paymentFrequency} onChange={(event) => handleInputChanged("paymentFrequency", event.target.value)}>
                            <option value={-1}></option>
                            <option value={0}>Single Payment</option>
                            <option value={1}>Monthly</option>
                            <option value={2}>Yearly</option>
                        </InputView>
                    </div>

                    {/* PAYMENT TERMS */}
                    <div style={style.inputRight}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                paymentTerms
                            </TranslationView>
                        </div>
                        <InputView placeholder="0" type="text" value={element.paymentTerms} onChange={(event) => handleInputChanged("paymentTerms", event.target.value)} />
                    </div>

                    {/* AUTOMATIC RENEWAL NEGATIVE */}
                    <div style={style.inputLeft}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                automaticRenewalNegative
                            </TranslationView>
                        </div>
                        <InputView placeholder="" type='select' value={element.automaticRenewalNegative} onChange={(event) => handleInputChanged("automaticRenewalNegative", event.target.value)}>
                            <option value={-1}></option>
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>
                        </InputView>
                    </div>

                    <div style={style.inputRight} />
                </div>

            </div>
        </div >
    );
}

export default CompanyContractView;