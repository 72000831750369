import { format } from 'date-fns';
import React, { useContext } from 'react';
import { useTranslation } from 'src/components/localizationContext/LocalizationContext';
import UserContext from 'src/components/userContext/UserContext';
import './style.css';

export default function HomeView() {
	// Attributes
	const { translate } = useTranslation();
	const { user } = useContext(UserContext);
	const userGreetings: string = translate('home.userGreetings');
	const weekString: string = translate('home.week');

	// Render
	return (
		<div className='homepage-container'>
			<div className='homepage-top-section'>
				{/* HEADER */}
				<div className='homepage-header-container'>
					<span className='homepage-title'>{translate('home.title')}</span>
					<span className='homepage-subtitle'>
						{translate('home.description')}
					</span>
				</div>
				{/* BODY */}
				<div className='homepage-body-container'>
					<span className='homepage-date'>
						{format(
							new Date(),
							`# '${translate('todayIs')}' do '${translate('of')}' LLLL`
						)}
					</span>
					<span className='homepage-week'>
						{weekString.replace('{{week}}', format(new Date(), 'I'))}
					</span>
				</div>
			</div>
			{/* ILLUSTRATION */}
			<div className='homepage-illustration-section'>
				<div className='homepage-illustration-content'>
					<img src='/assets/bo_helper.svg' alt='illustration' />
					<div className='homepage-illustration-text-bubble'>
						<div>
							<span className='user-greetings'>
								{userGreetings.replace('{{user.firstname}}', user?.firstName)}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
