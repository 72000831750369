import React from 'react';
import SearchViewProps from './SearchViewProps'
import { useTranslation } from 'src/components/localizationContext/LocalizationContext';
import SearchViewTitle from './components/header';
import { motion } from 'framer-motion';
import SearchBar from './components/search-bar';
import StyleCardShadow from './components/card-shadow';
import SearchViewStyle from './SearchViewStyle';

const SearchView = (props: SearchViewProps) => {

    // Attributes
    const { translate } = useTranslation()
    const style = new SearchViewStyle()

    return (
        <div className="col">
            <SearchViewTitle title={props.title} history={props.history} headerChildren={props.headerChildren} />
            <motion.div initial={{ y: 20 }} animate={{ y: 0 }}>
                <StyleCardShadow>
                    <SearchBar secondaryActionChildren={props.secondaryActionChildren} handleNew={props.handleNew} cardHeaderChildren={props.cardHeaderChildren} search={props.search} handleSearchChanged={props.handleSearchChanged} handleSecondaryAction={props.handleSecondaryAction} />
                    <div className="card-body" style={style.cardBody}>
                        {props.children}
                    </div>
                </StyleCardShadow>
            </motion.div>
        </div>
    )
}

export default SearchView;