export default class SettingViewStyle
{
    td: React.CSSProperties = 
    {
        WebkitUserSelect: 'text'
    }

    editLink: React.CSSProperties =
    {
        width:20, 
        height:20, 
        fill:'white', 
        color:'white', 
        margin:5, 
        cursor:'pointer'
    }
    
    action: React.CSSProperties =
    {
        width:70
    }

    editIcon: React.CSSProperties = 
    {
        width:20, 
        height:20, 
        fill:'white', 
        color:'white', 
        margin:5, 
        filter:'invert(83%) sepia(10%) saturate(107%) hue-rotate(169deg) brightness(100%) contrast(82%)'
    }

    environment: React.CSSProperties = 
    {
        maxWidth:175
    }
}