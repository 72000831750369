export default class ButtonViewStyle
{
    boxFooter: React.CSSProperties =
    {
        display:'flex', 
        flexDirection:'row', 
        alignItems:'center', 
        justifyContent:'space-between', 
        padding:30
    }

    button: React.CSSProperties =
    {
        minWidth:100,
        padding: '.625rem 1.25rem',
        fontWeight:500,
        color:'rgb(26, 165, 207)',
        alignItems:'center',
        justifyContent:'center',
        display:'flex',
        cursor:'pointer',
        backgroundColor: "rgb(245, 250, 255)",
        border:'1px solid rgb(26, 165, 207)',
        borderRadius: 8,
        transform: 'scale(1)', 
        filter: 'brightness(100%)'
    }

    hoveredButton: React.CSSProperties = 
    {
        ...this.button,
        color:'white',
        backgroundColor: "rgb(26, 165, 207)"
    }

    disabledButton: React.CSSProperties =
    {
        ...this.button,
        backgroundColor: "#D6DCE5"
    }

    previousButton: React.CSSProperties =
    {
        ...this.button,
        backgroundColor:'#D6DCE5'
    }

    previous: React.CSSProperties =
    {
        width:120
    }

    image: React.CSSProperties =
    {
        width: 22.5,
        height: 22.5,
        filter: 'invert(64%) sepia(17%) saturate(5984%) hue-rotate(156deg) brightness(87%) contrast(85%)'
    }

    imageHovered : React.CSSProperties =
    {
        ...this.image,
        filter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(106deg) brightness(104%) contrast(105%)'
    }
}