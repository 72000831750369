import React, { useState } from 'react';
import { useTranslation } from 'src/components/localizationContext/LocalizationContext';
import SearchView from '../../components/searchView/SearchView';
import CheckService from '../../../../services/CheckService';
import { useQuery } from 'react-query';
import useSearch from 'src/components/useSearch';
import CheckDTO from 'src/models/CheckDTO';
import ListView from '../../components/listView/ListView';
import CheckTableHeader from './components/check-table-header';
import { App } from 'src/app';
import CheckTableFilters from './components/check-table-filters';
import CheckTableRow from './components/check-table-row';

const FIELDS = ['creationDate', 'request.worker.firstName', 'request.worker.fullName', 'request.worker.lastName', 'doctor.lastName', 'doctor.fullName', 'doctor.firstName', 'request.company.name'];
const SEARCH = "CHECKS_SEARCH_STATE";
const PAGING = "CHECKS_PAGING_STATE";

const CheckView = () => {
    // Attributes
    const { translate } = useTranslation();
    const query = useQuery("checks", refresh, { refetchIntervalInBackground: false, refetchInterval: 1000 });
    const { search, setSearch, filtered } = useSearch<CheckDTO>(query.data, FIELDS);
    const service = new CheckService()
    const [lastRefresh, setLastRefresh] = React.useState<Date>(new Date());
    const [filters, setFilters] = useState({ 1: true, 4: true, 5: true, 6: true, 8: true });
    const [page, setPage] = useState(0);
    const [currentCheck, setCurrentCheck] = useState('');

    // Effects 
    React.useEffect(handleStorageNotEmpty, []);

    // Methodes
    function handleStorageNotEmpty() {
        if (localStorage.getItem(SEARCH) && localStorage.getItem(PAGING)) {
            const search = localStorage.getItem(SEARCH);
            const paging = localStorage.getItem(PAGING);

            setSearch(search);
            setPage(parseInt(paging));

            localStorage.removeItem(SEARCH);
            localStorage.removeItem(PAGING);
        }
    }


    async function refresh() {
        const data = (await service.get());
        setLastRefresh(new Date());
        return data;
    }

    // ListView Methods
    function handleNavigateClicked(check: CheckDTO) {
        //TOCHECK localStorageElements
        localStorage.setItem(SEARCH, search);
        localStorage.setItem(PAGING, `${page}`);
        App.history.push('/dashboard/check/detail', { check: check });
    }

    function applyFilters() {
        if (filtered) {
            if (filtered.length > 0) {
                return filtered.filter(elt => filters[elt.state]);
            }
        }

        return [];
    }

    // Rendering
    return (
        <SearchView
            search={search}
            handleSearchChanged={(event) => setSearch(event.target.value)}
            isBusy={query.status !== "success"}
            title={translate('checks')}
            history={[{ name: translate('checks'), path: "/dashboard/check" }]}
            cardHeaderChildren={
                <CheckTableFilters
                    lastRefresh={lastRefresh}
                    filtered={filtered}
                    filters={filters}
                    setFilters={setFilters}
                />
            }
        >
            <ListView
                page={page}
                handlePageChanged={setPage}
                noHideLoading
                isBusy={filtered === undefined}
                elements={filtered ? applyFilters() : []}
                mapElements={(check) =>
                    <CheckTableRow
                        check={check}
                        currentCheck={currentCheck}
                        setCurrentCheck={setCurrentCheck}
                        handleNavigateClicked={handleNavigateClicked}
                    />
                }
                mapHeaders={CheckTableHeader}
            />
        </SearchView>
    )
}

export default CheckView;