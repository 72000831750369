import React, { useState } from 'react';
import SettingDetailViewStyle from '../../SettingDetailViewStyle';
import SaveButtonView from '../../../../../../components/saveButtonView/SaveButtonView';
import UserCompanyService from 'src/services/UserCompanyService';
import UserService from 'src/services/UserService';
import { handleSaved } from '../../utils';

interface SaveButtonInterface {
    user: any,
    setErrors: any,
    userCompanies: any,
    errors: any
}

const SaveButton = ({ user, setErrors, userCompanies, errors }: SaveButtonInterface) => {

    // Attributes
    const style = new SettingDetailViewStyle()
    const [isBusy, setIsBusy] = useState(false)
    const userService = new UserService()
    const userCompanyService = new UserCompanyService()

    // Methods

    return (
        <div style={style.container}>
            <SaveButtonView isBusy={isBusy} handleSaved={() => handleSaved({ user, setErrors, userCompanies, errors, userService, userCompanyService })} />
        </div>
    )
}

export default SaveButton;