import React from 'react';
import TagView from 'src/modules/dashboard/components/tagView/TagView';
import RequestTableFiltersProps from './props';

const RequestTableFilters = (props: RequestTableFiltersProps) => {
    // Attributes
    const { filters, setFilters, filtered, lastRefresh } = props;
    const activeFilterTypes = Object.keys(filters)?.map(filterKey => parseInt(filterKey, 10));

    // Rendering
    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {activeFilterTypes?.map((type) => <TagView key={type} type={type} numberOfItems={filtered ? filtered.filter(elt => elt.state === type).length : 0} value={filters[type]} onChange={() => setFilters(previous => {
                    const newFilters = JSON.parse(JSON.stringify(previous));
                    newFilters[type] = !newFilters[type];
                    return newFilters;
                })} />)}
            </div>
            <div style={{ whiteSpace: 'nowrap', color: lastRefresh ? 'inherit' : 'red' }}>
                Last update - {lastRefresh.toLocaleTimeString()}
            </div>
        </div>
    );
}

export default RequestTableFilters;