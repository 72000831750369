import * as React from 'react';
import CompanyDetailViewStyle from '../../CompanyDetailViewStyle';
import { InputView } from '../../../../../../../../components/inputView/InputView'
import TranslationView from '../../../../../../../../components/translationView/TranslationView'

const style = new CompanyDetailViewStyle();

interface CompanyContactProps {
    errors: any;
    element: any;
    handleInputChanged: any;
}

const CompanyContactView = (props: CompanyContactProps) => {

    const { element, errors, handleInputChanged } = props;

    return (
        <div className="card shadow" style={style.card}>
            {/* TITLE */}
            <div className="card-header border-1" style={style.cardHeader}>
                <TranslationView>
                    companyDetailContactsForPsychosocialProblems
                </TranslationView>
            </div>
            <div className="card-body">
                <div className="row">

                    {/* PREVENTION ADVISOR FIRST NAME */}
                    <div style={style.inputLeft}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailPreventionAdvisorFirstName
                            </TranslationView>
                        </div>
                        <InputView placeholder="Thibault" type="text" value={element.preventionAdvisorFirstName} onChange={(event) => handleInputChanged("preventionAdvisorFirstName", event.target.value)} />
                    </div>

                    {/* PREVENTION ADVISOR LAST NAME */}
                    <div style={style.inputRight}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailPreventionAdvisorLastName
                            </TranslationView>
                        </div>
                        <InputView placeholder="Gueguen" type="text" value={element.preventionAdvisorLastName} onChange={(event) => handleInputChanged("preventionAdvisorLastName", event.target.value)} />
                    </div>

                    {/* COMPANY DOCTOR FIRST NAME */}
                    <div style={style.inputLeft}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailCompanyDoctorFirstName
                            </TranslationView>
                        </div>
                        <InputView placeholder="Kimi" type="text" value={element.companyDoctorFirstName} onChange={(event) => handleInputChanged("companyDoctorFirstName", event.target.value)} />
                    </div>

                    {/* COMPANY DOCTOR LAST NAME */}
                    <div style={style.inputRight}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailCompanyDoctorLastName
                            </TranslationView>
                        </div>
                        <InputView placeholder="Poncelet" type="text" value={element.companyDoctorLastName} onChange={(event) => handleInputChanged("companyDoctorLastName", event.target.value)} />
                    </div>

                </div>
                <div className="row">

                    {/* PREVENTION ADVISOR EMAIL */}
                    <div style={style.inputLeft}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailPreventionAdvisorEmail
                            </TranslationView>
                        </div>
                        <InputView error={errors["preventionAdvisorEmail"]} placeholder="thibault@medicheck.io" type="text" value={element.preventionAdvisorEmail} onChange={(event) => handleInputChanged("preventionAdvisorEmail", event.target.value)} />
                    </div>

                    {/* PREVENTION ADVISOR PHONE */}
                    <div style={style.inputRight}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailPreventionAdvisorPhone
                            </TranslationView>
                        </div>
                        <InputView error={errors["preventionAdvisorPhoneNumber"]} placeholder="02 897 20 01" type="text" value={element.preventionAdvisorPhoneNumber} onChange={(event) => handleInputChanged("preventionAdvisorPhoneNumber", event.target.value)} />
                    </div>

                    {/* COMPANY DOCTOR EMAIL */}
                    <div style={style.inputLeft}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailCompanyDoctorEmail
                            </TranslationView>
                        </div>
                        <InputView error={errors["companyDoctorEmail"]} placeholder="kimi@medicheck.io" type="text" value={element.companyDoctorEmail} onChange={(event) => handleInputChanged("companyDoctorEmail", event.target.value)} />
                    </div>

                    {/* COMPANY DOCTOR PHONE */}
                    <div style={style.inputRight}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailCompanyDoctorPhone
                            </TranslationView>
                        </div>
                        <InputView error={errors["companyDoctorPhoneNumber"]} placeholder="02 897 20 01" type="text" value={element.companyDoctorPhoneNumber} onChange={(event) => handleInputChanged("companyDoctorPhoneNumber", event.target.value)} />
                    </div>

                </div>
                <div className="row">

                    {/* PERSON OF TRUST FIRST NAME */}
                    <div style={style.inputLeft}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailPersonOfTrustFirstName
                            </TranslationView>
                        </div>
                        <InputView placeholder="Narek" type="text" value={element.personOfTrustFirstName} onChange={(event) => handleInputChanged("personOfTrustFirstName", event.target.value)} />
                    </div>

                    {/* PERSON OF TRUST LAST NAME */}
                    <div style={style.inputRight}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailPersonOfTrustLastName
                            </TranslationView>
                        </div>
                        <InputView placeholder="Ceropian" type="text" value={element.personOfTrustLastName} onChange={(event) => handleInputChanged("personOfTrustLastName", event.target.value)} />
                    </div>

                    {/* DOUBLE INPUT FILLER */}
                    <div style={style.inputDouble} />

                </div>
                <div className="row">

                    {/* PERSON OF TRUST EMAIL */}
                    <div style={style.inputLeft}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailPersonOfTrustEmail
                            </TranslationView>
                        </div>
                        <InputView error={errors["personOfTrustEmail"]} placeholder="narek@medicheck.io" type="text" value={element.personOfTrustEmail} onChange={(event) => handleInputChanged("personOfTrustEmail", event.target.value)} />
                    </div>

                    {/* PERSON OF TRUST PHONE */}
                    <div style={style.inputRight}>
                        <div style={style.inputTitle}>
                            <TranslationView>
                                companyDetailPersonOfTrustPhone
                            </TranslationView>
                        </div>
                        <InputView error={errors["personOfTrustPhoneNumber"]} placeholder="02 897 20 01" type="text" value={element.personOfTrustPhoneNumber} onChange={(event) => handleInputChanged("personOfTrustPhoneNumber", event.target.value)} />
                    </div>

                    {/* DOUBLE INPUT FILLER */}
                    <div style={style.inputDouble} />

                </div>
            </div>
        </div>
    )
}

export default CompanyContactView;