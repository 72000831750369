import React from 'react';
import SearchViewStyle from '../../SearchViewStyle';

const StyleCardShadow = ({ children }: any) => {

    // Attributes
    const style = new SearchViewStyle()

    return (
        <div className="card shadow" style={style.card}>
            {children}
        </div>
    )
}

export default StyleCardShadow;