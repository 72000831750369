import * as React from 'react';
import CheckStateService from 'src/services/CheckStateService';
import { CheckStatus } from 'src/constants/CheckStatus';
import { motion } from 'framer-motion';
import { CheckContext } from 'src/modules/dashboard/components/checkContext/CheckContext';
import SeparatorView from '../components/SeparatorView';

const ActionLogView = ({ log }) => {

    const [hovered, setHovered] = React.useState(false);
    const { handleRefreshLogs, handleRefreshCheck } = React.useContext(CheckContext);

    const updateState = async () => {
        if (log.check.state === CheckStatus.REQUESTUPDATED) {
            if (log.check.doctor) {
                await new CheckStateService().put(log.check.id, { ...log.check, state: CheckStatus.PAUSED });
            }
            else {
                await new CheckStateService().put(log.check.id, { ...log.check, state: CheckStatus.REQUESTED });
            }
        }
        else {
            await new CheckStateService().put(log.check.id, { ...log.check, state: CheckStatus.SCHEDULED });
        }

        handleRefreshLogs();
        handleRefreshCheck();
    }

    return (
        <>
            {log.type === "0000081" && <SeparatorView visible />}
            <div style={{ padding: 20, margin: 1, height: 75 }}>
                <motion.div whileHover={{ scale: 0.95 }} whileTap={{ scale: 1.05 }} onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)} onClick={updateState} style={{ float: 'right', height: 34, width: 142, cursor: 'pointer' }}>
                    <img src={`/assets/button_ok${hovered ? "_hovered" : ""}.png`} style={{ height: 34, width: 142 }} />
                </motion.div>
            </div>
        </>
    );
}

export default ActionLogView;