import TranslationView from "src/components/translationView/TranslationView";

const CompanyTableHeader = () => {
    return (
        <tr>
            <th scope="col">
                <TranslationView>
                    Communication
                </TranslationView>
            </th>
            <th scope="col">
                <TranslationView>
                    Description
                </TranslationView>
            </th>
            <th scope="col">
                <TranslationView>
                    BasicCommunication?
                </TranslationView>
            </th>
            <th scope="col">
                <TranslationView>
                    recipient
                </TranslationView>
            </th>
        </tr>
    )
}

export default CompanyTableHeader;