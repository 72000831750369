import React from 'react';
import { actions } from '../../../../reducer';
import { GeneralProps } from '../../props';
import MyCertificateInput from '../input';
import TranslationView from 'src/components/translationView/TranslationView';
import './style.css';
import { TypeOfEntry } from 'src/dtos/EntryType';

const LeftGroup = ({ dispatch, myCertificate }: GeneralProps) => {
  //Attributes
  const typeOfEntrySelectOptions = [
    { label: 'Absence declaration', value: TypeOfEntry.ABSENCE_DECLARATION },
    { label: 'Medical certificate', value: TypeOfEntry.MEDICAL_CERTIFICATE },
  ];

  //Render
  return (
    <div className='company-mycertificate-leftgroup-container'>
      <MyCertificateInput
        type='text'
        target='key'
        label='URL Key'
        value={myCertificate.company.key}
        handleOnChange={(event) =>
          dispatch({
            type: actions.companyChanged,
            payload: { target: 'key', value: event.target.value },
          })
        }
      />
      <MyCertificateInput
        type='text'
        target='logoURL'
        label='Logo URL'
        value={myCertificate.company.logoURL}
        handleOnChange={(event) =>
          dispatch({
            type: actions.companyChanged,
            payload: { target: 'logoURL', value: event.target.value },
          })
        }
      />
      <MyCertificateInput
        target='entryTypes'
        label='Usage purpose'
        type='multi-select'
        value={myCertificate.company.configuration?.entryTypes
          ?.filter((elt) => elt.isActive)
          ?.map((elt) => elt.type)}
        handleOnChange={(event) =>
          dispatch({
            type: actions.configChanged,
            payload: {
              target: 'entryTypes',
              value: myCertificate.company.configuration.entryTypes?.map(
                (elt) => {
                  if (event.target.value?.includes(elt.type)) {
                    elt.isActive = true;
                  } else {
                    elt.isActive = false;
                  }

                  return elt;
                }
              ),
            },
          })
        }
      >
        {typeOfEntrySelectOptions?.map((option) => {
          return (
            <option value={option.value}>
              <TranslationView>{option.label}</TranslationView>
            </option>
          );
        })}
      </MyCertificateInput>

      <MyCertificateInput
        type='text'
        target='hrEmails'
        label='HR Email(s)'
        value={myCertificate.company.hrEmails}
        handleOnChange={(event) =>
          dispatch({
            type: actions.companyChanged,
            payload: { target: 'hrEmails', value: event.target.value },
          })
        }
      />
      <div className='company-mycertificate-leftgroup-row'>
        <div style={{ width: 200, marginRight: 30 }}>
          <MyCertificateInput
            type='select'
            target='isCertificateForSuperiorsAllowed'
            label='Superior access to certif'
            value={
              myCertificate.company.configuration
                .isCertificateForSuperiorsAllowed
            }
            handleOnChange={(event) =>
              dispatch({
                type: actions.configChanged,
                payload: {
                  target: 'isCertificateForSuperiorsAllowed',
                  value: event.target.value,
                },
              })
            }
          >
            <option value={true as any}>Yes</option>
            <option value={false as any}>No</option>
          </MyCertificateInput>
        </div>
        <div style={{ width: 200 }}>
          <MyCertificateInput
            type='select'
            target='isCertificateForHRAllowed'
            label='HR access to certif'
            value={
              myCertificate.company.configuration.isCertificateForHRAllowed
            }
            handleOnChange={(event) =>
              dispatch({
                type: actions.configChanged,
                payload: {
                  target: 'isCertificateForHRAllowed',
                  value: event.target.value,
                },
              })
            }
          >
            <option value={true as any}>Yes</option>
            <option value={false as any}>No</option>
          </MyCertificateInput>
        </div>
      </div>
    </div>
  );
};

export default LeftGroup;
