import * as React from 'react';
import StarViewProps from './StarViewProps';
import BaseComponent from '../../../../../../abstracts/BaseComponent';

export default class StarView extends BaseComponent<StarViewProps, any>
{
    /* CONSTRUCTOR */
    constructor(props: any)
    {
        super(props);

        // Bindings
        this.handleClicked = this.handleClicked.bind(this);
    }

    /* METHODS */

    /* HANDLERS */
    handleClicked(index: number)
    {
        if(this.props.disabled === false)
        {
            this.props.onChange({target:{value:index}});
        }
    }

    /* RENDER */
    render()
    {
        const stars = [];

        for(let i = 0; i < this.props.stars; i++)
        {
            stars.push(<img key={i} src="/assets/icon_star_blue_selected.svg" style={{height:25, width:25, margin:7.5}} />);
        }

        for(let i = 0; i < 5 - this.props.stars; i++)
        {
            stars.push(<img key={i} src="/assets/icon_star_blue.svg" style={{height:25, width:25, margin:7.5}} />);
        }

        return (
            <div style={{display:'flex', flexDirection:'row', flex:1}}>
                {stars?.map((elt, index) => 
                    {
                        return (
                            <div key={index} onClick={() => this.handleClicked(index+1)}>
                                {elt}
                            </div>
                        );
                    }
                )}
            </div>
        );
    }
}