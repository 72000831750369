import React from 'react'
import TranslationView from 'src/components/translationView/TranslationView';

const FormTitle = ({ children }: any) => {
    return (
        <div className="card-header border-1" style={{ display: 'flex', flexDirection: 'row', color: '#405364', fontSize: 24 }}>
            <TranslationView>
                {children}
            </TranslationView>
        </div>
    )
}

export default FormTitle;