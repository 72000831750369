import React from 'react';
import { format, getDayOfYear, isFirstDayOfMonth, isLastDayOfMonth, getMonth, startOfMonth, lastDayOfMonth, startOfWeek, lastDayOfWeek, parseISO } from 'date-fns';
import { useTranslation } from 'src/components/localizationContext/LocalizationContext';
import DateButton from '../date-button';
import './style.css'

const Buttons = ({ startDate, endDate, setStartDate, setEndDate }) => {
    // Attributes
    const { translate } = useTranslation()
    const thisMonth = { startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'), endDate: format(lastDayOfMonth(new Date()), 'yyyy-MM-dd') }
    const thisWeek = { startDate: format(startOfWeek(new Date()), 'yyyy-MM-dd'), endDate: format(lastDayOfWeek(new Date()), 'yyyy-MM-dd') }
    const today = format(new Date(), 'yyyy-MM-dd')

    // Methods
    function handleThisMonth() {
        setStartDate(thisMonth.startDate)
        setEndDate(thisMonth.endDate)
    }

    function handleThisWeek() {
        setStartDate(thisWeek.startDate)
        setEndDate(thisWeek.endDate)
    }

    function handleToday() {
        setStartDate(today)
        setEndDate(today)
    }

    function handleIsSelected(type) {
        switch (type) {
            case 'today':
                return startDate === endDate;
            case 'week':
                return getDayOfYear(parseISO(endDate)) === (getDayOfYear(parseISO(startDate)) + 6);
            case 'month':
                return isFirstDayOfMonth(parseISO(startDate)) && isLastDayOfMonth(parseISO(endDate)) && (getMonth(parseISO(startDate)) === getMonth(parseISO(endDate)));
            default:
                return false;
        }
    }
    return (
        <div className='dashboard-filter-date-buttons-container'>
            <DateButton title={translate('today')} isSelected={handleIsSelected('today')} onClick={handleToday} />
            <DateButton title={translate('this week')} isSelected={handleIsSelected('week')} onClick={handleThisWeek} />
            <DateButton title={translate('this month')} isSelected={handleIsSelected('month')} onClick={handleThisMonth} />
        </div>
    )
}

export default Buttons;