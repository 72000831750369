export default class CertificateTableRowStyle {
    button: React.CSSProperties =
        {
            width: 20,
            height: 20,
            fill: 'white',
            color: 'white',
            margin: 5,
            cursor: 'pointer'
        }

    buttonImage: React.CSSProperties =
        {
            width: 30,
            height: 30,
            fill: 'white',
            color: 'white',
            margin: 5,
            cursor: 'pointer',
        }

    td: React.CSSProperties =
        {
            WebkitUserSelect: "text"
        }
}