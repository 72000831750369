import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'src/components/localizationContext/LocalizationContext';
import HeaderView from '../../components/headerView/HeaderView';
import { InputView } from '../../../../components/inputView/InputView';
import DoubleClickButtonView from '../../../../components/buttonView/DoubleClickButtonView';
import PeerMessagingService from '../../../../services/PeerMessagingService';
import './style.css';
import { PeerMessagingDTO } from 'src/dtos/PeerMessagingDTO';
import AlertHelper from 'src/helpers/AlertHelper';

const PeerMessaging = () => {

    //Attributes
    const { translate } = useTranslation();
    const [number, setNumber] = useState("");
    const [message, setMessage] = useState("");
    const [isBusy, setIsBusy] = useState(false);
    const peerMessagingService = new PeerMessagingService();

    //Functions
    async function handleSendMessage() {
        setIsBusy(true);

        const peerMessaginDTO:PeerMessagingDTO = {
            Body: message,
            Receiver: number
        };

        const response = await peerMessagingService.post(peerMessaginDTO);

        if(response) {
            setMessage("");
            setNumber("");
            AlertHelper.success(`${translate("peerMessageSent")}`);
        }else {
            AlertHelper.danger(`${translate("peerMessageError")}`);
        }
        setIsBusy(false);
    }

    //Render
    return (
        <div className="col">
            <div className="peerMessagin-header">
                <motion.div initial={{ y: -20 }} animate={{ y: 0 }}>
                    <HeaderView title={'Communications'} history={[{ name: translate('Communications'), path: "/peer-messaging" }]} />
                </motion.div>
            </div>

            <div className="peerMessagin-input-container">
                <div className="peerMessagin-number-input">
                    <InputView type="text" placeholder="GSM number" value={number} onChange={(e) => setNumber(e.target.value)} />
                </div>
                <div className="peerMessagin-message-input">
                    <InputView type="textarea" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} />
                </div>
            </div>

            <div className="peer-messaging-button-container">
                <DoubleClickButtonView
                    isBusy={isBusy}
                    onClick={handleSendMessage}
                    width={215}
                    image="icon_double_confirmation.svg"
                >
                    peerMessageSendMessage
                </DoubleClickButtonView>
            </div>

        </div>
    )
}

export default PeerMessaging;