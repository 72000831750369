import CheckDTO from 'src/models/CheckDTO';

const checkRequired = (element: any, target: string, errors: []) => {
    let newTarget = target;

    if (newTarget.includes('.')) {
        const targetArray = newTarget.split('.');
        targetArray.forEach((elt, index) => {
            if (index < targetArray.length - 1) {
                element = element[elt];
            }
            else {
                newTarget = elt;
            }
        });
    }

    if (element[newTarget] === '' || element[newTarget] === undefined) {
        errors[target] = true;
    }

    if (element[newTarget] !== undefined) {
        if (Array.isArray(element[newTarget])) {
            if (element[newTarget].length == 0) {
                errors[target] = true;
            }
        }
    }

    return errors;
}

function isCheck(check: CheckDTO) {
    switch (check.state) {
        case 1:
        case 5:
        case 6:
        case 4:
        case 9:
        case 0:
        case 2:
            return true;
        default:
            return false;
    }
}

const ValidationHelper = { checkRequired, isCheck };

export default ValidationHelper;