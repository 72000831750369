import DetailComponentStyle from '../../../../abstracts/detailComponent/DetailComponentStyle';

export default class CompanyServiceViewStyle extends DetailComponentStyle
{
    service: React.CSSProperties =
    {
        color: '#68737E',
        fontWeight:300,
        fontSize: 16
    }

    cardHeaderTitle: React.CSSProperties = 
    {
        marginTop: 6.5
    }

    creditHistoryButton: React.CSSProperties =
    {
        marginLeft:'auto',
        float:'right'   
    }

    creditButton: React.CSSProperties =
    {
        margin: 'auto',
        marginTop: 20,
    }

    creditButtonImage: React.CSSProperties =
    {
        width:20, 
        height:20, 
        marginTop: -2,
        marginRight:15,
        filter:'invert(100%) sepia(0%) saturate(7485%) hue-rotate(273deg) brightness(104%) contrast(100%)'
    }

    button: React.CSSProperties =
    {
        marginTop:20,
        marginLeft:'auto',
        marginRight:17.5,
        float:'right'
    }

    buttonImage: React.CSSProperties =
    {
        width:20, 
        height:20, 
        marginTop: -2,
        marginRight:15
    }

    buttonText: React.CSSProperties =
    {
        fontWeight:400, 
        marginTop:3,
        fontSize:17
    }
}