const execute = async (promise: Promise<any>) => {
    try {
        return await promise;
    }
    catch (exception) {
        throw exception;
    }
}

const NetworkHelper = { execute };

export default NetworkHelper;