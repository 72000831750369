import Service from './Service';
import Axios from 'axios';
import SuperiorDTO from '../models/SuperiorDTO';

export default class SuperiorService extends Service
{
    get(companyId: string)
    {
        return this.execute(Axios.get(`${this.url}/superiors/${companyId}`, this.config()));
    }

    post(body: SuperiorDTO)
    {
        return this.execute(Axios.post(`${this.url}/superiors`, body, this.config()));
    }

    put(body: SuperiorDTO)
    {
        return this.execute(Axios.put(`${this.url}/superiors`, body, this.config()));
    }

    delete(id: string)
    {
        return this.execute(Axios.delete(`${this.url}/superiors/${id}`, this.config()));
    }
}