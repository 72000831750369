import React from "react";
import { useTranslation } from "src/components/localizationContext/LocalizationContext";

const CertificateTableHeader = () => {
	// Attributes
	const { translate } = useTranslation();
	return (
		<tr>
			<th scope="col">{translate("Entry date & time", "en")}</th>
			<th scope="col">{translate("Worker ID", "en")}</th>
			<th scope="col">{translate("Worker first name", "en")}</th>
			<th scope="col">{translate("Worker last name", "en")}</th>
			<th scope="col">{translate("Worker language", "en")}</th>
			<th scope="col">{translate("Gender", "en")}</th>
			<th scope="col">{translate("Phone number", "en")}</th>
			<th scope="col">{translate("Address", "en")}</th>
			<th scope="col">{translate("Company", "en")}</th>
			<th scope="col">{translate("Superior", "en")}</th>
			<th scope="col">{translate("start date", "en")}</th>
			<th scope="col">{translate("end date", "en")}</th>
			<th scope="col">{translate("incapacityReason", "en")}</th>
			<th scope="col">{translate("certificateType", "en")}</th>
			<th scope="col">{translate("authorized leave", "en")}</th>
			<th scope="col">{translate("treating doctor", "en")}</th>
			<th scope="col">{translate("certificate link", "en")}</th>
			<th scope="col">{translate("check launched", "en")}</th>
			<th scope="col">{translate("launch check")}</th>
		</tr>
	);
};

export default CertificateTableHeader;
