import Service from './Service';
import Axios from 'axios';
import RequestDTO from '../models/RequestDTO';

export default class RequestService extends Service {
    get() {
        return this.execute(Axios.get(`${this.url}/requests?page=0&size=2000`, this.config()));
    }

    put(body: RequestDTO) {
        return this.execute(Axios.put(`${this.url}/requests`, body, this.config()));
    }
}