import Axios from 'axios';
import Service from './Service';
import CommunicationDTO from '../models/CommunicationDTO';

export default class DoctorCommunicationService extends Service
{
    get(checkId: string, doctorId: string)
    {
        return this.execute(Axios.get(`${this.url}/checks/${checkId}/doctorcommunications/${doctorId}`, this.config()));
    }

    post(checkId: string, doctorId: string, communicationDTO: CommunicationDTO)
    {
        return this.execute(Axios.post(`${this.url}/checks/${checkId}/doctorcommunications/${doctorId}`, communicationDTO, this.config()));
    }
}