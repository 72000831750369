import React, { useState } from 'react';
import ProofOfReceiptService from 'src/services/ProofOfReceiptService';
import { useCheck } from 'src/modules/dashboard/components/checkContext/CheckContext';
import AlertHelper from 'src/helpers/AlertHelper';

const PORButton = ({ log }) => {

    const { handleRefreshLogs, handleRefreshIntegrations, handleRefreshCheck, handleRefreshCommunications } = useCheck();
    const [isHovered, setIsHovered] = useState(false);

    // Methods
    async function handleClicked() {
        AlertHelper.information("Changing official POR");
        await new ProofOfReceiptService().post(log.id);
        handleRefreshCheck();
        AlertHelper.success("Changed official POR");
    }

    return (
        <div onMouseLeave={() => setIsHovered(false)} style={{ display: 'flex', flexDirection: 'row', width: 'fit-content' }}>
            {(isHovered || log.id === log.check.proofOfReceiptId)
                ?
                (
                    <div onClick={handleClicked} style={{ height: 30, width: 30, borderRadius: 15, marginRight: 10, cursor: 'pointer' }}>
                        <img style={{ height: 30, width: 30, borderRadius: 15 }} src={'/assets/official_por_selected.svg'} />
                    </div>
                )
                :
                (
                    <div onMouseOver={() => setIsHovered(true)} style={{ height: 30, width: 30, borderRadius: 15, marginRight: 10, cursor: 'pointer' }}>
                        <img style={{ height: 30, width: 30, borderRadius: 15 }} src={'/assets/official_por.svg'} />
                    </div>
                )
            }
        </div>
    )
}

export default PORButton;