const style = {
    container: {
        padding: 32.5,
        marginTop: 30
    },
    title: {
        color: '#405364',
        fontFamily: 'Roboto',
        fontSize: 18,
        lineHeight: '21px',
        marginBottom: 25
    },
    description: {
        marginTop: 5,
        color: '#68737E',
        fontFamily: 'Roboto',
        fontSize: '16px',
        lineHeight: '19px'
    },
    suggestionContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    inputContainer: {
        marginTop: 20,
    },
    inputTitle: {
        marginTop: 5,
        marginBottom: 10,
        color: '#68737E',
        fontFamily: 'Roboto',
        fontSize: '16px',
        lineHeight: '19px'
    },
    input: {
        marginTop: 5,
        width: 220
    }
}

export default style;