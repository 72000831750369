import * as React from 'react';
import DashboardShellViewStyle from './DashboardShellViewStyle';
import DashboardShellViewState from './DashboardShellViewState';
import SideBarView from './components/sideBarView/SideBarView';
import CompanyShellView from './modules/company/CompanyShellView';
import RequestView from './modules/request/RequestView';
import { Switch, Route, Redirect } from 'react-router';
import DashboardView from './modules/dashboard/DashboardView';
import CheckView from './modules/check';
import ArchiveView from './modules/archive/ArchiveView';
import DoctorShellView from './modules/doctor/DoctorShellView';
import { Spring } from 'react-spring';
import { App } from '../../app';
import SettingShellView from './modules/settings/SettingShellView';
import Service from '../../services/Service';
import CheckDetailShellView from './modules/checkdetail/CheckDetailShellView';
import withUser from 'src/functions/withUser';
import DashboardBoardView from './modules/board';
import Certificate from './modules/certificate';
import PeerMessaging from './modules/peer-messaging';
import DashboardDetail from './modules/dashboard/detail';
import NewCertificate from './modules/new-certificate';
import HomeView from './modules/home/HomeView';

class DashboardShellView extends React.Component<any, DashboardShellViewState> {
	/* ATTRIBUTES */
	style: DashboardShellViewStyle = new DashboardShellViewStyle();

	/* CONSTRUCTOR */
	constructor(props: {}) {
		super(props);

		// Initial state
		this.state = { appearing: false };

		// Bindings
		this.handleLogOut = this.handleLogOut.bind(this);
	}

	/* LIFE CYCLE EVENTS */
	componentDidMount() {
		setTimeout(() => this.setState({ appearing: true }), 100);
	}

	/* METHODS */
	handleLogOut() {
		const { logOut } = this.props;

		setTimeout(() => {
			logOut();
			App.history.push('/login', { redirect: true, shouldSignOut: true });
		}, 200);
	}

	handleUserSettings() {
		App.navigate('/dashboard/setting', undefined);
	}

	/* RENDERING */
	render() {
		return (
			<div style={this.style.container}>
				{/* SIDE BAR ANIMATION */}
				<Spring
					from={{ translate: -90 }}
					to={{ translate: this.state.appearing ? 0 : -90 }}
				>
					{(style: any) => {
						return (
							<div style={{ marginLeft: style.translate }}>
								<SideBarView
									handleLogOut={this.handleLogOut}
									handleUserSettings={this.handleUserSettings}
								/>
							</div>
						);
					}}
				</Spring>

				{/* CONTENT ANIMATION */}
				<Spring
					from={{ translate: -90 }}
					to={{ translate: this.state.appearing ? 0 : -90 }}
				>
					{(style: any) => {
						return (
							<div
								style={{ ...this.style.content, marginTop: style.translate }}
							>
								<div>
									<Switch>
										<Route
											exact
											path='/dashboard/certificates'
											render={() => <NewCertificate />}
										/>
										<Route
											exact
											path='/dashboard/detail'
											render={() => <DashboardDetail />}
										/>
										<Route
											exact
											path='/dashboard/global'
											render={() => <DashboardView />}
										/>

										<Route
											exact
											path='/dashboard/peer-messaging'
											render={() => <PeerMessaging />}
										/>

										<Route
											exact
											path='/dashboard/board'
											render={() => <DashboardBoardView />}
										/>

										<Route
											path='/dashboard/certificate'
											render={() => <Certificate />}
										/>

										<Route
											path='/dashboard/(check|request|archive)/(detail|schedule)'
											render={() => <CheckDetailShellView />}
										/>

										<Route
											path='/dashboard/request'
											render={() => <RequestView />}
										/>

										<Route
											exact
											path='/dashboard/check'
											render={() => <CheckView />}
										/>

										<Route
											path='/dashboard/archive'
											render={() => <ArchiveView />}
										/>

										<Route
											path='/dashboard/doctor'
											render={() => <DoctorShellView />}
										/>

										<Route
											path='/dashboard/company'
											render={() => <CompanyShellView />}
										/>

										<Route
											path='/dashboard/setting'
											render={() => <SettingShellView />}
										/>

										<Route path='/dashboard/home' render={() => <HomeView />} />

										<Route
											path='/dashboard'
											render={() => <Redirect to='/dashboard/home' />}
										/>
									</Switch>
								</div>

								<div style={this.style.placeholder} />
							</div>
						);
					}}
				</Spring>
			</div>
		);
	}
}

export default withUser(DashboardShellView);
