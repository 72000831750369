import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { App } from "src/app";
import { useTranslation } from "src/components/localizationContext/LocalizationContext";
import useSearch from "src/components/useSearch";
import UserDTO from "src/models/UserDTO";
import ListView from "src/modules/dashboard/components/listView/ListView";
import SearchView from "src/modules/dashboard/components/searchView";
import UserService from "src/services/UserService";
import UserRow from "./components/user-row";
import UserTableHeader from "./components/user-table-header";

const searchValues = ["firstName", "lastName"];

const SettingView = () => {
	// Attributes
	const { translate } = useTranslation();
	const service = new UserService();
	const query = useQuery("users", refreshUsers);
	const { search, setSearch, filtered } = useSearch(query.data, searchValues);
	const [page, setPage] = useState(0);

	// Effects
	useEffect(() => {
		query.refetch();
	}, []);

	// Methods
	async function refreshUsers() {
		const response = await service.get();

		return response.sort(sortTable);
	}

	function sortTable(a: UserDTO, b: UserDTO) {
		const firstFullName = `${a.firstName} ${a.lastName}`;
		const secondFullName = `${b.firstName} ${b.lastName}`;

		if (firstFullName < secondFullName) {
			return -1;
		}

		if (firstFullName === secondFullName) {
			return 0;
		}

		return 1;
	}

	return (
		<div>
			<SearchView title={translate("users")} history={[{ name: translate("users"), path: "/dashboard/setting" }]} handleSearchChanged={(event) => setSearch(event.target.value)} search={search} isBusy={query.status !== "success"} handleNew={() => App.history.push("/dashboard/setting/detail")}>
				<ListView
					isBusy={filtered === undefined}
					mapElements={(user: UserDTO, index: number) => {
						return <UserRow user={user} index={index} />;
					}}
					mapHeaders={UserTableHeader}
					elements={filtered ? filtered : []}
					handlePageChanged={setPage}
					page={page}
					hasBusyIndicator
				/>
			</SearchView>
		</div>
	);
};

export default SettingView;
