import React, { useCallback, useEffect, useState } from 'react';
import csv from 'csv';
import { App } from 'src/app';
import { useDropzone } from 'react-dropzone';
import SuperiorDTO from 'src/models/SuperiorDTO';
import CompanyConfigurationSuperiorService from 'src/services/CompanyConfigurationSuperiorService';
import CustomModal from 'src/components/modal';
import { useUser } from 'src/components/userContext/UserContext';
import AlertHelper from 'src/helpers/AlertHelper';
import Button from 'src/components/button';

export default function SuperiorUploadButton() {
	// Attributes
	const companyId = App.history.location.state.company.id;
	const onDrop = useCallback((acceptedFiles) => {
		handleSuperiorCSVUpload(acceptedFiles[0]);
	}, []);
	const { getInputProps, getRootProps } = useDropzone({ onDrop });
	const { user } = useUser();
	const [isOpen, setIsOpen] = useState(false);
	const [superiors, setSuperiors] = useState<SuperiorDTO[]>([]);
	const [isLoading, setisLoading] = useState(false);

	// Effects
	useEffect(() => {
		if (superiors?.length > 0) {
			setIsOpen(true);
		}
	}, [superiors]);

	// Functions
	async function handleSuperiorUpdate() {
		try {
			setisLoading(true);
			const response =
				await new CompanyConfigurationSuperiorService().addSuperiors(
					companyId,
					superiors
				);

			if (response) {
				AlertHelper.success('Superiors added successfully');
			}

			return response;
		} catch (error) {
			AlertHelper.danger(error.message);
		} finally {
			setSuperiors([]);
			setIsOpen(false);
			setisLoading(false);
		}
	}

	async function handleSuperiorCSVUpload(file: any) {
		const reader = new FileReader();

		reader.onload = () => {
			// Parse CSV file
			const parser = csv as any;
			const tmpSuperiors = [];

			parser.parse(
				reader.result,
				{ delimiter: ';', encoding: 'utf8' },
				async (err: any, data: any) => {
					// iterate through the data and create a superiorDTO for each row
					for (let i = 1; i < data.length; i++) {
						const superior: SuperiorDTO = {
							id: '',
							firstName: data[i][0],
							lastName: data[i][1],
							fullName: data[i][0] + ' ' + data[i][1],
							email: data[i][2],
							phoneNumber: data[i][3],
							language: data[i][4],
							isInterface: false,
							companyId: companyId,
							requestForm: data[i][5],
						};
						tmpSuperiors.push(superior);
					}
					setSuperiors(tmpSuperiors);
				}
			);
		};

		reader.readAsText(file);
	}

	// Render
	return (
		<div {...getRootProps()}>
			<input {...getInputProps()} />
			<Button iconPath='/assets/icon_upload_3.svg' />
			<CustomModal
				isLoading={isLoading}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				name={`Hey ${user?.firstName}!`}
				description='uploadCompanySuperiors'
				confirmButtonText='uploadCompanySuperiorsConfirm'
				cancelButtonText='oopsNoGoBack'
				imagePath='/assets/illu_red_warning.png'
				confirmButtonIconPath='/assets/Upload-white.svg'
				confirmButtonColor='#EC5467'
				nameTextColor='#EC5467'
				onCancel={() => setIsOpen(false)}
				onConfirm={handleSuperiorUpdate}
			/>
		</div>
	);
}
