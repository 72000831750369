import * as React from 'react';
import TableViewProps from './TableViewProps';
import TableViewState from './TableViewState';
import TableViewStyle from './TableViewStyle';
import { motion } from 'framer-motion';

export class TableView extends React.Component<TableViewProps, TableViewState>
{
	/* ATTRIBUTES */
	style: TableViewStyle = new TableViewStyle();

	/* CONSTRUCTOR */
	constructor(props: TableViewProps) {
		super(props);

		// Initial State
		this.state = { page: 0 };
	}

	/* RENDERING */
	render() {
		return (
			<div className="table-responsive" style={{ overflow: this.props.overflow }}>
				<motion.table initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { ease: 'linear' } }} className="table align-items-center">
					<thead className="thead-light">
						{
							this.props.children
						}
					</thead>
					<tbody>
						{
							this.props.values.slice(this.props.page * 8, ((this.props.page * 8) + 8))?.map(this.props.map)
						}
					</tbody>
				</motion.table>
			</div >
		);
	}
}

