export const CommunicationConstants = {
   WORKER_RESULT_ER : "WORKER_RESULT_ER",
   WORKER_OFFICE : "WORKER_OFFICE",
   WORKER_TELECHECK : "WORKER_TELECHECK",
   WORKER_HOUSE : "WORKER_HOUSE",
   WORKER_MODIFICATION_OFFICE : "WORKER_MODIFICATION_OFFICE",
   WORKER_MODIFICATION_HOUSE : "WORKER_MODIFICATION_HOUSE",
   WORKER_MODIFICATION_TELECHECK : "WORKER_MODIFICATION_TELECHECK",
   SUPERIOR_REQUEST_CONFIRMATION : "SUPERIOR_REQUEST_CONFIRMATION",
   WORKER_2DE_CHECK : "WORKER_2DE_CHECK",
   SUPERIOR_2DE_CHECK : "SUPERIOR_2DE_CHECK",
   SUPERIOR_GSM_PROBLEM : "SUPERIOR_GSM_PROBLEM",
   TELECHECK_TO_SUPERIOR : "TELECHECK_TO_SUPERIOR",
}
