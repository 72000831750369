import FormTitle from "src/modules/dashboard/components/formTitle";
import LeftGroup from "./components/left-group";
import RightGroup from "./components/right-group";
import { MyCertificateRandomChecksSetupProps } from "./props";

const MyCertificateRandomChecksSetup = ({
  dispatch,
  myCertificate,
}: MyCertificateRandomChecksSetupProps) => {
  // Attributes

  return (
    <div
      className="card shadow"
      style={{ marginTop: 30, width: 1047, marginBottom: 30 }}
    >
      <FormTitle>RandomChecksSetup</FormTitle>
      <div
        className="card-body"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <LeftGroup dispatch={dispatch} myCertificate={myCertificate} />
        <RightGroup dispatch={dispatch} myCertificate={myCertificate} />
      </div>
    </div>
  );
};

export default MyCertificateRandomChecksSetup;
