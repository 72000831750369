import * as React from 'react';
import SeparatorView from '../components/SeparatorView';

const DeliveryLogView = ({ log }) => {

    if (log.type !== "SMS") {
        return (
            <div />
        )
    }

    return (
        <>
            <SeparatorView />
            <div>
            </div>
        </>
    );
}

export default DeliveryLogView;