import React from 'react';
import './style.css';

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	text?: string;
	iconPath?: string;
	customStyle?: any;
}

export default function Button({
	text = '',
	iconPath = '/assets/icon_download.svg',
	style: customStyle,
	...rest
}: ButtonProps) {
	return (
		<button
			type='button'
			className='btn btn-base btn-base-primary'
			{...rest}
			style={{ ...customStyle }}
		>
			<span>
				<img
					className='img-base-primary'
					src={iconPath}
					style={{ ...style.downloadIcon, marginRight: text ? '14px' : '0px' }}
				/>
				<span className='roboto' style={style.newText}>
					{text}
				</span>
			</span>
		</button>
	);
}

const style = {
	newIcon: {
		width: 20,
		height: 20,
		marginRight: 15,
		filter:
			'invert(100%) sepia(0%) saturate(7496%) hue-rotate(272deg) brightness(100%) contrast(103%)',
	},

	downloadIcon: {
		width: 20,
		height: 20,
	},

	newText: {
		fontWeight: 600,
		marginTop: -20,
		fontSize: 16,
	},
};
