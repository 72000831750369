import React from 'react';
import TranslationView from 'src/components/translationView/TranslationView';
import SearchViewStyle from '../../../../SearchViewStyle';

const NewButton = ({ handleNew }: any) => {

    const style = new SearchViewStyle();

    return (
        <button onClick={handleNew} type="button" className="btn btn-primary" style={style.newContainer}>
            <span>
                <img src='/assets/icon_new.svg' style={style.newIcon} />
                <span className='roboto' style={style.newText}>
                    <TranslationView>
                        new
                    </TranslationView>
                </span>
            </span>
        </button>
    )
}

export default NewButton;