import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'src/components/localizationContext/LocalizationContext';
import { InputView } from 'src/components/inputView/InputView';
import SearchViewStyle from '../../../../SearchViewStyle';


const SearchInput = ({ cardHeaderChildren, search, handleSearchChanged }: any) => {

    // Attributes
    const { translate } = useTranslation()
    const style = new SearchViewStyle();

    return (
        <div style={style.searchContainer}>
            <motion.div whileHover={{ width: 350 }} style={style.searchInput}>
                <InputView placeholder={translate('search', 'en')} type="email" value={search} onChange={handleSearchChanged}>
                    <img src='/assets/icon_search.svg' style={style.searchIcon} />
                </InputView>
            </motion.div>
            {cardHeaderChildren && cardHeaderChildren}
        </div>
    )
}

export default SearchInput;