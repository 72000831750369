import TranslationView from "src/components/translationView/TranslationView";

const CompanyCommunicationTableSubheader = ({element}) => {
    return (<tr>
        <th colSpan={5} style={{padding:0}}>
            <div style={{backgroundColor:'#E3E3E3', width:'100%', justifyContent:'center', display:'flex', fontWeight:400}}>
                <TranslationView>
                    {`COMMUNICATION_TYPE_${element.communication.type}`}
                </TranslationView>
            </div>
        </th>
    </tr>);
}

export default CompanyCommunicationTableSubheader;