import React, { useContext } from "react";
import AccordeonView from "../../../../../../components/accordeonView/AccordeonView";
import { InputView } from "../../../../../../../../components/inputView/InputView";
import { CheckContext } from "src/modules/dashboard/components/checkContext/CheckContext";
import FileButtonView from "../../../../components/FileButtonView/FileButtonView";
import CheckRequestInformationStyle from "./CheckRequestInformationStyle";
import { CheckStatus } from "src/constants/CheckStatus";
import { isRequest } from "src/helpers/CheckStatusHelper";

enum incapacityType {
	illness = 0,
	workAccident = 1,
}

enum certificateType {
	firstCertificate = 0,
	prolongation = 1,
	relapse = 2,
}

enum incapacityPercentage {
	twentyFive = 0,
	fifty = 1,
	hundred = 2,
}

const CheckRequestInformationView = ({ errors, handleRequestSync, handleDeleteMedicalCertificateClicked, handleMedicalCertificateClicked }) => {
	//Attributes
	const { check, handleInputChanged } = useContext(CheckContext);
	const archived = check.state === 0;
	const style = new CheckRequestInformationStyle();
	const { request } = check;

	//Render
	return (
		<AccordeonView isOpenByDefault={archived || isRequest(check) || check.state === CheckStatus.REQUESTUPDATED} title="Request Information" header={<FileButtonView disabled={archived} type="MEDICAL_CERTIFICATE" fileName={request.medicalCertificate} checkId={check.id} onDeleteClicked={handleDeleteMedicalCertificateClicked} onClick={handleMedicalCertificateClicked} />}>
			<div className="row">
				{/* START DATE */}
				<div style={style.inputLeft}>
					<div style={style.inputTitle}>Incapacity Start Date*</div>
					<InputView disabled={archived} error={errors["request.startDate"]} updateSync={handleRequestSync} placeholder="01/01/2020" type="date" value={request.startDate} onChange={(event) => handleInputChanged("request.startDate", event.target.value)} />
				</div>

				{/* END DATE */}
				<div style={style.inputRight}>
					<div style={style.inputTitle}>Incapacity End Date</div>
					<InputView disabled={archived} updateSync={handleRequestSync} placeholder="01/01/2020" type="date" value={request.endDate} onChange={(event) => handleInputChanged("request.endDate", event.target.value)} />
				</div>
			</div>

			<div className="row">
				{/* INCAPACITY TYPE */}
				<div style={style.inputLeft}>
					<div style={style.inputTitle}>Incapacity type</div>
					<InputView disabled={archived} error={errors["incapacityType"]} updateSync={handleRequestSync} placeholder="Select an option" type="select" value={request.incapacityType} onChange={(event) => handleInputChanged("request.incapacityType", event.target.value)}>
						<option key={"ILLNESS"} value={incapacityType.illness}>
							Illness
						</option>
						<option key={"WORKACCIDENT"} value={incapacityType.workAccident}>
							Work accident
						</option>
					</InputView>
				</div>

				{/* CERTIFICATE TYPE */}
				<div style={style.inputRight}>
					<div style={style.inputTitle}>Certificate type</div>
					<InputView disabled={archived} error={errors["certificateType"]} updateSync={handleRequestSync} placeholder="Select an option" type="select" value={request.certificateType} onChange={(event) => handleInputChanged("request.certificateType", event.target.value)}>
						<option key={"-1"} value={-1}></option>
						<option key={"FIRSTCERTIFICATE"} value={certificateType.firstCertificate}>
							1st certificate
						</option>
						<option key={"PROLONGATION"} value={certificateType.prolongation}>
							Prolongation
						</option>
						<option key={"RELAPSE"} value={certificateType.relapse}>
							Relapse
						</option>
					</InputView>
				</div>
			</div>

			<div className="row">
				{/* INCAPACITY PERCENTAGE */}
				<div style={{ ...style.inputLeft, marginLeft: "40px" }}>
					<div style={style.inputTitle}>Incapacity percentage</div>
					<InputView disabled={archived} error={errors["incapacityPercentage"]} updateSync={handleRequestSync} placeholder="Select an option" type="select" value={request.incapacityPercentage} onChange={(event) => handleInputChanged("request.incapacityPercentage", event.target.value)}>
						<option key={"25%"} value={incapacityPercentage.twentyFive}>
							25%
						</option>
						<option key={"50%"} value={incapacityPercentage.fifty}>
							50%
						</option>
						<option key={"100%"} value={incapacityPercentage.hundred}>
							100%
						</option>
					</InputView>
				</div>
				<div></div>
			</div>

			<div className="row">
				{/* TIMING OF CHECK */}
				<div style={style.inputLeft}>
					<div style={style.inputTitle}>Timing of Check*</div>
					<InputView disabled={archived} error={errors["timing"]} updateSync={handleRequestSync} placeholder="Select an option" type="select" value={request.timing} onChange={(event) => handleInputChanged("request.timing", event.target.value)}>
						<option key={"IMMEDIATE"} value={"IMMEDIATE"}>
							Immediat
						</option>
						<option key={"OPTIMIZED"} value={"OPTIMIZED"}>
							Optimized
						</option>
					</InputView>
				</div>

				{/* LOCATION OF CHECK */}
				<div style={style.inputRight}>
					<div style={style.inputTitle}>Location of Check*</div>
					<InputView disabled={archived} error={errors["location"]} updateSync={handleRequestSync} placeholder="Select an option" type="select" value={request.location} onChange={(event) => handleInputChanged("request.location", event.target.value)}>
						<option key={"OFFICE"} value={1}>
							Doctor
						</option>
						<option key={"HOUSE"} value={0}>
							Home
						</option>
						<option key={"TELECHECK"} value={2}>
							Telecheck
						</option>
					</InputView>
				</div>
			</div>

			{/* COMMENT OF DOCTOR */}
			<div style={style.inputDouble}>
				<div style={style.inputTitle}>Comment for the Doctor</div>
				<InputView disabled={archived} updateSync={handleRequestSync} type="textarea" placeholder="Comment" value={request.doctorComment} onChange={(event) => handleInputChanged("request.doctorComment", event.target.value)} />
			</div>
		</AccordeonView>
	);
};

export default CheckRequestInformationView;
