export default class DoctorDTO {
  id: string;

  firstName: string;
  lastName: string;

  fullName: string = '';

  gender: number;
  inami: string;
  mainLanguage: string;
  otherLanguages: string;
  email: string;
  gsm: string;
  fixNumber: string;
  faxNumber: string;
  appointmentWebsite: string;
  appointmentMethods: AppointmentMethodType[];
  inhouseAppreciation: number;
  telecheckLink: string;

  bankAccountNumber: string;
  companyName: string;
  companyNumber: string;
  invoicingNumber: string;
  companyAddress: string;
  officeAddress: string;
  longitude: string | undefined;
  latitude: string | undefined;
  subjectToVAT: boolean | undefined;

  signedDate: string;
  startDate: string;
  endDate: string;
  comments: string;
  salesRepresentative: string;

  weeklyScheduleDescription: string;
  holidays: string;

  constructor() {
    this.fullName = this.firstName + ' ' + this.lastName;
  }
}

export enum AppointmentMethodType {
  SMS = 'sms',
  Email = 'email',
  Phone = 'phone',
  Online = 'online',
  Schedule = 'automated schedule',
}

export function mapAppointmentMethodsToEnum(
  methods: any
): AppointmentMethodType[] {
  const appointmentMethodValues: AppointmentMethodType[] = [];

  methods.forEach((method) => {
    switch (method) {
      case 0:
        appointmentMethodValues.push(AppointmentMethodType.SMS);
        break;
      case 1:
        appointmentMethodValues.push(AppointmentMethodType.Email);
        break;
      case 2:
        appointmentMethodValues.push(AppointmentMethodType.Phone);
        break;
      case 3:
        appointmentMethodValues.push(AppointmentMethodType.Online);
        break;
      case 4:
        appointmentMethodValues.push(AppointmentMethodType.Schedule);
        break;
      default:
        break;
    }
  });
  return appointmentMethodValues;
}

export function mapAppointmentMethodsFromEnum(
  methods: AppointmentMethodType[]
): number[] {
  const appointmentMethodValues: number[] = [];

  methods.forEach((method) => {
    switch (method) {
      case AppointmentMethodType.SMS:
        appointmentMethodValues.push(0);
        break;
      case AppointmentMethodType.Email:
        appointmentMethodValues.push(1);
        break;
      case AppointmentMethodType.Phone:
        appointmentMethodValues.push(2);
        break;
      case AppointmentMethodType.Online:
        appointmentMethodValues.push(3);
        break;
      case AppointmentMethodType.Schedule:
        appointmentMethodValues.push(4);
        break;
      default:
        break;
    }
  });

  return appointmentMethodValues;
}
