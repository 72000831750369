import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import SuperiorView from './modules/list/SuperiorView';
import SuperiorDetailView from './modules/detail/SuperiorDetailView';


export default class SuperiorShellView extends React.Component 
{
    /* RENDERING */
	render()
	{
		return (
			<Switch>
				<Route exact path="/dashboard/company/superior" render={() => <SuperiorView />} />
				<Route path="/dashboard/company/superior/detail" render={() => <SuperiorDetailView />} />
				<Route path="/dashboard/company/superior" render={() => <Redirect to="/dashboard/company/superior" />} />
			</Switch>
		);
	}
}

