import * as React from 'react';
import CompanyView from './modules/list/CompanyView';
import { Switch, Route, Redirect } from 'react-router';
import SuperiorShellView from './modules/superior/SuperiorShellView';
import CompanyDetailView from './modules/detail/CompanyDetailView';
import CompanyServiceView from './modules/service/CompanyServiceView';
import CompanyMyCertificate from './modules/mycertificate';


export default class CompanyShellView extends React.Component {
	/* RENDERING */
	render() {
		return (
			<Switch>
				<Route exact path="/dashboard/company" render={() => <CompanyView />} />
				<Route path="/dashboard/company/detail" render={() => <CompanyDetailView />} />
				<Route path="/dashboard/company/service" render={() => <CompanyServiceView />} />
				<Route path="/dashboard/company/superior" render={() => <SuperiorShellView />} />
				<Route path="/dashboard/company/mycertificate" render={() => <CompanyMyCertificate />} />
				<Route path="/dashboard/company" render={() => <Redirect to="/dashboard/company" />} />
			</Switch>
		);
	}
}

