import Service from './Service';
import Axios from 'axios';
import CompanyDoctorDTO from '../models/CompanyDoctorDTO';

export default class CompanyDoctorService extends Service
{
    get(companyId: string)
    {
        return this.execute(Axios.get(`${this.url}/companies/${companyId}/doctors`, this.config()));
    }

    post(companyId:string,body: CompanyDoctorDTO[])
    {
        return this.execute(Axios.post(`${this.url}/companies/${companyId}/doctors`, body, this.config()));
    }

    put(companyId:string, body: CompanyDoctorDTO[])
    {
        return this.execute(Axios.put(`${this.url}/companies/${companyId}/doctors`, body, this.config()));
    }

    delete(companyId: string, doctorId: string)
    {
        return this.execute(Axios.delete(`${this.url}/companies/${companyId}/doctors/${doctorId}`, this.config()));
    }
}