import * as React from 'react';
import LogViewStyle from '../LogViewStyle';
import SeparatorView from '../components/SeparatorView';

const CommentLogView = ({ log }) => {

    const style = new LogViewStyle();

    if ((log.type !== "0000000" && log.type !== "0000001" && log.type !== "0000063" && log.type !== "0000062" && log.type !== "0000071" && log.type !== "0000041" && log.type !== "0000072" && log.type !== "0000073" && log.type !== "0000061" && log.type !== "0000075" && log.type !== "0000076" && log.type !== "0000074" && log.type !== "0000077") || (!log.payLoad && !log.comment)) {
        return (
            <div />
        );
    }

    return (
        <div>
            <SeparatorView visible />
            <div style={style.logTitleText}>
                Comment left  :
            </div>
            <div style={{ ...style.logBodyText, fontWeight: 300 }}>
                {
                    log.comment ? log.comment : log.payLoad
                }
            </div>
        </div>
    );
}

export default CommentLogView;