import DetailComponentStyle from '../../../../abstracts/detailComponent/DetailComponentStyle';

export default class CheckDetailViewStyle extends DetailComponentStyle {
    cardBody: React.CSSProperties =
        {
            padding: 0,
            paddingTop: 5
        }

    scheduleButton: React.CSSProperties =
        {
            height: 48.25,
            width: '100%',
            margin: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 0
        }

    parameterButton: React.CSSProperties =
        {
            height: 45,
            width: 35,
            margin: 22.5,
            marginTop: 5,
            marginBottom: 0
        }

    button: React.CSSProperties =
        {
            height: 45,
            width: 240,
            margin: 22.5,
            marginTop: 5,
            marginBottom: 0, 
            padding: 5
        }

    buttonImage: React.CSSProperties =
        {
            width: 20,
            height: 20,
            marginTop: 0,
            marginRight: 5
        }

    buttonText: React.CSSProperties =
        {
            fontWeight: 400,
            marginTop: -2,
            marginLeft: 4,
            fontSize: 15
        }

    buttonContent: React.CSSProperties =
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around'
        }

    communicationsWithToolBox: React.CSSProperties =
        {
            flex: 1, display: 'flex', flexDirection: 'column', marginLeft: 35, marginRight: 85
        }

    communicationsWithoutToolBox: React.CSSProperties =
        {
            flex: 1, display: 'flex', flexDirection: 'column', marginLeft: 35,
            maxWidth: 'calc(100% - 517px - 80px)'
        }

    calculationTitle: React.CSSProperties =
        {
            marginLeft: 15,
            fontWeight: 300
        }

    checkDistance: React.CSSProperties =
        {
            ...this.inputHalfLeft,
            marginBottom: 0,
            marginTop: 0
        }

    checkTime: React.CSSProperties =
        {
            ...this.inputHalfRight,
            marginBottom: 0,
            marginTop: 0
        }

    checkPricingRow: React.CSSProperties =
        {
            marginLeft: 10,
            marginTop: 12.5
        }

    buttonSetPrices: React.CSSProperties =
        {
            marginRight: 30,
            width: '100%',
            height: 45
        }

    inputContainerView: React.CSSProperties =
        {
            width: 530
        }

    deleteButtonImage: React.CSSProperties =
        {
            width: 20,
            height: 20,
            marginRight: 15,
            filter: 'invert(100%) sepia(0%) saturate(7500%) hue-rotate(6deg) brightness(103%) contrast(103%)',
            cursor: 'pointer'
        }

    deleteButtonText: React.CSSProperties =
        {
            fontWeight: 600,
            marginTop: -20,
            fontSize: 16
        }

    modal: React.CSSProperties =
        {
            height: 535,
            width: 400,
            textAlign: 'center'
        }

    modalContainer: React.CSSProperties =
        {
            height: 535,
            width: 400,
            padding: 22
        }

    closeButtonContainer: React.CSSProperties =
        {
            cursor: 'pointer',
            height: 25,
            width: 15,
            padding: 0,
            marginLeft: 'auto'
        }

    closeButton: React.CSSProperties =
        {
            height: 25,
            width: 25,
            marginLeft: -5
        }

    modalIllustration: React.CSSProperties =
        {
            margin: 'auto',
            height: 250,
            width: 250
        }

    nameContainer: React.CSSProperties =
        {
            color: '#EC5467',
            fontWeight: 500,
            fontSize: 16,
            margin: 'auto',
            width: '100%',
            textAlign: 'center',
            marginTop: 25
        }

    name: React.CSSProperties =
        {
            margin: 'auto'
        }

    validation: React.CSSProperties =
        {
            width: '100%',
            fontSize: 18,
            fontWeight: 300,
            color: '#405364',
            margin: 'auto',
            textAlign: 'center'
        }

    deleteButton: React.CSSProperties =
        {
            margin: 'auto',
            marginTop: 20,
            boxShadow: 'none'
        }

    dontSaveButtonContainer: React.CSSProperties =
        {
            marginTop: 15
        }

    dontSaveButton: React.CSSProperties =
        {
            margin: 'auto',
            textAlign: 'center',
            color: '#7D91A0',
            fontWeight: 300,
            fontSize: 16,
            textDecoration: 'underline',
            cursor: 'pointer'
        }


    creditButtonImage: React.CSSProperties =
        {
            width: 20,
            height: 20,
            marginTop: -2,
            marginRight: 15,
            filter: 'invert(100%) sepia(0%) saturate(7485%) hue-rotate(273deg) brightness(104%) contrast(100%)'
        }

    creditButton: React.CSSProperties =
        {
            margin: 'auto',
            marginTop: 20,
        }
    
    lottie: React.CSSProperties = 
    {
        height:45,
        width:150,
        marginTop: -10
    }
}