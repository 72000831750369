import * as React from 'react';
import DateHelper from 'src/helpers/DateHelper';
import LogViewStyle from '../LogViewStyle';

const StampView = ({ log, firstName, lastName, company }) => {

    const style = new LogViewStyle();
    const timeStamp = `${DateHelper.formatDate(log.creationDate)}`;

    return (
        <span style={style.basicStampRight}>
            {`${firstName} ${lastName} (${company}) ${timeStamp}`}
        </span>
    );
}

export default StampView;