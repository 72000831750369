import SuperiorDTO from 'src/models/SuperiorDTO';
import Service from './Service';
import Axios from 'axios';
import MyCertificateCompanyConfigurationSuperiorDTO from '../dtos/MyCertificateCompanyConfigurationSuperiorDTO';

export default class CompanyConfigurationSuperiorService extends Service {
	/* METHODS */
	get(id: string) {
		return this.execute(
			Axios.get(
				`${this.getUrl('v2')}/companies/${id}/configurations/superiors`,
				this.config()
			),
			'Unable to retrieve company details.'
		);
	}

	put(id: string, body: MyCertificateCompanyConfigurationSuperiorDTO) {
		return this.execute(
			Axios.put(
				`${this.getUrl('v2')}/companies/${id}/configurations/superiors`,
				body,
				this.config()
			),
			'Unable to update company.'
		);
	}

	addSuperiors(companyId: string, body: SuperiorDTO[]) {
		const config = {
			headers: {
				Authorization: `bearer ${Service.BEARER_TOKEN}`,
				'Content-Type': 'application/json',
				'x-api-resource': 'companies',
			},
		};

		return this.execute(
			Axios.put(
				`${this.getUrl(
					'new-api'
				)}/companies/${companyId}/configurations/superiors`,
				body,
				config
			),
			'Unable to add superiors, please check your csv file.'
		);
	}
}
