import Service from './Service';
import Axios from 'axios';
import CommunicationDTO from '../models/CommunicationDTO';

export default class CheckCommunicationService extends Service
{
    get(checkId: string)
    {
        return this.execute(Axios.get(`${this.url}/checks/${checkId}/communications`, this.config()));
    }

    post(checkId: string, communicationDTO: CommunicationDTO)
    {
        return this.execute(Axios.post(`${this.url}/checks/${checkId}/communications`, communicationDTO, this.config()));
    }
}