import React from 'react';
import CompanySelect from './components/company';
import DateSelect from './components/date';
import './style.css'

interface FilterTabProps {
    filters: {
        companyFilter: any,
        setCompanyFilter: any,
        startDateFilter: any,
        setStartDateFilter: any,
        endDateFilter: any,
        setEndDateFilter: any
    }
}

const FilterTab = (props: FilterTabProps) => {
    return (
        <div className='dashboard-filter-container'>
            <CompanySelect
                companyFilter={props.filters.companyFilter}
                setCompanyFilter={props.filters.setCompanyFilter}
            />
            <DateSelect
                setStartDate={props.filters.setStartDateFilter}
                setEndDate={props.filters.setEndDateFilter}
                startDate={props.filters.startDateFilter}
                endDate={props.filters.endDateFilter}
            />
        </div>
    )
}

export default FilterTab;