import React from 'react';
import './style.css'

const DateInput = ({ value, onClick }: any) => {
    return (

        <div className='react-datepicker__input-container_dashboard' onClick={onClick}>
            {value}
        </div>
    )
}

export default DateInput;