export const CheckStatus = {
    ARCHIVED: 0,
    SCHEDULED: 1,
    PAUSED: 2,
    REQUESTED: 3,
    UPDATED: 4,
    FINISHED: 5,
    PROBLEM: 6,
    REQUESTUPDATED: 7,
    CHECK_AUTOMATIC_PAUSED: 8,
    REQUEST_AUTOMATIC_PAUSED: 9,
    REQUEST_PROBLEM: 10
}