import { App } from 'src/app';
import AlertHelper from 'src/helpers/AlertHelper';
const requiredFields = [
	'email',
	'language',
	'firstName',
	'lastName',
	'roles',
	'password',
];

interface checkEmailProps {
	user: any;
	setErrors: any;
}

export function isValidEmail({ user, setErrors }: checkEmailProps) {
	const target = 'email';
	const emailRegex =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	if (
		user[target] != undefined &&
		user[target] != '' &&
		!emailRegex.test(String(user[target]).toLowerCase())
	) {
		setErrors((previous) => {
			return {
				...previous,
				[target]: true,
			};
		});
		AlertHelper.danger('Some fields have wrong formatting.');
		return false;
	}
	return true;
}

interface checkRequiredProps {
	field: string;
	user: any;
	setErrors: any;
}
export function checkRequired({ field, user, setErrors }: checkRequiredProps) {
	if (user[field] === '' || user[field] === undefined) {
		setErrors((previous) => {
			return {
				...previous,
				[field]: true,
			};
		});
	}

	if (user[field] !== undefined) {
		if (Array.isArray(user[field])) {
			if (user[field].length == 0) {
				setErrors((previous) => {
					return {
						...previous,
						[field]: true,
					};
				});
			}
		}
	}
}

interface handleSavedProps {
	user: any;
	setErrors: any;
	errors: any;
	userCompanies: any;
	userService: any;
	userCompanyService: any;
}

export async function handleSaved({
	user,
	setErrors,
	errors,
	userCompanies,
	userCompanyService,
	userService,
}: handleSavedProps) {
	// check if every input is filled
	requiredFields.forEach((field: string) =>
		checkRequired({ field, user, setErrors })
	);
	if (
		requiredFields.filter((target) => {
			if (errors[target]) return target;
			return;
		}).length > 0
	) {
		AlertHelper.danger('Required fields are missing');
		return;
	}
	// check if email input is in good format
	if (!isValidEmail({ user, setErrors })) {
		return;
	}

	// check if password input is in good format
	if (user.password) {
		if (user.password.length < 7) {
			AlertHelper.danger('Password too short.');
			return;
		}
	}

	// update or save new user
	if (App.history.location.state != undefined) {
		await userCompanyService.put(user.id, userCompanies);
		await userService.put(user);
		AlertHelper.success(`User ${user.email} updated.`);
	} else {
		try {
			const response = await userService.post(user);
			if (response) {
				AlertHelper.success(`User ${user.email} created.`);
			} else {
				AlertHelper.danger('Something went wrong.');
				return;
			}
		} catch {}
	}

	App.history.goBack();
}
