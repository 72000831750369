import * as React from 'react';

const MessageButtonLogView = () => {
    return (
        <div>

        </div>
    );
}

export default MessageButtonLogView;