export default class DoctorViewStyle {
    mapHeight: React.CSSProperties =
        {
            height: 619
        }

    td: React.CSSProperties =
        {
            WebkitUserSelect: "text"
        }

    actionHeader: React.CSSProperties =
        { width: 70 }

    action: React.CSSProperties =
        { width: 30, height: 30, fill: 'white', color: 'white', margin: 5, cursor: 'pointer' }

    actionIcon: React.CSSProperties =
        { width: 30, height: 30, fill: 'white', color: 'white', margin: 5, cursor: 'pointer' }

    toggleStyle: React.CSSProperties =
        {
            paddingTop: 10,
            paddingBottom: 10
        }

    mapStyles = [
        {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#444444"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#ebedf0"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": 45
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                },
                {
                    "hue": "#009aff"
                },
                {
                    "saturation": "-62"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#f5faff"
                },
                {
                    "visibility": "on"
                }
            ]
        }
    ];
}