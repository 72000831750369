import React from 'react';
import TranslationView from 'src/components/translationView/TranslationView';

const CheckTableHeader = () => {
    return (
        <tr>
            <th scope="col">

            </th>
            <th scope="col">
                <TranslationView>
                    checkWorkerFullName
                    </TranslationView>
            </th>
            <th scope="col">
                <TranslationView>
                    checkCompanyName
                    </TranslationView>
            </th>
            <th scope="col">
                <TranslationView>
                    checkDoctorFullName
                    </TranslationView>
            </th>
            <th scope="col">
                <TranslationView>
                    checkDate
                    </TranslationView>
            </th>
            <th scope="col">
                <TranslationView>
                    checkTime
                    </TranslationView>
            </th>
            <th scope="col">
                <TranslationView>
                    checkLocation
                    </TranslationView>
            </th>
            <th scope="col">
                <TranslationView>
                    assigned
                    </TranslationView>
            </th>
            <th scope="col" style={{ width: 70 }}></th>
        </tr>
    )
}

export default CheckTableHeader;