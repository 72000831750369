import GoogleMapReact from "google-map-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import config from "src/config";
import DoctorDTO from "src/models/DoctorDTO";
import PinView from "../../../../components/pinView/PinView";
import DoctorViewStyle from "../../../DoctorViewStyle";
import { DoctorsMapProps } from "./props";
import useSupercluster from "use-supercluster";
import "./style.css";

const Marker = ({ children, lat, lng }) => children;

const DoctorsMap = ({ doctors }: DoctorsMapProps) => {
    // Attributes
    const style = new DoctorViewStyle();
    const [center, setCenter] = useState({ lat: 50.8503, lng: 4.3517 });
    const googleMapsKey = config.googleMapsKey;
    const mapRef = useRef();
    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(10);

    const points = useMemo(() => doctors?.map((doctor: DoctorDTO, index: number) => ({

        type: "Feature",
        properties: { cluster: false, doctorId: doctor.id, doctor: doctor },
        geometry: { "type": "Point", "coordinates": [parseFloat(doctor.longitude), parseFloat(doctor.latitude)] },

    })), [doctors.length]);

    const { clusters } = useSupercluster({
        points,
        bounds,
        zoom,
        options: { radius: 75, maxZoom: 20 }
    })

    // Handlers
    function handleCenter(lat, lng) {
        setCenter({ lat: parseFloat(lat), lng: parseFloat(lng) })
    }

    return (
        <div style={style.mapHeight}>
            <GoogleMapReact
                options={{ styles: style.mapStyles, disableDefaultUI: true }}
                center={center}
                zoom={zoom}
                bootstrapURLKeys={{ key: googleMapsKey }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map }) => {
                    mapRef.current = map;
                }}
                onChange={({ zoom: newZoom, bounds }) => {
                    setZoom(newZoom);
                    setBounds([
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat
                    ])
                }}
            >
                {clusters?.map(cluster => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const { cluster: isCluster, point_count: pointCount, doctor } = cluster.properties;

                    if (isCluster) {
                        return (
                            <Marker key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
                                <div
                                    className="cluster-marker"
                                    style={{
                                        width: `${10 + (pointCount / points.length) * 20}px`,
                                        height: `${10 + (pointCount / points.length) * 20}px`
                                    }}
                                >
                                    {pointCount}
                                </div>
                            </Marker>
                        )
                    }
                    return (
                        <PinView key={doctor.id} doctor={doctor} center={handleCenter} lng={longitude} lat={latitude} />
                    )
                })}
            </GoogleMapReact>
        </div>
    )
};

export default DoctorsMap;