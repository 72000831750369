import React from "react";
import { useTranslation } from "src/components/localizationContext/LocalizationContext";
import { Language } from "src/enums/language";
import DateHelper from "src/helpers/DateHelper";
import EnumHelper from "src/helpers/EnumHelper";
import CertificateService from "src/services/CertificateService";
import CertificateTableRowStyle from "./CertificateTableRowStyle";
import CertificateTableRowProps from "./props";
const CertificateTableRow = ({
	certificate,
	index,
}: CertificateTableRowProps) => {
	// Attributes
	const {
		id,
		gender,
		employee,
		creationDate,
		superior,
		startDate,
		endDate,
		incapacityReason,
		certifType,
		isCheckRequestLaunched,
		urlCertificate,
		authorizedExit,
		doctorName,
	} = certificate;
	const style = new CertificateTableRowStyle();
	const { translate } = useTranslation();
	const certifTypeNames = [
		"Première Incapacité",
		"Prolongation",
		"Rechute",
		"JourSansCertif",
	];


	// Function
	async function downloadCertificate() {
		window.open(urlCertificate);
	}

	return (
		<tr key={index}>
			<td style={style.td}>{DateHelper.formatDate(creationDate)}</td>
			<td style={style.td}>{employee.matricule}</td>
			<td style={style.td}>{employee.firstName}</td>
			<td style={style.td}>{employee.lastName}</td>
			<td style={style.td}>{Language[employee.language]}</td>
			<td style={style.td}>{EnumHelper.gender(gender)}</td>
			<td style={style.td}>{employee.phoneNumber}</td>
			<td style={style.td}>{employee.address}</td>
			<td style={style.td}>{employee.company.name}</td>
			<td style={style.td}>{superior}</td>
			<td style={style.td}>{DateHelper.formatDate(startDate)}</td>
			<td style={style.td}>{DateHelper.formatDate(endDate)}</td>
			<td style={style.td}>{translate(incapacityReason)}</td>
			<td style={style.td}>
				{certifType !== -1 && certifType !== 4
					? translate(certifTypeNames[certifType])
					: ""}
			</td>
			<td style={style.td}>
				{authorizedExit ? translate("yes") : translate("no")}
			</td>
			<td style={style.td}>{doctorName}</td>
			<td style={style.td}>
				<div
					onClick={downloadCertificate}
					style={{ cursor: "pointer" }}
				>
					Download
				</div>
			</td>
			<td style={style.td}>
				{isCheckRequestLaunched
					? translate("yes")
					: translate("no")}
			</td>
			<td style={style.td}>
				<a
					target="_blank"
					href={`https://${employee.company.key}.mycertificate.be/employee/${employee.id}/certificate/${id}`}
				>
					(Re)launch
				</a>
			</td>
		</tr>
	);
};

export default CertificateTableRow;
