import Service from './Service';
import Axios from 'axios';
import CompanyDTO from '../models/CompanyDTO';

export default class CompanyService extends Service
{
    /* METHODS */
    get(id?: string)
    {        
        if(id)
        {
            return this.execute(Axios.get(`${this.url}/companies/${id}`, this.config()), "Unable to retrieve company details.");
        }
        else
        {
            return this.execute(Axios.get(`${this.url}/companies?page=0&size=20000`, this.config()), "Unable to retrieve companies.");
        }
    }

    post(body: CompanyDTO)
    {
        return this.execute(Axios.post(`${this.url}/companies`, body, this.config()), "Unable to create company.");
    }

    put(body: CompanyDTO)
    {
        return this.execute(Axios.put(`${this.url}/companies`, body, this.config()), "Unable to update company.");
    }

    delete(id: string)
    {
        return this.execute(Axios.delete(`${this.url}/companies/${id}`, this.config()), "Unable to delete company.");
    }
}