export default class SaveButtonViewStyle
{
    button: React.CSSProperties =
    {
        marginLeft:'auto',
        float:'right'
    }

    buttonImage: React.CSSProperties =
    {
        width:20, 
        height:20, 
        marginRight:15, 
        filter:'invert(100%) sepia(0%) saturate(7500%) hue-rotate(6deg) brightness(103%) contrast(103%)', 
        cursor:'pointer'
    }

    buttonText: React.CSSProperties =
    {
        fontWeight:600, 
        marginTop:-20, 
        fontSize:16
    }

    lottie: React.CSSProperties = 
    {
        width: 163,
        height: 23,
        marginLeft: 0,
        marginTop: 0
    }
}