import DetailComponentStyle from '../../../abstracts/detailComponent/DetailComponentStyle';

export default class DoctorDetailViewStyle extends DetailComponentStyle
{
    service: React.CSSProperties =
    {
        color: '#68737E',
        fontWeight:300,
        fontSize: 16
    }

    buttonContainer: React.CSSProperties =
    {
        ...this.container,
        height: 49,
        display:'flex',
        justifyContent: 'space-between'
    }

    doctorIllustration: React.CSSProperties =
    {
        ...this.illustration, width:213, height:538
    }
}