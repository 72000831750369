import Service from './Service';
import Axios from 'axios';
import CheckDTO from '../models/CheckDTO';

export default class ArchiveService extends Service {
    get() {
        return this.execute(Axios.get(`${this.url}/archives`, this.config()));
    }

    put(check: CheckDTO) {
        return this.execute(Axios.put(`${this.url}/archives`, check, this.config()));
    }

    putWithCommunications(check: CheckDTO) {
        return this.execute(Axios.put(`${this.url}/archives/communications`, check, this.config()));
    }

    delete(checkId: string) {
        return this.execute(Axios.delete(`${this.url}/archives/${checkId}`, this.config()));
    }
}