import RoleDTO from './RoleDTO';

export default class UserDTO {
    id: string;

    firstName: string;
    lastName: string;
    email: string;

    externalId?: string;

    roles: any[] = [];
    language: string;

    password: string;
}