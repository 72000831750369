import Service from "./Service";
import Axios from "axios";

interface DashboardFiltersInterface {
	companyId: string;
	startDate: string;
	endDate: string;
}

export default class DashboardService extends Service {
	get(filters: DashboardFiltersInterface) {
		return this.execute(Axios.get(`${this.url}/dashboard?${filters.companyId ? `companyId=${filters.companyId}&` : ""}startDate=${filters.startDate}&endDate=${filters.endDate}`, this.config()));
	}
}
