import * as React from 'react';
import BaseComponent from '../../../../../../abstracts/BaseComponent';
import OnlineLogViewStyle from './OnlineLogViewStyle';

export default class OnlineLogView extends BaseComponent<any, any>
{
    /* ATTRIBUTES */
    style = new OnlineLogViewStyle();

    /* CONSTRUCTOR */
    constructor(props: any)
    {
        super(props);

        // Initial state

        // Bindings
    }

    /* HANDLERS */
    /* RENDER */
    render()
    {
        const {doctor} = this.props;

        return (     
            <div style={this.style.basicLogContainer}>

                <div style={this.style.basicLog}>

                    <div style={{...this.style.commentLogText, backgroundColor:'#85D8C8', borderTopLeftRadius:4, borderTopRightRadius:4, height:38, borderBottomLeftRadius:0, borderBottomRightRadius:0}}>
                        <div style={{float:'left'}}>
                        Ask for an appointment to the doctor online
                        </div>
                    </div>

                    {/* LOG RENDERING */}
                    <div style={{...this.style.logText, backgroundColor:'#85D8C8'}}>
                        <div style={{display:'flex', flexDirection:'row'}}>
                            <div style={{...this.style.logTitleText, marginTop:5, width:85}}>
                                Website :
                            </div>
                            <div style={{...this.style.logBodyText, width:'100%', fontSize:12, padding:6}}>
                                {doctor.appointmentWebsite ? doctor.appointmentWebsite : '- - -'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}