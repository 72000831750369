import React from 'react';
import { format } from 'date-fns';
import moment from 'moment';
import './style.css';
import TranslationView from 'src/components/translationView/TranslationView';
import ReactDatePicker from 'react-datepicker';
import DateInput from './date-input';
import CalendarStart from './calendar-start';
import CalendarEnd from './calendar-end';

const Inputs = ({ startDate, endDate, setEndDate, setStartDate }) => {

    // Methods
    function handleChangeStartDate(date: any) {
        const newDate = format(date, 'yyyy-MM-dd')
        setStartDate(newDate)
    }
    function handleChangeEndDate(date: any) {
        const newDate = format(date, 'yyyy-MM-dd')
        setEndDate(newDate)
    }

    function mapDate(date: any) {
        if (date !== undefined && date !== null && date !== "") {
            const m = moment(date);
            return moment(m).utc().add(m.utcOffset(), 'm').toDate();
        }

        if (date === undefined) {
            date = null;
        }

        // Little hack for the react-datepicker
        return date;
    }

    return (
        <div className='dashboard-filter-date-inputs-container'>
            <div className='dashboard-filter-date-inputs-subtitle'>
                <TranslationView>
                    OrSelectDate
                </TranslationView>
            </div>
            <ReactDatePicker
                selected={mapDate(startDate)}
                value={mapDate(startDate)}
                onChange={date => handleChangeStartDate(date)}
                customInput={<DateInput />}
                dateFormat="dd/MM/yyyy"
                calendarContainer={CalendarStart}
            />
            <div style={{ margin: '0 10px' }}>-</div>
            <ReactDatePicker
                selected={mapDate(endDate)}
                value={mapDate(endDate)}
                onChange={date => handleChangeEndDate(date)}
                customInput={<DateInput />}
                dateFormat="dd/MM/yyyy"
                calendarContainer={CalendarEnd}
            />
        </div>
    )
}

export default Inputs;