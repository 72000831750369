import Service from './Service';
import Axios from 'axios';
import CompanyCreditDTO from '../models/CompanyCreditDTO';

export default class CompanyCreditService extends Service
{
    /* METHODS */
    get(id:string)
    {
        return this.execute(Axios.get(`${this.url}/companies/${id}/credits`, this.config()));
    }

    post(companyId: string, body: CompanyCreditDTO)
    {
        body.companyId = companyId;
        body.amountOfCredit = parseFloat(body.amountOfCredit as any);
        return this.execute(Axios.post(`${this.url}/companies/${companyId}/credits`, body, this.config()));
    }
}