import CompanyExportService from '../services/CompanyExportService';
import DoctorExportService from '../services/DoctorExportService';
import CertificateExportService from 'src/services/CertificateExportService';

const exportDoctor = async () => {
    alert({ message: 'Starting doctor export', type: 'info' })
    downloadCSV(await new DoctorExportService().get(), `doctors_export`);
}
 
const exportCompanies = async () => {
    alert({ message: 'Starting company export', type: 'info' })
    downloadCSV(await new CompanyExportService().get(), `companies_export`);
}


const exportCertificates = async () => {
    alert({ message: 'Starting company export', type: 'info' })
    downloadCSV(await new CertificateExportService().get(), `certificates_export`);
}

const flattenObjectToArray = (object, arr) => {

    for (const key in object) {
        if (typeof object[key] === 'object') {
            flattenObjectToArray(object[key], arr)
        } else {
            arr.push(object[key])
        }
    }
    return arr
}

const downloadCSV = (csv: any, fileName: string) => {
    var blob = new Blob([`\uFEFF${csv}`], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    link.download = `${fileName}.csv`;
    link.href = `${URL.createObjectURL(blob)}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    alert({ message: 'Downloaded file', type: 'success' })
}

export { exportCompanies, exportDoctor, exportCertificates, downloadCSV, flattenObjectToArray };