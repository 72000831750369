import * as React from 'react';
import DoctorGeneralViewProps from './DoctorGeneralViewProps';
import ListView from '../../../../../components/listView/ListView';
import ServiceService from '../../../../../../../services/ServiceService';
import TranslationView from '../../../../../../../components/translationView/TranslationView';
import { InputView } from '../../../../../../../components/inputView/InputView';
import AddressInputView from '../../../../../../../components/addressInputView/AddressInputView';
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';

const DoctorGeneralView = (props: DoctorGeneralViewProps) => {
    const { style, element, handleInputChanged, errors } = props;

    // Methods
    function mapLanguageOptions() {
        return [
            <option value="FR">FR</option>,
            <option value="NL">NL</option>,
            <option value="EN">EN</option>,
            <option value="DE">DE</option>
        ];
    }

    function mapLanguageValues() {
        return element.otherLanguages ?
            element.otherLanguages.split(',')?.map((elt: any) => { return elt }) :
            [];
    }

    function handleLanguagesChanged(event: any) {
        handleInputChanged('otherLanguages', event.target.value.reduce((total, language) => total + "," + language));
    }

    return (
        <LocalizationContext.Consumer>
            {({ translate }) => (
                <div className="card shadow" style={style.container}>
                    {/* TITLE */}
                    <div className="card-header border-1" style={style.cardHeader}>
                        <TranslationView>
                            doctorDetailGeneralInformation
                        </TranslationView>
                    </div>
                    {/* BODY */}
                    <div className="card-body">
                        <div className="row">

                            {/* FIRST NAME */}
                            <div style={style.inputLeft}>
                                <div style={style.inputTitle}>
                                    <TranslationView>
                                        doctorDetailFirstName
                                    </TranslationView>
                                </div>
                                <InputView error={errors["firstName"]} placeholder="Amélie" type="text" value={element.firstName} onChange={(event) => handleInputChanged("firstName", event.target.value)} />
                            </div>

                            {/* LAST NAME */}
                            <div style={style.inputRight}>
                                <div style={style.inputTitle}>
                                    <TranslationView>
                                        doctorDetailLastName
                                    </TranslationView>
                                </div>
                                <InputView error={errors["lastName"]} placeholder="Meunier" type="text" value={element.lastName} onChange={(event) => handleInputChanged("lastName", event.target.value)} />
                            </div>

                            {/* MAIN LANGUAGE */}
                            <div style={style.inputLeft}>
                                <div style={style.inputTitle}>
                                    <TranslationView>
                                        doctorDetailMainLanguage
                                    </TranslationView>
                                </div>
                                <InputView error={errors["mainLanguage"]} placeholder="Select an option" type="select" value={element.mainLanguage} onChange={(event) => handleInputChanged("mainLanguage", event.target.value)}>
                                    <option value={"FR"}>FR</option>
                                    <option value={"NL"}>NL</option>
                                </InputView>
                            </div>

                            {/* OTHER LANGUAGES */}
                            <div style={style.inputRight}>
                                <div style={style.inputTitle}>
                                    <TranslationView>
                                        doctorDetailOtherLanguages
                                    </TranslationView>
                                </div>

                                <InputView placeholder="FR, NL, EN, DE" type="multi-select" value={mapLanguageValues()} onChange={handleLanguagesChanged}>
                                    {mapLanguageOptions()}
                                </InputView>
                            </div>

                        </div>
                        <div className="row">

                            {/* OFFICE ADDRESS */}
                            <div style={style.inputDouble}>
                                <div style={style.inputTitle}>
                                    <TranslationView>
                                        doctorDetailOfficeAddress
                            </TranslationView>
                                </div>
                                <AddressInputView error={errors["officeAddress"]} placeholder="Parvis Sainte Gudule 5, 1000 Bruxelles" value={element.officeAddress} target={"officeAddress"} handleInputChanged={handleInputChanged} />
                            </div>

                            {/* SIGNED DATE */}
                            <div style={style.inputLeft}>
                                <div style={style.inputTitle}>
                                    <TranslationView>
                                        doctorDetailSignedDate
                            </TranslationView>
                                </div>
                                <InputView placeholder="01/01/2020" type="date" value={element.signedDate} onChange={(event) => handleInputChanged("signedDate", event.target.value)} />
                            </div>

                            {/* START DATE */}
                            <div style={style.inputRight}>
                                <div style={style.inputTitle}>
                                    <TranslationView>
                                        doctorDetailStartDate
                            </TranslationView>
                                </div>
                                <InputView placeholder="01/01/2020" type="date" value={element.startDate} onChange={(event) => handleInputChanged("startDate", event.target.value)} />
                            </div>

                        </div>
                        <div className="row">

                            {/* GENDER */}
                            <div style={style.inputLeft}>
                                <div style={style.inputTitle}>
                                    <TranslationView>
                                        doctorDetailGender
                            </TranslationView>
                                </div>
                                <InputView error={errors["gender"]} placeholder="Select an option" type="select" value={element.gender} onChange={(event) => handleInputChanged("gender", event.target.value)}>
                                    <option value={0}>{translate('doctorDetailGenderMale', 'en')}</option>
                                    <option value={1}>{translate('doctorDetailGenderFemale', 'en')}</option>
                                    <option value={2}>{translate('doctorDetailGenderOther', 'en')}</option>
                                </InputView>
                            </div>

                            {/* INAMI */}
                            <div style={style.inputRight}>
                                <div style={style.inputTitle}>
                                    <TranslationView>
                                        doctorDetailINAMI
                            </TranslationView>
                                </div>
                                <InputView placeholder="19228566004" type="text" value={element.inami} onChange={(event) => handleInputChanged("inami", event.target.value)} />
                            </div>

                            {/* END DATE */}
                            <div style={style.inputLeft}>
                                <div style={style.inputTitle}>
                                    <TranslationView>
                                        doctorDetailEndDate
                            </TranslationView>
                                </div>
                                <InputView placeholder="01/01/2020" type="date" value={element.endDate} onChange={(event) => handleInputChanged("endDate", event.target.value)} />
                            </div>

                            {/* SALES REP */}
                            <div style={style.inputRight}>
                                <div style={style.inputTitle}>
                                    <TranslationView>
                                        doctorDetailSalesRepresentative
                            </TranslationView>
                                </div>
                                <InputView placeholder="Miel" type="text" value={element.salesRepresentative} onChange={(event) => handleInputChanged("salesRepresentative", event.target.value)} />
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </LocalizationContext.Consumer>
    );
}

export default DoctorGeneralView;