export default class CompanyCreditDTO
{
    amountOfCredit: number;
    packType: number;
    startDate: string;
    endDate: string;
    purchaseOrderNumber: string = "";
    justification: number;
    additionalDetails: string = "";
    companyId: string;
    creationDate: string;
}