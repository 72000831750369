import React from 'react';
import { InputView } from 'src/components/inputView/InputView';
import TranslationView from 'src/components/translationView/TranslationView';
import SettingDetailViewStyle from '../../../../SettingDetailViewStyle';
import './style.css';

interface InputProps {
    element?: any,
    placeholder: any,
    type: any,
    children?: any,
    label: any,
    error: any,
    value: any,
    onChange: any,
    disabled?: any
}

const Input = ({ type, placeholder, children, label, error, value, onChange, disabled }: InputProps) => {
    // Attributes
    const style = new SettingDetailViewStyle();

    // Methods

    return (
        <>
            <div style={style.inputTitle}>
                <TranslationView>
                    {label}
                </TranslationView>
            </div>
            <InputView disabled={disabled} error={error} placeholder={placeholder} type={type} value={value} onChange={onChange}>
                {children}
            </InputView>
        </>
    )
}

export default Input;