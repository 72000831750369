import DetailComponentStyle from '../../../../abstracts/detailComponent/DetailComponentStyle';
export default class ScheduleViewStyle extends DetailComponentStyle
{
    extendedCard: React.CSSProperties =
    {
        ...this.card,
        padding: 31
    }
    takeAppointmentTitle: React.CSSProperties =
    {
        height: 28,
        width: 436,
        color: "#405364",
        fontSize: 18,
        lineHeight: "21px"
    }

    suggestDateForCheck: React.CSSProperties =
    {
        color: "#68737E",
        fontSize: 16,
        lineHeight: "19px",
        marginTop:20,
        display: 'flex',
        flexDirection:'row',
        alignItems:'center'
    }

    suggestDateForCheckText: React.CSSProperties =
    {
        width: 273
    }

    suggestDateForCheckDate: React.CSSProperties =
    {
        marginLeft: 20,
        width: 240
    }

    communicationBox: React.CSSProperties =
    {
        boxSizing: "border-box",
        height: 42,
        width: 235,
        borderRadius: 8,
        backgroundColor: "#FFF",
        border: "2px solid #C0D3E0",
        display:'flex', 
        flexDirection:'row', 
        cursor:'pointer'
    }

    communicationBoxSelected: React.CSSProperties =
    {
        boxSizing: "border-box",
        height: 42,
        width: 235,
        borderRadius: 8,
        backgroundColor: "#FFF",
        border: "2px solid #85D8C8",
        display:'flex', 
        flexDirection:'row', 
        cursor:'pointer'
    }

    communicationBoxImage: React.CSSProperties =
    {
        display:'flex', 
        alignContent:'center', 
        justifyContent:'center', 
        alignItems:'center',
        width:60,
        backgroundColor:'#C0D3E0'
    }

    communicationBoxImageSelected: React.CSSProperties =
    {
        display:'flex', 
        alignContent:'center', 
        justifyContent:'center', 
        alignItems:'center',
        width:60,
        backgroundColor:'#85D8C8'
    }

    communicationImage: React.CSSProperties =
    {
        filter: 'invert(100%) sepia(96%) saturate(0%) hue-rotate(126deg) brightness(104%) contrast(106%)', 
        height:24, 
        width:24
    }

    communicationBoxText: React.CSSProperties =
    {
        fontSize: 16, 
        lineHeight: "19px", 
        width:'100%', 
        display:'flex', 
        justifyContent:'center', 
        alignItems:'center', 
        alignContent:'center',
        color:'#C0D3E0'
    }

    communicationBoxTextSelected: React.CSSProperties =
    {
        ...this.communicationBoxText,
        color:'#85D8C8'
    }

    workerInformationBox: React.CSSProperties =
    {
        boxSizing: "border-box",
        borderRadius: 8,
        backgroundColor: "#FFF",
        border: "2px solid #C0D3E0"
    }

    logBodyText: React.CSSProperties =
    {
        backgroundColor:'white', 
        padding:8, 
        fontSize:14, 
        borderRadius:6, 
        color: '#68737E', 
        marginBottom:10, 
        marginTop: 1,
        WebkitUserSelect:'text',
        width:610,
        maxWidth:530,
        float:'right'
    }

    button: React.CSSProperties =
    {
        height:45, 
        width:250,
        marginTop:20,
        marginBottom:0
    }

    buttonScheduleCommunication: React.CSSProperties =
    {
        height:45, 
        width:260,
        marginTop:20,
        marginBottom:0
    }

    buttonImage: React.CSSProperties =
    {
        width:20, 
        height:20, 
        marginTop: 0,
        marginRight:5
    }

    buttonText: React.CSSProperties =
    {
        fontWeight:400, 
        marginTop: -2,
        fontSize:17
    }

    buttonContent: React.CSSProperties = 
    {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
    }

    fillerTH : React.CSSProperties =
    {
        width:70
    }

    doctorSelector: React.CSSProperties =
    {
        cursor:'pointer'
    }

    doctorSelectorImage: React.CSSProperties =
    {
        height:20, 
        width:20
    }

    doctorServiceImage: React.CSSProperties =
    {
        width:15,
        height:15,
        marginRight:3
    }

    doctorOpenButton: React.CSSProperties =
    {
        cursor:'pointer', 
        fontSize:16, 
        fontWeight:600
    }

    doctorInfoContainer: React.CSSProperties =
    {
        float:'left', 
        flex:0.25, 
        display:'flex', 
        fontSize:12, 
        flexDirection:'column', 
        marginLeft:10, 
        marginRight:10
    }

    doctorInfoTitle: React.CSSProperties =
    {
        fontWeight:500
    }

    doctorPreferencesContainer: React.CSSProperties =
    {
        float:'left', 
        flex:0.25, 
        display:'flex', 
        flexDirection:'column', 
        fontSize:12, 
        marginLeft:10, 
        marginRight:10
    }

    doctorPreferences: React.CSSProperties =
    {
        userSelect:'text', 
        padding:7.5,
        boxSizing: 'border-box', 
        minHeight:110, 
        width: 240,
        borderRadius: 6,
        backgroundColor: '#FFF',
        border: '1px solid #EBEDF0',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap'
    }

    logTitle: React.CSSProperties =
    {
        padding:10, 
        borderTopLeftRadius: 8, 
        borderTopRightRadius:8, 
        height:27, 
        width:630, 
        backgroundColor:'#85D8C8', 
        display:'flex', 
        alignContent:'center', 
        alignItems:'center', 
        fontSize:14, 
        fontWeight:600, 
        color:'white'
    }

    logFirstBox: React.CSSProperties =
    {
        padding:10, 
        paddingBottom:2, 
        marginTop:2, 
        width:630, 
        backgroundColor:'#85D8C8', 
        justifyContent:'space-around', 
        display:'flex', 
        flexDirection:'row', 
        fontSize:14, 
        fontWeight:400, 
        color:'white'
    }

    logFirstFullBox: React.CSSProperties =
    {
        padding:10, 
        paddingBottom:2, 
        marginTop:2,
        borderBottomLeftRadius:8,
        borderBottomRightRadius:8, 
        width:630, 
        backgroundColor:'#85D8C8', 
        justifyContent:'space-around', 
        display:'flex', 
        flexDirection:'row', 
        fontSize:14, 
        fontWeight:400, 
        color:'white'
    }

    logTitleText: React.CSSProperties =
    {
        fontSize:14, 
        fontWeight:400
    }

    logBoxTitle: React.CSSProperties =
    {
        marginTop:3
    }

    logSecondBox: React.CSSProperties =
    {
        padding:10, 
        paddingTop:0, 
        borderBottomLeftRadius: 8, 
        borderBottomRightRadius:8, 
        width:630, 
        backgroundColor:'#85D8C8', 
        justifyContent:'space-around', 
        display:'flex',
        flexDirection:'row', 
        fontSize:14, 
        fontWeight:400, 
        color:'white'
    }

    logSecondHalfBox: React.CSSProperties =
    {
        padding:10, 
        paddingTop:0, 
        borderBottomLeftRadius: 0, 
        borderBottomRightRadius:0, 
        width:630, 
        backgroundColor:'#85D8C8', 
        justifyContent:'space-around', 
        display:'flex',
        flexDirection:'row', 
        fontSize:14, 
        fontWeight:400, 
        color:'white'
    }

    scheduleContainer: React.CSSProperties =
    {
        marginLeft:0, 
        marginRight:0
    }

    doctorTableExtendedCard: React.CSSProperties =
    {
        ...this.extendedCard, 
        padding: 0
    }

    doctorChooseADoctor: React.CSSProperties =
    {
        ...this.takeAppointmentTitle, 
        marginLeft:32.5,
        marginTop:32.5,
        marginBottom: 32.5,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }

    selectCommunicationMethod: React.CSSProperties =
    {
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'space-between', 
        marginTop:20
    }

    logContainer: React.CSSProperties =
    {
        marginTop:20, 
        display:'flex', 
        flexDirection:'row'
    }

    imageContainer: React.CSSProperties =
    {
        width:380, 
        height:260, 
        textAlign:'center'
    }

    logImage: React.CSSProperties =
    {
        margin:'auto', 
        height:260
    }

    logContent: React.CSSProperties =
    {
        width:630, 
        paddingBottom:30
    }

    logBody: React.CSSProperties =
    {
        width:630
    }

    extendedWorkerInformationBox: React.CSSProperties =
    {
        marginTop:10, 
        height:30, 
        width:630, 
        display:'flex', 
        flexDirection:'row', 
        alignItems:'center', 
        alignContent:'center', 
        ...this.workerInformationBox
    }

    workerInformationMemoImage: React.CSSProperties =
    {
        filter: 'invert(100%) sepia(96%) saturate(0%) hue-rotate(126deg) brightness(104%) contrast(106%)', 
        height:20
    }

    workerInformationMemoImageContainer: React.CSSProperties =
    {
        backgroundColor:'#C0D3E0', 
        width:40, 
        borderTopLeftRadius:8, 
        borderBottomLeftRadius:8, 
        alignContent:'center', 
        justifyContent:'center', 
        alignItems:'center', 
        display:'flex', 
        height:30
    }

    workerInformationText: React.CSSProperties =
    {
        marginLeft:8, 
        fontWeight:300, 
        fontSize:13, 
        WebkitUserSelect:'text'
    }

    inputContainer: React.CSSProperties =
    {
        display:'flex', 
        flexDirection:'row'
    }

    leftSide: React.CSSProperties =
    {
        width:"48%"
    }

    firstInput: React.CSSProperties =
    {
        display:'flex', 
        flexDirection:'row', 
        alignItems:'center', 
        alignContent:'center'
    }

    otherInput: React.CSSProperties =
    {
        ...this.firstInput,
        marginTop:20
    }

    inputTextSize: React.CSSProperties =
    {
        width: 200
    }

    saveButtons: React.CSSProperties =
    {
        display:'flex', 
        flexDirection:'row', 
        alignItems:'left', 
        alignContent:'left'
    }

    inputSize: React.CSSProperties =
    {
        width:'100%', 
        marginRight:25
    }

    rightSide: React.CSSProperties =
    {
        width:'48%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }

    lottie: React.CSSProperties = 
    {
        height:45,
        width:150,
        marginTop: -10
    }
}
