import * as React from 'react';
import ReceiverLogView from './CommunicationLogView/ReceiverLogView';
import SubjectLogView from './CommunicationLogView/SubjectLogView';
import MessageShellLogView from './CommunicationLogView/MessageShellLogView';
import SeparatorView from '../components/SeparatorView';

const CommunicationLogView = ({ log }) => {

    if (log.type !== "SMS" && log.type !== "EMAIL") {
        return <div />
    }

    return (
        <>
            <SeparatorView />
            <div>
                <ReceiverLogView />
                <SubjectLogView />
                <MessageShellLogView />
            </div>
        </>

    );
}

export default CommunicationLogView;