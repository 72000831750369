import { createContext, useContext } from "react";

interface LocalizationContextValue {
    language: string,
    translate: any
}

const LocalizationContext = createContext<LocalizationContextValue>({
    language: "en",
    translate: () => { }
});

function useTranslation() {
    return useContext(LocalizationContext);
}

export { LocalizationContext, useTranslation };