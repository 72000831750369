import Service from './Service';
import Axios from 'axios';
import DoctorDTO from '../models/DoctorDTO';

export default class DoctorService extends Service
{
    /* METHODS */
    get()
    {
        return this.execute(Axios.get(`${this.url}/doctors?page=0&size=20000`, this.config()));
    }

    post(body: DoctorDTO)
    {        
        return this.execute(Axios.post(`${this.url}/doctors`, body, this.config()));
    }

    put(body: DoctorDTO)
    {
        return this.execute(Axios.put(`${this.url}/doctors`, body, this.config()));
    }
}