import React, { useEffect, useState } from 'react';
import SearchView from '../../components/searchView';
import ListView from '../../components/listView/ListView';
import CertificateTableRow from './components/certificate-table-row'
import CertificateTableHeader from './components/certificate-table-header';
import useSearch from 'src/components/useSearch';
import CertificateService from 'src/services/CertificateService';
import { useQuery } from 'react-query';
import NetworkHelper from 'src/helpers/NetworkHelper';
import { downloadCSV, flattenObjectToArray } from 'src/helpers/ExportHelper'
import { useTranslation } from 'src/components/localizationContext/LocalizationContext';
import { useUser } from 'src/components/userContext/UserContext';
import CertificateDTO from 'src/models/CertificateDTO';

const FIELDS = ['employee.firstName', 'employee.lastName', 'gender', 'employee.phoneNumber', 'employee.address', 'superior', 'employeeId', 'creationDate', 'employee.company.name']

const Certificate = () => {

    // Attributes
    const { translate } = useTranslation()
    const service = new CertificateService()
    const query = useQuery<CertificateDTO[], any>('certificate', refresh, { refetchIntervalInBackground: false, refetchInterval: 1000000 })
    const { search, setSearch, filtered } = useSearch<CertificateDTO>(query.data, FIELDS)
    const [page, setPage] = useState(0)
    const { isInRole } = useUser()


    // Methods
    function handleDownload() {
        if (query.data) {
            const rows = [["id", "employee.company.name", "employee.matricule", "employee.firstName", "employee.lastName", "gender", "superior", "startDate","endDate","certifType","incapacityReason","authorizedExit","urlCertificate","shouldBeChecked","completionDay","isCheckRequestLaunched","checkRequestReason","creationDate","doctorName"]];
            

            query.data?.map((certif, index) => {
                let arr = [];

                for(let i=0; i < rows[0].length; i++)
                {
                    let key = rows[0][i];
                    let obj;
                    
                    if(key.includes('.'))
                    {
                        const splittedKey = key.split('.');
                        for(let j = 0; j < splittedKey.length; j++)
                        {
                            if(j === 0)
                            {
                                obj = certif[splittedKey[j]];
                            }
                            else
                            {
                                obj = obj[splittedKey[j]];
                            }
                        }   
                    }
                    else
                    {
                        obj = certif[key];
                    }

                    arr.push(obj);
                }
                
                rows.push(arr)
            })

            let csvContent = "";

            rows.forEach((rowArray) => {
                let row = rowArray.join(";");
                csvContent += row + "\r\n";
            });

            downloadCSV(csvContent, 'certificates')
        }
    }

    async function refresh() {
        const data = await service.get()
        return data
    }

    return (
        <SearchView
            title={translate('certificates')}
            history={[{ name: translate('certificates'), path: "/dashboard/certificate" }]}
            search={search}
            handleSearchChanged={(event) => setSearch(event?.target.value)}
            handleSecondaryAction={isInRole('operator') && handleDownload}
        >
            <ListView
                page={page}
                handlePageChanged={setPage}
                elements={filtered ? filtered : []}
                isBusy={filtered === undefined}
                mapElements={(certificate, index) => {
                    return <CertificateTableRow index={index} certificate={certificate} />
                }}
                mapHeaders={() => <CertificateTableHeader />}
            />
        </SearchView>
    )
}

export default Certificate;