import React from 'react';
import { App } from 'src/app';
import { CompanyNavViewProps } from './props';

const CompanyNavView = ({ type, company }: CompanyNavViewProps) => {
	// Attributes

	return (
		<div style={{ display: 'flex', width: 'fit-content' }}>
			<div
				onClick={() =>
					App.history.push(
						'/dashboard/company/detail',
						App.history.location.state
					)
				}
				style={{ cursor: 'pointer' }}
			>
				<img
					src={`/assets/edit_company${
						type === 'detail' ? '_selected' : ''
					}.svg`}
					style={{ height: 30, width: 30, marginLeft: 10 }}
				/>
			</div>
			<div
				onClick={() =>
					App.history.push(
						'/dashboard/company/superior',
						App.history.location.state
					)
				}
				style={{ cursor: 'pointer' }}
			>
				<img
					src={`/assets/edit_superior${
						type === 'superiors' ? '_selected' : ''
					}.svg`}
					style={{ height: 30, width: 30, marginLeft: 10 }}
				/>
			</div>
			<div
				onClick={() =>
					App.history.push(
						'/dashboard/company/service',
						App.history.location.state
					)
				}
				style={{ cursor: 'pointer' }}
			>
				<img
					src={`/assets/edit_credits${
						type === 'services' ? '_selected' : ''
					}.svg`}
					style={{ height: 30, width: 30, marginLeft: 10 }}
				/>
			</div>
			{company.hasMyCertificate && (
				<div
					onClick={() =>
						App.history.push(
							'/dashboard/company/mycertificate',
							App.history.location.state
						)
					}
					style={{ cursor: 'pointer' }}
				>
					<img
						src={`/assets/edit_mycertificate${
							type === 'mycertificate' ? '_selected' : ''
						}.svg`}
						style={{ height: 30, width: 30, marginLeft: 10 }}
					/>
				</div>
			)}
		</div>
	);
};

export default CompanyNavView;
