import { useMemo } from "react";
import { InputView } from "src/components/inputView/InputView";
import TranslationView from "src/components/translationView/TranslationView";
import { CommunicationRecipient } from "src/constants/CommunicationRecipient";
import ToggleView from "src/modules/dashboard/components/toggleView/ToggleView";
import CompanyCommunicationTableSubheader from "./CompanyCommunicationTableSubheaderView";

const CompanyCommunicationTableRow = ({ element, handleToggleChanged, handleRecipientChanged, displayHeader }) => {
    
    const recipients = useMemo(() => {
        const validRecipients = Object.keys(CommunicationRecipient)
          .filter((key) => isNaN(Number(key)))
          .map((key) => {
            const value = CommunicationRecipient[key];
            return {
              label: key,
              value: value,
            };
          });
          
        return validRecipients;
      }, []);

    return (
        <>
            {displayHeader && 
                <CompanyCommunicationTableSubheader element={element}/>
            }

            <tr>
                <td style={{ userSelect: "text" }}>
                    <TranslationView>
                        {element.communication.title}
                    </TranslationView>
                </td>
                <td style={{ userSelect: "text" }}>
                    <TranslationView>
                        {`${element.communication.title}_DESCRIPTION`}
                    </TranslationView>
                </td>
                <td>
                    <ToggleView isToggled={element.isSelected} handleIsToggled={handleToggleChanged} />
                </td>
                <td>
                    <InputView
                        error={undefined}
                        disabled={element.recipient === null}
                        placeholder={"Select..."}
                        type='select'
                        value={element.recipient ?? undefined}
                        onChange={handleRecipientChanged}
                        options={recipients}
                    />
                </td>
            </tr>
        </>
    );
}

export default CompanyCommunicationTableRow;