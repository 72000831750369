import React from 'react'
import UploadButton from './components/button'
import './style.css'

const MyCertificateUplaod = () => {

    // Attributes
    const buttons = ['superiors', 'white list', 'black list']

    return (
        <div className='company-certificate-upload-container'>
            { buttons?.map((button) => {
                let type : 'superior' | 'white-list' | 'black-list';
                
                switch(button)
                {
                    case 'superiors':
                        type = 'superior';
                        break;
                    case 'white list':
                        type = 'white-list';
                        break;
                    default:
                        type = 'black-list';
                        break;
                }
                return (
                    <UploadButton title={`Upload ${button}`} type={type} />
                )
            })
            }
        </div>
    )
}

export default MyCertificateUplaod;