import React from 'react';
import Group from 'react-select/lib/components/Group';
import './style.css';

export enum cardMetric {
    HOURS = 'hours',
    KM = 'km',
    DAYS = 'days',
    EUROS = 'euros'
}

interface CardProps {
    title: string,
    total: number,
    percentage?: number,
    color: string,
    metric?: cardMetric,
}


const Card = ({ title, total, percentage, metric, color }: CardProps) => {
    return (
        <div className='dashboard-card-container'>
            <div className='dashboard-card-first-part'>
                <div className='dashboard-lateral-bar' style={{ backgroundColor: `${color}` }} />
                <div className='dashboard-card-numbers-container'>
                    <div className='dashboard-card-total'>{total}</div>
                    {percentage
                        ?
                        (<div className='dashboard-card-metric'>{`${percentage}%`}</div>)
                        :
                        metric
                            ?
                            (<div className='dashboard-card-metric'>{metric}</div>)
                            :
                            (<div className='dashboard-card-empty'>empty</div>)
                    }
                </div>
            </div>
            <div className='dashboard-card-title'>{title}</div>
        </div>
    )
}

export default Card;