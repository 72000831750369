import * as React from "react";
import DoctorContactViewProps from "./DoctorContactViewProps";
import { InputView } from "../../../../../../../components/inputView/InputView";
import TranslationView from "../../../../../../../components/translationView/TranslationView";
import {
  AppointmentMethodType,
  mapAppointmentMethodsFromEnum,
  mapAppointmentMethodsToEnum,
} from "src/models/DoctorDTO";

const DoctorContactView = (props: DoctorContactViewProps) => {
  const { style, element, handleInputChanged, errors } = props;

  return (
    <div className="card shadow" style={style.container}>
      {/* TITLE */}
      <div className="card-header border-1" style={style.cardHeader}>
        <TranslationView>doctorDetailContactInformation</TranslationView>
      </div>
      <div className="card-body">
        <div className="row">
          {/* EMAIL */}
          <div style={style.inputLeft}>
            <div style={style.inputTitle}>
              <TranslationView>doctorDetailEmail</TranslationView>
            </div>
            <InputView
              error={errors["email"]}
              placeholder="lilian@medicheck.io"
              type="text"
              value={element.email}
              onChange={(event) =>
                handleInputChanged("email", event.target.value)
              }
            />
          </div>

          {/* GSM */}
          <div style={style.inputRight}>
            <div style={style.inputTitle}>
              <TranslationView>doctorDetailGSM</TranslationView>
            </div>
            <InputView
              placeholder="+32 496 21 94 18"
              type="text"
              value={element.gsm}
              onChange={(event) =>
                handleInputChanged("gsm", event.target.value)
              }
            />
          </div>

          {/* HOW TO REQUEST APPOINTMENT */}
          <div style={style.inputDouble}>
            <div style={style.inputTitle}>
              <TranslationView>
                doctorDetailHowToRequestAppointment
              </TranslationView>
            </div>
            <InputView
              placeholder="Select an option"
              type="multi-select"
              value={mapAppointmentMethodsToEnum(element.appointmentMethods)}
              onChange={(event) => {
                const selectedOption = event.target.value;
                const updatedValue =
                  mapAppointmentMethodsFromEnum(selectedOption);
                handleInputChanged("appointmentMethods", updatedValue);
              }}
            >
              {Object.values(AppointmentMethodType).map((method) => (
                <option key={method} value={method}>
                  {method}
                </option>
              ))}
            </InputView>
          </div>
        </div>
        <div className="row">
          {/* FIX NUMBER */}
          <div style={style.inputLeft}>
            <div style={style.inputTitle}>
              <TranslationView>doctorDetailFixNumber</TranslationView>
            </div>
            <InputView
              placeholder="02 897 20 01"
              type="text"
              value={element.fixNumber}
              onChange={(event) =>
                handleInputChanged("fixNumber", event.target.value)
              }
            />
          </div>

          {/* FAX NUMBER */}
          <div style={style.inputRight}>
            <div style={style.inputTitle}>
              <TranslationView>doctorDetailFaxNumber</TranslationView>
            </div>
            <InputView
              placeholder="02 897 20 01"
              type="text"
              value={element.faxNumber}
              onChange={(event) =>
                handleInputChanged("faxNumber", event.target.value)
              }
            />
          </div>

          {/* APPOINTMENT WEBSITE */}
          <div style={style.inputDouble}>
            <div style={style.inputTitle}>
              <TranslationView>doctorDetailAppointmentWebsite</TranslationView>
            </div>
            <InputView
              placeholder="https://yourwebsite.com"
              type="text"
              value={element.appointmentWebsite}
              onChange={(event) =>
                handleInputChanged("appointmentWebsite", event.target.value)
              }
            />
          </div>
        </div>

        <div className="row">
          {/* COMMENT */}
          <div style={style.inputDouble}>
            <div style={style.inputTitle}>
              <TranslationView>doctorDetailComment</TranslationView>
            </div>
            <InputView
              type="textarea"
              placeholder="Comment"
              value={element.comments}
              onChange={(event) =>
                handleInputChanged("comments", event.target.value)
              }
            />
          </div>

          {/* TELECHECK LINK */}
          <div style={style.inputDouble}>
            <div style={style.inputTitle}>
              <TranslationView>doctorDetailTelecheckLink</TranslationView>
            </div>
            <InputView
              placeholder="https://telecheckwebsite.com"
              type="text"
              value={element.telecheckLink}
              onChange={(event) =>
                handleInputChanged("telecheckLink", event.target.value)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorContactView;
