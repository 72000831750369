import Service from "./Service";
import Axios from "axios";

export default class CompanySuperiorService extends Service {
  get(id: String) {
    return this.execute(
      Axios.get(`${this.url}/companies/${id}/superiors?size=500`, this.config())
    );
  }
}
