import * as React from 'react';
import CompanyDetailViewStyle from '../../CompanyDetailViewStyle';
import { InputView } from '../../../../../../../../components/inputView/InputView'
import TranslationView from '../../../../../../../../components/translationView/TranslationView'
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';

const style = new CompanyDetailViewStyle();

interface CompanyPreferenceProps {
    element: any;
    handleInputChanged: any;
    mapCompanyDoctorOptions: any;
    mapCompanyDoctorValues: any;
    handleCompanyAlwaysDoctor: any;
    handleCompanyNeverDoctor: any;
    companyNeverDoctors: any;
    companyAlwaysDoctors: any;
}

const CompanyPreferenceView = (props: CompanyPreferenceProps) => {

    const { element, handleInputChanged, mapCompanyDoctorOptions, mapCompanyDoctorValues, companyAlwaysDoctors, companyNeverDoctors, handleCompanyAlwaysDoctor, handleCompanyNeverDoctor } = props;

    return (
        <LocalizationContext.Consumer>
            {({ translate }) => (
                <div className="card shadow" style={style.card}>
                    {/* TITLE */}
                    <div className="card-header border-1" style={style.cardHeader}>
                        <TranslationView>
                            companyDetailCheckPreferences
                </TranslationView>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">

                                {/* GENERAL COMMENTS */}
                                <div style={style.inputDouble}>
                                    <div style={style.inputTitle}>
                                        <TranslationView>
                                            companyDetailGeneralComments
                                        </TranslationView>
                                    </div>
                                    <InputView type="textarea" placeholder="This company launches Check requests for every worker and prefers doctors to be as close as possible." value={element.generalComments} onChange={(event) => handleInputChanged("generalComments", event.target.value)} />
                                </div>

                            </div>
                            <div className="col">
                                <div className="row">

                                    {/* SCHEDULE START WEEK */}
                                    <div style={style.inputLeft}>
                                        <div style={style.inputTitle}>
                                            <TranslationView>
                                                companyDetailScheduleStartWeek
                                            </TranslationView>
                                        </div>
                                        <InputView placeholder="8:00:00" mask="h9:m9:s9" type="text" value={element.weekScheduleStart} onChange={(event) => handleInputChanged("weekScheduleStart", event.target.value)} />
                                    </div>

                                    {/* SCHEDULE STOP WEEK */}
                                    <div style={style.inputRight}>
                                        <div style={style.inputTitle}>
                                            <TranslationView>
                                                companyDetailScheduleStopWeek
                                    </TranslationView>
                                        </div>
                                        <InputView placeholder="8:00:00" mask="h9:m9:s9" type="text" value={element.weekScheduleEnd} onChange={(event) => handleInputChanged("weekScheduleEnd", event.target.value)} />
                                    </div>

                                </div>
                                <div className="row">

                                    {/* SCHEDULE START WEEKEND */}
                                    <div style={style.inputLeft}>
                                        <div style={style.inputTitle}>
                                            <TranslationView>
                                                companyDetailScheduleStartWeekend
                                            </TranslationView>
                                        </div>
                                        <InputView placeholder="8:00:00" mask="h9:m9:s9" type="text" value={element.weekendScheduleStart} onChange={(event) => handleInputChanged("weekendScheduleStart", event.target.value)} />
                                    </div>

                                    {/* SCHEDULE END WEEKEND */}
                                    <div style={style.inputRight}>
                                        <div style={style.inputTitle}>
                                            <TranslationView>
                                                companyDetailScheduleStopWeekend
                                            </TranslationView>
                                        </div>
                                        <InputView placeholder="8:00:00" mask="h9:m9:s9" type="text" value={element.weekendScheduleEnd} onChange={(event) => handleInputChanged("weekendScheduleEnd", event.target.value)} />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="row">

                                    {/* DOCTOR TO ALWAYS USE */}
                                    <div style={style.inputDouble}>
                                        <div style={style.inputTitle}>
                                            <TranslationView>
                                                companyDetailDoctorsToAlwaysUse
                                    </TranslationView>
                                        </div>
                                        <InputView placeholder="Bérangère Haudecour" type="multi-select" value={mapCompanyDoctorValues(companyAlwaysDoctors)} onChange={handleCompanyAlwaysDoctor}>
                                            {mapCompanyDoctorOptions()}
                                        </InputView>
                                    </div>

                                </div>
                            </div>
                            <div className="col">

                                <div className="row">

                                    <div style={style.inputLeft}>
                                        <div style={style.inputTitle}>
                                            <TranslationView>
                                                electronicBraceletNeeded
                                            </TranslationView>
                                        </div>
                                        <InputView placeholder="Select an option" type="select" value={element.electronicBraceletNeeded} onChange={(event) => handleInputChanged("electronicBraceletNeeded", event.target.value)}>
                                            <option value={-1}></option>
                                            <option value={0}>No</option>
                                            <option value={1}>Yes</option>
                                        </InputView>
                                    </div>
                                    <div style={style.inputRight} />
                                </div>

                            </div>
                        </div>

                        <div className='row'>
                            <div className="col">
                                <div className='row'>
                                    {/* DOCTOR TO NEVER USE */}
                                    <div style={style.inputDouble}>
                                        <div style={style.inputTitle}>
                                            <TranslationView>
                                                companyDetailDoctorsToNeverUse
                                    </TranslationView>
                                        </div>
                                        <InputView placeholder="Bérangère Haudecour" type="multi-select" value={mapCompanyDoctorValues(companyNeverDoctors)} onChange={handleCompanyNeverDoctor}>
                                            {mapCompanyDoctorOptions()}
                                        </InputView>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row">

                                    {/* ELECTRONIC START */}
                                    <div style={style.inputLeft}>
                                        <div style={style.inputTitle}>
                                            <TranslationView>
                                                electronicBraceletStart
                                            </TranslationView>
                                        </div>
                                        <InputView placeholder="8:00:00" mask="h9:m9:s9" type="text" value={element.electronicBraceletStart} onChange={(event) => handleInputChanged("electronicBraceletStart", event.target.value)} />
                                    </div>

                                    {/* ELECTRONIC STOP */}
                                    <div style={style.inputRight}>
                                        <div style={style.inputTitle}>
                                            <TranslationView>
                                                electronicBraceletStop
                                            </TranslationView>
                                        </div>
                                        <InputView placeholder="8:00:00" mask="h9:m9:s9" type="text" value={element.electronicBraceletStop} onChange={(event) => handleInputChanged("electronicBraceletStop", event.target.value)} />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="row">

                                    {/* DISTANCE MIN */}
                                    <div style={style.inputLeft}>
                                        <div style={style.inputTitle}>
                                            <TranslationView>
                                                companyDetailDistanceMin
                                            </TranslationView>
                                        </div>
                                        <InputView placeholder="0 km" type="text" mask="99 k\m" value={element.distanceMin} onChange={(event) => handleInputChanged("distanceMin", event.target.value)} />
                                    </div>

                                    {/* DISTANCE MAX */}
                                    <div style={style.inputRight}>
                                        <div style={style.inputTitle}>
                                            <TranslationView>
                                                companyDetailDistanceMax
                                            </TranslationView>
                                        </div>
                                        <InputView placeholder="20 km" type="text" mask="99 k\m" value={element.distanceMax} onChange={(event) => handleInputChanged("distanceMax", event.target.value)} />
                                    </div>

                                </div>
                            </div>

                            <div className="col">
                                <div className="row">

                                    {/* ACTION IF SMS NOT DELIVERED */}
                                    <div style={style.inputLeft}>
                                        <div style={style.inputTitle}>
                                            <TranslationView>
                                                companyDetailActionIfSMSNotDelivered
                                            </TranslationView>
                                        </div>
                                        <InputView placeholder="Select an option" type="select" value={element.noDeliveryAction} onChange={(event) => handleInputChanged("noDeliveryAction", event.target.value)}>
                                            <option value={-1}></option>
                                            <option value={"0"}>{translate('companyDetailActionIfSMSNotDeliveredAskForANewNumberByEmail')}</option>
                                            <option value={"1"}>{translate('companyDetailActionIfSMSNotDeliveredIgnore')}</option>
                                            <option value={"2"}>{translate('companyDetailActionIfSMSNotDeliveredCancelTheCheck')}</option>
                                            <option value={"3"}>{translate('companyDetailActionIfSMSNotDeliveredChangeIntoHouseVisit')}</option>
                                        </InputView>
                                    </div>

                                    {/* CHECK LAST DAY */}
                                    <div style={style.inputRight}>
                                        <div style={style.inputTitle}>
                                            <TranslationView>
                                                companyDetailCheckOnLastDay
                                            </TranslationView>
                                        </div>
                                        <InputView placeholder="Select an option" type="select" value={`${element.lastDayCheck}`} onChange={(event) => handleInputChanged("lastDayCheck", event.target.value)}>
                                            <option value={-1}></option>
                                            <option value={"true"}>Yes</option>
                                            <option value={"false"}>No</option>
                                        </InputView>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div style={style.inputLeft}>
                                <div style={style.inputTitle}>
                                    <TranslationView>
                                        maxDistanceAction
                                    </TranslationView>
                                </div>
                                <InputView placeholder="Select an option" type="select" value={element.maxDistanceAction} onChange={(event) => handleInputChanged("maxDistanceAction", event.target.value)}>
                                    <option value={-1}></option>
                                    <option value={0}>Classify as non-operable</option>
                                    <option value={1}>Change to home Check</option>
                                    <option value={2}>Ask the company what they prefer</option>
                                </InputView>
                            </div>
                            <div style={style.inputRight} />
                            <div style={style.inputLeft}>
                                <div style={style.inputTitle}>
                                    <TranslationView>
                                        checkNextDay
                                    </TranslationView>
                                </div>
                                <InputView placeholder="Select an option" type="select" value={element.nextDayConvocation} onChange={(event) => handleInputChanged("nextDayConvocation", event.target.value)}>
                                    <option value={-1}></option>
                                    <option value={0}>No</option>
                                    <option value={1}>Yes</option>
                                </InputView>
                            </div>
                            <div style={style.inputRight} >
                                <div style={style.inputTitle}>
                                    <TranslationView>
                                        checkDoctorWhitelisting
                                    </TranslationView>
                                    <InputView placeholder="Select an option" type="select" value={element.doctorWhitelisting} onChange={(event) => handleInputChanged("doctorWhitelisting", event.target.value)}>
                                        <option value={0}>No</option>
                                        <option value={1}>Yes</option>
                                    </InputView>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </LocalizationContext.Consumer >
    )
}

export default CompanyPreferenceView;