import * as React from 'react';
import TranslationViewProps from './TranslationViewProps';
import BaseComponent from '../../abstracts/BaseComponent';
import { LocalizationContext } from '../localizationContext/LocalizationContext';

export default class TranslationView extends BaseComponent<TranslationViewProps>
{
    /* RENDERING */
    render() {
        const { children, upperCase } = this.props
        return (
            <LocalizationContext.Consumer>
                {({ translate }) => translate(children, upperCase)}
            </LocalizationContext.Consumer>
        );
    }
}