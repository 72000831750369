import Axios from 'axios';
import Service from './Service';

export default class ProofOfReceiptService extends Service {
    get(id: string) {
        return this.execute(Axios.get(`${this.url}/pors/${id}`, this.config()));
    }

    post(id: string) {
        return this.execute(Axios.post(`${this.url}/pors/${id}`, {}, this.config()));
    }
}