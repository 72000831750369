export default class PopUpViewStyle {
    button: React.CSSProperties =
    {
        border: 'none',
        margin:'auto',
        boxShadow:'none',
        marginTop: 25 
    }

    buttonIcon: React.CSSProperties = 
    {
        width:20, 
        height:20, 
        marginRight:15, 
        filter:'invert(100%) sepia(0%) saturate(7496%) hue-rotate(272deg) brightness(100%) contrast(103%)'
    }

    buttonText: React.CSSProperties =
    {
        fontWeight:600, 
        marginTop:-20, 
        fontSize:16
    }

    container: React.CSSProperties =
    {
        height:535, 
        width:400, 
        textAlign:'center'
    }

    modal: React.CSSProperties =
    {
        height:535, 
        width:400, 
        padding:22
    }

    closeContainer: React.CSSProperties =
    {
        cursor:'pointer', 
        height:25, 
        width:15, 
        padding: 0, 
        marginLeft:'auto'
    }

    close: React.CSSProperties =
    {
        height:25, 
        width:25, 
        marginLeft:-5
    }

    illustration: React.CSSProperties =
    {
        margin:'auto', 
        height:250, 
        width:250
    }

    nameContainer: React.CSSProperties =
    {
        fontWeight:500, 
        fontSize:16, 
        margin:'auto', 
        width:'100%', 
        textAlign:'center', 
        marginTop:25
    }

    name: React.CSSProperties =
    {
        margin:'auto'
    }

    title: React.CSSProperties =
    {
        width:300, 
        fontSize:18, 
        fontWeight:300, 
        color:'#405364', 
        margin:'auto', 
        textAlign:'center',
        display: 'block'
    }

    ignoreContainer: React.CSSProperties =
    {
        marginTop:15
    }

    ignore: React.CSSProperties =
    {
        margin:'auto', 
        textAlign:'center', 
        color:'#7D91A0', 
        fontWeight:300, 
        fontSize:16, 
        textDecoration:'underline', 
        cursor:'pointer'
    }
}