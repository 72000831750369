import { createContext, useContext } from "react";
import UserDTO from "src/models/UserDTO";

interface UserContextValue {
	user: UserDTO;
	updateUser: any;
	isInRole: any;
	logOut: any;
	updateToken: any;
}

const UserContext = createContext<UserContextValue>({
	user: undefined,
	updateUser: () => {},
	updateToken: (bearer_token, refresh_token) => {},
	isInRole: (_role: string) => {},
	logOut: () => {},
});

export const useUser = () => {
	return useContext(UserContext);
};

export default UserContext;
