export default class InputViewStyle {
    unFocused: React.CSSProperties =
        {
            border: '1px solid #E3E3E3',
            boxShadow: 'none'
        }

    focused: React.CSSProperties =
        {
            border: '1px solid #009DC8',
            boxShadow: 'none'
        }

    error: React.CSSProperties =
        {
            border: '1px solid #EC5467',
            boxShadow: 'none'
        }

    warning: React.CSSProperties =
        {
            border: '1px solid #FDB56D',
            boxShadow: 'none'
        }

    disabled: React.CSSProperties =
        {
            backgroundColor: '#E3E3E3'
        }

    textArea: React.CSSProperties =
        {
            boxSizing: 'border-box',
            borderRadius: 6,
            backgroundColor: '#FFF',
            border: '1px solid #E3E3E3',
            width: '100%',
            outline: 'none'
        }

    select: React.CSSProperties =
        {
            fontWeight: 300,
            color: '#68737E',
            textIndent: 5,
        }
}