import Service from './Service';
import Axios from 'axios';

export default class CheckDoctorService extends Service
{
    getById(checkId: string, doctorId)
    {
        return this.execute(Axios.get(`${this.url}/checks/${checkId}/doctors/${doctorId}`, this.config()));
    }

    getByPages(checkId: string, page: number, amount: number)
    {
        return this.execute(Axios.get(`${this.url}/checks/${checkId}/doctors?size=${amount}&page=${page}`, this.config()));
    }

    post(checkId: string, doctor: any)
    {
        return this.execute(Axios.post(`${this.url}/checks/${checkId}/doctors`, doctor, this.config()));
    }

    delete(checkId: string)
    {
        return this.execute(Axios.delete(`${this.url}/checks/${checkId}/doctors`, this.config()));
    }
}