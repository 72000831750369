import * as React from 'react';
import { Modal } from 'reactstrap';
import DeleteButtonViewStyle from './DeleteButtonViewStyle';
import DeleteButtonViewProps from './DeleteButtonViewProps';
import DeleteButtonViewState from './DeleteButtonViewState';
import TranslationView from '../../../../components/translationView/TranslationView';
import BaseComponent from '../../../../abstracts/BaseComponent';
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';
import UserContext from 'src/components/userContext/UserContext';

export default class DeleteButtonView extends BaseComponent<DeleteButtonViewProps, DeleteButtonViewState>
{
    /* ATTRIBUTES */
    style = new DeleteButtonViewStyle();

    /* CONSTRUCTOR */
    constructor(props: DeleteButtonViewProps) {
        super(props);

        // initial State
        this.state = { modal: false, isBusy: false };

        // Bindings
        this.toggleModal = this.toggleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    /* HANDLERS */
    handleDelete() {
        this.setState({ modal: false }, this.props.handleDelete);
    }

    /* METHODS */
    toggleModal() {
        this.setState((previousState) => {
            return { modal: !previousState.modal }
        })
    };

    /* RENDER */
    render() {
        return (
            <LocalizationContext.Consumer>
                {({ translate }) => (
                    <>
                        <button onClick={this.toggleModal} type="button" className="btn btn-danger" style={{ ...this.style.button, boxShadow: 'none' }}>
                            <span>
                                <img src='/assets/icon_trash.svg' style={this.style.buttonImage} />
                                <span className='roboto' style={this.style.buttonText}>
                                    {`Delete ${this.props.title}`}
                                </span>
                            </span>
                        </button>

                        {/* POP UP */}
                        <Modal className="modal-dialog-centered" isOpen={this.state.modal} toggle={this.toggleModal} style={this.style.modal}>

                            <div style={this.style.modalContainer}>

                                {/* CLOSE BUTTON */}
                                <div onClick={this.toggleModal} style={this.style.closeButtonContainer}>
                                    <img style={this.style.closeButton} src="/assets/icon_cross.png" />
                                </div>

                                {/* ILLUSTRATION */}
                                <img className="row" style={this.style.modalIllustration} src="/assets/illu_red_warning.png" />

                                {/* NAME */}
                                <UserContext.Consumer>
                                    {({ user }) => (
                                        <div className="row" style={this.style.nameContainer}>
                                            <span style={this.style.name}>
                                                {`${translate('hey', 'en')} ${user?.firstName}`}
                                            </span>
                                        </div>
                                    )}
                                </UserContext.Consumer>

                                {/* VALIDATION */}
                                <div className="row" style={this.style.validation}>
                                    {`${translate("areYouSureToWantToDelete", 'en')} ${this.props.title}?`}
                                </div>

                                {/* DELETE BUTTON */}
                                <div className='row' style={{}}>
                                    <button onClick={this.handleDelete} type="button" className="btn btn-danger" style={this.style.deleteButton}>
                                        <span>
                                            <img src='/assets/icon_trash.svg' style={this.style.buttonImage} />
                                            <span className='roboto' style={this.style.buttonText}>
                                                {`${translate('delete', 'en')} ${this.props.title}`}
                                            </span>
                                        </span>
                                    </button>
                                </div>

                                {/* DON'T SAVE */}
                                <div className="row" style={this.style.dontSaveButtonContainer}>
                                    <span onClick={this.toggleModal} style={this.style.dontSaveButton}>
                                        <TranslationView>
                                            oopsNoGoBack
                                </TranslationView>
                                    </span>
                                </div>

                            </div>

                        </Modal>
                    </>
                )}

            </LocalizationContext.Consumer>
        );
    }
}