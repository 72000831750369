import * as React from 'react';
import DoctorPricingViewProps from './DoctorPricingViewProps';
import ListView from '../../../../../components/listView/ListView';
import TranslationView from '../../../../../../../components/translationView/TranslationView';

const DoctorPricingView = (props: DoctorPricingViewProps) => 
{
    const {style, services, mapServices, mapServiceHeaders} = props;

    return (
        <div className="card shadow" style={style.card}>
            {/* TITLE */}
            <div className="card-header border-1" style={style.cardHeader}>
                <TranslationView>
                    doctorDetailPricingInformation
                </TranslationView>
            </div>
            <ListView isBusy={services.length === 0} overflow='visible' noPaging elements={services} mapElements={mapServices} mapHeaders={mapServiceHeaders} />
        </div>
    );
}

export default DoctorPricingView;