import * as React from "react";
import TranslationView from "../../../../../../../components/translationView/TranslationView";
import DoctorInvoiceViewProps from "./DoctorInvoiceViewProps";
import { InputView } from "../../../../../../../components/inputView/InputView";
import AddressInputView from "../../../../../../../components/addressInputView/AddressInputView";

export default class DoctorInvoiceView extends React.Component<DoctorInvoiceViewProps> {
  render() {
    const { style, element, handleInputChanged, errors } = this.props;
    const VATOptions = [
      { label: "YES", value: 1 },
      { label: "NO", value: 0 },
    ];

    return (
      <div className="card shadow" style={style.card}>
        {/* TITLE */}
        <div className="card-header border-1" style={style.cardHeader}>
          <TranslationView>doctorDetailInvoicingInformation</TranslationView>
        </div>
        <div className="card-body">
          <div className="row">
            {/* BANK ACCOUNT NUMBER */}
            <div style={style.inputLeft}>
              <div style={style.inputTitle}>
                <TranslationView>doctorDetailBankAccountNumber</TranslationView>
              </div>
              <InputView
                placeholder="BE19 0018 2354 3012"
                type="text"
                value={element.bankAccountNumber}
                onChange={(event) =>
                  handleInputChanged("bankAccountNumber", event.target.value)
                }
              />
            </div>

            {/* LAST NAME */}
            <div style={style.inputRight}>
              <div style={style.inputTitle}>
                <TranslationView>doctorDetailInvoiceNumber</TranslationView>
              </div>
              <InputView
                placeholder="2019/001"
                type="text"
                value={element.invoicingNumber}
                onChange={(event) =>
                  handleInputChanged("invoicingNumber", event.target.value)
                }
              />
            </div>

            {/* COMPANY NAME */}
            <div style={style.inputLeft}>
              <div style={style.inputTitle}>
                <TranslationView>doctorDetailCompanyName</TranslationView>
              </div>
              <InputView
                placeholder="Docteur Lilian SPRL"
                type="text"
                value={element.companyName}
                onChange={(event) =>
                  handleInputChanged("companyName", event.target.value)
                }
              />
            </div>

            {/* FIRST NAME */}
            <div style={style.inputRight}>
              <div style={style.inputTitle}>
                <TranslationView>doctorDetailCompanyNumber</TranslationView>
              </div>
              <InputView
                placeholder="BE 0683.604.629"
                type="text"
                value={element.companyNumber}
                onChange={(event) =>
                  handleInputChanged("companyNumber", event.target.value)
                }
              />
            </div>
          </div>
          <div className="row">
            <div style={style.inputLeft}>
              <div style={style.inputTitle}>
                <TranslationView>doctorDetailSubjectToVAT</TranslationView>
              </div>
              <InputView
                placeholder="Select an option"
                type="select"
                value={element.subjectToVAT}
                onChange={(event: any) =>
                  handleInputChanged("subjectToVAT", event.target.value)
                }
                options={VATOptions}
              />
            </div>
            <div style={style.inputRight} />

            {/* OFFICE ADDRESS */}
            <div style={style.inputDouble}>
              <div style={style.inputTitle}>
                <TranslationView>
                  doctorDetailCompanyOfficeAddress
                </TranslationView>
              </div>
              <AddressInputView
                noCoordinates
                error={errors["companyAddress"]}
                placeholder="Parvis Sainte Gudule 5, 1000 Bruxelles"
                value={element.companyAddress}
                target={"companyAddress"}
                handleInputChanged={handleInputChanged}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
