import * as React from 'react';
import BaseComponentProps from './BaseComponentProps';
import BaseComponentState from './BaseComponentState';

abstract class BaseComponent<Props = {}, State extends BaseComponentState = { isBusy: false }> extends React.Component<Props, State>
{
    /* ATTRIBUTES */
    mounted: boolean;
    static translations: any;

    /* CONSTRUCTOR */
    constructor(props: Props) {
        super(props);

        if (!BaseComponent.translations) {
            BaseComponent.translations = require('../translations.json');
        }

        this.handleCopied = this.handleCopied.bind(this);
    }

    /* LIFE CYCLE EVENTS */
    componentDidMount() {
        this.mounted = true;

        // Little hack to ignore set states after being unmounted.
        this.setState = (state: any, callback: any) => {
            if (this.mounted) {
                super.setState(state, callback);
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    /* HANDLERS */
    handleCopied() {
        this.success('Copied to clipboard.');
    }

    /* METHODS */
    information(message: string) {
        alert({ type: 'info', message: message });
    }

    danger(message: string) {
        alert({ type: 'danger', message: message });
    }

    success(message: string) {
        alert({ type: 'success', message: message });
    }

    async execute<T>(promise: Promise<T>) {
        try {
            this.setState({ isBusy: true });
            return await promise;
        }
        catch (exception) {
            throw exception;
        }
        finally {
            this.setState({ isBusy: false });
        }
    }
}

export default BaseComponent;