import React from 'react';
import './style.css';
import Group5 from './components/group5';
import Group3 from './components/group3';
import Group4 from './components/group4';
import Group2 from './components/group2';

const Results = ({ data }) => {
    return (
        <div className="dashboard-results-container">
            <Group4 data={data} />
            <Group4 data={data} />
            <Group3 data={data} />
            <Group5 data={data} />
            <Group2 data={data} />
            <Group3 data={data} />
            <Group2 data={data} />

        </div>
    )
}

export default Results;