import DetailComponentStyle from '../../../../../../abstracts/detailComponent/DetailComponentStyle';

export default class SuperiorDetailViewStyle extends DetailComponentStyle
{
    buttonContainer: React.CSSProperties =
    {
        ...this.container,
        height: 49,
        display:'flex',
        justifyContent: 'space-between'
    }

    resetPasswordButtonContainer: React.CSSProperties =
    {
        ...this.container,
        marginTop: 0,
        height: 49,
        display:'flex',
        justifyContent: 'flex-start'
    }

    interfaceHolder: React.CSSProperties = 
    {
        display: 'flex', 
        fontSize: 15, 
        alignItems: 'center'
    }
}