import * as React from "react";
import { Spring } from "react-spring";
import LogViewStyle from "./LogViewStyle";
import StampView from "./components/StampView";
import BadgeView from "./components/BadgeView";
import TranslationView from "../../../../../../components/translationView/TranslationView";
import LogViewProps from "./LogViewProps";
import SystemLogView from "./modules/SystemLogView";
import CommunicationLogView from "./modules/CommunicationLogView";
import CommentLogView from "./modules/CommentLogView";
import ActionLogView from "./modules/ActionLogView";
import DeliveryLogView from "./modules/DeliveryLogView";
import LogDTO from "src/models/LogDTO";
import { CheckContext } from "src/modules/dashboard/components/checkContext/CheckContext";
import withCheck from "./functions/withCheck";
import { motion } from "framer-motion";

const getCompany = (log: LogDTO) => {
	if (log.user) {
		return "Medicheck";
	}

	if (log.type === "0000061" || log.type === "0000062" || log.type === "0000063" || log.type === "0000041" || log.type === "0000071" || log.type === "0000072") {
		return "Doctor";
	}

	if (log.request.company) {
		return log.request.company.name;
	}

	if (log.type === "0000081") {
		return "Medicheck";
	}

	return "Medicheck";
};

const getUser = (log: LogDTO) => {
	if (log.user) {
		return log.user;
	}

	if (log.type === "0000061" || log.type === "0000062" || log.type === "0000063" || log.type === "0000041" || log.type === "0000071" || log.type === "0000072") {
		if (log.check.doctor) {
			return log.check.doctor;
		}
		return { firstName: "", lastName: "" };
	}

	if (log.type === "0000081" || log.type === "0000003" || log.type === "0000100") {
		return { firstName: "Valou", lastName: "System" };
	}

	return log.request.superior;
};

const LogView: React.FC<LogViewProps> = ({ log, internal = false, delay, lastUpdate, check, updateLogs, updateCheck }) => {
	log.check = check;
	const user = getUser(log);
	const company = getCompany(log);
	const style = new LogViewStyle();

	return (
		<motion.div initial={{ x: window.innerWidth - 517 - 80 }} animate={{ x: 0, transition: { duration: 0.5, ease: "easeIn", delay: delay / 1000 } }} key={log.id} style={{ display: "flex", flexDirection: "row", paddingTop: 20 }}>
			{!internal && <BadgeView log={log} internal={internal} />}

			<div style={style.basicLog}>
				{/* STAMP RENDERING */}
				<StampView company={company} firstName={user?.firstName} lastName={user?.lastName} log={log} />

				{/* LOG RENDERING */}
				<div style={{ ...style.log, ...style.mapColors(log) }}>
					<TranslationView>{log.type}</TranslationView>

					<SystemLogView log={log} />

					<CommunicationLogView log={log} />

					<CommentLogView log={log} />

					{lastUpdate && (check.state === 4 || check.state === 6 || check.state === 7) && <ActionLogView log={log} />}

					<DeliveryLogView log={log} />
				</div>
			</div>

			{internal && <BadgeView log={log} internal={internal} />}
		</motion.div>
	);
};

export default withCheck<LogViewProps>(LogView);
