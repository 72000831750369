import React, { useState } from "react";
import style from "./style";
import TranslationView from "src/components/translationView/TranslationView";
import BoardElementProps from "./props";
import { CheckStatus } from "src/constants/CheckStatus";
import { App } from "src/app";
import CheckChat from "src/modules/dashboard/components/check-chat";
import DateHelper from "src/helpers/DateHelper";

const BoardElement = (props: BoardElementProps) => {
  // Attributes
  const myStyle: any = style;
  const { check, onAmountClicked } = props;
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isCheckHovered, setIsCheckHovered] = useState(false);
  const [isCommentHovered, setIsCommentHovered] = useState(false);
  const [isAmountHovered, setIsAmountHovered] = useState(false);

  function getLocationIcon() {
    if (check.request.location === 1) {
      return "icon_office";
    }
    return "icon_home";
  }

  function checkStatus(status: number) {
    let color = "";

    switch (status) {
      case CheckStatus.REQUESTED:
        color = "#BED2DE";
        break;
      case CheckStatus.PAUSED:
        color = "#019BC7";
        break;
      case CheckStatus.REQUEST_PROBLEM:
      case CheckStatus.PROBLEM:
        color = "#EC5467";
        break;
      case CheckStatus.SCHEDULED:
        color = "#9FD6E5";
        break;
      case CheckStatus.FINISHED:
        color = "#0A728E";
        break;
      case CheckStatus.REQUESTUPDATED:
      case CheckStatus.UPDATED:
        color = "#FDB56D";
        break;
      case CheckStatus.ARCHIVED:
        color = "black";
        break;
      default:
        color = "#c0d3e0";
        break;
    }

    return {
      backgroundColor: color,
      height: 13,
      width: 13,
      borderRadius: 6.5,
    };
  }

  function findDateBackground(dateString) {
    if (dateString && dateString !== "0001-01-01T00:00:00") {
      if (new Date(dateString) < new Date()) {
        return { backgroundColor: "#ef8695" };
      }

      if (
        new Date(dateString) <
        new Date(new Date().setTime(new Date().getTime() + 86400000))
      ) {
        return { backgroundColor: "#fbcda0" };
      }
    }
    return { backgroundColor: "#c0d3e0" };
  }

  function formatDate(dateString: string, timeString?: string) {
    const date = new Date(dateString);
    let day = date.getDate();
    let formattedDay = "";

    if (day < 10) {
      formattedDay = "0" + day;
    } else {
      formattedDay = "" + day;
    }

    let month = date.getMonth() + 1;
    let formattedMonth = "";

    if (month < 10) {
      formattedMonth = "0" + month;
    } else {
      formattedMonth = month + "";
    }

    if (timeString) {
      return (
        formattedDay +
        "/" +
        formattedMonth +
        "/" +
        date.getFullYear() +
        " " +
        timeString.substring(0, timeString.length - 3)
      );
    }

    let hour = date.getHours();
    let formattedHour = "";

    if (hour < 10) {
      formattedHour = "0" + hour;
    } else {
      formattedHour = "" + hour;
    }

    let minutes = date.getMinutes();
    let formattedMinutes = "";

    if (minutes < 10) {
      formattedMinutes = "0" + minutes;
    } else {
      formattedMinutes = "" + minutes;
    }

    return (
      formattedDay +
      "/" +
      formattedMonth +
      "/" +
      date.getFullYear() +
      " " +
      formattedHour +
      ":" +
      formattedMinutes
    );
  }

  function handleChatClicked() {
    setIsChatOpen(true);
  }

  return (
    <>
      <div
        style={{
          userSelect: "text",
          margin: 10,
          padding: 10,
          backgroundColor: "#F5FAFF",
          borderRadius: 8,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            style={{
              userSelect: "text",
              fontSize: 14,
              color: "#405364",
              fontWeight: 500,
            }}
          >
            {check.request.worker.firstName} {check.request.worker.lastName}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              src={`/assets/${getLocationIcon()}.svg`}
              style={{ width: 13, height: 13 }}
            />
            <div
              style={{
                userSelect: "text",
                fontSize: 14,
                color: "#405364",
                marginLeft: 5,
                fontWeight: 300,
                marginTop: 2,
              }}
            >
              Dr. {check.doctor ? check.doctor.lastName : "- - -"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 5,
              alignItems: "center",
            }}
          >
            <div
              style={{
                ...checkStatus(check.state),
                height: 13,
                width: 13,
                borderRadius: 6.5,
              }}
            />
            <div
              style={{
                userSelect: "text",
                marginLeft: 5,
                padding: "0px 3px",
                width: "fit-content",
                height: 15,
                textAlign: "center",
                fontSize: 11,
                ...findDateBackground(
                  check.secondCheckNeeded
                    ? check.secondCheckDate
                    : check.checkDate
                ),
                borderRadius: 3,
              }}
            >
              {check.secondCheckDate
                ? check.secondCheckDate &&
                  check.secondCheckDate !== "0001-01-01T00:00:00"
                  ? formatDate(check.secondCheckDate, check.secondCheckTime)
                  : "- - -"
                : check.checkDate && check.checkDate !== "0001-01-01T00:00:00"
                ? DateHelper.formatDate(check.checkDate)
                : "- - -"}
            </div>
            {check.user && (
              <div
                style={{
                  userSelect: "text",
                  marginLeft: 5,
                  padding: "0px 3px",
                  width: "fit-content",
                  height: 15,
                  textAlign: "center",
                  fontSize: 11,
                  backgroundColor: "#c0d3e0",
                  borderRadius: 3,
                }}
              >
                {check.user
                  ? check.user.firstName[0] + check.user.lastName[0]
                  : "--"}
              </div>
            )}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            onMouseEnter={() => setIsCheckHovered(true)}
            onMouseLeave={() => setIsCheckHovered(false)}
            onClick={() =>
              App.history.push("/dashboard/check/detail", { check: check })
            }
            style={{
              cursor: "pointer",
              backgroundColor: "transparant",
              width: 15,
              height: 15,
            }}
          >
            <img
              src={`/assets/icon_open_board${
                isCheckHovered ? "_hovered" : ""
              }.png`}
              style={{ width: 15, transform: "translateY(-2px)" }}
            />
          </div>
          <div
            onMouseEnter={() => setIsCommentHovered(true)}
            onMouseLeave={() => setIsCommentHovered(false)}
            onClick={handleChatClicked}
            style={{
              cursor: "pointer",
              backgroundColor: "transparant",
              width: 15,
              height: 15,
              marginTop: 9,
            }}
          >
            {check.hasMessages ? (
              <img
                src={
                  isCommentHovered
                    ? `/assets/icon_view_comment_board_selected.svg`
                    : "/assets/icon_view_chat.svg"
                }
                style={{ width: 15, transform: "translateY(-2px)" }}
              />
            ) : (
              <img
                src={`/assets/icon_add_comment${
                  isCommentHovered ? "_selected" : ""
                }.svg`}
                style={{ width: 15, transform: "translateY(-2px)" }}
              />
            )}
          </div>
          {check.amountOfMessages > 0 && (
            <>
              {isAmountHovered && onAmountClicked ? (
                <div
                  onMouseLeave={() => setIsAmountHovered(false)}
                  onClick={onAmountClicked}
                  style={{
                    cursor: "pointer",
                    width: 23.5,
                    height: 20,
                    marginTop: 6,
                  }}
                >
                  <img
                    src={`/assets/icon_all_coms_sent.svg`}
                    style={{ width: 23.5 }}
                  />
                </div>
              ) : (
                <div
                  onMouseEnter={() => setIsAmountHovered(true)}
                  style={{
                    userSelect: "text",
                    marginLeft: 0,
                    height: 15,
                    padding: "0px 3px",
                    width: "fit-content",
                    textAlign: "center",
                    fontSize: 11,
                    backgroundColor: "#c0d3e0",
                    borderRadius: 3,
                    marginTop: 11,
                  }}
                >
                  {check.amountOfMessagesSent}/{check.amountOfMessages}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <CheckChat
        checkId={check.id}
        isOpen={isChatOpen}
        closeChat={() => setIsChatOpen(false)}
      />
    </>
  );
};

export default BoardElement;
