import * as React from 'react';
import RequestService from '../../../../services/RequestService';
import SearchView from '../../components/searchView/SearchView';
import ListView from '../../components/listView/ListView';
import { App } from '../../../../app';
import CheckDTO from '../../../../models/CheckDTO';
import { useTranslation } from 'src/components/localizationContext/LocalizationContext';
import useSearch from 'src/components/useSearch';
import { useQuery } from 'react-query';
import RequestTableHeader from './components/request-table-header';
import RequestTableRow from './components/request-table-row';
import RequestTableFilters from './components/request-table-filters';

const SEARCH = "REQUESTS_SEARCH_STATE";
const PAGING = "REQUESTS_PAGING_STATE";
const FIELDS = ['request.creationDate', 'request.worker.firstName', 'request.worker.lastName', 'request.worker.fullName', 'request.company.name'];

const RequestView = () => {
    // Attributes
    const { translate } = useTranslation();
    const service = new RequestService();
    const [page, setPage] = React.useState(0);
    const query = useQuery("requests", refresh, { refetchIntervalInBackground: false, refetchInterval: 1000 });
    const [lastRefresh, setLastRefresh] = React.useState<Date>(new Date());
    const [currentCheck, setCurrentCheck] = React.useState("");
    const [filters, setFilters] = React.useState({ 3: true, 2: true, 7: true, 9: true, 10: true });
    const { search, setSearch, filtered } = useSearch<CheckDTO>(query.data, FIELDS);

    // Effects
    React.useEffect(handleStorageNotEmpty, []);

    // Methods
    function applyFilters() {
        if (filtered) {
            if (filtered.length > 0) {
                return filtered.filter(elt => filters[elt.state]);
            }
        }

        return [];
    }

    function handleNavigateClicked(check: CheckDTO) {
        localStorage.setItem(SEARCH, search);
        localStorage.setItem(PAGING, `${page}`);
        App.history.push('/dashboard/check/detail', { check: check });
    }

    function handleStorageNotEmpty() {
        if (localStorage.getItem(SEARCH) && localStorage.getItem(PAGING)) {
            const search = localStorage.getItem(SEARCH);
            const paging = localStorage.getItem(PAGING);

            setSearch(search);
            setPage(parseInt(paging));

            localStorage.removeItem(SEARCH);
            localStorage.removeItem(PAGING);
        }
    }

    async function refresh() {
        const data = (await service.get());
        setLastRefresh(new Date());
        return data;
    }

    // Rendering
    return (
        <SearchView
            search={search}
            title={translate('requestTitle')}
            isBusy={query.status !== "success"}
            handleSearchChanged={(event) => setSearch(event.target.value)}
            history={[{ name: translate('requestTitle'), path: "/dashboard/request" }]}
            cardHeaderChildren={
                <RequestTableFilters
                    lastRefresh={lastRefresh}
                    filtered={filtered}
                    filters={filters}
                    setFilters={setFilters}
                />
            }
        >
            <ListView
                page={page}
                noHideLoading
                handlePageChanged={setPage}
                isBusy={filtered === undefined}
                elements={filtered ? applyFilters() : []}
                mapElements={(check) =>
                    <RequestTableRow
                        check={check}
                        currentCheck={currentCheck}
                        setCurrentCheck={setCurrentCheck}
                        handleNavigateClicked={handleNavigateClicked}
                    />
                }
                mapHeaders={RequestTableHeader}
            />
        </SearchView>
    );
}

export default RequestView;