import * as React from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import { format, startOfMonth, lastDayOfMonth } from 'date-fns'
import DashboardService from 'src/services/DashboardService';
import DashboardViewState from './DashboardViewState';
import FilterTab from './filter-tab';
import Results from './results';
import { motion } from 'framer-motion';
import HeaderView from '../../components/headerView/HeaderView';
import { useHistory } from 'react-router';
import { useTranslation } from 'src/components/localizationContext/LocalizationContext';
import ViewTab from './view-tab';

const DashboardView = () => {
    // Attributes
    const { translate } = useTranslation()
    const results = { company: 'Belgium', startDate: '22/03/2022', endDate: '25/03/2022', requestedChecks: 12294, cancelledChecks: 12222, nonOperableChecks: 1234, days: 12 }

    const initialFirstDay = startOfMonth(new Date())
    const [startDateFilter, setStartDateFilter] = React.useState((format(initialFirstDay, 'yyyy-MM-dd')))
    const initialLastDay = lastDayOfMonth(new Date())
    const [endDateFilter, setEndDateFilter] = React.useState((format(initialLastDay, 'yyyy-MM-dd')))
    const [companyFilter, setCompanyFilter] = React.useState<any>(null)
    const service = new DashboardService()
    const query = useQuery('dashboard', refresh)
    const filtersProps = {
        companyFilter,
        setCompanyFilter,
        startDateFilter,
        setStartDateFilter,
        endDateFilter,
        setEndDateFilter
    }

    // Effects
    React.useEffect(() => {
        if (query.data) {
            query.refetch()
        }
    }, [companyFilter, startDateFilter, endDateFilter])

    // Methods
    async function refresh() {
        const data = await service.get({
            companyId: companyFilter?.id,
            endDate: format(mapDate(endDateFilter), 'dd/MM/yyyy'),
            startDate: format(mapDate(startDateFilter), 'dd/MM/yyyy')
        })
        return data
    }

    function mapDate(date: any) {
        if (date !== undefined && date !== null && date !== "") {
            const m = moment(date);
            return moment(m).utc().add(m.utcOffset(), 'm').toDate();
        }

        if (date === undefined) {
            date = null;
        }

        // Little hack for the react-datepicker
        return date;
    }

    return (
        <div className="col">
            <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
                <motion.div initial={{ y: -20 }} animate={{ y: 0 }}>
                    <HeaderView title={'Dashboard'} history={[{ name: translate('dashboard'), path: "/dashboard" }]} />
                </motion.div>
                <div style={{ transform: "translate(100px 0px)" }}>
                    <ViewTab />
                </div>

            </div>
            <FilterTab filters={filtersProps} />
            {/* {query.data ?
                (
                    <Results data={query.data} />
                )
                :
                (
                    <div style={{ marginTop: 30 }}>Is loading...</div>
                )
            } */}
            <Results data={results} />

        </div>
    );
}

export default DashboardView;