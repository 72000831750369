import TranslationView from "src/components/translationView/TranslationView";
import { actions } from "../../../../reducer";
import { MyCertificateRandomChecksSetupProps } from "../../props";
import MyCertificateInput from "../input";
import "./style.css";

const RightGroup = ({
  myCertificate,
  dispatch,
}: MyCertificateRandomChecksSetupProps) => {
  //Functions
  const handleOnChangeIncapacityTypes = (event: any) => {
    dispatch({
      type: actions.companyChanged,
      payload: {
        target: "incapacityTypes",
        value: myCertificate.company.configuration.incapacityTypes?.map(
          (elt) => {
            if (event.target.value?.includes(elt.type)) {
              elt.isRandom = true;
            } else {
              elt.isRandom = false;
            }

            return elt;
          }
        ),
      },
    });
  };

  const handleOnChangeCertificateTypes = (event: any) => {
    dispatch({
      type: actions.companyChanged,
      payload: {
        target: "certificateTypes",
        value: myCertificate.company.configuration.certificateTypes?.map(
          (elt) => {
            if (event.target.value?.includes(elt.type)) {
              elt.isRandom = true;
            } else {
              elt.isRandom = false;
            }

            return elt;
          }
        ),
      },
    });
  };

  return (
    <div className="company-mycertificate-rightgroup-container">
      <MyCertificateInput
        target="incapacityTypes"
        label="Incapacity type criteria"
        type="multi-select"
        value={myCertificate.company.configuration.incapacityTypes
          .filter((elt) => elt.isRandom)
          ?.map((elt) => elt.type)}
        handleOnChange={(event) => handleOnChangeIncapacityTypes(event)}
      >
        {myCertificate.company.configuration.incapacityTypes
          .filter((elt) => elt.isActive)
          ?.map((option) => {
            return (
              <option value={option.type}>
                <TranslationView>{option.name}</TranslationView>
              </option>
            );
          })}
      </MyCertificateInput>
      <MyCertificateInput
        target="certificateTypes"
        label="Certificate type criteria"
        type="multi-select"
        value={myCertificate.company.configuration.certificateTypes
          .filter((elt) => elt.isRandom)
          ?.map((elt) => elt.type)}
        handleOnChange={(event) => handleOnChangeCertificateTypes(event)}
      >
        {myCertificate.company.configuration.certificateTypes
          .filter((elt) => elt.isActive)
          ?.map((option) => {
            return (
              <option value={option.type}>
                <TranslationView>{option.name}</TranslationView>
              </option>
            );
          })}
      </MyCertificateInput>
    </div>
  );
};

export default RightGroup;
