import * as React from 'react';
import ToolBoxElementViewStyle from './ToolBoxElementViewStyle';
import TranslationView from '../../../../../../../../components/translationView/TranslationView';
import { motion } from 'framer-motion';

const ToolBoxElementView = (props: any) => {
    // Attributes
    const { elt, handleOnClick } = props;
    const style = new ToolBoxElementViewStyle();

    // Render
    return (
        <div style={style.container}>
            <motion.div initial={{ scale: 0.60 }} animate={{ scale: 1, transition: { delay: 0.5 } }} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }} onClick={() => handleOnClick()} style={style.imageContainer}>
                <img src={`/assets/${elt.image}.svg`} style={style.image} />
            </motion.div>
            <div style={style.title}><TranslationView>{elt.title}</TranslationView></div>
        </div>
    );
}

export default ToolBoxElementView;