export default class DashboardViewStyle {
	container: React.CSSProperties = {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		height: '100%',
	};

	content: React.CSSProperties = {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		padding: 20,
		paddingTop: 54,
		overflowY: 'scroll',
		overflowX: 'hidden',
		height: '100%',
		backgroundColor: '#F5FAFF',
	};

	placeholder: React.CSSProperties = {
		height: 50,
		width: '100%',
	};
}
