import React from "react";
import RequestTableRowProps from "./props";
import { useTranslation } from "src/components/localizationContext/LocalizationContext";
import { useUser } from "src/components/userContext/UserContext";
import { CheckStatus } from "src/constants/CheckStatus";
import RequestTableRowStyle from "./style";
import CheckDTO from "src/models/CheckDTO";
import CheckAssignService from "src/services/CheckAssignService";
import NetworkHelper from "src/helpers/NetworkHelper";
import DateHelper from "src/helpers/DateHelper";

const RequestTableRow = (props: RequestTableRowProps) => {
	// Attributes
	const { user } = useUser();
	const { check, currentCheck, setCurrentCheck, handleNavigateClicked } = props;
	const { request } = check;
	const style = new RequestTableRowStyle();
	const { translate } = useTranslation();
	const isCurrentCheck = currentCheck === check.id;
	const [isAssignHovered, setIsAssignHovered] = React.useState(false);
	const location = translate(getLocationTranslation(request.location), "en");
	const timing = request.timing === "OPTIMIZED" ? translate("requestOptimized", "en") : translate("requestImmediate", "en");
	const endDate = request.endDate ? `${request.endDate.split("T")[0].split("-")[2]}/${request.endDate.split("T")[0].split("-")[1]}/${request.endDate.split("T")[0].split("-")[0]}` : "-";
	const creationDate = DateHelper.formatDate(request.creationDate);

	// Methods
	async function handleAssignClicked(check: CheckDTO) {
		await NetworkHelper.execute(new CheckAssignService().put(check));
	}

	function getLocationTranslation(location){
		switch(location){
			case 0:
				return 'requestPatient';
			case 1:
				return 'requestDoctor';
			case 2:
				return 'requestTelecheck';
			default:
				return 'requestPatient';
		}
	}

	// Rendering
	return (
		<tr style={{ opacity: user.id === check.userId || !check.userId ? 1 : 0.5 }}>
			<td>{check.state === CheckStatus.REQUEST_AUTOMATIC_PAUSED ? <img style={{ height: 13, width: 13 }} src="assets/icon_pause.svg" /> : <div style={style.checkStatus(check.state)} />}</td>
			<td style={style.td}>{creationDate}</td>
			<td style={{ ...style.td, color: check.hasDuplicate ? "#fbb56c" : "" }}>{`${request.worker.firstName} ${request.worker.lastName}`}</td>
			<td style={style.td}>{request.company.name}</td>
			<td style={style.td}>{timing}</td>
			<td style={style.td}>{endDate}</td>
			<td style={style.td}>{location}</td>
			<td
				onMouseEnter={() => {
					setIsAssignHovered(true);
					setCurrentCheck(check.id);
				}}
				onMouseLeave={() => {
					setIsAssignHovered(false);
					setCurrentCheck("");
				}}
				style={{ textAlign: "center" }}
			>
				{check.operatorInitials ? (
					<>
						{isAssignHovered && isCurrentCheck ? (
							<div onClick={() => handleAssignClicked(check)} style={style.assignButtonContainer}>
								<img src={`/assets/icon_assign_to_me${isAssignHovered ? "_selected" : ""}_listing.svg`} style={style.assignButton} />
							</div>
						) : (
							<span style={style.operatorStyle}>{check.operatorInitials}</span>
						)}
					</>
				) : (
					<div onClick={() => handleAssignClicked(check)} style={style.assignButtonContainer}>
						<img src={`/assets/icon_assign_to_me${isAssignHovered && isCurrentCheck ? "_selected" : ""}_listing.svg`} style={style.assignButton} />
					</div>
				)}
			</td>
			<td style={{ textAlign: "center" }}>
				<div onClick={() => handleNavigateClicked(check)} style={style.buttonContainer}>
					<img src={`/assets/icon_open.svg`} style={style.button} />
				</div>
			</td>
		</tr>
	);
};

export default RequestTableRow;
