import React from 'react';
import TranslationView from 'src/components/translationView/TranslationView';
import SearchViewStyle from '../../../../SearchViewStyle';

const DownloadButton = ({ handleSecondaryAction }: any) => {

    const style = new SearchViewStyle();

    return (
        <button onClick={handleSecondaryAction} type="button" className="btn btn-base btn-base-primary" style={style.newContainer}>
            <span>
                <img className="img-base-primary" src='/assets/icon_download.svg' style={style.downloadIcon} />
                <span className='roboto' style={style.newText}>
                    <TranslationView>
                        downloadDB
                    </TranslationView>
                </span>
            </span>
        </button>
    )
}

export default DownloadButton;