export default class CompanyDTO {
	id: string = "";

	legalName: string = "";
	name: string = "";
	companyNumber: string = "";
	companyHubspotId: string = "";
	language: string = "";
	address: string = "";

	contactFirstName: string = "";
	contactLastName: string = "";
	contactEmail: string = "";
	contactPhoneNumber: string = "";

	salesRepresentative: string = "";
	automatedReport: string = "";
	creditBalance: any = "0.0";

	preventionAdvisorFirstName: string = "";
	preventionAdvisorLastName: string = "";
	preventionAdvisorEmail: string = "";
	preventionAdvisorPhoneNumber: string = "";

	companyDoctorFirstName: string = "";
	companyDoctorLastName: string = "";
	companyDoctorEmail: string = "";
	companyDoctorPhoneNumber: string = "";

	personOfTrustFirstName: string = "";
	personOfTrustLastName: string = "";
	personOfTrustEmail: string = "";
	personOfTrustPhoneNumber: string = "";

	weekScheduleStart: string = "";
	weekScheduleEnd: string = "";
	weekendScheduleStart: string = "";
	weekendScheduleEnd: string = "";

	distanceMin: any = 0;
	distanceMax: any = 30;
	noDeliveryAction: string = "";
	lastDayCheck: boolean;
	generalComments: string = "";

	emailResults: string = "";
	emailReports: string = "";
	emailInvoices: string = "";
	emailHR: string = "";

	reportFrequency: number = -1;
	typeOfreport: number = -1;

	doctorAlwaysUse: any[];
	doctorNeverUse: any[];

	hasMyCertificate?: boolean;
	doctorWhitelisting?: boolean;
	type?: number;
}
