import * as React from 'react';
import SaveButtonViewStyle from './SaveButtonViewStyle';
import SaveButtonViewProps from './SaveButtonViewProps';
import BaseComponent from '../../../../abstracts/BaseComponent';
import Lottie from 'lottie-react-web';
import TranslationView from '../../../../components/translationView/TranslationView';

export default class SaveButtonView extends BaseComponent<SaveButtonViewProps>
{
    /* ATTRIBUTES */
    style = new SaveButtonViewStyle();

    /* CONSTRUCTOR */
    constructor(props: SaveButtonViewProps) {
        super(props);

        // Initial state

        // Bindings 
    }

    /* LIFE CYCLE METHOD */

    /* HANDLERS */

    /* METHODS */

    /* RENDERING */
    render() {
        return (
            <button disabled={this.props.isBusy} onClick={this.props.handleSaved} type="button" className="btn btn-primary" style={this.style.button}>
                {this.props.isBusy ?
                    (
                        <Lottie
                            options={{
                                animationData: require('./assets/loading.json')
                            }}
                            style={{ ...this.style.lottie }}
                        />
                    )
                    :
                    (
                        <span>
                            <img src='/assets/icon_save.svg' style={this.style.buttonImage} />
                            <span className='roboto' style={this.style.buttonText}>
                                <TranslationView>
                                    saveInformation
                                </TranslationView>
                            </span>
                        </span>
                    )
                }
            </button>
        );
    }
}