import CertificateType from "./CertificateType";
import IncapacityType from "./IncapacityType";
import MyCertificateScreenDTO from "./MyCertificateScreenDTO";
import { EntryType } from "./EntryType";

export default interface MyCertificateConfigurationDTO {
  companyId: string;
  isRequestFromSuperiorsAllowed: boolean;
  isRequestFromHRAllowed: boolean;
  isCertificateForSuperiorsAllowed: boolean;
  isCertificateForHRAllowed: boolean;
  numberOfDaysBeforeCheck: number;
  numberOfCertificates: number;
  numberOfDays: number;
  matriculeFormat: string;
  screens: MyCertificateScreenDTO[];
  matriculeExampleUrl: string;
  isMatriculeVisible: boolean;
  incapacityTypes: IncapacityType[];
  certificateTypes: CertificateType[];
  entryTypes?: EntryType[];
  checkLaunchType?: CheckLaunchType;
  isNumberOfDayCriteriaActive: boolean;
  isFrequencyCriteriaActive: boolean;
  numberOfRandomCheck: number;
}

export enum CheckLaunchType {
  UNKNOWN = -1,
  ABSENCE_DECLARATION,
  MEDICAL_CERTIFICATE,
}
