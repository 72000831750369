import React, { Component } from "react";
import { LocalizationContext } from "./LocalizationContext";
import LocalizationContextState from './LocalizationContextState';

export default class LocalizationContextProvider extends Component<any, LocalizationContextState> {

    static translations: any;

    constructor(props) {
        super(props);

        this.state = {
            language: "en"
        }

        if (!LocalizationContextProvider.translations) {
            LocalizationContextProvider.translations = require('../../translations.json');
        }

        this._translate = this._translate.bind(this);
        this._computeValue = this._computeValue.bind(this);
    }

    private _translate = (key: string, upperCase?: boolean) => {
        try {
            let word: string = "";

            word = LocalizationContextProvider.translations[key][this.state.language];

            if (word == "") {
                return `[${key}]`;
            }

            if (upperCase) {
                return word.toUpperCase();
            }

            return word;
        }
        catch (ex) {
            return `[${key}]`;
        }
    }

    private _computeValue = () => {
        return {
            language: "en",
            translate: this._translate
        };
    }

    render() {
        return (
            <LocalizationContext.Provider value={this._computeValue()}>
                {this.props.children}
            </LocalizationContext.Provider>
        );
    }
}