const style = {
    container: {
        width: 'fit-content',
        marginRight: 30,
        minWidth: 295,
        boxShadow: '0 3px 10px 0 rgba(0,0,0,0.2)',
        backgroundColor: '#FFF',
        borderRadius: 8,
        height: 'fit-content'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10
    },
    title: {
        color: '#405364',
        fontSize: 18,
    },
    amount: {
        color: "#405364",
        fontSize: 18,
        fontWeight: 300,
        textAlign: 'right',
        marginLeft: 10
    },
    separator: {
        height: 1,
        backgroundColor: 'rgba(235,237,240,0.86)'
    },
    body: {
        height: 'fit-content'
    }
}

export default style;