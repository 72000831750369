import React from 'react';
import style from './style';
import TranslationView from 'src/components/translationView/TranslationView';

const RequestTableHeader =() => {
    return (
        <tr>
            <th scope="col">

            </th>
            <th scope="col">
                <TranslationView>
                    requestDateAndTime
                </TranslationView>
            </th>
            <th scope="col">
                <TranslationView>
                    requestWorker
                </TranslationView>
            </th>
            <th scope="col">
                <TranslationView>
                    requestCompanyName
                </TranslationView>
            </th>
            <th scope="col">
                <TranslationView>
                    requestTiming
                </TranslationView>
            </th>
            <th scope="col">
                <TranslationView>
                    requestIncapacityEndDate
                </TranslationView>
            </th>
            <th scope="col">
                <TranslationView>
                    requestLocation
                </TranslationView>
            </th>
            <th scope="col">
                <TranslationView>
                    assigned
                </TranslationView>
            </th>
            <th scope="col" style={style.actionHeader}>

            </th>
        </tr>
    );
}

export default RequestTableHeader;