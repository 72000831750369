export default class LogConstants {
  static CHECK_LAUNCHED = "0000000";
  static COMMENT_RECEIVED = "0000001";
  static CHECK_SCHEDULED = "0000002";
  static UPDATE_CHECKED = "0000010";
  static UPDATED_TIMING = "0000011";
  static UPDATED_LOCATION = "0000012";
  static UPDATED_PHONE = "0000013";
  static UPDATED_ADDRESS = "0000014";
  static UPDATED_WORKER_LANGUAGE = "0000015";
  static UPDATED_INCAPACITY_START_DATE = "0000016";
  static UPDATED_INCAPACITY_END_DATE = "0000017";

  static SEND_WORKER_MAIL = "0000021";
  static SEND_WORKER_SMS = "0000022";

  static SEND_COMPANY_MAIL = "0000023";
  static SEND_COMPANY_SMS = "0000024";

  static SEND_DOCTOR_MAIL = "0000025";
  static SEND_DOCTOR_SMS = "0000026";

  static SECOND_CHECK_RECEIVED = "0000041";

  static RESULT_RECEIVED = "0000061";

  static FORMS_AVAILIBITY_CONFIRMED = "0000071";
  static FORMS_AVAILIBITY_DENIED = "0000072";
  static FORMS_GSM_PROBLEM = "0000073";
  static FORMS_MODIFY_CHECK_BY_SUPERIOR = "0000074";
  static FORMS_CANCEL_CHECK = "0000075";
  static FORMS_SWITCH_TELECHECK = "0000077";

  static SYSTEM_GSM_PROBLEM = "0000081";

  static CHECK_ARCHIVED = "0000100";
}
