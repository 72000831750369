import style from "./style";
import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import CompanyDTO from "src/models/CompanyDTO";
import { InputView } from "src/components/inputView/InputView";
import TranslationView from "src/components/translationView/TranslationView";
import NetworkHelper from "src/helpers/NetworkHelper";
import CompanyService from "src/services/CompanyService";
import { App } from "src/app";
import { LocalizationContext } from "src/components/localizationContext/LocalizationContext";
import AlertHelper from "src/helpers/AlertHelper";
import ListView from "src/modules/dashboard/components/listView/ListView";
import ListArchivesSectionProps from "./props";
import { Spring } from "react-spring";
import DateHelper from "src/helpers/DateHelper";

const ListArchivesSection = (props: ListArchivesSectionProps) => {
  // Attributes
  const [currentCheck, setCurrentCheck] = React.useState();
  const [isHovered, setIsHovered] = React.useState(false);
  const [isBusyHovered, setIsBusyHovered] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const { elements } = props;
  const [sortedElements, setSortedElements] = useState([]);

  // Effects
  useEffect(() => {
    setSortedElements(
      elements.filter(
        (elt) =>
          elt.request.worker.firstName
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          elt.request.worker.lastName
            .toLowerCase()
            .includes(search.toLowerCase())
      )
    );
  }, [search]);

  useEffect(() => {
    setSortedElements(elements);
  }, [elements]);

  // Handlers
  function handleCurrentCheck(check: any) {
    setCurrentCheck(check.id);
  }

  function handleHovered() {
    setIsHovered(!isHovered);
  }

  function handleCopied() {
    AlertHelper.success("Copied succesfully");
  }

  // Methods
  function mapHeaders() {
    return (
      <tr>
        <th scope="col">
          <TranslationView>archiveDateTime</TranslationView>
        </th>
        <th scope="col">
          <TranslationView>archiveWorker</TranslationView>
        </th>
        <th scope="col">
          <TranslationView>archiveCompanyName</TranslationView>
        </th>
        <th scope="col">
          <TranslationView>archiveSuperior</TranslationView>
        </th>
        <th scope="col">
          <TranslationView>archiveTiming</TranslationView>
        </th>
        <th scope="col">
          <TranslationView>archiveLocation</TranslationView>
        </th>
        <th scope="col" style={{ width: 70 }}></th>
      </tr>
    );
  }

  function mapArchives(check: any, index: number) {
    const timeStamp = `${DateHelper.formatDate(check.modificationDate)}`;
    return (
      <LocalizationContext.Consumer>
        {({ translate }) => (
          <tr onMouseEnter={() => handleCurrentCheck(check)} key={index}>
            <td style={{ WebkitUserSelect: "text" }} onCopy={handleCopied}>
              {`${timeStamp.split("-")[0]}/${timeStamp.split("-")[1]}/${
                timeStamp.split("-")[2].split(" ")[0]
              } ${timeStamp.split(" ")[1]}`}
            </td>
            <td style={{ WebkitUserSelect: "text" }} onCopy={handleCopied}>
              {`${check.request.worker.firstName} ${check.request.worker.lastName}`}
            </td>
            <td style={{ WebkitUserSelect: "text" }} onCopy={handleCopied}>
              {check.request.company.name}
            </td>
            <td style={{ WebkitUserSelect: "text" }} onCopy={handleCopied}>
              {`${check.request.superior.firstName} ${check.request.superior.lastName}`}
            </td>
            <td style={{ WebkitUserSelect: "text" }} onCopy={handleCopied}>
              {check.request.timing === "OPTIMIZED"
                ? translate("archiveOptimizedTiming", "en")
                : translate("archiveImmediateTiming", "en")}
            </td>
            <td style={{ WebkitUserSelect: "text" }} onCopy={handleCopied}>
              {translate(getLocationTranslation(check.request.location), "en")}
            </td>
            <td style={{ WebkitUserSelect: "text" }} onCopy={handleCopied}>
              <div
                onMouseEnter={handleHovered}
                onMouseLeave={handleHovered}
                onClick={() =>
                  props.handleNavigateToDetail(() =>
                    App.history.push("/dashboard/archive/detail", {
                      check: check,
                    })
                  )
                }
                style={style.buttonContainer}
              >
                <img
                  src={`/assets/icon_open${
                    isHovered && currentCheck === check.id ? "_selected" : ""
                  }.svg`}
                  style={style.button}
                />
              </div>
            </td>
          </tr>
        )}
      </LocalizationContext.Consumer>
    );
  }

  function getLocationTranslation(location) {
    switch (location) {
      case 0:
        return "archivePatientLocation";
      case 1:
        return "archiveDoctorLocation";
      case 2:
        return "archiveTelecheckLocation";
      default:
        return "archivePatientLocation";
    }
  }

  // Rendering
  return (
    <div className="card shadow" style={style.container}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginRight: 30,
        }}
      >
        <div
          style={{
            width: 600,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div style={{ width: 300, marginLeft: 40 }}>
            <InputView
              placeholder="Search"
              type="text"
              value={search}
              onChange={(event: any) => setSearch(event.target.value)}
            />
          </div>
        </div>
        <Spring
          from={{ rotation: props.isBusy ? 0 : 360 }}
          to={{ rotation: props.isBusy ? 360 : 0 }}
        >
          {(style: any) => {
            return (
              <motion.div
                whileTap={{ scale: 0.9 }}
                onMouseEnter={() => setIsBusyHovered(true)}
                onMouseOut={() => setIsBusyHovered(false)}
                onClick={props.refreshChecksForCompany}
                style={{
                  height: 35,
                  width: 35,
                  cursor: "pointer",
                  marginTop: 7.5,
                }}
              >
                {props.isBusy || isBusyHovered ? (
                  <img
                    src="/assets/button_reload_busy.png"
                    style={{
                      height: 35,
                      width: 35,
                      transform: `rotate(${style.rotation}deg)`,
                    }}
                  />
                ) : (
                  <img
                    src="/assets/button_reload.png"
                    style={{
                      height: 35,
                      width: 35,
                      transform: `rotate(${style.rotation}deg)`,
                    }}
                  />
                )}
              </motion.div>
            );
          }}
        </Spring>
      </div>
      <div style={{ marginTop: 30 }}>
        <ListView
          page={props.page}
          handlePageChanged={props.handlePageChanged}
          hasBusyIndicator
          noHideLoading
          isBusy={props.isBusy}
          elements={sortedElements}
          mapElements={mapArchives}
          mapHeaders={mapHeaders}
        />
      </div>
    </div>
  );
};

export default ListArchivesSection;
