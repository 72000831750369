export default class ToggleViewStyle 
{
    toggled =
    {
        height:25, 
        width:25, 
        borderRadius:12.5, 
        backgroundColor:'#E3E3E3', 
        marginRight:'auto', 
        marginLeft:5
    }

    unToggled = 
    {
        height:25, 
        width:25, 
        borderRadius:12.5, 
        backgroundColor:'#009DC8', 
        marginLeft:'auto', 
        marginRight:5
    }
}