import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

function formatDate(date: string) {
  return format(
    utcToZonedTime(new Date(date + "Z"), "Europe/Paris"),
    "dd-MM-yyyy HH:mm:ss"
  );
}
function formatHour(date: string) {
  return format(
    utcToZonedTime(new Date(date + "Z"), "Europe/Paris"),
    "HH:mm:ss"
  );
}

const DateHelper = {
  formatDate,
  formatHour,
};

export default DateHelper;
