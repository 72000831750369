export default class SMSViewStyle {
    badgeText: React.CSSProperties =
        {
            fontSize: 14,
            fontWeight: 600,
            color: 'white'
        }

    logText: React.CSSProperties =
        {
            fontWeight: 300,
            fontSize: 12,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            color: 'white',
            marginTop: 1,
            padding: 16,
            marginLeft: 2
        }

    logTitleText: React.CSSProperties =
        {
            fontSize: 14,
            fontWeight: 400
        }

    logBodyText: React.CSSProperties =
        {
            backgroundColor: 'white',
            padding: 8,
            fontSize: 14,
            borderRadius: 6,
            color: '#68737E',
            marginBottom: 10,
            marginTop: 1,
            WebkitUserSelect: 'text'
        }

    logNoBorderBodyText: React.CSSProperties =
        {
            marginBottom: 10,
            marginTop: 1,
            fontSize: 14,
            fontWeight: 300,
            WebkitUserSelect: 'text'
        }

    userIcon: React.CSSProperties =
        {
            height: 16,
            width: 16,
            filter: "invert(99%) sepia(7%) saturate(0%) hue-rotate(351deg) brightness(109%) contrast(100%)"
        }

    basicLog: React.CSSProperties =
        {
            width: 'calc(100% - 60px)'
        }

    launchLog: React.CSSProperties =
        {
            ...this.basicLog,
            marginLeft: 20
        }

    badgeContainer: React.CSSProperties =
        {
            height: 40,
            minWidth: 40,
            borderRadius: 20,
            backgroundColor: '#90A7B8',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            marginLeft: 20,
            marginTop: 5
        }

    launchLogContainer: React.CSSProperties =
        {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 15
        }

    commentLog: React.CSSProperties =
        {
            ...this.basicLog,
            marginLeft: 20
        }

    commentLogContainer: React.CSSProperties =
        {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 15
        }

    basicStamp: React.CSSProperties =
        {
            color: '#C8C8C8',
            fontWeight: 300,
            fontSize: 13
        }

    basicStampRight: React.CSSProperties =
        {
            ...this.basicStamp,
            marginLeft: 'auto'
        }

    basicLogContainer: React.CSSProperties =
        {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 15
        }

    log: React.CSSProperties =
        {
            fontWeight: 500,
            fontSize: 13,
            marginLeft: 2,
            padding: 10,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 4,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            color: 'white'
        }

    commentLogText: React.CSSProperties =
        {
            ...this.log,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        }

    creditButtonImage: React.CSSProperties =
        {
            width: 20,
            height: 20,
            marginTop: -2,
            marginRight: 15,
            filter: 'invert(100%) sepia(0%) saturate(7485%) hue-rotate(273deg) brightness(104%) contrast(100%)'
        }

    button: React.CSSProperties =
        {
            marginTop: 20,
            marginLeft: 'auto',
            marginRight: 17.5,
            float: 'right'
        }

    buttonImage: React.CSSProperties =
        {
            width: 20,
            height: 20,
            marginTop: -2,
            marginRight: 15
        }

    buttonText: React.CSSProperties =
        {
            fontWeight: 400,
            marginTop: 3,
            fontSize: 17
        }

    creditButton: React.CSSProperties =
        {
            margin: 'auto',
            marginTop: 20,
        }
}