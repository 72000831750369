import Service from './Service';
import Axios from 'axios';

export default class CompanyArchiveService extends Service {
    get(companyId: string, search?: string) {
        if (search && search !== "/") {
            return this.execute(Axios.get(`${this.url}/companies/${companyId}/archives/${search}`, this.config()));
        }
        return this.execute(Axios.get(`${this.url}/companies/${companyId}/archives`, this.config()));
    }
}