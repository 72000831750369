import React from 'react';
import { App } from 'src/app';
import CompanyDTO from 'src/models/CompanyDTO';
import FormTitle from 'src/modules/dashboard/components/formTitle';
import Input from './components/input';
import InputLeft from './components/input-left';
import InputRight from './components/input-right';
import './style.css';

interface FormInterfaceProps {
	user: any;
	errors: any;
	handleInputChanged: any;
	companies: any;
	userCompanies: any;
	setUserCompanies: any;
}

const Form = ({
	user,
	errors,
	handleInputChanged,
	companies,
	userCompanies,
	setUserCompanies,
}: FormInterfaceProps) => {
	return (
		<div className='card shadow' style={{ marginTop: 30, width: 1047 }}>
			<FormTitle>settingsGeneralInformation</FormTitle>
			<div className='card-body'>
				<div className='row'>
					<InputLeft>
						<Input
							error={errors['firstName']}
							label={'settingsFirstName'}
							placeholder='Amélie'
							type='text'
							value={user?.firstName}
							onChange={(event) =>
								handleInputChanged('firstName', event.target.value)
							}
						/>
					</InputLeft>
					<InputRight>
						<Input
							error={errors['lastName']}
							label={'settingsLastName'}
							placeholder='Meunier'
							type='text'
							value={user.lastName}
							onChange={(event) =>
								handleInputChanged('lastName', event.target.value)
							}
						/>
					</InputRight>
					<InputLeft>
						<Input
							disabled={App.history.location.state != undefined}
							error={errors['email']}
							label={'settingsEmail'}
							placeholder='amelie@medicheck.io'
							type='text'
							value={user.email}
							onChange={(event) =>
								handleInputChanged('email', event.target.value)
							}
						/>
					</InputLeft>
					<InputRight>
						<Input
							error={errors['language']}
							label={'settingsLanguage'}
							placeholder='Select an option'
							type='select'
							value={user.language}
							onChange={(event) =>
								handleInputChanged('language', event.target.value)
							}
						>
							<option value='FR'>FR</option>
							<option value='NL'>NL</option>
							<option value='EN'>EN</option>
						</Input>
					</InputRight>
				</div>
				<div className='settings-detail-second-row'>
					<div className='settings-detail-second-row-input'>
						<Input
							error={errors['roles']}
							label={'settingsRoles'}
							placeholder='Select an option'
							type='multi-select'
							value={user.roles?.map((role) => {
								return role.name;
							})}
							onChange={(event) =>
								handleInputChanged(
									'roles',
									(event.target.value as any)?.map((role: any) => {
										return { name: role };
									})
								)
							}
						>
							<option value='admin'>admin</option>
							<option value='operator'>operator</option>
							<option value='sales'>sales</option>
							<option value='my-certificate'>my-certificate</option>
						</Input>
					</div>
					<div style={{ width: 70 }} />
					<div className='settings-detail-second-row-input'>
						<Input
							disabled={
								user.id === undefined ||
								!user.roles?.map((elt) => elt.name).includes('operator')
							}
							error={errors['roles']}
							label={'settingsCompanies'}
							placeholder='Select an option'
							type='multi-select'
							value={userCompanies?.map((elt) => {
								return elt.companyId;
							})}
							onChange={(event) =>
								setUserCompanies(
									event.target.value?.map((company) => {
										return { userId: user.id, companyId: company };
									})
								)
							}
						>
							{companies?.map((elt: CompanyDTO) => (
								<option value={elt.id}>{elt.name} </option>
							))}
						</Input>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Form;
