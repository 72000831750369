import * as React from 'react';
import MessageButtonLogView from './MessageLogView/MessageButtonLogView';
import MessageLogView from './MessageLogView/MessageLogView';

const MessageShellLogView = () => {
    return (
        <div>
            <MessageLogView />
            <MessageButtonLogView />
        </div>
    );
}

export default MessageShellLogView;