import React from 'react';
import './style.css';

const InputRight = ({ children }: any) => {
    return (
        <div className="settings-detail-input-right-container">
            {children}
        </div>
    )
}

export default InputRight;