export default class MenuImageViewStyle
{
    menuImage: React.CSSProperties =
    {
        marginLeft:20, 
        marginRight:20, 
        marginTop: 10, 
        marginBottom: 10, 
        cursor:'pointer'
    }   

    image: React.CSSProperties = 
    {
        height:42, 
        width:42
    }
}