import * as React from "react";
import CompanyDetailViewStyle from "../../CompanyDetailViewStyle";
import { InputView } from "../../../../../../../../components/inputView/InputView";
import TranslationView from "../../../../../../../../components/translationView/TranslationView";
import { LocalizationContext } from "src/components/localizationContext/LocalizationContext";
import { ReportingType } from "src/enums/reporting";
import { ReportingOptionDTO } from "src/models/ReportingOptionDTO";
import { useQuery } from "react-query";
import NetworkHelper from "src/helpers/NetworkHelper";
import reportingOptionService from "src/services/ReportingOptionService";
import { useEffect } from "react";

const style = new CompanyDetailViewStyle();

interface CompanyReportingProps {
	element: any;
	handleInputChanged: any;
}

const CompanyReportingView = ({ element, handleInputChanged }: CompanyReportingProps) => {
	//Queries
	const onGoingCheckOptionsQuery = useQuery("ongoing-check-options", getOngoingCheckOptions);
	const medicalCertificatesOptionsQuery = useQuery("medical-certificates-options", getMedicalCertificatesOptions);
	const checkReportOptionsQuery = useQuery("check-report-options", getCheckReportOptions);

	//States
	const [changed, setChanged] = React.useState(false);
	const [onGoingCheckOptions, setOnGoingCheckOptions] = React.useState<ReportingOptionDTO[]>(onGoingCheckOptionsQuery.data ?? []);
	const [checkReportOptions, setCheckReportOptions] = React.useState<ReportingOptionDTO[]>(checkReportOptionsQuery.data ?? []);
	const [medicalCertificatesOptions, setMedicalCertificatesOptions] = React.useState<ReportingOptionDTO[]>(medicalCertificatesOptionsQuery.data ?? []);

	//Functions
	async function getOngoingCheckOptions() {
		if (element.id) {
			const response = await NetworkHelper.execute(reportingOptionService.getAllByCompanyIdAndReportingType(element.id, ReportingType.OngoingCheck));
			setOnGoingCheckOptions(response.data);
			return response.data;
		}
	}

	async function getMedicalCertificatesOptions() {
		if (element.id) {
			const response = await NetworkHelper.execute(reportingOptionService.getAllByCompanyIdAndReportingType(element.id, ReportingType.MedicalCertificates));
			setMedicalCertificatesOptions(response.data);
			return response.data;
		}
	}

	async function getCheckReportOptions() {
		if (element.id) {
			const response = await NetworkHelper.execute(reportingOptionService.getAllByCompanyIdAndReportingType(element.id, ReportingType.CheckReport));
			setCheckReportOptions(response.data);
			return response.data;
		}
	}

	// Effects
	useEffect(() => {
		if (changed) {
			Promise.all(onGoingCheckOptions?.map((option) => NetworkHelper.execute(reportingOptionService.put(element.id, option.id, option)))).then(() => {
				setChanged(false);
			});
		}
	}, [onGoingCheckOptions]);

	useEffect(() => {
		if (changed) {
			Promise.all(medicalCertificatesOptions?.map((option) => NetworkHelper.execute(reportingOptionService.put(element.id, option.id, option)))).then(() => {
				setChanged(false);
			});
		}
	}, [medicalCertificatesOptions]);

	useEffect(() => {
		if (changed) {
			Promise.all(checkReportOptions?.map((option) => NetworkHelper.execute(reportingOptionService.put(element.id, option.id, option)))).then(() => {
				setChanged(false);
			});
		}
	}, [checkReportOptions]);

	useEffect(() => {
		if (!changed) {
			onGoingCheckOptionsQuery.refetch();
		}
	}, [changed]);

	function mapReportingFields(type: ReportingType) {
		switch (type) {
			case ReportingType.CheckReport:
				return checkReportOptions?.map((elt) => <option value={elt.option.id}>{elt?.option?.name}</option>);
			case ReportingType.OngoingCheck:
				return onGoingCheckOptions?.map((elt) => <option value={elt.option.id}>{elt?.option?.name}</option>);
			case ReportingType.MedicalCertificates:
				return medicalCertificatesOptions?.map((elt) => <option value={elt.option.id}>{elt?.option?.name}</option>);
			default:
				break;
		}
	}

	//@param event is the reportingOptionId
	function handleMultiSelectChange(values: string[], type: ReportingType) {
		switch (type) {
			case ReportingType.CheckReport:
				setCheckReportOptions((prev) =>
					prev?.map((elt) => {
						elt.selected = values?.includes(elt.option.id) ?? false;
						return elt;
					})
				);
				break;
			case ReportingType.OngoingCheck:
				setOnGoingCheckOptions((prev) =>
					prev?.map((elt) => {
						elt.selected = values?.includes(elt.option.id) ?? false;
						return elt;
					})
				);
				break;
			case ReportingType.MedicalCertificates:
				setMedicalCertificatesOptions((prev) =>
					prev?.map((elt) => {
						elt.selected = values?.includes(elt.option.id) ?? false;
						return elt;
					})
				);
				break;
			default:
				break;
		}
		setChanged(true);
	}

	return (
		<div className="card shadow" style={style.card}>
			{/* TITLE */}
			<div className="card-header border-1" style={style.cardHeader}>
				<TranslationView>companyDetailCommunicationPreferencesReporting</TranslationView>
			</div>
			<div className="card-body">
				<div className="row">
					{/* EMAILS FOR REPORTS */}
					<div style={style.inputDouble}>
						<div style={style.inputTitle}>
							<TranslationView>companyDetailEmailsForReports</TranslationView>
						</div>
						<InputView placeholder="report@medicheck.io" type="text" value={element.emailReports} onChange={(event) => handleInputChanged("emailReports", event.target.value)} />
					</div>

					{/* AUTOMATED REPORT TYPES */}
					<div style={style.inputLeft}>
						<div style={style.input}>
							<div style={style.inputTitle}>
								<TranslationView>companyDetailAutomatedReportType</TranslationView>
							</div>
							<InputView placeholder="Select an option" type="select" value={element.automatedReport} onChange={(event) => handleInputChanged("automatedReport", event.target.value)}>
								<option value={-1}></option>
								<option value={0}>No</option>
								<option value={1}>Yes</option>
							</InputView>
						</div>
					</div>
					<div style={style.inputRight}>
						<div style={style.input}>
							<div style={style.inputTitle}>
								<TranslationView>companyDetailReportingFreq</TranslationView>
							</div>
							<InputView placeholder="Select an option" type="select" value={element.reportFrequency} onChange={(event) => handleInputChanged("reportFrequency", event.target.value)}>
								<option value={-1}></option>
								<option value={0}>Daily</option>
								<option value={1}>Weekly</option>
								<option value={2}>Monthly</option>
								<option value={3}>Quarterly</option>
								<option value={4}>Semi-annually</option>
								<option value={5}>Annually</option>
							</InputView>
						</div>
					</div>
				</div>
				<div className="row">
					{/* ONGOING CHECK REPORTING FIELDS */}
					<div style={style.inputDouble}>
						<div style={{ ...style.input, width: "100%" }}>
							<div style={style.inputTitle}>
								<TranslationView>companyDetailOngoingCheckReportingFields</TranslationView>
							</div>
							<InputView noFixedHeight placeholder="Select an option" type="multi-select" value={onGoingCheckOptions.filter((elt) => elt.selected)?.map((elt) => elt?.option?.id)} onChange={(event) => handleMultiSelectChange(event?.target?.value, ReportingType.OngoingCheck)}>
								{mapReportingFields(ReportingType.OngoingCheck)}
							</InputView>
						</div>
					</div>

					{/* CHECK REPORT REPORTING FIELDS */}
					<div style={style.inputDouble}>
						<div style={{ ...style.input, width: "100%" }}>
							<div style={style.inputTitle}>
								<TranslationView>companyDetailCheckReportReportingFields</TranslationView>
							</div>
							<InputView noFixedHeight placeholder="Select an option" type="multi-select" value={checkReportOptions.filter((elt) => elt.selected)?.map((elt) => elt?.option?.id)} onChange={(event) => handleMultiSelectChange(event?.target?.value, ReportingType.CheckReport)}>
								{mapReportingFields(ReportingType.CheckReport)}
							</InputView>
						</div>
					</div>
				</div>
				<div className="row">
					{/* MEDICAL CERTIFICATES FIELDS */}
					<div style={style.inputDouble}>
						<div style={{ ...style.inputLeft, width: "100%" }}>
							<div style={style.inputTitle}>
								<TranslationView>companyDetailMedicalCertificatesFields</TranslationView>
							</div>
							<InputView noFixedHeight placeholder="Select an option" type="multi-select" value={medicalCertificatesOptions.filter((elt) => elt.selected)?.map((elt) => elt?.option?.id)} onChange={(event) => handleMultiSelectChange(event?.target?.value, ReportingType.MedicalCertificates)}>
								{mapReportingFields(ReportingType.MedicalCertificates)}
							</InputView>
						</div>
					</div>
					<div style={style.inputDouble} />
				</div>
			</div>
		</div>
	);
};

export default CompanyReportingView;
