import Service from './Service';
import Axios from 'axios';
import CheckDTO from '../models/CheckDTO';

export default class CheckStateService extends Service
{
    put(checkId: string, check: CheckDTO)
    {
        return this.execute(Axios.put(`${this.url}/checks/${checkId}/state`, check, this.config()));
    }
}