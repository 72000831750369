import { createContext, useContext } from "react";
import CheckDTO from "src/models/CheckDTO";

interface CheckContextValue {
	check: CheckDTO;
	logs: any;
	integrations: any;
	communications: any;
	handleInputChanged: any;
	handleUpdateRequest: any;
	handleUpdateCheck: any;
	handleRefreshCheck: any;
	handleRefreshLogs: any;
	handleRefreshIntegrations: any;
	handleRefreshCommunications: any;
}

export const CheckContext = createContext<CheckContextValue>({
	check: undefined,
	logs: undefined,
	integrations: undefined,
	communications: undefined,
	handleInputChanged: () => {},
	handleUpdateRequest: () => {},
	handleUpdateCheck: () => {},
	handleRefreshCheck: () => {},
	handleRefreshLogs: () => {},
	handleRefreshIntegrations: () => {},
	handleRefreshCommunications: () => {},
});

export const useCheck = () => useContext(CheckContext);
