import * as React from 'react';
import TranslationView from '../../../../../../../components/translationView/TranslationView';
import DoctorStatsViewProps from './DoctorStatsViewProps';
import { InputView } from '../../../../../../../components/inputView/InputView';
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';

export default class DoctorStatsView extends React.Component<DoctorStatsViewProps>
{
    render() {
        const { style, information, element, handleInputChanged, errors } = this.props;

        return (
            <LocalizationContext.Consumer>
                {({ translate }) => (
                    <div className="card shadow" style={style.card}>
                        {/* TITLE */}
                        <div className="card-header border-1" style={style.cardHeader}>
                            <TranslationView>
                                doctorDetailStatistics
                            </TranslationView>
                        </div>
                        <div className="card-body">
                            <div className="row">

                                {/* SHOULD BE USED */}
                                <div style={style.inputLeft}>
                                    <div style={style.inputTitle}>
                                        <TranslationView>
                                            doctorDetailShouldBeUsed
                                        </TranslationView>
                                    </div>
                                    <InputView error={errors["inhouseAppreciation"]} placeholder="Select an option" type="select" value={element.inhouseAppreciation} onChange={(event) => handleInputChanged("inhouseAppreciation", event.target.value)}>
                                        <option value={0}>{translate('doctorShouldBeUsedWhiteList', 'en')}</option>
                                        <option value={1}>{translate('doctorShouldBeUsedGrayList', 'en')}</option>
                                        <option value={2}>{translate('doctorShouldBeUsedBlackList', 'en')}</option>
                                    </InputView>
                                </div>

                                {/* PLACEHOLDER */}
                                <div style={style.inputRight} />

                                {/* STAT APP USAGE */}
                                <div style={style.inputLeft}>
                                    <div style={style.inputTitle}>
                                        <TranslationView>
                                            doctorDetailStatAppUsage
                                        </TranslationView>
                                    </div>
                                    <InputView disabled placeholder="0" type="text" value={information.appUsage} onChange={() => { }} />
                                </div>

                                {/* STAT EARLY RETURNS */}
                                <div style={style.inputRight}>
                                    <div style={style.inputTitle}>
                                        <TranslationView>
                                            doctorDetailStatEarlyReturns
                                        </TranslationView>
                                    </div>
                                    <InputView disabled placeholder="0" type="text" value={information.statER} onChange={() => { }} />
                                </div>

                            </div>
                            <div className="row">

                                {/* FIRST DATE USED */}
                                <div style={style.inputLeft}>
                                    <div style={style.inputTitle}>
                                        <TranslationView>
                                            doctorDetailFirstDateUsed
                                        </TranslationView>
                                    </div>
                                    <InputView disabled placeholder="01/01/1" type="text" value={new Date(information.firstDateUse).toLocaleDateString()} onChange={() => { }} />
                                </div>

                                {/* # CHECKS OPERATED */}
                                <div style={style.inputRight}>
                                    <div style={style.inputTitle}>
                                        <TranslationView>
                                            doctorDetailNbChecksDone
                                        </TranslationView>
                                    </div>
                                    <InputView disabled placeholder="0" type="text" value={information.numberOfChecks} onChange={() => { }} />
                                </div>

                                {/* STAT EFFICIENCY */}
                                <div style={style.inputLeft}>
                                    <div style={style.inputTitle}>
                                        <TranslationView>
                                            doctorDetailStatEfficiency
                                        </TranslationView>
                                    </div>
                                    <InputView disabled placeholder="0" type="text" value={information.efficiency} onChange={() => { }} />
                                </div>

                                {/* STAT REACTIVITY */}
                                <div style={style.inputRight}>
                                    <div style={style.inputTitle}>
                                        <TranslationView>
                                            doctorDetailStatReactivity
                                        </TranslationView>
                                    </div>
                                    <InputView disabled placeholder="0" type="text" value={information.reactivity} onChange={() => { }} />
                                </div>

                            </div>
                        </div>
                    </div>
                )}
            </LocalizationContext.Consumer>
        );
    }
}