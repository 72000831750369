import React from 'react';
import TranslationView from 'src/components/translationView/TranslationView';
import SettingViewStyle from '../../SettingViewStyle';

const UserTableHeader = () => {

    // Attributes
    const style = new SettingViewStyle()

    return (
        <tr>
            <th scope="col">
                <TranslationView>
                    settingsName
                    </TranslationView>
            </th>
            <th scope="col">
                <TranslationView>
                    settingsEmail
                    </TranslationView>
            </th>
            <th scope="col">
                <TranslationView>
                    settingsRoles
                    </TranslationView>
            </th>
            <th scope="col" style={style.action}>

            </th>
        </tr>
    )
}

export default UserTableHeader;