export default class SideBarViewStyle
{
    container: React.CSSProperties =
    {
        display:'flex', 
        backgroundColor:'white', 
        border:'1px solid #EBEDF0', 
        width:85, 
        height:'100%', 
        flexDirection:'column'
    }

    logo: React.CSSProperties =
    {
        height:53, 
        width:53, 
        marginTop: 21, 
        marginBottom:21, 
        marginLeft:15, 
        marginRight:15
    }

    separator: React.CSSProperties =
    {
        height:1, 
        width:'100%', 
        backgroundColor:'#EBEDF0'
    }

    profile: React.CSSProperties =
    {
        margin:'auto', 
        alignSelf:'end', 
        marginBottom: 20, 
        marginLeft:20, 
        height:42, 
        width:42, 
        backgroundColor:'#90A7B8', 
        borderRadius:21, 
        color:'white', 
        fontWeight:700, 
        fontSize:20, 
        textAlign:'center', 
        paddingTop:6, 
        cursor:'pointer'
    }

    button: React.CSSProperties =
    {
        backgroundColor:'#009DC8', 
        border: 'none',
        margin:'auto',
        boxShadow:'none',
        marginTop: 25,
        width:200
    }

    buttonImage: React.CSSProperties = 
    {
        width:20, 
        height:20, 
        marginRight:15, 
        filter:'invert(100%) sepia(0%) saturate(7496%) hue-rotate(272deg) brightness(100%) contrast(103%)'
    }

    buttonText: React.CSSProperties =
    {
        fontWeight:600, 
        marginTop:-20, 
        fontSize:16
    }

    modal: React.CSSProperties =
    {
        height:535, 
        width:400, 
        textAlign:'center'
    }

    closeButtonContainer: React.CSSProperties =
    {
        cursor:'pointer', 
        height:25, 
        width:15, 
        padding: 0, 
        marginLeft:'auto'   
    }

    closeButton: React.CSSProperties =
    {
        height:25, 
        width:25, 
        marginLeft:-5
    }

    modalIllustration: React.CSSProperties =
    {
        margin:'auto', 
        height:250, 
        width:250
    }

    nameContainer: React.CSSProperties =
    {
        color:'#009DC8', 
        fontWeight:500, 
        fontSize:16, 
        margin:'auto', 
        width:'100%', 
        textAlign:'center', 
        marginTop:25
    }

    name: React.CSSProperties =
    {
        margin:'auto'
    }

    validation: React.CSSProperties =
    {
        display:'inline', 
        width:400, 
        fontSize:18, 
        fontWeight:300, 
        color:'#405364', 
        margin:'auto', 
        textAlign:'center', 
        marginTop:10
    }

    logOutButton: React.CSSProperties =
    {
        ...this.button, 
        margin:'auto',
        marginTop:30, 
        boxShadow:'none'
    }

    settingsButton: React.CSSProperties =
    {
        ...this.button, 
        margin:'auto', 
        marginTop:10, 
        boxShadow:'none'
    }
}