import * as React from 'react';
import { ToolBoxView } from '../components';
import SMSView from '../../../components/SMSView/SMSView';
import EmailView from '../../../components/EmailView/EmailView';
import CheckCommunicationService from 'src/services/CheckCommunicationService';
import { useCheck } from 'src/modules/dashboard/components/checkContext/CheckContext';
import CheckCommunicationTemplateService from '../../../../../../../services/CheckCommunicationTemplateService';
import IntegrationLogView from '../../../components/IntegrationLogView';
import CheckIntegrationService from 'src/services/CheckIntegrationService';
import { CommunicationConstants } from 'src/constants/CommunicationConstants';

const CheckCommunicationsView = () => {

    // ATTRIBUTES
    const { check, communications, handleRefreshLogs, integrations } = useCheck();
    const [selectedIntegrations, setSelectedIntegrations] = React.useState([]);
    const [selectedCommunications, setSelectedCommunications] = React.useState([]);

    // METHODS
    const handleCommunicationClicked = async (elt) => {
        const resp = await new CheckCommunicationService().post(check.id, elt);
        setSelectedCommunications((selectedCommunications) => selectedCommunications.filter(e => e.id !== elt.id));

        handleRefreshLogs();
    }

    const handleIntegrationSelected = async (elt) => {
        const newArray = [elt];
        setSelectedIntegrations(newArray.concat(selectedIntegrations));
    }

    const handleCommunicationSelected = async (elt) => {
        const communication = await new CheckCommunicationTemplateService().get(check.id, elt.id);
        const newArray = [communication];
        setSelectedCommunications(newArray.concat(selectedCommunications));
    }

    const handleIntegrationClicked = async (elt) => {
        await new CheckIntegrationService().post(check.request.superior.companyId, { ...elt, checkId: check.id });
        setSelectedIntegrations((selectedIntegrations) => selectedIntegrations.filter(e => e.id !== elt.id));
        handleRefreshLogs();
    }

    const handleDeleteCommunication = (communication) => {
        const newArray = selectedCommunications.filter((elt) => elt.id !== communication.id);
        setSelectedCommunications(newArray);
    }

    const handleIntegrationDeleted = (integration) => {
        const newArray = selectedIntegrations.filter((elt) => elt.id !== integration.id);
        setSelectedIntegrations(newArray);
    }

    // RENDERING
    if (!communications || !integrations) {
        return (
            <div />
        );
    }

    return (
        <div>
            {
                selectedCommunications?.map((elt, index) => {
                    switch (`${elt.methodType}`) {
                        case `0`:
                            return <EmailView handleDeleteCommunication={handleDeleteCommunication} communication={elt} onClick={handleCommunicationClicked} />

                        default:
                            return <SMSView handleDeleteCommunication={handleDeleteCommunication} communication={elt} onClick={handleCommunicationClicked} />
                    }
                })
            }

            {
                selectedIntegrations?.map((elt) => {
                    return (
                        <IntegrationLogView integration={elt} onClick={handleIntegrationClicked} onDelete={handleIntegrationDeleted} />
                    )
                })
            }

            {check.state !== 0 && communications.length > 0 && <ToolBoxView integrations={integrations} communications={communications} handleIntegrationClicked={handleIntegrationSelected} handleCommunicationClicked={handleCommunicationSelected} />}
        </div>
    )
}

export default CheckCommunicationsView;