import * as React from 'react';
import DeliveryFileButtonProps from './DeliveryFileButtonProps';
import DeliveryFileButtonState from './DeliveryFileButtonState';
import BaseComponent from '../../../../../../../../abstracts/BaseComponent';
import ProofOfReceiptService from '../../../../../../../../services/ProofOfReceiptService';
import Service from 'src/services/Service';

export default class DeliveryFileButton extends BaseComponent<DeliveryFileButtonProps, DeliveryFileButtonState>
{
    /* ATTRIBUTE S*/
    service: any = new ProofOfReceiptService();

    /* CONSTRUCTOR */
    constructor(props: DeliveryFileButtonProps) {
        super(props);

        this.state = { isHovered: false, isBusy: false };

        this.handleClicked = this.handleClicked.bind(this);

    }

    /* LIFE CYCLE */

    /* HANDLERS */
    async handleClicked() {
        try {
            eval("require('electron').shell.openExternal('" + `https://previewer.medicheck.io?secret=${btoa(`${(new Service()).getUrl("v1")}/pors/${this.props.log.id}`)}` + "')");
        }
        catch
        {
            window.open(`https://previewer.medicheck.io?secret=${btoa(`${(new Service()).getUrl("v1")}/pors/${this.props.log.id}`)}`)
        }
    }

    /* RENDERING */
    render() {
        return (
            <>
                <div onMouseLeave={() => this.setState({ isHovered: false })} style={{ display: 'flex', flexDirection: 'row', width: 'fit-content' }}>
                    {this.state.isHovered
                        ?
                        (
                            <div onClick={this.handleClicked} style={{ height: 30, width: 30, borderRadius: 15, marginRight: 10, cursor: 'pointer' }}>
                                <img style={{ height: 30, width: 30, borderRadius: 15 }} src={'/assets/por_open.svg'} />
                            </div>
                        )
                        :
                        (
                            <div onMouseOver={() => this.setState({ isHovered: true })} style={{ height: 30, width: 30, borderRadius: 15, marginRight: 10, cursor: 'pointer' }}>
                                <img style={{ height: 30, width: 30, borderRadius: 15 }} src={'/assets/pdf_por_selected.svg'} />
                            </div>
                        )
                    }
                </div>
            </>
        );
    }
}