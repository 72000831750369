import Lottie from "lottie-react-web";
import React, { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { App } from "src/app";
import { InputView } from "src/components/inputView/InputView";
import { Location } from "src/enums/location";
import AlertHelper from "src/helpers/AlertHelper";
import DoctorDTO from "src/models/DoctorDTO";
import { useCheck } from "src/modules/dashboard/components/checkContext/CheckContext";
import CheckDoctorService from "src/services/CheckDoctorService";
import CheckPendingService from "src/services/CheckPendingService";
import CheckScheduleService from "src/services/CheckScheduleService";
import StarView from "../../../../components/StarView/StarView";
import ScheduleViewStyle from "../../ScheduleViewStyle";

const ConfirmationCard = ({ isDelete, isModification, selectedDoctor, checkDate, checkTime, setCheckDate, setCheckTime, setRedirectType }) => {
	// Attributes
	const style = new ScheduleViewStyle();
	const { check, handleInputChanged } = useCheck();
	const adressCheck = Location[check.request.location] === Location[Location.House] ? check.request.worker.address : (selectedDoctor as DoctorDTO).officeAddress;
	const [isLoadingScheduleCommunicationButton, setIsLoadingScheduleCommunicationButton] = React.useState(false);
	const [isLoadingScheduleButton, setIsLoadingScheduleButton] = React.useState(false);

	// MEMOS
	const dateConstraint = useMemo(() => {
		return mapCheckDate(checkDate);
	}, [checkDate, check]);

	const timeConstraint = useMemo(() => {
		return mapTimeConstraint(checkTime);
	}, [checkTime, check]);

	const lastDayCheck = useMemo(() => {
		return mapLastDayCheck(check.request.company.lastDayCheck, new Date(check.request.endDate), checkDate);
	}, [checkDate, check]);

	// Functions
	function mapCheckDate(checkDate: Date | string) {
		const { startDate, endDate } = check.request;

		const today = new Date();
		today.setHours(0);
		today.setMinutes(0);
		today.setSeconds(0);
		today.setMilliseconds(0);

		if (new Date(checkDate) < today) {
			return true;
		}

		if (new Date(checkDate) < new Date(startDate)) {
			return true;
		}

		if (new Date(checkDate) > new Date(endDate)) {
			return true;
		}

		return false;
	}

	function mapLastDayCheck(lastDayCheck: boolean, endDate: Date, checkDate: Date | string) {
		if (endDate && checkDate) {
			endDate.setHours(0);
			endDate.setMinutes(0);
			endDate.setSeconds(0);
			endDate.setMilliseconds(0);
			return lastDayCheck && checkDate.valueOf() === endDate.valueOf();
		}
		return false;
	}

	// Used to know if the boundries are respected
	function mapTimeConstraint(time: string) {
		const { checkDate } = check;
		const { company } = check.request;

		if (checkDate) {
			const date = new Date(checkDate);

			// WeekEnd
			if (date.getDay() === 0 || date.getDay() === 6) {
				// Start
				if (company.weekendScheduleStart) {
					if (compareTime(time, company.weekendScheduleStart) < 0) {
						return true;
					}
				} else {
					if (compareTime(time, "07:00:00") < 0) {
						return true;
					}
				}

				// End
				if (company.weekendScheduleEnd) {
					if (compareTime(time, company.weekendScheduleEnd) > 0) {
						return true;
					}
				} else {
					if (compareTime(time, "21:00:00") > 0) {
						return true;
					}
				}
			}

			// WeekDay
			else {
				// Start
				if (company.weekScheduleStart) {
					if (compareTime(time, company.weekScheduleStart) < 0) {
						return true;
					}
				} else {
					if (compareTime(time, "07:00:00") < 0) {
						return true;
					}
				}

				if (company.weekScheduleEnd) {
					if (compareTime(time, company.weekScheduleEnd) > 0) {
						return true;
					}
				} else {
					if (compareTime(time, "21:00:00") > 0) {
						return true;
					}
				}
			}
		}

		return false;
	}

	/** Used to compare two time strings */
	function compareTime(time1: string, time2: string) {
		if (calculateSeconds(time1) > calculateSeconds(time2)) {
			return 1;
		}
		if (calculateSeconds(time1) === calculateSeconds(time2)) {
			return 0;
		}
		return -1;
	}

	/** Used to transform xx:xx:xx into a number of seconds */
	function calculateSeconds(time: any) {
		let sum = 0;
		if (time) {
			if (time.hasValue) {
				sum += time.value.totalSeconds;
			} else {
				const table = time.split(":");
				sum += parseInt(table[0]) * 60 * 60;
				sum += parseInt(table[1]) * 60;
				sum += parseInt(table[2]);
			}
		}
		return sum;
	}

	async function handleSchedule() {
		// If a doctor is selected we will send to the server the new doctor and the check information
		if (selectedDoctor) {
			setIsLoadingScheduleButton(true);
			let date = checkDate;
			if (checkDate instanceof Date) {
				date = checkDate.toISOString();
			}
			check.checkDate = `${date.split("T")[0]}T${checkTime}`;
			check.doctorId = selectedDoctor.id;
			check.doctor = selectedDoctor;
			const resp = await new CheckDoctorService().post(check.id, selectedDoctor);
			const resp2 = await new CheckScheduleService().post(check.id, check);
			if (!resp || !resp2) {
				setIsLoadingScheduleButton(false);
				return AlertHelper.danger("Appointment has failed.");
			}
			setIsLoadingScheduleButton(false);
			setRedirectType(0);
		}
	}

	async function handleScheduleCommunications() {
		// If a doctor is selected we will send to the server the new doctor and the check information
		if (selectedDoctor) {
			setIsLoadingScheduleCommunicationButton(true);
			let date = checkDate;
			if (checkDate instanceof Date) {
				date = checkDate.toISOString();
			}
			check.checkDate = `${date.split("T")[0]}T${checkTime}`;
			check.doctorId = selectedDoctor.id;
			check.doctor = selectedDoctor;
			const resp = await new CheckDoctorService().post(check.id, selectedDoctor);
			const resp2 = await new CheckScheduleService().postWithCommunications(check.id, check);
			if (!resp || !resp2) {
				setIsLoadingScheduleCommunicationButton(false);
				return AlertHelper.danger("Appointment has failed.");
			}
			setIsLoadingScheduleCommunicationButton(false);
			setRedirectType(2);
		}
	}

	async function handlePutOnHold() {
		if (selectedDoctor) {
			check.doctorId = selectedDoctor.id;
			check.doctor = selectedDoctor;
			await new CheckPendingService().post(check.id, check);
			setRedirectType(1);
		}
	}

	if (isDelete) {
		return <React.Fragment />;
	}

	return (
		<>
			{/* SCHEDULE | PUT ON HOLD */}
			<div className="card shadow" style={style.extendedCard}>
				<div
					style={{
						...style.inputContainer,
						justifyContent: "space-between",
					}}
				>
					<div style={style.leftSide}>
					<div style={{marginBottom : 25, }}>You’re about to schedule a Check with <b> Dr. {(selectedDoctor as DoctorDTO).fullName}</b> at <b>{Location[check.request.location].toLowerCase()}</b>, at the following address : <b>{adressCheck}</b> </div>
						<div style={style.firstInput}>
							<div style={style.inputTextSize}>Enter the date :</div>
							<div style={style.inputSize}>
								<InputView
									isSundayRuleApplied
									warning={lastDayCheck || dateConstraint}
									placeholder={`${new Date()}`}
									type="date"
									value={checkDate}
									onChange={(event) => {
										setCheckDate(event.target.value);
										handleInputChanged("checkDate", new Date(event.target.value).toISOString().split("T")[0]);
									}}
								/>
							</div>
						</div>

						<div style={style.otherInput}>
							<div style={style.inputTextSize}>Enter the time :</div>
							<div style={style.inputSize}>
								<InputView
									warning={timeConstraint}
									placeholder="8:00:00"
									mask="h9:m9:s9"
									type="text"
									value={checkTime}
									onChange={(event) => {
										setCheckTime(event.target.value);
									}}
								/>
							</div>
						</div>

						<div style={style.otherInput}>
							<div style={style.inputTextSize}>Doctor reactivity :</div>
							<div style={style.inputSize}>
								<StarView
									disabled={false}
									stars={check.doctorReactivity}
									onChange={(event: any) => {
										handleInputChanged("doctorReactivity", event.target.value);
									}}
								/>
							</div>
						</div>

						<div style={{
									...style.saveButtons,
								}}>
							<button
								onDoubleClick={handleScheduleCommunications}
								type="button"
								className="btn btn-base btn-base-primary"
								style={{
									...style.button,
									cursor: isLoadingScheduleCommunicationButton ? 'progress' : 'pointer',
								}}
								disabled={isLoadingScheduleCommunicationButton}
							>
								{!isLoadingScheduleCommunicationButton ? (
									<div style={style.buttonContent}>
										<img className="img-base-primary" src="/assets/icon_send.svg" style={{marginLeft : 15, marginRight : 20, ...style.buttonImage}} />
										<div style={{marginRight : 15, ...style.buttonText}}>Schedule and send details</div>
									</div> 
								) : (
									<Lottie
										options={{
											animationData: require('./assets/loading.json'),
										}}
										style={{ ...style.lottie }}
									/>
								)}
							</button>
							<img
								style={{marginTop : 20, marginRight: 10}}
								src="/assets/illu_schedule_or_small.svg"
							/>
							<button
								onClick={handleSchedule}
								type="button"
								className="btn btn-base btn-base-primary"
								style={{
									...style.button,
									cursor: isLoadingScheduleButton ? 'progress' : 'pointer',
								}}
								disabled={isLoadingScheduleButton}
							>
								{!isLoadingScheduleButton ? (
									<div style={style.buttonContent}>
										<img className="img-base-primary" src="/assets/icon_calendar.svg" style={style.buttonImage} />
										<div style={style.buttonText}>Schedule</div>
									</div>
								) : (
									<Lottie
										options={{
											animationData: require('./assets/loading.json'),
										}}
										style={{ ...style.lottie }}
									/>
								)}
							</button>
						</div>
					</div>
					<img
						src="/assets/illu_schedule_or.svg"
						style={{
							width: 40,
							marginLeft: 70,
						}}
					/>
					<div style={style.rightSide}>
						<img
							src="/assets/illu_put_on_hold.png"
							style={{
								marginTop: 20,
								width: 155,
								height: 180,
							}}
						/>
						<button
							onClick={handlePutOnHold}
							type="button"
							className="btn btn-base btn-base-primary"
							style={{
								...style.button,
								marginTop: 0,
							}}
						>
							<div style={style.buttonContent}>
								<img className="img-base-primary" src="/assets/icon_put_on_hold.svg" style={style.buttonImage} />
								<div style={style.buttonText}>{check.state === 2 ? "Remove" : "Put"} on hold</div>
							</div>
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default ConfirmationCard;
