import * as React from 'react';
import SwitchViewProps from './SwitchViewProps';
import SwitchViewStyle from './SwitchViewStyle';
import { useMounted } from 'src/functions/useMounted';

const SwitchView: React.FC<SwitchViewProps> = (props) => {
    /* ATTRIBUTES */
    const style = new SwitchViewStyle();
    const isMounted = useMounted();

    /* EFFECTS */
    React.useEffect(() => {
        if (isMounted) {
            if (props.updateSync) {
                props.updateSync();
            }
        }
    }, [props.value]);

    /* HANDLERS */
    function handleClicked() {
        if (!props.disabled) {
            props.onChange({ target: { value: !props.value } });
        }
    }

    /* RENDER */
    return (
        <div onClick={handleClicked} style={{ ...style.switchContainer, border: `2px solid ${props.value ? '#009DC8' : '#EBEDF0'}`, cursor: props.disabled ? '' : 'pointer' }}>
            {props.value ?
                (
                    <>
                        <span style={style.selectedSpan} />
                        <span style={style.selectedBall} />
                    </>
                )
                :
                (
                    <>
                        <span style={style.unselectedBall} />
                        <span style={style.unselectedSpan} />
                    </>
                )
            }

        </div>
    );
}

export default SwitchView;