import React from "react";
import { App } from "src/app";
import { useState } from "react";
import { useEffect } from "react";
import DeleteCard from "./components/delete-card";
import DoctorTable from "./components/doctor-table";
import ScheduleViewStyle from "./ScheduleViewStyle";
import AppointmentCard from "./components/appointment";
import ConfirmationCard from "./components/confirmation-card";
import HeaderView from "../../../../components/headerView/HeaderView";
import SelectCommunicationCard from "./components/selected-communication-card";
import { useCheck } from "src/modules/dashboard/components/checkContext/CheckContext";
import AlertHelper from "src/helpers/AlertHelper";
import CheckDoctorService from "src/services/CheckDoctorService";
import { useRef } from "react";
import { CheckStatus } from "src/constants/CheckStatus";

let lastRedirectType = 0;

const ScheduleView = () => {
	// Attributes
	const [redirectType, setRedirectType] = useState<number>(-1);
	const redirectTypeRef = useRef<number>(-1);
	const style = new ScheduleViewStyle();
	const { check } = useCheck();
	const isModification = App.history.location.state.isModification;
	const isDelete = App.history.location.state.isDelete;
	const type = App.history.location.pathname.replace("/dashboard/", "").replace("/schedule", "");
	const formattedType = `${type.charAt(0).toUpperCase()}${type.slice(1)}`;
	const [checkDate, setCheckDate] = useState(check?.checkDate ? check?.checkDate : check?.suggestedCheckDate);
	const [checkTime, setCheckTime] = useState(check?.checkTime && check?.checkTime);
	const [selectedDoctor, setSelectedDoctor] = useState(check?.doctor);

	// Effects
	useEffect(() => {
		if (check) {
			if (check && !check?.checkDate.includes("0000") && !check?.checkDate.includes("0001")) {
				setCheckDate(check.checkDate);
			} else {
				setCheckDate(check.suggestedCheckDate);
			}
		}
	}, [check]);

	useEffect(() => {
		switch (redirectType) {
			case 0:
				redirectTypeRef.current = redirectType;
				App.navigate("/dashboard/check/detail", { check: check });
				AlertHelper.success("Appointment has been scheduled.");
				break;
			case 1:
				redirectTypeRef.current = redirectType;
				App.history.goBack();
				App.history.goBack();
				AlertHelper.success("Appointment has been put on hold.");
				break;
			case 2:
				redirectTypeRef.current = redirectType;
				App.navigate("/dashboard/request", { check: check });
				AlertHelper.success("Appointment has been scheduled.");
				break;
		}
	}, [redirectType]);

	if (!check) {
		return <div>Loading</div>;
	}

	const { request } = check;
	const { worker } = request;
	const title = `${worker.firstName} ${worker.lastName} 's Check Schedule`;
	const history = [
		{ name: `${formattedType}s`, path: `/dashboard/${type}` },
		{
			name: `${worker.firstName} ${worker.lastName}`,
			path: `/dashboard/${type}/detail`,
			state: App.history.location.state,
		},
		{
			name: `Schedule`,
			path: `/dashboard/${type}/detail/schedule`,
			state: App.history.location.state,
		},
	];

	return (
		<div className="col" style={{ marginTop: 0 }}>
			{/* PAGE HEADER */}
			<div style={style.headerContainer}>
				<HeaderView title={title} history={history} />
			</div>

			<div className="row" style={style.scheduleContainer}>
				<AppointmentCard isDelete={isDelete} isModification={isModification} />
				<DoctorTable selectedDoctor={selectedDoctor} setSelectedDoctor={setSelectedDoctor} />
				{selectedDoctor && (
					<>
						<SelectCommunicationCard selectedDoctor={selectedDoctor} />
						<DeleteCard isDelete={isDelete} />
						<ConfirmationCard isModification={isModification} setRedirectType={setRedirectType} checkDate={checkDate} checkTime={checkTime} setCheckDate={setCheckDate} setCheckTime={setCheckTime} isDelete={isDelete} selectedDoctor={selectedDoctor} />
					</>
				)}
			</div>
		</div>
	);
};

export default ScheduleView;
