import Axios from 'axios';
import MyCertificateCompanyDTO from 'src/models/MyCertificateCompanyDTO';
import Service from './Service';

function get(id: string) {
  const service = new Service();

  return Axios.get(
    `${service.getUrl('v2')}/companies/${id}/configurations`,
    service.config()
  );
}

function put(id: string, companyDTO: MyCertificateCompanyDTO) {
  const service = new Service();
  return Axios.put(
    `${service.getUrl('v2')}/companies/${id}/configurations`,
    companyDTO,
    service.config()
  );
}

export default { get, put };
