import * as React from "react";
import CheckDetailViewState from "./CheckDetailViewState";
import CheckDetailViewProps from "./CheckDetailViewProps";
import AccordeonView from "../../../../components/accordeonView/AccordeonView";
import CheckDetailViewStyle from "./CheckDetailViewStyle";
import { InputView } from "../../../../../../components/inputView/InputView";
import AddressInputView from "../../../../../../components/addressInputView/AddressInputView";
import { App } from "../../../../../../app";
import HeaderView from "../../../../components/headerView/HeaderView";
import RequestLogService from "../../../../../../services/RequestLogService";
import ArchiveService from "../../../../../../services/ArchiveService";
import StarView from "../../components/StarView/StarView";
import CheckService from "../../../../../../services/CheckService";
import SwitchView from "../../../../components/switchView/SwitchView";
import FileButtonView from "../../components/FileButtonView/FileButtonView";
import CheckCertificateFileService from "../../../../../../services/CheckCertificateFileService";
import CheckConvocationFileService from "../../../../../../services/CheckConvocationFileService";
import CheckResultFileService from "../../../../../../services/CheckResultFileService";
import CheckPriceService from "../../../../../../services/CheckPriceService";
import PopUpView from "../../../../components/popUpView/PopUpView";
import { CheckContext } from "src/modules/dashboard/components/checkContext/CheckContext";
import AlertHelper from "../../../../../../helpers/AlertHelper";
import CheckLogsView from "./v2/CheckLogsView";
import WorkAccidentInformationView from "./v2/WorkAccidentInformation/WorkAccidentInformationView";
import CheckCommunicationsView from "./v2/CheckCommunicationsView";
import CheckRequestInformationView from "./v2/CheckRequestInformation/CheckRequestInformationView";
import CheckResultView from "./v2/CheckResult/CheckResultView";
import NetworkHelper from "src/helpers/NetworkHelper";
import ValidationHelper from "src/helpers/ValidationHelper";
import { ToolBar } from "./components";
import { CheckStatus } from "src/constants/CheckStatus";
import { isCheckStatusPending, isRequest } from "src/helpers/CheckStatusHelper";
import { formatAmount } from "src/utils/utils";
import Lottie from "lottie-react-web";

export default class CheckDetailView extends React.Component<CheckDetailViewProps, CheckDetailViewState> {
	/* ATTRIBUTES */
	element;
	handleUpdateCheck;
	handleRefreshCheck;
	handleUpdateRequest;
	handleInputChanged;
	handleRefreshCommunications;

	priceServive = new CheckPriceService();
	logService = new RequestLogService();
	archiveService = new ArchiveService();
	checkService = new CheckService();
	style = new CheckDetailViewStyle();
	requiredFields = ["request.worker.firstName", "request.worker.lastName", "request.worker.address", "request.worker.gender", "request.worker.languages", "request.startDate", "request.timing", "request.location"];

	/* CONSTRUCTOR */
	constructor(props: CheckDetailViewProps) {
		super(props);

		// Initial State
		this.state = { isBusy: false, priceModal: false, archiveModal: false, errors: {}, fields: [], secondMagicLinkHovered: false, magicLinkBusy: false, secondMagicLinkBusy: false, magicLinkHovered: false, archiveHovered: false, setPriceHovered: false, archiveResultHovered: false, isLoadingArchiveCommunicationButton: false };

		// Bindings
		this.calculateDoctorTotal = this.calculateDoctorTotal.bind(this);
		this.calculateCompanyTotal = this.calculateCompanyTotal.bind(this);

		this.handleFixPrices = this.handleFixPrices.bind(this);
		this.handleCheckSync = this.handleCheckSync.bind(this);
		this.togglePriceModal = this.togglePriceModal.bind(this);
		this.handleRequestSync = this.handleRequestSync.bind(this);
		this.toggleArchiveModal = this.toggleArchiveModal.bind(this);
		this.handlePricesChanged = this.handlePricesChanged.bind(this);
		this.handleArchiveClicked = this.handleArchiveClicked.bind(this);

		this.handleCreateSchedule = this.handleCreateSchedule.bind(this);

		this.handleConvocationClicked = this.handleConvocationClicked.bind(this);
		this.handleDeleteConvocationClicked = this.handleDeleteConvocationClicked.bind(this);

		this.handleResultClicked = this.handleResultClicked.bind(this);
		this.handleDeleteResultClicked = this.handleDeleteResultClicked.bind(this);

		this.handleMedicalCertificateClicked = this.handleMedicalCertificateClicked.bind(this);
		this.handleDeleteMedicalCertificateClicked = this.handleDeleteMedicalCertificateClicked.bind(this);

		this.handleModifySchedule = this.handleModifySchedule.bind(this);
		this.handleCancelSchedule = this.handleCancelSchedule.bind(this);
	}

	/* HANDLERS */
	handleCreateSchedule() {
		App.history.push("/dashboard/check/schedule", { ...App.history.location.state, check: this.element, isDelete: false, isModification: false });
	}

	handleModifySchedule() {
		App.history.push("/dashboard/check/schedule", { ...App.history.location.state, check: this.element, isDelete: false, isModification: true });
	}

	handleCancelSchedule() {
		App.history.push("/dashboard/check/schedule", { ...App.history.location.state, check: this.element, isDelete: true, isModification: false });
	}

	handleFixPrices(withCommunication: boolean) {
		if (this.element.checkOccurence < 0) {
			AlertHelper.danger("No Check Occurence has been set.");
			this.setState({ isBusy: false });
			this.setState({ isLoadingArchiveCommunicationButton: false });
		// } else if (this.element.checkOccurence <= 1) {
		// 	this.togglePriceModal();
		} else {
			this.handlePricesChanged(withCommunication);
		}
	}

	async handlePricesChanged(withCommunication: boolean) {
		AlertHelper.information("Fixing prices.");
		await NetworkHelper.execute(await this.priceServive.post(this.element.id, this.element));
		AlertHelper.success("Prices fixed.");
		await this.handleUpdateCheck();
		if(withCommunication){
			await this.handleArchiveCommunicationClicked();
		} else {
			await this.handleArchiveClicked();
		}
	}

	async handleRequestSync(handleUpdateRequest: any) {
		/* REQUIRED FIELD CHECKS */
		const { errors } = this.state;

		this.requiredFields.forEach((field) => ValidationHelper.checkRequired(this.element, field, errors));

		if (
			this.requiredFields.filter((target) => {
				if (errors[target]) return target;
				return;
			}).length > 0
		) {
			this.setState(this.state, () => AlertHelper.danger("Required fields are missing"));
			return;
		}

		handleUpdateRequest();
	}

	async handleCheckSync(handleUpdateCheck: any) {
		handleUpdateCheck();
	}

	//Not used anymore
	togglePriceModal() {
		this.setState((previousState) => {
			return { priceModal: !previousState.priceModal };
		});
	}
	
	//Not used anymore
	toggleArchiveModal() {
		this.setState((previousState) => {
			return { archiveModal: !previousState.archiveModal };
		});
	}

	async handleArchiveCommunicationClicked() {
		AlertHelper.information("Archiving check & sending communications");
		const resp = await NetworkHelper.execute(this.archiveService.putWithCommunications(this.element));

		if (!resp) {
			AlertHelper.danger("Has not archived properly");
		} else {
			AlertHelper.success("Check archived.");
		}

		this.setState({ isBusy: false });
		this.setState({ isLoadingArchiveCommunicationButton: false });
		App.navigate("/dashboard/check", {});
	}

	async handleArchiveClicked() {
		AlertHelper.information("Archiving check");
		const resp = await NetworkHelper.execute(this.archiveService.put(this.element));

		if (!resp) {
			AlertHelper.danger("Has not archived properly");
		} else {
			AlertHelper.success("Check archived.");
		}

		this.setState({ isBusy: false });
		this.setState({ isLoadingArchiveCommunicationButton: false });
		App.navigate("/dashboard/check", {});
	}

	async handleResultClicked(file: any) {
		await NetworkHelper.execute(new CheckResultFileService().post(this.element.id, file));
		this.handleRefreshCheck();
	}

	async handleDeleteResultClicked(checkId: string) {
		await NetworkHelper.execute(new CheckResultFileService().delete(checkId));
		this.handleRefreshCheck();
	}

	async handleConvocationClicked(file: any) {
		await NetworkHelper.execute(new CheckConvocationFileService().post(this.element.id, file));
		this.handleRefreshCheck();
	}

	async handleDeleteConvocationClicked(checkId: string) {
		await NetworkHelper.execute(new CheckConvocationFileService().delete(checkId));
		this.handleRefreshCheck();
	}

	async handleMedicalCertificateClicked(file: any) {
		await NetworkHelper.execute(new CheckCertificateFileService().post(this.element.id, file));
		this.handleRefreshCheck();
	}

	async handleDeleteMedicalCertificateClicked(checkId: string) {
		await NetworkHelper.execute(new CheckCertificateFileService().delete(checkId));
		this.handleRefreshCheck();
	}

	/* METHODS */
	mapCheckDate(date: string) {
		try {
			if (date !== undefined && date !== null && date.includes("T")) {
				return date.split("T")[0];
			}
		} catch {
			try {
				if (date !== undefined && date !== null && date.toString().includes("T")) {
					return date.toString().split("T")[0];
				}
			} catch {
				return date;
			}
		}
		return new Date(date);
	}

	mapCheckTime(date: string) {
		try {
			if (date !== undefined && date.includes("T")) {
				return date.split("T")[1].split(".")[0];
			}
		} catch {
			try {
				if (date !== undefined && date.toString().includes("T")) {
					return date.toString().split("T")[1].split(".")[0];
				}
			} catch {
				return date;
			}
		}

		return "00:00:00";
	}

	calculateCompanyTotal() {
		return this.element.companyTotalPrice;
	}

	calculateDoctorTotal() {
		if (this.element.request.location === "OFFICE") {
			return parseFloat(this.element.doctorExtraCosts) + parseFloat(this.element.doctorBasePrice);
		}

		return parseFloat(this.element.doctorExtraCosts) + parseFloat(this.element.doctorBasePrice) + parseFloat(this.element.doctorTravelCosts);
	}

	/* RENDER */
	render() {
		return (
			<CheckContext.Consumer>
				{({ check, handleRefreshCheck, handleInputChanged, handleRefreshCommunications, handleUpdateCheck, handleUpdateRequest }) => {
					if (!check) {
						handleRefreshCheck();

						return <div>loading the check details</div>;
					}

					// Deconstruct
					const element = check;
					const { worker } = element.request;
					const { archiveHovered, setPriceHovered, archiveResultHovered, isLoadingArchiveCommunicationButton } = this.state;
					const archived = check.state === 0;
					const type = App.history.location.pathname.replace("/dashboard/", "").replace("/detail", "");
					const formattedType = `${type.charAt(0).toUpperCase()}${type.slice(1)}`;
					const doctor = element.doctor;
					const isCheck = ValidationHelper.isCheck(check);
					let doctorFullName = undefined;

					if (doctor) {
						doctorFullName = `${doctor.firstName} ${doctor.lastName}`;
					}

					const title = `${worker.firstName} ${worker.lastName} 's Check`;
					const history = [
						{ name: `${formattedType}s`, path: `/dashboard/${type}` },
						{ name: `${worker.firstName} ${worker.lastName}`, path: `/dashboard/${type}/detail`, state: App.history.location.state },
					];

					this.element = check;
					this.handleRefreshCheck = handleRefreshCheck;
					this.handleUpdateCheck = handleUpdateCheck;
					this.handleUpdateRequest = handleUpdateRequest;
					this.handleInputChanged = handleInputChanged;
					this.handleRefreshCommunications = handleRefreshCommunications;

					return (
						<div>
							<div className="col" style={{ marginTop: 0 }}>
								{/* PAGE HEADER */}
								<div style={this.style.headerContainer}>
									<HeaderView title={title} history={history} />
								</div>
								<div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
									<div style={this.style.inputContainerView}>
										{/* WORKER INFORMATION */}
										<AccordeonView isOpenByDefault={archived || isRequest(check) || check.state === CheckStatus.REQUESTUPDATED} title="Worker Information">
											<div className="row">
												{/* FIRST NAME */}
												<div style={this.style.inputLeft}>
													<div style={this.style.inputTitle}>First Name*</div>
													<InputView disabled={archived} updateSync={() => this.handleRequestSync(handleUpdateRequest)} error={this.state.errors["request.worker.firstName"]} placeholder="Amélie" type="text" value={worker.firstName} onChange={(event) => handleInputChanged("request.worker.firstName", event.target.value)} />
												</div>

												{/* LAST NAME */}
												<div style={this.style.inputRight}>
													<div style={this.style.inputTitle}>Last Name*</div>
													<InputView disabled={archived} updateSync={() => this.handleRequestSync(handleUpdateRequest)} error={this.state.errors["request.worker.lastName"]} placeholder="Meunier" type="text" value={worker.lastName} onChange={(event) => handleInputChanged("request.worker.lastName", event.target.value)} />
												</div>
											</div>

											{/* ADDRESS */}
											<div style={this.style.inputDouble}>
												<div style={this.style.inputTitle}>Address*</div>
												<AddressInputView disabled={archived} updateSync={() => this.handleRequestSync(handleUpdateRequest)} error={this.state.errors["request.worker.address"]} placeholder="Parvis Sainte Gudule 5, 1000 Bruxelles" value={worker.address} target={"request.worker.address"} handleInputChanged={handleInputChanged} />
											</div>

											<div className="row">
												{/* PHONE NUMBER */}
												<div style={this.style.inputLeft}>
													<div style={this.style.inputTitle}>Phone number (GSM)</div>
													<InputView disabled={archived} updateSync={() => this.handleRequestSync(handleUpdateRequest)} placeholder="02 897 20 01" type="text" value={worker.phoneNumber} onChange={(event) => handleInputChanged("request.worker.phoneNumber", event.target.value)} />
												</div>

												{/* FUNCTION */}
												<div style={this.style.inputRight}>
													<div style={this.style.inputTitle}>Function</div>
													<InputView disabled={archived} updateSync={() => this.handleRequestSync(handleUpdateRequest)} placeholder="COO" type="text" value={worker.function} onChange={(event) => handleInputChanged("request.worker.function", event.target.value)} />
												</div>
											</div>

											<div className="row">
												{/* PHONE NUMBER */}
												<div style={this.style.inputLeft}>
													<div style={this.style.inputTitle}>Gender*</div>
													<InputView disabled={archived} error={this.state.errors["request.worker.gender"]} updateSync={() => this.handleRequestSync(handleUpdateRequest)} placeholder="Select an option" type="select" value={worker.gender} onChange={(event) => handleInputChanged("request.worker.gender", event.target.value)}>
														<option key={"MALE"} value={"MALE"}>
															Male
														</option>
														<option key={"FEMALE"} value={"FEMALE"}>
															Female
														</option>
													</InputView>
												</div>

												{/* FUNCTION */}
												<div style={this.style.inputRight}>
													<div style={this.style.inputTitle}>Language(s)*</div>
													<InputView disabled={archived} error={this.state.errors["request.worker.languages"]} updateSync={() => this.handleRequestSync(handleUpdateRequest)} placeholder="Select an option" type="multi-select" value={worker.languages} onChange={(event) => handleInputChanged("request.worker.languages", event.target.value)}>
														<option key={"FR"} value={"FR"}>
															FR
														</option>
														<option key={"NL"} value={"NL"}>
															NL
														</option>
														<option key={"EN"} value={"EN"}>
															EN
														</option>
														<option key={"DE"} value={"DE"}>
															DE
														</option>
													</InputView>
												</div>
											</div>
										</AccordeonView>

										{/* REQUEST INFORMATION */}
										<CheckRequestInformationView errors={this.state.errors} handleRequestSync={() => this.handleRequestSync(handleUpdateRequest)} handleDeleteMedicalCertificateClicked={this.handleDeleteMedicalCertificateClicked} handleMedicalCertificateClicked={this.handleMedicalCertificateClicked} />

										{/* CHECK DETAILS */}
										<AccordeonView isOpenByDefault={isRequest(check) || isCheckStatusPending(check) || check.state === CheckStatus.FINISHED} title="Check Details" header={<FileButtonView disabled={archived} type="CONVOCATION" fileName={element.secondCheckConvocation} checkId={element.id} onDeleteClicked={this.handleDeleteConvocationClicked} onClick={this.handleConvocationClicked} />}>
											<div className="row">
												{/* BUTTONS */}
												<span style={{ ...this.style.inputLeft, maxWidth: 270 }}>
													<div style={this.style.inputTitle}>Schedule</div>
													<div style={{ display: "flex", flexDirection: "row" }}>
														{this.element.doctor && this.element.state !== 2 ? (
															<>
																<button disabled={archived} onClick={this.handleModifySchedule} className="btn btn-base btn-base-primary" style={{ ...this.style.scheduleButton, padding: 5, paddingTop: 10, marginLeft: 0 }}>
																	<div style={this.style.buttonContent}>
																		<img className="img-base-primary" src="/assets/icon_edit.svg" style={{ ...this.style.buttonImage, margin: 0, transform: "translate(-1px, -2px)" }} />
																	</div>
																</button>

																<button disabled={archived} onClick={this.handleCancelSchedule} className="btn btn-base btn-base-primary" style={{ ...this.style.scheduleButton, padding: 5, paddingTop: 10, marginLeft: 15 }}>
																	<div style={this.style.buttonContent}>
																		<img className="img-base-primary" src="/assets/icon_trash.svg" style={{ ...this.style.buttonImage, margin: 0, transform: "translate(-1px, -2px)" }} />
																	</div>
																</button>
															</>
														) : (
															<button disabled={archived} onClick={this.handleCreateSchedule} className="btn btn-base btn-base-primary" style={{ ...this.style.scheduleButton, padding: 5, paddingTop: 10 }}>
																<div style={this.style.buttonContent}>
																	<img className="img-base-primary" src="/assets/icon_calendar.svg" style={{ ...this.style.buttonImage, marginRight: 0, transform: "translate(-1px, -2px)" }} />
																	<div style={this.style.buttonText}>Schedule</div>
																</div>
															</button>
														)}
													</div>
												</span>

												{/* DOCTORS */}
												<div style={this.style.inputRight}>
													<div style={this.style.inputTitle}>Doctor</div>
													<InputView disabled updateSync={() => this.handleRequestSync(handleUpdateRequest)} placeholder="A doctor" type="text" value={doctorFullName} onChange={() => {}} />
												</div>
											</div>

											<div className="row">
												{/* DATE */}
												<div style={this.style.inputLeft}>
													<div style={this.style.inputTitle}>Date</div>
													<InputView disabled error={this.state.errors["checkDate"]} placeholder="01/01/2020" type="date" value={this.mapCheckDate(element.checkDate)} onChange={(event) => handleInputChanged("checkDate", event.target.value)} />
												</div>

												{/* TIME */}
												<div style={this.style.inputRight}>
													<div style={this.style.inputTitle}>Time</div>
													<InputView disabled placeholder="00:00:00" mask="h9:m9:s9" type="text" value={this.mapCheckTime(element.checkDate)} onChange={(event) => handleInputChanged("timeToCheck", event.target.value)} />
												</div>
											</div>

											{/* ADDRESS */}
											<div style={this.style.inputDouble}>
												<div style={this.style.inputTitle}>Address of the Check</div>
												<AddressInputView disabled={archived} updateSync={() => this.handleCheckSync(handleUpdateCheck)} placeholder="Parvis Sainte Gudule 5, 1000 Bruxelles" value={element.checkAddress} target={"checkAddress"} handleInputChanged={handleInputChanged} />
											</div>

											<div className="row">
												{/* DOCTOR REACTIVITY */}
												<div style={this.style.inputLeft}>
													<div style={this.style.inputTitle}>Doctor Reactivity</div>
													<StarView
														disabled
														stars={element.doctorReactivity}
														onChange={(event: any) => {
															handleInputChanged("doctorReactivity", event.target.value);
															this.handleCheckSync(handleUpdateCheck);
														}}
													/>
												</div>

												<div style={{ ...this.style.inputRight, display: "flex", flexDirection: "row", marginBottom: 0 }}>
													{/* DISTANCE */}
													<div style={this.style.checkDistance}>
														<div style={this.style.inputTitle}>Distance</div>
														<InputView disabled updateSync={() => this.handleCheckSync(handleUpdateCheck)} placeholder="0.0" type="text" value={element.distanceToCheck} onChange={(event) => handleInputChanged("distanceToCheck", event.target.value)} />
													</div>

													{/* TIME */}
													<div style={this.style.checkTime}>
														<div style={this.style.inputTitle}>Time</div>
														<InputView disabled updateSync={() => this.handleCheckSync(handleUpdateCheck)} placeholder="0min" type="text" value={element.timeToCheck} onChange={() => {}} />
													</div>
												</div>
											</div>

											{/* MAGIC LINK */}
											<div style={this.style.inputDouble}>
												<div style={this.style.inputTitle}>Magic Link</div>
												<div style={{ display: "flex", flexDirection: "row" }}>
													<InputView disabled placeholder="Magic Link" type="text" value={element.magicLink} onChange={() => {}} />
												</div>
											</div>

											<div className="row">
												<div style={this.style.inputDouble}>
													<div style={{ ...this.style.inputTitle, display: "flex", flexDirection: "row" }}>
														<div style={{ marginTop: 5 }}>Is a second Check at the doctor's office needed ?</div>
														<SwitchView
															disabled={archived}
															value={element.secondCheckNeeded}
															onChange={(event: any) => {
																handleInputChanged("secondCheckNeeded", event.target.value);
															}}
															updateSync={handleUpdateCheck}
														/>
													</div>
												</div>
											</div>

											{element.secondCheckNeeded && (
												<>
													<div className="row">
														{/* DATE OF THE 2DE CHECK */}
														<div style={this.style.inputLeft}>
															<div style={this.style.inputTitle}>Date of the 2de Check</div>
															<InputView disabled={archived} updateSync={() => this.handleCheckSync(handleUpdateCheck)} placeholder="01/01/2020" type="date" value={element.secondCheckDate} onChange={(event) => handleInputChanged("secondCheckDate", event.target.value)} />
														</div>

														{/* TIME OF THE 2DE CHECK */}
														<div style={this.style.inputRight}>
															<div style={this.style.inputTitle}>Time of the 2de Check</div>
															<InputView disabled={archived} updateSync={() => this.handleCheckSync(handleUpdateCheck)} placeholder="8:00:00" mask="h9:m9:s9" type="text" value={element.secondCheckTime} onChange={(event) => handleInputChanged("secondCheckTime", event.target.value)} />
														</div>
													</div>
												</>
											)}
										</AccordeonView>

										{/* CHECK RESULT */}
										<CheckResultView handleInputChanged={handleInputChanged} handleDateChanged={(key: string, value: string) => handleInputChanged(key, value)} handleResultClicked={this.handleResultClicked} handleDeleteResultClicked={this.handleDeleteResultClicked} />

										{/* WORK ACCIDENT INFORMATION */}
										{element.request.incapacityType === 1 && <WorkAccidentInformationView handleDateChanged={(key: string, value: string) => this.handleInputChanged(key, value)} handleInputChanged={this.handleInputChanged} />}

										{/* CHECK PRICING */}
										<AccordeonView isOpenByDefault={archived || check.state === CheckStatus.FINISHED} title="Check Pricing">
											{/* COMPANY PRICE */}
											<div style={this.style.checkPricingRow}>
												<div style={this.style.calculationTitle}>Company Price Calculation :</div>
												<div className="row">
													<div style={this.style.inputHalfLeft}>
														<div style={this.style.inputTitle}>Base Price*</div>
														<InputView disabled placeholder="0.0" type="text" value={formatAmount(element.companyBasePrice)} onChange={() => {}} />
													</div>
													<div style={this.style.inputHalf}>
														<div style={this.style.inputTitle}>Travel Costs</div>
														<InputView disabled placeholder="0.0" type="text" value={formatAmount(element.companyTravelCosts)} onChange={() => {}} />
													</div>
													<div style={this.style.inputHalf}>
														<div style={this.style.inputTitle}>Extra Costs</div>
														<InputView disabled={archived} updateSync={() => this.handleCheckSync(handleUpdateCheck)} placeholder="0.0" type="text" value={element.companyExtraCosts} onChange={(event) => handleInputChanged("companyExtraCosts", event.target.value ? event.target.value : "0")} />
													</div>
													<div style={this.style.inputHalfRight}>
														<div style={this.style.inputTitle}>Total Price*</div>
														<InputView disabled placeholder="0.0" type="text" value={formatAmount(this.calculateCompanyTotal())} onChange={() => {}} />
													</div>
												</div>
											</div>

											{/* DOCTOR PRICE */}
											<div style={this.style.checkPricingRow}>
												<div style={this.style.calculationTitle}>Doctor Price Calculation :</div>
												<div className="row">
													<div style={this.style.inputHalfLeft}>
														<div style={this.style.inputTitle}>Base Price*</div>
														<InputView disabled placeholder="0.0" type="text" value={formatAmount(element.doctorBasePrice)} onChange={() => {}} />
													</div>
													<div style={this.style.inputHalf}>
														<div style={this.style.inputTitle}>Travel Costs</div>
														<InputView disabled placeholder="0.0" type="text" value={formatAmount(element.doctorTravelCosts)} onChange={() => {}} />
													</div>
													<div style={this.style.inputHalf}>
														<div style={this.style.inputTitle}>Extra Costs</div>
														<InputView disabled={archived} updateSync={() => this.handleCheckSync(handleUpdateCheck)} placeholder="0.0" type="text" value={element.doctorExtraCosts} onChange={(event) => handleInputChanged("doctorExtraCosts", event.target.value ? event.target.value : "0")} />
													</div>
													<div style={this.style.inputHalfRight}>
														<div style={this.style.inputTitle}>Total Price*</div>
														<InputView disabled placeholder="0.0" type="text" value={formatAmount(this.calculateDoctorTotal())} onChange={() => {}} />
													</div>
												</div>
											</div>
										</AccordeonView>

										{/* ARCHIVE CHECK */}
										<AccordeonView isOpenByDefault={archived || check.state === CheckStatus.FINISHED} title="Archive Check">
											{/* COMMENT ABOUT THE CHECK */}
											<div style={this.style.inputDouble}>
												<div style={this.style.inputTitle}>Comment about the check</div>
												<InputView disabled={archived} type="textarea" placeholder="Comment" value={element.archiveComment} onChange={(event) => handleInputChanged("archiveComment", event.target.value)} />
											</div>

											{/* ARCHIVE BUTTON */}
											<div style={{display: "flex"}}>
												<button disabled={archived || this.state.isBusy || this.state.isLoadingArchiveCommunicationButton} onMouseLeave={() => this.setState({ archiveResultHovered: false })} onMouseEnter={() => this.setState({ archiveResultHovered: true })} onDoubleClick={() => { this.setState({ isLoadingArchiveCommunicationButton: true }); this.handleFixPrices(true); }} type="button" className="btn btn-base btn-base-danger" style={this.style.button} >
													{!isLoadingArchiveCommunicationButton ? (
														<div style={this.style.buttonContent}>
															<img alt="archive" src={`/assets/icon_send.svg`} style={{ ...this.style.buttonImage, filter: archiveResultHovered ? "" : "invert(63%) sepia(16%) saturate(392%) hue-rotate(163deg) brightness(100%) contrast(92%)" }} />
															<div style={this.style.buttonText}>Send results and archive</div>
														</div>
													) : (
														<Lottie
															options={{
																animationData: require('./assets/loading.json'),
															}}
															style={ this.style.lottie }
														/>
													)}
												</button>
												<img
													style={{}}
													src="/assets/illu_schedule_or_small.svg"
												/>
												<button disabled={archived || this.state.isBusy || this.state.isLoadingArchiveCommunicationButton} onMouseLeave={() => this.setState({ archiveHovered: false })} onMouseEnter={() => this.setState({ archiveHovered: true })} onDoubleClick={() => {this.setState({ isBusy: true }); this.handleFixPrices(false);}} type="button" className="btn btn-base btn-base-danger" style={this.style.button}>
													{!this.state.isBusy ? (
														<div style={this.style.buttonContent}>
															<img alt="archive" src={`/assets/icon_archive${archiveHovered ? "_white" : ""}.svg`} style={{ ...this.style.buttonImage, filter: archiveHovered ? "" : "invert(63%) sepia(16%) saturate(392%) hue-rotate(163deg) brightness(100%) contrast(92%)" }} />
															<div style={this.style.buttonText}>Archive the Check</div>
														</div>
													) : (
														<Lottie
															options={{
																animationData: require('./assets/loading.json'),
															}}
															style={ this.style.lottie }
														/>
													)}
												</button>
											</div>
										</AccordeonView>
									</div>

									{/* COMMUNICATIONS AND LOGS */}
									<div style={this.style.communicationsWithoutToolBox}>
										{/* LOGS */}
										<ToolBar />
										<CheckLogsView />
										<CheckCommunicationsView />
									</div>
								</div>

								{/* POP UP */}
								{/* <PopUpView theme={"danger"} illustration={"warning"} title={`areYouSureCorrectPricing`} toggled={this.state.priceModal} onToggle={this.togglePriceModal} onIgnore={() => {}} onClick={this.handlePricesChanged} actionTitle={`yesSetPrices`} ignoreTitle={"oopsNoGoBack"} />
								<PopUpView theme={"danger"} illustration={"warning"} title={`areYouSureToWantToArchive`} toggled={this.state.archiveModal} onToggle={this.toggleArchiveModal} onIgnore={() => {}} onClick={this.handleFixPrices} actionTitle={`archive`} ignoreTitle={"oopsNoGoBack"} /> */}
							</div>
						</div>
					);
				}}
			</CheckContext.Consumer>
		);
	}
}
