import { MyCertificateDTO } from 'src/dtos/MyCertificateDTO';

export const actions = {
  serverChanged: 'serverChanged',
  companyChanged: 'companyChanged',
  screenChanged: 'screenChanged',
  fieldChanged: 'fieldChanged',
  configChanged: 'configChanged',
};

export const reducer = (state: MyCertificateDTO, action: any) => {
  switch (action.type) {
    case actions.serverChanged:
      return {
        company: action.payload,
      };
    case actions.configChanged:
      return {
        ...state,
        company: {
          ...state.company,
          configuration: {
            ...state.company.configuration,
            [action.payload.target]: action.payload.value,
          },
        },
      };
    case actions.companyChanged:
      return {
        ...state,
        company: {
          ...state.company,
          [action.payload.target]: action.payload.value,
        },
      };
    case actions.screenChanged:
      const clone = [...state.company.configuration.screens];
      const screenIndex = clone.findIndex(
        (screen) => screen.name === action.payload.screenName
      );
      const obj = clone.filter(
        (screen) => action.payload.screenName === screen.name
      )[0];
      const updateObj = {
        ...obj,
        [action.payload.target]: action.payload.value,
      };

      clone[screenIndex] = updateObj;

      return {
        ...state,
        company: {
          ...state.company,
          configuration: {
            ...state.company.configuration,
            screens: clone,
          },
        },
      };
    case actions.fieldChanged:
      const cloneScreens = [...state.company.configuration.screens];
      const index = cloneScreens.findIndex(
        (screen) => screen.name === action.payload.screenName
      );
      const screen = cloneScreens.filter(
        (screen) => screen.name === action.payload.screenName
      )[0];
      const cloneFields = [...screen.fields];
      const fieldIndex = cloneFields.findIndex(
        (field) => field.name === action.payload.fieldName
      );
      const field = cloneFields.filter(
        (field) => field.name === action.payload.fieldName
      )[0];
      const updateField = {
        ...field,
        [action.payload.target]: action.payload.value,
      };
      cloneFields[fieldIndex] = updateField;
      cloneScreens[index] = {
        ...screen,
        fields: [...cloneFields],
      };
      return {
        ...state,
        company: {
          ...state.company,
          configuration: {
            ...state.company.configuration,
            screens: [...cloneScreens],
          },
        },
      };
    default:
      return state;
  }
};
