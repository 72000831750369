import Service from './Service';
import Axios from 'axios';
import MyCertificateCompanyConfigurationListDTO from '../dtos/MyCertificateCompanyConfigurationListDTO';

export default class CompanyConfigurationListService extends Service
{
    /* METHODS */
    get(id: string, type: string)
    {        
        return this.execute(Axios.get(`${this.getUrl("v2")}/companies/${id}/configurations/${type}lists`, this.config()), "Unable to retrieve company details.");
    }

    put(id: string, type: string, body: MyCertificateCompanyConfigurationListDTO)
    {
        return this.execute(Axios.put(`${this.getUrl("v2")}/companies/${id}/configurations/${type}lists`, body, this.config()), "Unable to update company.");
    }
}