import React from 'react';
import UserContext from 'src/components/userContext/UserContext';

function withUser(WrappedComponent) {
    class HOC<Props = {}, State = {}> extends React.Component<Props, State> {
        render() {
            return (
                <UserContext.Consumer>
                    {(context) => <WrappedComponent {...this.props} {...context} />}
                </UserContext.Consumer>
            );
        }
    }

    return HOC;
};

export default withUser;