export default class ListViewStyle
{
    busyAnimation: React.CSSProperties =
    {
        width:'15%', 
        height:'15%', 
        margin:'auto'
    }

    noResultFound: React.CSSProperties =
    {
        marginLeft:20, 
        marginBottom:10
    }

    pagesContainer: React.CSSProperties =
    {
        margin:30, 
        marginTop:10, 
        alignContent:'flex-end', 
        alignItems:'flex-end', 
        display:'flex', 
        justifyContent:'flex-end'
    }

    pageNumber: React.CSSProperties =
    {
        margin:2.5, 
        border:'1px solid #1aa5cf', 
        paddingLeft:9.5, 
        textAlign:'center', 
        color:'white', 
        fontWeight:600, 
        alignContent:'center', 
        alignItems:'center', 
        backgroundColor:'#1aa5cf', 
        width:30, 
        height:30, 
        borderRadius:15, 
        display:'flex', 
        cursor:'pointer'
    }

    currentPage: React.CSSProperties =
    {
        margin:2.5, 
        paddingLeft:9.5, 
        border:'1px solid #E3E3E3', 
        textAlign:'center', 
        color:'#7D91A0', 
        fontWeight:600, 
        alignContent:'center', 
        alignItems:'center', 
        backgroundColor:'transparent', 
        width:30, 
        height:30, 
        borderRadius:15, 
        display:'flex', 
        cursor:'pointer'
    }

    pages: React.CSSProperties =
    {
        margin:2.5, 
        border:'1px solid #E3E3E3', 
        textAlign:'center', 
        color:'#7D91A0', 
        fontWeight:600, 
        alignContent:'center', 
        alignItems:'center', 
        backgroundColor:'transparent', 
        width:30, 
        height:30, 
        borderRadius:15, 
        display:'flex',
        cursor:'pointer'
    }
}