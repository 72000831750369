import Service from './Service';
import Axios from 'axios';

export default class CheckResultFileService extends Service
{
    get(checkId: string)
    {
        return this.execute(Axios.get(`${this.url}/checks/${checkId}/resultfiles`, this.config()));
    }

    post(checkId: string, checkFile: any)
    {
        return this.execute(Axios.post(`${this.url}/checks/${checkId}/resultfiles`, checkFile ,this.config()));
    }

    delete(checkId: string)
    {
        return this.execute(Axios.delete(`${this.url}/checks/${checkId}/resultfiles`, this.config()));
    }
}