import React from 'react';
import { CheckContext } from 'src/modules/dashboard/components/checkContext/CheckContext';

function withCheck<T>(WrappedComponent) {
    class HOC extends React.Component<T> {
        render() {
            return (
                <CheckContext.Consumer>
                    {(context) => <WrappedComponent {...this.props} {...context} />}
                </CheckContext.Consumer>
            );
        }
    }

    return HOC;
};

export default withCheck;