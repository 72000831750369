import * as React from "react";
import NewLogView from "../../../components/v2/LogView";
import SMSView from "../../../components/SMSView/SMSView";
import EmailView from "../../../components/EmailView/EmailView";
import { CheckContext } from "src/modules/dashboard/components/checkContext/CheckContext";
import LogDTO from "src/models/LogDTO";
import IntegrationLogView from "../../../components/IntegrationLogView";
import DateHelper from "src/helpers/DateHelper";
import LogConstants from "src/constants/LogConstants";

const CheckLogsView = () => {
	const mapCamelCase = (key: string, value: any) => {
		if (value && typeof value === "object") {
			for (var k in value) {
				if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
					value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
					delete value[k];
				}
			}
		}

		return value;
	};

	return (
		<CheckContext.Consumer>
			{({ check, logs, handleRefreshLogs }) => {
				// Still need to load logs
				if (!logs) {
					handleRefreshLogs();

					return <div />;
				}

				let lastModifiedLog = { id: "" };

				logs.forEach((element: LogDTO) => {
					if (
						element.type === LogConstants.UPDATE_CHECKED ||
						element.type === LogConstants.UPDATED_TIMING ||
						element.type === LogConstants.UPDATED_LOCATION ||
						element.type === LogConstants.UPDATED_PHONE ||
						element.type === LogConstants.UPDATED_ADDRESS ||
						element.type === LogConstants.UPDATED_WORKER_LANGUAGE ||
						element.type === LogConstants.UPDATED_INCAPACITY_START_DATE ||
						element.type === LogConstants.UPDATED_INCAPACITY_END_DATE ||
						element.type === LogConstants.FORMS_AVAILIBITY_CONFIRMED ||
						element.type === LogConstants.SYSTEM_GSM_PROBLEM ||
						element.type === LogConstants.FORMS_AVAILIBITY_DENIED ||
						element.type === LogConstants.FORMS_GSM_PROBLEM ||
						element.type === LogConstants.FORMS_MODIFY_CHECK_BY_SUPERIOR ||
						element.type === LogConstants.FORMS_CANCEL_CHECK ||
						element.type === LogConstants.FORMS_SWITCH_TELECHECK ||
						element.type === LogConstants.SECOND_CHECK_RECEIVED
					  ) {
						lastModifiedLog = element;
					  }
				});

				return logs?.map((log: any, index: number) => {
					log.check = check;

					switch (log.type) {
						case "Email":
							return <EmailView log={log} delay={index * 100} communication={JSON.parse(log.payLoad, mapCamelCase)} />;

						case "SMS":
							return <SMSView log={log} delay={index * 100} communication={JSON.parse(log.payLoad, mapCamelCase)} handleRefreshCommunication={handleRefreshLogs} />;

						case "Integration":
							return <IntegrationLogView log={log} integration={JSON.parse(log.payLoad, mapCamelCase)} />;

						default:
							return <NewLogView lastUpdate={lastModifiedLog.id === log.id} delay={index * 100} internal={log.user} log={log} />;
					}
				});
			}}
		</CheckContext.Consumer>
	);
};

export default CheckLogsView;
