import Service from './Service';
import Axios from 'axios';

export default class CheckConvocationFileService extends Service
{
    get(checkId: string)
    {
        return this.execute(Axios.get(`${this.url}/checks/${checkId}/convocationfiles`, this.config()));
    }

    post(checkId: string, checkFile: any)
    {
        return this.execute(Axios.post(`${this.url}/checks/${checkId}/convocationfiles`, checkFile ,this.config()));
    }

    delete(checkId: string)
    {
        return this.execute(Axios.delete(`${this.url}/checks/${checkId}/convocationfiles`, this.config()));
    }
}