import { App } from "../../../../../../../../app";
import SuperiorDetailViewState from "./SuperiorDetailViewState";
import { InputView } from "../../../../../../../../components/inputView/InputView";
import HeaderView from "../../../../../../components/headerView/HeaderView";
import SuperiorDTO from "../../../../../../../../models/SuperiorDTO";
import SuperiorService from "../../../../../../../../services/SuperiorService";
import DetailComponent from "../../../../../../abstracts/detailComponent/DetailComponent";
import SuperiorDetailViewStyle from "./SuperiorDetailViewStyle";
import DeleteButtonView from "../../../../../../components/deleteButtonView/DeleteButtonView";
import SaveButtonView from "../../../../../../components/saveButtonView/SaveButtonView";
import DetailView from "../../../../../../components/detailView/DetailView";
import TranslationView from "../../../../../../../../components/translationView/TranslationView";
import DoubleClickButtonView from "../../../../../../../../components/buttonView/DoubleClickButtonView";
import SuperiorConfirmationService from "../../../../../../../../services/SuperiorConfirmationService";
import UserContext from "src/components/userContext/UserContext";
import SuperiorResetPasswordService from "src/services/SuperiorResetPasswordService";

export default class SuperiorDetailView extends DetailComponent<
	{},
	SuperiorDetailViewState
> {
	/* ATTRIBUTES */
	emailFields = ["email"];
	service = new SuperiorService();
	requiredFields = ["email", "language"];
	style = new SuperiorDetailViewStyle();

	/* CONSTRUCTOR */
	constructor(props: {}) {
		super(props);

		// Initial State
		this.state = {
			element: new SuperiorDTO(),
			errors: {},
			isBusy: false,
			fields: [],
		};

		// Handlers
		this.handleSaved = this.handleSaved.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.handleIsInterfaceChanged =
			this.handleIsInterfaceChanged.bind(this);
		this.handleSendEncodingConfirmation =
			this.handleSendEncodingConfirmation.bind(this);
		this.handleResetPassword =
			this.handleResetPassword.bind(this);
	}

	/* LIFE CYCLE EVENTS */
	componentDidMount() {
		if (App.history.location.state.superior != undefined) {
			this.setState({
				element: App.history.location.state.superior,
			});
		}
	}

	/* HANDLERS */
	async handleDelete(superior: SuperiorDTO) {
		await this.execute(this.service.delete(superior.id));
		this.success(
			`Superior ${superior.firstName} ${superior.lastName} deleted.`
		);
		App.history.push("/dashboard/company/superior", {
			company: App.history.location.state.company,
		});
	}

	handleIsInterfaceChanged(isInterface: boolean) {
		this.setState((previousState) => {
			previousState.element.isInterface = isInterface;
			return previousState;
		});
	}

	async handleSendEncodingConfirmation() {
		await this.handleSaved(false);
		await this.execute(
			new SuperiorConfirmationService().post(this.state.element.id)
		);
		this.success(`E-mail confirmation sent.`);
	}

	async handleResetPassword() {
		await this.handleSaved(false);
		await this.execute(
			new SuperiorResetPasswordService().post(this.state.element.id)
		);
		this.success(`E-mail to reset password sent.`);
	}

	async handleSaved(navigate: boolean = true) {
		// Destructuring
		const { element, errors } = this.state;
		const { company } = App.history.location.state;

		/* REQUIRED FIELD CHECKS */
		this.requiredFields.forEach((field) => this.checkRequired(field));

		/* REQUIRED FIELD CHECKS */
		if (
			this.requiredFields.filter((target) => {
				if (errors[target]) return target;
			}).length > 0
		) {
			this.setState(this.state, () =>
				this.danger("You're missing some information.")
			);
		} else {
			/* SERVER CALL */
			/* EMAIL CHECKS */
			this.emailFields.forEach((field) => this.checkEmail(field));

			/* EMAIL CHECKS */
			if (
				this.emailFields.filter((target) => {
					if (errors[target]) return target;
				}).length > 0
			) {
				this.setState(this.state, () =>
					this.danger("You're missing some information.")
				);
			} else {
				/* SERVER CALL */
				element.companyId = company.id;

				if (element.id) {
					await this.execute(this.service.put(element));
					this.success(
						`Superior ${element.firstName} ${element.lastName} updated.`
					);
				} else {
					this.setState(
						{
							element: await this.execute(
								this.service.post(element)
							),
						},
						() =>
							this.success(
								`Superior ${element.firstName} ${element.lastName} created.`
							)
					);
				}

				if (navigate) {
					App.history.goBack();
				}
			}
		}
	}

	/* METHODS */

	/* RENDERING */
	render() {
		// Destructuring
		const { company, superior } = App.history.location.state;
		const { element } = this.state;

		// History and title
		let title = "New Superior";
		let history = [
			{ name: "Companies", path: "/dashboard/company" },
			{
				name: company.name,
				path: "/dashboard/company/detail",
				state: { company: company },
			},
			{
				name: "Superiors",
				path: "/dashboard/company/superior",
				state: { company: company },
			},
			{
				name: "New Superior",
				path: "/dashboard/company/superior/detail",
				state: App.history.location.state,
			},
		];

		if (superior != undefined) {
			title = `${superior.firstName} ${superior.lastName}`;
			history = [
				{ name: "Companies", path: "/dashboard/company" },
				{
					name: company.name,
					path: "/dashboard/company/detail",
					state: { company: company },
				},
				{
					name: "Superiors",
					path: "/dashboard/company/superior",
					state: { company: company },
				},
				{
					name: title,
					path: "/dashboard/company/superior/detail",
					state: App.history.location.state,
				},
			];
		}

		return (
			<UserContext.Consumer>
				{({ isInRole }) => (
					<DetailView
						fields={this.state.fields}
						handleSaved={this.handleSaved}
						className="col"
						style={{ ...this.style.container, marginTop: 0 }}
					>
						{/* PAGE ILLUSTRATION */}
						{superior != undefined ? (
							<img
								src="/assets/illu_superior_edit.png"
								style={{
									...this.style.illustration,
									height: 180,
									width: 741,
								}}
							/>
						) : (
							<img
								src="/assets/illu_superior_new.png"
								style={{
									...this.style.illustration,
									height: 233,
									width: 741,
								}}
							/>
						)}

						{/* PAGE HEADER */}
						<div style={this.style.headerContainer}>
							<>
								<HeaderView
									title={title}
									history={history}
								/>
							</>
							{superior != undefined && isInRole("admin") && (
								<div
									style={
										this.style.headerDeleteContainer
									}
								>
									<DeleteButtonView
										title="superior"
										handleDelete={() =>
											this.handleDelete(
												this.state.element
											)
										}
									/>
								</div>
							)}
						</div>

						{/* PAGE CONTENT */}
						<div>
							{/* GENERAL INFORMATION */}
							<div
								className="card shadow"
								style={this.style.card}
							>
								{/* TITLE */}
								<div
									className="card-header border-1"
									style={{
										display: "flex",
										flexDirection: "row",
										color: "#405364",
										fontSize: 24,
										justifyContent: "space-between",
									}}
								>
									<TranslationView>
										superiorDetailGeneralInformation
									</TranslationView>
								</div>
								<div className="card-body">
									<div className="row">
										{/* FIRSTNAME */}
										<div
											style={{
												margin: 10,
												marginLeft: "auto",
												width: 230,
											}}
										>
											<div
												style={
													this.style.inputTitle
												}
											>
												<TranslationView>
													superiorDetailFirstName
												</TranslationView>
											</div>
											<InputView
												placeholder="Amélie"
												type="text"
												value={element.firstName}
												onChange={(event) =>
													this.handleInputChanged(
														"firstName",
														event.target.value
													)
												}
											/>
										</div>

										{/* LASTNAME */}
										<div style={this.style.inputRight}>
											<div
												style={
													this.style.inputTitle
												}
											>
												<TranslationView>
													superiorDetailLastName
												</TranslationView>
											</div>
											<InputView
												placeholder="Meunier"
												type="text"
												value={element.lastName}
												onChange={(event) =>
													this.handleInputChanged(
														"lastName",
														event.target.value
													)
												}
											/>
										</div>

										{/* EMAIL */}
										<div style={this.style.inputLeft}>
											<div
												style={
													this.style.inputTitle
												}
											>
												<TranslationView>
													superiorDetailEmail
												</TranslationView>
											</div>
											<InputView
												disabled={
													superior != undefined
												}
												error={
													this.state.errors[
														"email"
													]
												}
												placeholder="amelie@medicheck.io"
												type="text"
												value={element.email}
												onChange={(event) =>
													this.handleInputChanged(
														"email",
														event.target.value
													)
												}
											/>
										</div>

										{/* PHONE */}
										<div style={this.style.inputRight}>
											<div
												style={
													this.style.inputTitle
												}
											>
												<TranslationView>
													superiorDetailPhone
												</TranslationView>
											</div>
											<InputView
												placeholder="02 897 20 01"
												type="text"
												value={element.phoneNumber}
												onChange={(event) =>
													this.handleInputChanged(
														"phoneNumber",
														event.target.value
													)
												}
											/>
										</div>
									</div>
									<div className="row">
										{/* MAIN LANGUAGE */}
										<div style={this.style.inputLeft}>
											<div
												style={
													this.style.inputTitle
												}
											>
												<TranslationView>
													superiorDetailMainLanguage
												</TranslationView>
											</div>
											<InputView
												error={
													this.state.errors[
														"language"
													]
												}
												placeholder="Select a language"
												type="select"
												value={element.language}
												onChange={(event) =>
													this.handleInputChanged(
														"language",
														event.target.value
													)
												}
											>
												<option value={"FR"}>
													FR
												</option>
												<option value={"NL"}>
													NL
												</option>
												<option value={"EN"}>
													EN
												</option>
											</InputView>
										</div>

										{/* MAIN LANGUAGE */}
										<div style={this.style.inputRight}>
											<div
												style={
													this.style.inputTitle
												}
											>
												<TranslationView>
													superiorDetailRequestForm
												</TranslationView>
											</div>
											<InputView
												placeholder="Select an option"
												type="select"
												value={`${element.requestForm}`}
												onChange={(event) =>
													this.handleInputChanged(
														"requestForm",
														event.target.value
													)
												}
											>
												<option value={0}>
													One Shot
												</option>
												<option value={1}>
													Check Request
												</option>
												<option value={2}>
													Interface Basic
												</option>
												<option value={3}>
													Interface Admin
												</option>
											</InputView>
										</div>

										{/* TRIPPLE INPUT FILLER */}
										<div
											style={this.style.inputDouble}
										/>
									</div>
								</div>
							</div>

							{/* BUTTON CONTAINER */}
							<div style={this.style.buttonContainer}>
								<DoubleClickButtonView
									isBusy={this.state.isBusy}
									onClick={
										this.handleSendEncodingConfirmation
									}
									width={300}
									image="icon_double_confirmation.svg"
								>
									sendEncodingConfirmation
								</DoubleClickButtonView>
								<SaveButtonView
									isBusy={this.state.isBusy}
									handleSaved={this.handleSaved}
								/>
							</div>

							{(element.requestForm === 2 || element.requestForm === 3) &&
								<div style={this.style.resetPasswordButtonContainer}>
									<DoubleClickButtonView
										isBusy={this.state.isBusy}
										onClick={
											this.handleResetPassword
										}
										width={211}
										image="icon_double_confirmation.svg"
									>
										resetPassword
									</DoubleClickButtonView>
								</div>
							}
						</div>
					</DetailView>
				)}
			</UserContext.Consumer>
		);
	}
}
