import './style.ts';
import React, { useState } from 'react';
import Lottie from 'lottie-react-web';
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';
import { motion } from 'framer-motion';
import IntegrationLogViewStyle from './style';
import { IIntegrationProps } from './interfaces';
import DateHelper from 'src/helpers/DateHelper';

function IntegrationLogView(props: IIntegrationProps) {
    // Attributes
    const { integration, onClick } = props;
    const [open, setOpen] = useState(onClick !== undefined);
    const [isBusy, setIsBusy] = useState(false);
    const style = new IntegrationLogViewStyle();
    const [sendHovered, setSendHovered] = useState(false);
    const [badgeHovered, setBadgeHovered] = useState(false);

    /* HANDLERS */
    function handleSend() {
        const { onClick } = props;

        if (onClick && !isBusy) {
            onClick(props.integration);
            setIsBusy(true);
        }
    }

    function handleDelete() {
        const { onDelete } = props;

        if (onDelete && !isBusy) {
            onDelete(props.integration);
            setIsBusy(true);
        }
    }

    function handleSendHovered() {
        setSendHovered((sendHovered) => !sendHovered);
    }

    function handleBadgeHovered() {
        if (props.onClick) {
            setBadgeHovered((badgeHovered) => !badgeHovered);
        }
    }

    // METHODS
    function mapColors() {
        return { backgroundColor: '#C0D3E0' };
    }

    // RENDER
    return (
        <motion.div initial={{ x: window.innerWidth - 517 - 80 }} animate={{ x: 0 }}>
            <LocalizationContext.Consumer>
                {({ translate }) => (
                    <div key={integration.id} style={{ ...style.basicLogContainer }}>
                        <div style={style.basicLog}>

                            {/* STAMP RENDERING */}
                            <Stamp log={props.log} style={style} />

                            <div style={{ ...style.commentLogText, ...mapColors(), borderTopLeftRadius: 4, borderTopRightRadius: 4, height: 38, borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}>
                                <div style={{ float: 'left' }}>
                                    {translate(integration.title)}
                                </div>
                                <div onClick={() => setOpen(!open)} style={{ float: 'right', cursor: 'pointer' }}>
                                    {open ? '-' : '+'}
                                </div>
                            </div>

                            {/* LOG RENDERING */}
                            <div style={{ ...style.logText, ...mapColors(), display: open ? '' : 'none' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ ...style.logTitleText, marginTop: 5, width: 85 }}>
                                        Recipient :
                                    </div>
                                    <div style={{ ...style.logBodyText, width: '100%', fontSize: 12, padding: 6, maxWidth: 'calc(100% - 75px)' }}>
                                        {integration.recipient}
                                    </div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ ...style.logTitleText, marginTop: 5, width: 85 }}>
                                        Message :
                                    </div>
                                    <div style={{ ...style.logBodyText, width: '100%', fontSize: 12, padding: 6, marginBottom: 0, maxWidth: 'calc(100% - 75px)' }}>

                                        <div style={{ WebkitUserSelect: 'text' }}>
                                            {translate('externalSystemMessageBody')}
                                        </div>

                                        {props.onClick && (
                                            <div onMouseEnter={handleSendHovered} onMouseLeave={handleSendHovered} onDoubleClick={handleSend} style={{ cursor: 'pointer', position: 'relative', ...mapColors(), height: 34, width: 34, borderRadius: 17, float: 'right', transform: 'translate(10px, 10px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {!isBusy ?
                                                    (
                                                        <img src={`/assets/button_send_neutral${sendHovered ? '_hovered' : ''}.svg`} style={{ height: 30, width: 30 }} />
                                                    )
                                                    :
                                                    (
                                                        <Lottie options={{ animationData: require('../assets/loading.json') }} />
                                                    )
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>

                        </div>

                        {/* BADGE RENDERING */}
                        <Badge log={props.log} handleBadgeHovered={handleBadgeHovered} handleDelete={handleDelete} badgeHovered={badgeHovered} style={style} mapColors={mapColors} />
                    </div>
                )}
            </LocalizationContext.Consumer>
        </motion.div>
    );
}

function Stamp({ log, style }) {
    if (log) {
        if (log.user) {
            return (
                <span style={style.basicStampRight}>
                    {`${log.user.firstName} ${log.user.lastName} (Medicheck) ${DateHelper.formatDate(log.creationDate)}`}
                </span>
            );
        }
    }

    return (
        <span style={style.basicStampRight}>
            {`...`}
        </span>
    )
}

function Badge({ log, handleBadgeHovered, handleDelete, badgeHovered, style, mapColors }) {
    if (log) {
        if (log.user) {
            return (
                <div style={style.badgeContainer}>
                    <span style={style.badgeText}>
                        {`${log.user.firstName.charAt(0)}${log.user.lastName.charAt(0)}`}
                    </span>
                </div>
            );
        }
    }

    return (
        <div onMouseEnter={handleBadgeHovered} onMouseLeave={handleBadgeHovered} onClick={handleDelete} style={{ ...style.badgeContainer, ...mapColors(), cursor: 'pointer', padding: badgeHovered ? 0 : 10 }}>
            {badgeHovered ?
                (
                    <img src="/assets/button_delete_certif_hovered.png" style={{ height: 40, width: 40 }} />

                )
                :
                (
                    <img src="/assets/icon_user.svg" style={style.userIcon} />
                )}
        </div>
    );
}

export default IntegrationLogView;