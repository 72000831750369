import React, { useState, useEffect } from "react";
import { CheckContext } from "./CheckContext";
import CheckService from "src/services/CheckService";
import RequestLogService from "src/services/RequestLogService";
import CheckIntegrationService from "src/services/CheckIntegrationService";
import CheckCommunicationService from "src/services/CheckCommunicationService";
import AlertHelper from "src/helpers/AlertHelper";
import RequestService from "src/services/RequestService";
import cloneDeep from "lodash/cloneDeep";

function CheckContextProvider(props) {
	// Attirbutes

	const [logs, setLogs] = useState([]);
	const [check, setCheck] = useState(undefined);
	const [integrations, setIntegrations] = useState([]);
	const [communications, setCommunications] = useState([]);

	// Effects
	useEffect(() => {
		handleRefreshAll();
	}, []);

	// Handlers
	function handleInputChanged(targets: string, value: string) {
		const newCheck = cloneDeep(check);
		let element = newCheck;
		let newTarget = targets;
		if (newTarget.includes(".")) {
			const targetArray = newTarget.split(".");
			targetArray.forEach((target, index) => {
				if (index < targetArray.length - 1) {
					element = element[target];
				} else {
					newTarget = target;
				}
			});
		}
		element[newTarget] = value;
		setCheck(newCheck);
	}

	async function handleUpdateRequest() {
		await new RequestService().put(check.request);
		AlertHelper.success(`Request data has been synced.`);
		handleRefreshAll();
	}

	async function handleUpdateCheck() {
		await new CheckService().put(check);
		AlertHelper.success(`Check data has been synced.`);
		handleRefreshAll();
	}

	function handleRefreshAll() {
		handleRefreshCheck();
		handleRefreshCommunications();
		handleRefreshIntegrations();
		handleRefreshLogs();
	}

	async function handleRefreshIntegrations() {
		setIntegrations(await new CheckIntegrationService().get(props.check.request.superior.companyId));
	}

	async function handleRefreshCommunications() {
		setCommunications(await new CheckCommunicationService().get(props.check.id));
	}

	async function handleRefreshLogs() {
		setLogs(await new RequestLogService().get(props.check.request.id));
	}

	async function handleRefreshCheck() {
		setCheck(await new CheckService().get(props.check.id));
	}

	// Methods
	function computeValue() {
		return {
			check: check,
			logs: logs,
			integrations: integrations,
			communications: communications,
			handleUpdateRequest: handleUpdateRequest,
			handleUpdateCheck: handleUpdateCheck,
			handleRefreshCheck: handleRefreshCheck,
			handleRefreshLogs: handleRefreshLogs,
			handleRefreshIntegrations: handleRefreshIntegrations,
			handleRefreshCommunications: handleRefreshCommunications,
			handleInputChanged: (targets: string, value: string) => handleInputChanged(targets, value),
		};
	}

	// Render
	return <CheckContext.Provider value={computeValue()}>{props.children}</CheckContext.Provider>;
}

export default CheckContextProvider;
