import Axios from 'axios';
import CheckDTO from 'src/models/CheckDTO';
import Service from './Service';

function put(checkId: string, check: CheckDTO) {
    const service = new Service();

    return Axios.put(`${service.getUrl('v2')}/checks/${checkId}/boards`, check, service.config());
}

export default { put };