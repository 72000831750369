import Service from './Service';
import Axios from 'axios';

export default class DoctorExportService extends Service
{
    /* METHODS */
    get()
    {        
        return this.execute(Axios.get(`${this.url}/doctors/exports`, this.config()), "Unable to retrieve doctors exports.");
    }
}