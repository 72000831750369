import { read } from 'fs';
import React, { useEffect, useState } from 'react';
import { App } from 'src/app';
import TranslationView from 'src/components/translationView/TranslationView';
import AlertHelper from 'src/helpers/AlertHelper';
import { downloadCSV, flattenObjectToArray } from 'src/helpers/ExportHelper';
import CompanyConfigurationSuperiorService from 'src/services/CompanyConfigurationSuperiorService';
import DropZone from '../dropzone';
import csv from 'csv';
import './style.css';
import SuperiorDTO from 'src/models/SuperiorDTO';
import MyCertificateSuperiorDTO from 'src/dtos/MyCertificateSuperiorDTO';
import UploadButtonProps from './props';
import CompanyConfigurationListService from 'src/services/CompanyConfigurationListService';
import MyCertificateListDTO from 'src/dtos/MyCertificateListDTO';
import BusyIndicator from 'src/modules/dashboard/components/busyIndicator';

const UploadButton = ({ title, type }: UploadButtonProps) => {
  //Attributes
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  // Handlers
  async function handleOnDownload() {
    try {
      setIsDownloadLoading(true);
      switch (type) {
        case 'superior':
          await handleSuperiorDownload();
          break;
        case 'black-list':
          await handleListDownload('black');
          break;
        case 'white-list':
          await handleListDownload('white');
          break;
      }
    } finally {
      setIsDownloadLoading(false);
    }
  }

  async function handleListDownload(type: string) {
    const companyId = App.history.location.state.company.id;

    const lists = await new CompanyConfigurationListService().get(
      companyId,
      type
    );

    const rows = [['matricule', 'firstName', 'lastName']];

    lists?.map((list) => {
      const arr = [];
      rows[0].forEach((key) => arr.push(list[key]));
      rows.push(arr);
    });

    let csvContent = '';

    rows.forEach((rowArray) => {
      let row = rowArray.join(';');
      csvContent += row + '\r\n';
    });

    downloadCSV(csvContent, `${companyId}-${type}-list`);
  }

  async function handleSuperiorDownload() {
    const companyId = App.history.location.state.company.id;

    const superiors = await new CompanyConfigurationSuperiorService().get(
      companyId
    );

    const rows = [['fullName', 'emails', 'phoneNumber', 'language']];

    superiors?.map((superior) => {
      const arr = [];
      rows[0].forEach((key) => arr.push(superior[key]));
      rows.push(arr);
    });

    let csvContent = '';

    rows.forEach((rowArray) => {
      let row = rowArray.join(';');
      csvContent += row + '\r\n';
    });

    downloadCSV(csvContent, `${companyId}-superiors`);
  }

  async function handleListUpload(file: any, type: string) {
    const companyId = App.history.location.state.company.id;

    const reader = new FileReader();
    reader.onload = () => {
      // Parse CSV file
      const parser = csv as any;

      parser.parse(
        reader.result,
        { delimiter: ';', encoding: 'utf8', skipEmptyLines: true },
        async (err, data) => {
          if (err) {
            console.error('Error parsing CSV file', err);
            return;
          }

          const lists: MyCertificateListDTO[] = [];
          for (let i = 1; i < data.length; i++) {
            const matricule = data[i][0];
            const firstName = data[i][1];
            const lastName = data[i][2];

            // Skip if matricule, firstName, and lastName are all empty
            if (
              (!matricule || matricule === '') &&
              (!firstName || firstName === '') &&
              (!lastName || lastName === '')
            ) {
              continue;
            }

            const blackListedUser: MyCertificateListDTO = {
              firstName: firstName || '',
              lastName: lastName || '',
              matricule: matricule || '',
            };
            lists.push(blackListedUser);
          }

          await new CompanyConfigurationListService().put(companyId, type, {
            lists,
          });
          setIsUploadLoading(false);
          AlertHelper.success('File uploaded');
        }
      );
    };

    reader.readAsText(file);
  }

  async function handleSuperiorUpload(file) {
    const companyId = App.history.location.state.company.id;

    const reader = new FileReader();

    reader.onload = () => {
      // Parse CSV file
      const parser = csv as any;
      parser.parse(
        reader.result,
        { delimiter: ';', encoding: 'utf8' },
        async (err, data) => {
          const superiors: MyCertificateSuperiorDTO[] = [];
          for (let i = 1; i < data.length; i++) {
            const superior: MyCertificateSuperiorDTO = {
              fullName: data[i][0],
              emails: data[i][1],
              phoneNumber: data[i][2],
              language: data[i][3],
            };
            superiors.push(superior);
          }

          await new CompanyConfigurationSuperiorService().put(companyId, {
            superiors,
          });

          setIsUploadLoading(false);
          AlertHelper.success('File uploaded');
        }
      );
    };

    reader.readAsText(file);
  }

  async function handleUpload(file) {
    setIsUploadLoading(true);
    switch (type) {
      case 'superior':
        await handleSuperiorUpload(file);
        break;
      case 'black-list':
        await handleListUpload(file, 'black');
        break;
      case 'white-list':
        await handleListUpload(file, 'white');
        break;
    }
  }

  return (
    <div className='card shadow' style={{ marginBottom: 35, width: 1047 }}>
      <div className='company-certificate-upload-card-container'>
        <div className='company-certificate-upload-button-title'>{title}</div>
        <div className='company-certificate-upload-button-end'>
          <div
            className='company-certificate-upload-button-container'
            onClick={handleOnDownload}
          >
            <BusyIndicator
              height={20}
              width={210}
              isLoading={isDownloadLoading}
            >
              <img
                src='/assets/icon_download.svg'
                className='img-base-primary'
                style={{ width: 20, height: 20, marginRight: 15 }}
              />
              <TranslationView>DownloadLastVersion</TranslationView>
            </BusyIndicator>
          </div>

          <div style={{ marginRight: 13 }} />
          <div className='company-certificate-upload-button-container'>
            <DropZone handleUpload={handleUpload} isLoading={isUploadLoading} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadButton;
