export default abstract class DetailComponentStyle {
  cardHeader: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    color: '#405364',
    fontSize: 24,
  };

  inputHalf: React.CSSProperties = {
    margin: 15,
    width: 100,
  };

  inputHalfLeft: React.CSSProperties = {
    ...this.inputHalf,
    marginLeft: 'auto',
  };

  inputHalfRight: React.CSSProperties = {
    ...this.inputHalf,
    marginRight: 'auto',
  };

  inputLeft: React.CSSProperties = {
    margin: 10,
    marginLeft: 'auto',
    width: 230,
  };

  inputRight: React.CSSProperties = {
    margin: 10,
    marginRight: 'auto',
    width: 230,
  };

  inputDouble: React.CSSProperties = {
    margin: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 480,
  };

  inputFull: React.CSSProperties = {
    margin: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 995,
  };

  inputTriple: React.CSSProperties = {
    margin: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 740,
  };

  inputTitle: React.CSSProperties = {
    color: '#68737E',
    marginBottom: 5,
    fontSize: 16,
  };

  container: React.CSSProperties = {
    marginTop: 30,
    width: 1047,
  };

  illustration: React.CSSProperties = {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: 250,
    height: 445,
  };

  card: React.CSSProperties = {
    marginTop: 30,
    width: 1047,
  };

  input: React.CSSProperties = {
    width: 230,
  };

  headerContainer: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  };

  headerDeleteContainer: React.CSSProperties = {
    marginLeft: 'auto',
    alignSelf: 'center',
  };
}
