import { App } from "src/app";
import * as React from "react";
import { Switch, Route, Redirect } from "react-router";
import ScheduleView from "./modules/schedule/index";
import CheckDetailView from "./modules/detail/CheckDetailView";
import CheckContextProvider from "../../components/checkContext/CheckContextProvider";

export default class CheckDetailShellView extends React.Component {
	/* RENDERING */
	render() {
		return (
			<CheckContextProvider check={App.history.location.state.check}>
				<Switch>
					<Route exact path="/dashboard/(check|request|archive)/detail" render={() => <CheckDetailView />} />
					<Route exact path="/dashboard/(check|request|archive)/schedule" render={() => <ScheduleView />} />
					<Route path="/dashboard/(check|request|archive)" render={() => <Redirect to="/dashboard/(check|request|archive)" />} />
				</Switch>
			</CheckContextProvider>
		);
	}
}
