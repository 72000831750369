import React from 'react';
import { App } from 'src/app';
import AlertHelper from 'src/helpers/AlertHelper';
import UserDTO from 'src/models/UserDTO';
import DeleteButtonView from 'src/modules/dashboard/components/deleteButtonView/DeleteButtonView';
import HeaderView from 'src/modules/dashboard/components/headerView/HeaderView';
import UserService from 'src/services/UserService';
import './style.css';

const Header = ({ user }) => {

    // Attributes
    const userService = new UserService()
    let title = 'New User';
    let history = [{ name: "Users", path: "/dashboard/setting" }, { name: "New User", path: "/dashboard/setting/detail", state: {} }];

    if (App.history.location.state != undefined) {
        title = `${user?.firstName} ${user?.lastName}`;
        history = [{ name: "Users", path: "/dashboard/setting" }, { name: title, path: "/dashboard/setting/detail", state: { user } }];
    }

    // Methods
    async function handleDelete(user: UserDTO) {
        await userService.delete(user.id);
        AlertHelper.success(`User ${user.email} deleted.`)
        App.history.push('/dashboard/setting');
    }

    return (
        <div className="settings-detail-header-container">
            <>
                <HeaderView title={title} history={history} />
            </>
            {
                App.history.location.state != undefined &&
                (
                    <div className="settings-detail-header-delete-container">
                        <DeleteButtonView title='user' handleDelete={() => handleDelete(user)} />
                    </div>
                )
            }
        </div >
    )
}

export default Header;