import * as React from "react";
import { CheckContext } from "src/modules/dashboard/components/checkContext/CheckContext";
import { motion } from "framer-motion";
import { Spring } from "react-spring";
import NetworkHelper from "src/helpers/NetworkHelper";
import ArchiveService from "src/services/ArchiveService";
import CheckService from "src/services/CheckService";
import RequestDuplicationService from "src/services/RequestDuplicationService";
import AlertHelper from "src/helpers/AlertHelper";
import PopUpView from "src/modules/dashboard/components/popUpView/PopUpView";
import UserContext from "src/components/userContext/UserContext";
import CheckAutomaticPauseService from "src/services/CheckAutomaticPauseService";
import CheckAutomaticPlayService from "src/services/CheckAutomaticPlayService";
import CheckAssignService from "src/services/CheckAssignService";
import CheckCommunicationsView from "../../v2/CheckCommunicationsView";
import CheckChat from "src/modules/dashboard/components/check-chat";
import { useQuery } from "react-query";
import CheckMessageService from "src/services/CheckMessageService";
import PopUp from "src/components/pop-up";
import { App } from "src/app";

const ToolBar = () => {
	const { check, handleRefreshCheck, handleRefreshCommunications, handleRefreshIntegrations, handleRefreshLogs } = React.useContext(CheckContext);

	const [isBusy, setIsBusy] = React.useState(false);
	const [isRelaunchModalOpen, setIsRelaunchModalOpen] = React.useState(false);
	const [
		isRelaunchWithinTwentyFourHoursModalOpen,
		setIsRelaunchWithinTwentyFourHoursModalOpen,
	] = React.useState(false);
	const [isRefreshHovered, setIsRefreshHovered] = React.useState(false);
	const [isLaunchHovered, setIsLaunchHovered] = React.useState(false);
	const [
		isLaunchWithinTwentyFourHoursHovered,
		setIsLaunchWithinTwentyFourHoursHovered,
	] = React.useState(false);
	const [isUnArchiveHovered, setIsUnArchiveHovered] = React.useState(false);
	const [isPauseHovered, setIsPauseHovered] = React.useState(false);
	const [isPlayHovered, setIsPlayHovered] = React.useState(false);
	const [isAssignHovered, setIsAssignHovered] = React.useState(false);
	const [isChatHovered, setIsChatHovered] = React.useState(false);
	const [isChatOpen, setIsChatOpen] = React.useState(false);
	const [isDeleteHovered, setIsDeleteHovered] = React.useState(false);
	const [isPopUpOpen, setIsPopUpOpen] = React.useState(false);

	// Queries
	const query = useQuery(`${check.id}-comments`, refreshComments);

	// Methods
	async function refreshComments() {
		const response = await new CheckMessageService().get(check.id);
		return response.data;
	}

	async function handleAssignClicked() {
		await NetworkHelper.execute(new CheckAssignService().put(check));
		AlertHelper.success("Check assigned.");
		refreshCheck();
	}

	async function unArchiveCheck() {
		await NetworkHelper.execute(new ArchiveService().delete(check.id));
		AlertHelper.success("Check unarchived.");
		refreshCheck();
	}

	async function handlePlayClicked() {
		await NetworkHelper.execute(new CheckAutomaticPlayService().post(check.id, check));
		AlertHelper.success("Check succesfully reactivated.");
		refreshCheck();
	}

	async function handlePauseClicked() {
		await NetworkHelper.execute(new CheckAutomaticPauseService().post(check.id, check));
		AlertHelper.success("Check succesfully paused.");
		refreshCheck();
	}

	async function handleChatClicked() {
		setIsChatOpen(true);
	}

	async function refreshCheck() {
		setIsBusy(true);
		await handleRefreshCheck();
		await handleRefreshIntegrations();
		await handleRefreshCommunications();
		await handleRefreshLogs();
		setIsBusy(false);
		AlertHelper.success("Check refreshed.");
	}

	async function launchCheck() {
		await NetworkHelper.execute(new RequestDuplicationService().post(check.id));
		AlertHelper.success("Check duplicated.");
	}

	async function launchCheckWithinTwentyFourHours() {
		await NetworkHelper.execute(new RequestDuplicationService().post(check.id));
		AlertHelper.success("Check duplicated.");
	}

	async function deleteCheck() {
		const response = await NetworkHelper.execute(new CheckService().delete(check.id));
		AlertHelper.success("Check deleted.");
		App.history.goBack();
		refreshCheck();
	}

	return (
		<UserContext.Consumer>
			{({ isInRole }) => (
				<>
					<div style={{ zIndex: 998, position: "absolute", top: -27, right: check.state === 0 ? 30 : 90, background: "white", borderRadius: 20, width: "fit-content", padding: 5, height: 45, border: 1, borderColor: "red", display: "flex", flexDirection: "row", alignItems: "center" }}>
						{check.state === 0 && (
							<motion.div onClick={unArchiveCheck} whileTap={{ scale: 0.9 }} onMouseEnter={() => setIsUnArchiveHovered(true)} onMouseOut={() => setIsUnArchiveHovered(false)} style={{ height: 35, width: 35, cursor: "pointer", marginRight: 5 }}>
								{isUnArchiveHovered ? <img src="/assets/icon_unarchive_check_clicked.svg" style={{ height: 35, width: 35 }} /> : <img src="/assets/icon_unarchive_check.svg" style={{ height: 35, width: 35 }} />}
							</motion.div>
						)}

						{check.state > 0 && (
							<motion.div onMouseEnter={() => setIsAssignHovered(true)} onMouseLeave={() => setIsAssignHovered(false)} whileTap={{ scale: 0.9 }} style={{ height: 35, width: 35, marginRight: 5 }}>
								{check.operatorInitials && !isAssignHovered ? (
									<span
										style={{
											marginTop: 2.5,
											marginLeft: 2.5,
											height: 30,
											width: 30,
											borderRadius: 15,
											backgroundColor: "#90A7B8",
											color: "white",
											fontWeight: 400,
											fontSize: 14,
											display: "flex",
											flexDirection: "row",
											justifyContent: "center",
											alignContent: "center",
											alignItems: "center",
											paddingTop: 1,
											paddingLeft: 1,
										}}
									>
										{check.operatorInitials}
									</span>
								) : (
									<span
										onClick={handleAssignClicked}
										style={{
											width: 35,
											height: 35,
											fill: "white",
											color: "white",
											margin: 0,
											cursor: "pointer",
											transform: "translateY(-1px)",
										}}
									>
										<img
											src={`/assets/icon_assign_to_me${isAssignHovered ? "_selected" : ""}_toolbar.svg`}
											style={{
												width: 35,
												height: 35,
												fill: "white",
												color: "white",
												margin: 0,
												cursor: "pointer",
											}}
										/>
									</span>
								)}
							</motion.div>
						)}

						{isInRole("admin") && check.state !== 0 && (
							<motion.div onClick={() => setIsPopUpOpen(true)} whileTap={{ scale: 0.9 }} onMouseEnter={() => setIsDeleteHovered(true)} onMouseOut={() => setIsDeleteHovered(false)} style={{ height: 35, width: 35, cursor: "pointer", marginRight: 5 }}>
								{isDeleteHovered ? <img src="/assets/icon_delete_Check_toolbar_selected.svg" style={{ height: 35, width: 35 }} /> : <img src="/assets/icon_delete_Check_toolbar.svg" style={{ height: 35, width: 35 }} />}
							</motion.div>
						)}

						{check.state !== 0  ? (
							<motion.div onClick={() => setIsRelaunchWithinTwentyFourHoursModalOpen(true)} whileTap={{ scale: 0.9 }} onMouseEnter={() => setIsLaunchWithinTwentyFourHoursHovered(true)} onMouseOut={() => setIsLaunchWithinTwentyFourHoursHovered(false)} style={{ height: 30, width: 30, cursor: "pointer", marginRight: 5 }}>
								{isLaunchWithinTwentyFourHoursHovered ? <img src="/assets/icon_relaunch_check_24_hours_selected.svg" style={{ height: 30, width: 30 }} /> : <img src="/assets/icon_relaunch_check_24_hours.svg" style={{ height: 30, width: 30 }} />}
							</motion.div>
						) : (
							<motion.div onClick={() => setIsRelaunchModalOpen(true)} whileTap={{ scale: 0.9 }} onMouseEnter={() => setIsLaunchHovered(true)} onMouseOut={() => setIsLaunchHovered(false)} style={{ height: 35, width: 35, cursor: "pointer", marginRight: 5 }}>
								{isLaunchHovered ? <img src="/assets/icon_relaunch_check_clicked.svg" style={{ height: 35, width: 35 }} /> : <img src="/assets/icon_relaunch_check.svg" style={{ height: 35, width: 35 }} />}
							</motion.div>
						)}

						<motion.div onClick={handleChatClicked} whileTap={{ scale: 0.9 }} onMouseEnter={() => setIsChatHovered(true)} onMouseOut={() => setIsChatHovered(false)} style={{ height: 35, width: 35, cursor: "pointer", marginRight: 5 }}>
							{query.data && query.data.length > 0 ? <>{isChatHovered ? <img src="/assets/icon_view_comment_selected.svg" style={{ height: 35, width: 35,  }} /> : <img src="/assets/icon_view_comment.svg" style={{ height: 35, width: 35 }} />}</> : <>{isChatHovered ? <img src="/assets/icon_start_chat_selected.svg" style={{ height: 35, width: 35 }} /> : <img src="/assets/icon_start_chat.svg" style={{ height: 35, width: 35 }} />}</>}
						</motion.div>

						{check.state > 0 && check.state < 8 && (
							<motion.div onClick={handlePauseClicked} whileTap={{ scale: 0.9 }} onMouseEnter={() => setIsPauseHovered(true)} onMouseOut={() => setIsPauseHovered(false)} style={{ height: 35, width: 35, cursor: "pointer", marginRight: 5 }}>
								{isPauseHovered ? <img src="/assets/icon_pause_check_selected.svg" style={{ height: 35, width: 35 }} /> : <img src="/assets/icon_pause_check.svg" style={{ height: 35, width: 35 }} />}
							</motion.div>
						)}

						{check.state >= 8 && (
							<motion.div onClick={handlePlayClicked} whileTap={{ scale: 0.9 }} onMouseEnter={() => setIsPlayHovered(true)} onMouseOut={() => setIsPlayHovered(false)} style={{ height: 35, width: 35, cursor: "pointer", marginRight: 5 }}>
								{isPlayHovered ? <img src="/assets/icon_play_check_selected.svg" style={{ height: 35, width: 35 }} /> : <img src="/assets/icon_play_check.svg" style={{ height: 35, width: 35 }} />}
							</motion.div>
						)}

						<Spring from={{ rotation: isBusy ? 0 : 360 }} to={{ rotation: isBusy ? 360 : 0 }}>
							{(style: any) => {
								return (
									<motion.div onClick={refreshCheck} whileTap={{ scale: 0.9 }} onMouseEnter={() => setIsRefreshHovered(true)} onMouseOut={() => setIsRefreshHovered(false)} style={{ height: 35, width: 35, cursor: "pointer" }}>
										{isBusy || isRefreshHovered ? <img src="/assets/icon_reload_check_clicked.svg" style={{ height: 32.5, width: 32.5, transform: `rotate(${style.rotation}deg)` }} /> : <img src="/assets/icon_reload_check.svg" style={{ height: 32.5, width: 32.5, transform: `rotate(${style.rotation}deg)` }} />}
									</motion.div>
								);
							}}
						</Spring>
					</div>
					<PopUpView theme={"warning"} illustration={"question"} title={`areYouSureYouWantToRelaunch`} toggled={isRelaunchModalOpen || isRelaunchWithinTwentyFourHoursModalOpen} onToggle={() => {setIsRelaunchModalOpen(false); setIsRelaunchWithinTwentyFourHoursModalOpen(false);}} onIgnore={() => {}} onClick={isRelaunchModalOpen ? launchCheck : isRelaunchWithinTwentyFourHoursModalOpen ? launchCheckWithinTwentyFourHours : () => {}} actionTitle={`yesIAmSure`} ignoreTitle={"NoIDontWantTo"} />

					<CheckChat preload checkId={check.id} isOpen={isChatOpen} closeChat={() => setIsChatOpen(false)} />

					<PopUpView theme={"delete"} illustration={"warning"} title={`areYouSureToWantToPermanentDelete`} toggled={isPopUpOpen} onToggle={() => setIsPopUpOpen(false)} onIgnore={() => {}} onClick={deleteCheck} actionTitle={`deleteCheck`} ignoreTitle={"oopsNoGoBack"} />
				</>
			)}
		</UserContext.Consumer>
	);
};

export default ToolBar;
