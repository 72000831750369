import React from 'react';
import TranslationView from 'src/components/translationView/TranslationView';
import { CheckLaunchType } from 'src/dtos/MyCertificateConfigurationDTO';
import ToggleView from 'src/modules/dashboard/components/toggleView/ToggleView';
import { actions } from '../../../../reducer';
import { GeneralProps } from '../../props';
import MyCertificateInput from '../input';
import './style.css';

const LeftGroup = ({ dispatch, myCertificate }: GeneralProps) => {
  return (
    <div className='company-mycertificate-leftgroup-container'>
      <div className='company-mycertificate-rightgroup-row'>
        <div style={{ width: 200, marginRight: 30 }}>
          <MyCertificateInput
            target='checkLaunchType'
            label='Launch Check for ...'
            type='select'
            value={myCertificate.company.configuration.checkLaunchType ?? -1}
            handleOnChange={(event) =>
              dispatch({
                type: actions.configChanged,
                payload: {
                  target: 'checkLaunchType',
                  value: event.target.value,
                },
              })
            }
          >
            <option value={0}>Absence declaration</option>
            <option value={1}>Medical certificates</option>
          </MyCertificateInput>
        </div>
      </div>
      <div className='company-mycertificate-rightgroup-row'>
        <div style={{ width: 200, marginRight: 30 }}>
          <MyCertificateInput
            target='checkLaunchType'
            label='isNumberOfDayCriteriaActiveLabel'
            type='select'
            disabled={myCertificate.company.configuration.checkLaunchType === 0}
            value={
              myCertificate.company.configuration.checkLaunchType === 0
                ? ''
                : myCertificate.company.configuration
                    .isNumberOfDayCriteriaActive
            }
            handleOnChange={(event) =>
              dispatch({
                type: actions.configChanged,
                payload: {
                  target: 'isNumberOfDayCriteriaActive',
                  value: event.target.value,
                },
              })
            }
          >
            <option value={0}>No</option>
            <option value={1}>Yes</option>
          </MyCertificateInput>
        </div>
        <div style={{ width: 200, marginRight: 30 }}>
          <MyCertificateInput
            onBlur={() => {
              if (
                !myCertificate.company.configuration.numberOfDaysBeforeCheck
              ) {
                dispatch({
                  type: actions.configChanged,
                  payload: {
                    target: 'numberOfDaysBeforeCheck',
                    value: -1,
                  },
                });
              }
            }}
            disabled={
              myCertificate.company.configuration.checkLaunchType ===
                CheckLaunchType.ABSENCE_DECLARATION ||
              !myCertificate.company.configuration.isNumberOfDayCriteriaActive
            }
            target='numberOfDaysBeforeCheck'
            label='Number of day criteria'
            type='text'
            value={
              myCertificate.company.configuration.checkLaunchType ===
                CheckLaunchType.ABSENCE_DECLARATION ||
              myCertificate.company.configuration.numberOfDaysBeforeCheck < 0
                ? ''
                : myCertificate.company.configuration.numberOfDaysBeforeCheck
            }
            handleOnChange={(event) =>
              dispatch({
                type: actions.configChanged,
                payload: {
                  target: 'numberOfDaysBeforeCheck',
                  value: event.target.value,
                },
              })
            }
          />
        </div>
      </div>
      <div className='company-mycertificate-rightgroup-row'>
        <div style={{ width: 200, marginRight: 30 }}>
          <MyCertificateInput
            target='checkLaunchType'
            label='isFrequencyCriteriaActiveLabel'
            type='select'
            value={
              myCertificate.company.configuration.isFrequencyCriteriaActive
            }
            handleOnChange={(event) =>
              dispatch({
                type: actions.configChanged,
                payload: {
                  target: 'isFrequencyCriteriaActive',
                  value: event.target.value,
                },
              })
            }
          >
            <option value={0}>No</option>
            <option value={1}>Yes</option>
          </MyCertificateInput>
        </div>
      </div>
      <div className='company-mycertificate-rightgroup-row'>
        <div style={{ width: 200, marginRight: 30 }}>
          <MyCertificateInput
            onBlur={() => {
              if (!myCertificate.company.configuration.numberOfCertificates) {
                dispatch({
                  type: actions.configChanged,
                  payload: {
                    target: 'numberOfCertificates',
                    value: -1,
                  },
                });
              }
            }}
            target='numberOfCertificates'
            label='Frequency criteria (# certif)'
            type='text'
            disabled={
              !myCertificate.company.configuration.isFrequencyCriteriaActive
            }
            value={
              myCertificate.company.configuration.numberOfCertificates >= 0
                ? myCertificate.company.configuration.numberOfCertificates
                : ''
            }
            handleOnChange={(event) =>
              dispatch({
                type: actions.configChanged,
                payload: {
                  target: 'numberOfCertificates',
                  value: event.target.value,
                },
              })
            }
          />
        </div>
        <div style={{ width: 200 }}>
          <MyCertificateInput
            onBlur={() => {
              if (!myCertificate.company.configuration.numberOfDays) {
                dispatch({
                  type: actions.configChanged,
                  payload: {
                    target: 'numberOfDays',
                    value: -1,
                  },
                });
              }
            }}
            target='numberOfDays'
            label='Frequency criteria (# days)'
            type='text'
            disabled={
              !myCertificate.company.configuration.isFrequencyCriteriaActive
            }
            value={
              myCertificate.company.configuration.numberOfDays >= 0
                ? myCertificate.company.configuration.numberOfDays
                : ''
            }
            handleOnChange={(event) =>
              dispatch({
                type: actions.configChanged,
                payload: { target: 'numberOfDays', value: event.target.value },
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default LeftGroup;
