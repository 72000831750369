export default class HeaderViewStyle
{
    title: React.CSSProperties =
    {
        color:'#405364', 
        fontWeight:600, 
        fontSize:32, 
        lineHeight:'38px'
    }

    titleContainer: React.CSSProperties =
    {
        display:'flex', 
        flexDirection:'row'
    }

    historyContainer: React.CSSProperties =
    {
        color:'#90A7B8',
        marginTop:10
    }

    cursor: React.CSSProperties =
    {
        cursor:'pointer'
    }

    line: React.CSSProperties =
    {
        marginLeft:5
    }

    pageName: React.CSSProperties =
    {
        cursor:'pointer', 
        marginLeft:5
    }

    extraNavigation: React.CSSProperties =
    {
        marginLeft: 'auto'
    }
}