import React, { useState } from 'react';
import { InputView } from 'src/components/inputView/InputView';
import TranslationView from 'src/components/translationView/TranslationView';
import { MyCertificateInputProps } from './props'
import './style.css'

const MyCertificateInput = ({ disabled, label, value, handleOnChange, target, type, children }: MyCertificateInputProps) => {

    return (
        <div className="company-certificate-input-container">
            <div className='company-certificate-input-label'>
                <TranslationView>
                    {label}
                </TranslationView>
            </div>
            <InputView disabled={disabled} placeholder='' onChange={handleOnChange} type={type} value={value}>
                {children}
            </InputView>
        </div>
    )
}

export default MyCertificateInput;