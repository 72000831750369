import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import TranslationView from 'src/components/translationView/TranslationView';
import { DropzoneProps } from './props';
import BusyIndicator from 'src/modules/dashboard/components/busyIndicator';

const DropZone = ({ handleUpload, isLoading }: DropzoneProps) => {
  // Attributes
  const onDrop = useCallback((acceptedFiles) => {
    handleUpload(acceptedFiles[0]);
  }, []);

  const { getInputProps, getRootProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps({ style: { outline: 'none' } })}>
      <input {...getInputProps()} />
      <BusyIndicator height={20} width={60} isLoading={isLoading}>
        <TranslationView>Upload</TranslationView>
      </BusyIndicator>
    </div>
  );
};

export default DropZone;
