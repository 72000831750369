export default class AddressInputViewStyle
{
    autoCompleteContainer: React.CSSProperties = 
    {
        position:'absolute', 
        zIndex:1, 
        backgroundColor:'white', 
        padding:15, 
        paddingTop:15, 
        width:480, 
        minWidth:480, 
        maxWidth:480,
        borderRadius:4,
        border: '#fafafa solid 1px'
    }

    suggestionFocused: React.CSSProperties =
    {
        backgroundColor: '#fafafa', 
        cursor: 'pointer', 
        padding:3
    }

    suggestionUnFocused: React.CSSProperties =
    {
        backgroundColor: '#ffffff', 
        cursor: 'pointer', 
        padding:3
    }
}