export default class ToolBoxViewStyle
{
    toolBoxContainer: React.CSSProperties =
    {
        justifyContent:'space-evenly', 
        transform:'translate(1px, -1px)' , 
        position:'fixed', 
        zIndex: 999, 
        top:0, 
        right:0, 
        height:100, 
        width:100, 
        display: 'flex', 
        flexWrap:'wrap', 
        flexDirection: 'row'
    }

    toolBoxContainerWhite: React.CSSProperties =
    {
        ...this.toolBoxContainer,
        backgroundColor:'white',
        border: '1px solid #C0D3E0',
        overflowY: 'scroll'
    }

    toolBox: React.CSSProperties =
    {
        marginTop: 20,
        margin: 'auto', 
        height:45, 
        width:45, 
        borderRadius:22.5, 
        backgroundColor:'#C0D3E0', 
        cursor: 'pointer'
    }

    toolBoxIcon: React.CSSProperties = 
    {
        margin: 'auto', 
        height:45, 
        width:45, 
        borderRadius:22.5, 
        backgroundColor:'#C0D3E0', 
        cursor: 'pointer'
    }

    toolBoxClosed: React.CSSProperties =
    {
        width: 100, 
        height: 100
    }

    toolBoxOpen: React.CSSProperties =
    {
        width: window.innerWidth-730, 
        height: window.innerHeight,
        padding: 50,
        overflowY: 'scroll'
    }

    toolBoxBody: React.CSSProperties =
    {
        width:'100%',
        overflowY: 'scroll'
    }

    searchContainer: React.CSSProperties =
    {
        marginRight: 'auto', 
        width:250
    }

    searchIcon: React.CSSProperties =
    {
        width:20, 
        height:20, 
        filter:'invert(83%) sepia(10%) saturate(107%) hue-rotate(169deg) brightness(100%) contrast(82%)'
    }

    communicationContainer: React.CSSProperties =
    {
        width:'100%', 
        marginTop: 20
    }

    communicationBody: React.CSSProperties =
    {
        marginTop: 20, 
        justifyContent:'space-evenly', 
        height:'auto', 
        width:"100%", 
        backgroundColor:'white', 
        display: 'flex', 
        flexWrap:'wrap', 
        flexDirection: 'row'
    }
}