import React from 'react';
import Card, { cardMetric } from '../card';
import './style.css';

const Group5 = ({ data }) => {

    return (
        <div className='dashboard-group-container'>
            <Card title='Requested Checks' total={data.requestedChecks} color='rgba(144,167,184,0.4)' />
            <Card title='Cancelled Checks' total={data.cancelledChecks} color='red' percentage={15} />
            <Card title='Non-operable Checks' total={data.nonOperableChecks} color='green' percentage={8} />
            <Card title='Operated Checks' total={data.days} color='rgba(144,167,184,0.4)' metric={cardMetric.DAYS} />
            <Card title='Operated Checks' total={data.days} color='rgba(144,167,184,0.4)' metric={cardMetric.DAYS} />
        </div>
    )
}

export default Group5;