import * as React from 'react';
import { Alert } from 'reactstrap';
import { Spring } from 'react-spring';
import AlertableViewStyle from './AlertableViewStyle';
import AlertableViewState from './AlertableViewState';
import TranslationView from '../translationView/TranslationView';

export class AlertableView extends React.Component<any, AlertableViewState>
{
	/* ATTRIBUTES */
	timeOut: any;
	style: AlertableViewStyle = new AlertableViewStyle();

	/* CONSTRUCTOR */
	constructor(props: any) {
		super(props);

		// INITIAL STATE
		this.state = { alert: false, alertMessage: '', alertType: '' };

		// Bindings
		this.setTimeOutAlert = this.setTimeOutAlert.bind(this);
	}

	/* LIFE CYCLE EVENTS */
	componentDidMount() {
		window.alert = (data: any) => { this.setState({ alert: true, alertMessage: data.message + "", alertType: data.type }, this.setTimeOutAlert) };
	}

	/* METHODS */
	setTimeOutAlert() {
		if (this.timeOut !== undefined) {
			clearTimeout(this.timeOut);
		}

		this.timeOut = setTimeout(() => this.setState({ alert: false }), 5000)
	}

	mapMessage(message: any) {
		if (message.error_description !== undefined) {
			message = message.error_description;
		}

		return message;
	}

	/* RENDERING */
	render() {

		return (
			<div>
				{this.props.children}
				{this.state.alert &&
					(
						<Spring from={{ translateY: 0 }} to={{ translateY: 20 }}>
							{(style: any) => {
								return (
									<Alert color={this.state.alertType} style={{ ...this.style.alertStyle, bottom: style.translateY }}>
										{this.mapMessage(this.state.alertMessage + "")+""}
									</Alert>
								);
							}}
						</Spring>
					)}
			</div>
		);
	}
}

