import './style.css';
import React, { useContext, useState, useRef, useEffect } from 'react';
import { useQuery } from 'react-query';
import { motion } from 'framer-motion';
import UserContext from 'src/components/userContext/UserContext';
import CheckMessageService from 'src/services/CheckMessageService';
import { InputView } from 'src/components/inputView/InputView';
import useOnClickOutside from 'src/functions/useOnClickOutside';
import DateHelper from 'src/helpers/DateHelper';

const CheckChat = (props: any) => {
    // Attributes
    const { checkId, isOpen, closeChat } = props;
    const [message, setMessage] = useState("");
    const ref = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(containerRef, closeChat);

    // Queries
    const query = useQuery(`${props.checkId}-comments`, refreshComments);

    async function refreshComments() {
        if (props.isOpen || props.preload) {
            const response = await new CheckMessageService().get(checkId);
            return response;
        }
        return undefined;
    }

    // Effects
    useEffect(() => {
        if (ref.current) {
            ref.current.scrollBy({ top: 10000, behavior: 'smooth' });
        }
    }, [ref.current]);

    useEffect(() => {
        if (props.isOpen) {
            query.refetch();
        }
    }, [props.isOpen]);


    useEffect(() => {
        if (ref.current) {
            ref.current.scrollBy({ top: 10000, behavior: 'smooth' });
        }
    }, [query.data]);

    // Methods
    function handleKeyDown(e: any) {
        if (e.key === 'Enter') {
            handleClicked();
        }
    }

    async function handleClicked() {
        if (message === "") {
            return;
        }
        const response = await new CheckMessageService().post(checkId, { body: message });
        query.refetch();
        setMessage("");
    }

    function shouldShowName(index: number, data: any) {
        try {
            if (data[index - 1].userId === data[index].userId) {
                return false;
            }
            else {
                return true;
            }
        }
        catch {
            return true;
        }
    }

    function shouldShowHour(index: number, data: any) {
        try {
            if (data[index + 1].userId === data[index].userId) {
                return false;
            }
            else {
                return true;
            }
        }
        catch {
            return true;
        }
    }

    if (!isOpen) {
        return <div />
    }

    return (
        <motion.div ref={containerRef} className="chat-container" animate={{ marginRight: 0 }} style={{ padding: 20, marginRight: -500, position: "fixed", right: 0, top: 0, height: '100%', width: 500, zIndex: 10000, backgroundColor: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div style={{ fontSize: 25, fontWeight: 600, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div onClick={() => closeChat()} style={{ color: 'red', cursor: 'pointer', width: 25, height: 25, transform: 'translateY(-8.5px)' }}>
                    <img src="/assets/icon_cross.png" style={{ height: 25, width: 25 }} />
                </div>
                <div style={{ marginLeft: 10 }}>
                    Chat
                </div>
            </div>
            <div ref={ref} className="chat-body">
                <div style={{ overflow: 'scroll', marginTop: 20 }}>
                    {query.data && query.data?.map((elt: any, index: number) => <ChatMessage showName={shouldShowName(index, query.data)} showHour={shouldShowHour(index, query.data)} message={elt} />)}
                </div>
            </div>
            <div className="chat-input-container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                <InputView onKeyDown={handleKeyDown} placeholder="Message" type="text" value={message} onChange={(event) => setMessage(event.target.value)} />
            </div>
        </motion.div>
    );
}

const ChatMessage = (props: any) => {

    const { message, showHour, showName } = props;
    const { user } = useContext(UserContext);
    const isRight = user.id === message.userId;

    // Methods
    function mapHours() {
        if (message.creationDate) {
            return `${DateHelper.formatDate(message.creationDate)}`;
        }
    }

    if (isRight) {
        return (
            <motion.div animate={{ marginRight: 0 }} style={{ marginRight: -150 }}>
                <div className="chat-message">
                    <div className="chat-message-body right">
                        {props.message.body}
                    </div>
                    {showHour ?
                        (
                            <div className="chat-message-user">
                                {props.message.user.firstName[0].toUpperCase()}{props.message.user.lastName[0].toUpperCase()}
                            </div>
                        )
                        :
                        (
                            <div className="chat-message-user invisible" />
                        )
                    }
                </div>
                {showHour && (
                    <div style={{ textAlign: 'right', color: '#525f7f', fontSize: 10, opacity: 0.5, marginRight: 35, marginTop: 5, fontWeight: 300 }}>
                        {mapHours()}
                    </div>
                )}
            </motion.div>
        )
    }

    return (
        <motion.div animate={{ marginLeft: 0 }} style={{ marginLeft: -150, marginTop: 12 }}>
            {showName && (
                <div style={{ color: '#525f7f', fontSize: 11, fontWeight: 300, opacity: 0.75, marginLeft: 38, transform: 'translateY(4px)' }}>
                    {props.message.user.firstName} {props.message.user.lastName}
                </div>
            )}
            <div className="chat-message">
                {showHour ?
                    (
                        <div className="chat-message-user">
                            {props.message.user.firstName[0].toUpperCase()}{props.message.user.lastName[0].toUpperCase()}
                        </div>
                    )
                    :
                    (
                        <div className="chat-message-user invisible" />
                    )
                }
                <div className={message.isFromAdmin ? "chat-message-body left admin" : "chat-message-body left"}>
                    {props.message.body}
                </div>
            </div>
            {showHour && (
                <div style={{ color: '#525f7f', fontSize: 10, opacity: 0.5, marginLeft: 38, marginTop: 5, fontWeight: 300 }}>
                    {mapHours()}
                </div>
            )}
        </motion.div>
    )
}

export default CheckChat;