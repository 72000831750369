import Service from "./Service";
import Axios from "axios";

export default class SuperiorResetPasswordService extends Service {
  post(id: string) {
    return this.execute(
      Axios.post(
        `${this.url}/superiors/${id}/reset-password`,
        undefined,
        this.config()
      )
    );
  }
}
