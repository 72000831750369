import Service from './Service';
import Axios from 'axios';

export default class CertificateService extends Service {
    get(id?:string) {
        if(id)
        {
            return this.execute(Axios.get(`${this.url}/certificates/${id}`, this.config()));
        }
        return this.execute(Axios.get(`${this.url}/certificates`, this.config()));
    }
}
