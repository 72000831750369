const style = {
    container: {
        paddingTop: 30,
        marginTop: 30
    },
    title: {
        color: '#405364',
        fontFamily: 'Roboto',
        fontSize: 18,
        lineHeight: '21px',
        marginBottom: 25
    },
    description: {
        marginTop: 5,
        color: '#68737E',
        fontFamily: 'Roboto',
        fontSize: '16px',
        lineHeight: '19px'
    },
    buttonContainer:
    {
        width: 20,
        height: 20,
        fill: 'white',
        color: 'white',
        margin: 5,
        cursor: 'pointer'
    },
    button:
    {
        width: 30,
        height: 30,
        fill: 'white',
        color: 'white',
        margin: 5,
        cursor: 'pointer',
    },
    td:
    {
        WebkitUserSelect: 'inherit'
    },
    takeAppointmentTitle:
    {
        height: 28,
        width: 436,
        color: "#405364",
        fontSize: 18,
        lineHeight: "21px"
    }
}

export default style;