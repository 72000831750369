export enum PARSING_TYPE {
    DEFAULT = 1,
    DATE = 2,
    ADDRESS = 3,
    BOOL = 4,
    TRANSLATED = 5,
    FILE = 6
}

export default interface MyCertificateFieldDTO {
    screenId: string,
    isActive: boolean,
    isRequired: boolean,
    parsingType: PARSING_TYPE,
    // position: number,
    options: string,
    name: string
}