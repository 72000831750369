import React, { Component } from 'react';
import UserContext from './UserContext';
import UserContextProviderState from './UserContextProviderState';
import Service from 'src/services/Service';

export default class UserContextProvider extends Component<
	any,
	UserContextProviderState
> {
	constructor(props) {
		super(props);

		this.state = {
			user: undefined,
		};

		this._logOut = this._logOut.bind(this);
		this._isInRole = this._isInRole.bind(this);
		this._computeValue = this._computeValue.bind(this);
	}

	private _logOut() {
		this.state = {
			user: undefined,
		};
		this.setState({ user: undefined });
		Service.BEARER_TOKEN = undefined;
		Service.REFRESH_TOKEN = undefined;
	}

	private _isInRole(role: string) {
		if (this.state.user) {
			const { roles } = this.state.user;
			return roles.includes(role);
		}

		return false;
	}

	private _computeValue = () => {
		return {
			user: this.state.user,
			updateUser: (user) => this.setState({ user: user }),
			isInRole: this._isInRole,
			logOut: this._logOut,
			updateToken: (bearer_token, refresh_token) => {
				Service.BEARER_TOKEN = bearer_token;
				Service.REFRESH_TOKEN = refresh_token;
			},
		};
	};

	render() {
		return (
			<UserContext.Provider value={this._computeValue()}>
				{this.props.children}
			</UserContext.Provider>
		);
	}
}
