import * as React from 'react';
import { Modal } from 'reactstrap';
import SideBarViewStyle from './SideBarViewStyle';
import MenuImageView from '../menuImageView/MenuImageView';
import TranslationView from '../../../../components/translationView/TranslationView';
import BaseComponent from '../../../../abstracts/BaseComponent';
import { Spring } from 'react-spring';
import { App } from '../../../../app';
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';
import UserContext from 'src/components/userContext/UserContext';

export default class SideBarView extends BaseComponent<any, any> {
	/* ATTRIBUTES */
	style = new SideBarViewStyle();

	/* CONSTRUCTOR */
	constructor(props: {}) {
		super(props);

		// Initial state
		this.state = {
			modal: false,
			menuHovered: false,
		};

		// Bindings
		this.toggleModal = this.toggleModal.bind(this);
		this.handleLogOut = this.handleLogOut.bind(this);
	}

	/* HANDLERS */
	handleLogOut() {
		this.setState({ modal: false }, this.props.handleLogOut);
	}

	/* METHODS */
	toggleModal() {
		this.setState((previousState) => {
			return { modal: !previousState.modal };
		});
	}

	/* RENDERING */
	render() {
		return (
			<LocalizationContext.Consumer>
				{({ translate }) => (
					<UserContext.Consumer>
						{({ user, isInRole }) => (
							<div style={this.style.container}>
								{/* LOGO */}
								<div
									onClick={App.history.goBack}
									onMouseOver={() => this.setState({ menuHovered: true })}
									onMouseOut={() => this.setState({ menuHovered: false })}
									style={{
										...this.style.logo,
										cursor: 'pointer',
										borderRadius: 26.5,
									}}
								>
									<Spring
										from={{ rotation: this.state.menuHovered ? 0 : 360 }}
										to={{ rotation: this.state.menuHovered ? 360 : 0 }}
									>
										{(style: any) => {
											return this.state.menuHovered ? (
												<img
													src='/assets/button_back.svg'
													style={{ transform: `rotate(${style.rotation}deg)` }}
												/>
											) : (
												<img
													src='/assets/logo_menu.svg'
													style={{ transform: `rotate(${style.rotation}deg)` }}
												/>
											);
										}}
									</Spring>
								</div>

								{/* DASHBOARD */}
								<MenuImageView
									disabled={!isInRole('operator')}
									src='dashboard_icon'
									extension={'svg'}
									route='/dashboard/detail'
								/>

								{/* SEPARATOR */}
								<div style={this.style.separator} />

								{/* PEER MESSAGING */}
								<MenuImageView
									disabled={!isInRole('operator')}
									src='com_icon'
									extension={'svg'}
									route='/dashboard/peer-messaging'
								/>

								{/* BOARD */}
								<MenuImageView
									disabled={!isInRole('operator')}
									src='icon_board'
									extension={'png'}
									route='/dashboard/board'
								/>

								{/* REQUEST */}
								<MenuImageView
									disabled={!isInRole('operator')}
									src='request_icon'
									extension={'svg'}
									route='/dashboard/request'
								/>

								{/* CHECK */}
								<MenuImageView
									disabled={!isInRole('operator')}
									src='check_icon'
									extension={'svg'}
									route='/dashboard/check'
								/>

								{/* ARCHIVE */}
								<MenuImageView
									disabled={!isInRole('operator')}
									src='archive_icon'
									extension={'svg'}
									route='/dashboard/archive'
								/>

								{/* SEPARATOR */}
								<div style={this.style.separator} />

								{/* CERTIFICATE */}
								<MenuImageView
									disabled={!isInRole('my-certificate')}
									src='certificate_icon'
									extension={'svg'}
									route='/dashboard/certificates'
								/>

								{/* SEPARATOR */}
								<div style={this.style.separator} />

								{/* DOCTOR */}
								<MenuImageView
									disabled={!isInRole('sales')}
									src='doctor_icon'
									extension={'svg'}
									route='/dashboard/doctor'
								/>

								{/* COMPANY */}
								<MenuImageView
									disabled={!isInRole('sales')}
									src='company_icon'
									extension={'svg'}
									route='/dashboard/company'
								/>

								{/* PROFILE */}
								<div onClick={this.toggleModal} style={this.style.profile}>
									{`${user?.firstName[0]}${user?.lastName[0]}`}
								</div>

								{/* POP UP */}
								<Modal
									className='modal-dialog-centered'
									isOpen={this.state.modal}
									toggle={this.toggleModal}
									style={this.style.modal}
								>
									<div style={{ height: 535, width: 400, padding: 22 }}>
										{/* CLOSE BUTTON */}
										<div
											onClick={this.toggleModal}
											style={this.style.closeButtonContainer}
										>
											<img
												style={this.style.closeButton}
												src='/assets/icon_cross.png'
											/>
										</div>

										{/* ILLUSTRATION */}
										<img
											className='row'
											style={this.style.modalIllustration}
											src='/assets/illu_logout.png'
										/>

										{/* NAME */}
										<div className='row' style={this.style.nameContainer}>
											<span style={this.style.name}>{`${translate('hey')} ${
												user?.firstName
											}`}</span>
										</div>

										{/* VALIDATION */}
										<div className='row' style={this.style.validation}>
											<TranslationView>whatsup</TranslationView>
										</div>

										{/* LOGOUT BUTTON */}
										<div className='row'>
											<button
												onClick={this.handleLogOut}
												type='button'
												className='btn btn-primary'
												style={this.style.logOutButton}
											>
												<span>
													<img
														src='/assets/icon_logout.svg'
														style={this.style.buttonImage}
													/>
													<span
														className='roboto'
														style={this.style.buttonText}
													>
														<TranslationView>logOut</TranslationView>
													</span>
												</span>
											</button>
										</div>

										{/* USER SETTINGS BUTTON */}
										<div className='row'>
											<button
												onClick={() =>
													this.setState(
														{ modal: false },
														this.props.handleUserSettings
													)
												}
												type='button'
												className='btn btn-primary'
												style={{
													...this.style.settingsButton,
													...(!isInRole('admin')
														? {
																backgroundColor: '#C0D3E0',
																borderColor: '#C0D3E0',
																cursor: 'not-allowed',
														  }
														: {}),
												}}
											>
												<span>
													<img
														src='/assets/icon_user_settings.svg'
														style={this.style.buttonImage}
													/>
													<span
														className='roboto'
														style={this.style.buttonText}
													>
														<TranslationView>settings</TranslationView>
													</span>
												</span>
											</button>
										</div>
									</div>
								</Modal>
							</div>
						)}
					</UserContext.Consumer>
				)}
			</LocalizationContext.Consumer>
		);
	}
}
