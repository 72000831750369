import * as React from 'react';
import SearchableComponentState from './SearchableComponentState';
import BaseComponent from '../../../../abstracts/BaseComponent';

export default abstract class SearchableComponent<Props = {}, State extends SearchableComponentState = { search: "", elements: [], isBusy: false }> extends BaseComponent<Props, State>
{
    /* ATTRIBUTES */
    elements: any[] = [];
    searchableValues: string[] = [];


    /* HANDLERS */
    handleSearchChanged(event: any, dontUpdatePage?: boolean) {
        this.setState({
            isBusy: false, search: event.target.value, page: dontUpdatePage ? (this.state as any).page : 0, elements: this.elements.filter((element: any) => {
                // Ok for "", undefined and 0
                if (!event.target.value) {
                    return true;
                }

                if (this.searchableValues.filter((searchableValue) => this.search(element, searchableValue, event.target.value)).length > 0) {
                    return true;
                }

                return false;
            }
            )
        } as SearchableComponentState);
    }

    /* METHODS */
    search(element: any, searchableValue: string, search: string) {
        try {
            if (searchableValue.includes('.')) {
                searchableValue.split('.').forEach((searchValue) => element = element[searchValue]);

                if (element.toLowerCase().includes(search.toLowerCase())) {
                    return true;
                }
                else {
                    return false;
                }
            }

            return element[searchableValue].toLowerCase().includes(search.toLowerCase());
        }
        catch (exception) {
            return false;
        }
    }
}