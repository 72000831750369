import * as React from 'react';
import DetailViewStyle from './DetailViewStyle';
import { App } from '../../../../app';
import DetailViewProps from './DetailViewProps';
import BaseComponent from '../../../../abstracts/BaseComponent';
import DetailViewState from './DetailViewState';
import PopUpView from '../popUpView/PopUpView';

export default class DetailView extends BaseComponent<DetailViewProps, DetailViewState>
{
    /* ATTRIBUTES */
    style = new DetailViewStyle();

    state = {
        toggled: false,
        isBusy: false,
        modalFunction: () => { }
    };

    /* CONSTRUCTOR */
    constructor(props: DetailViewProps) {
        super(props);

        // Bindings
        this.navigate = this.navigate.bind(this);
        this.handleSaved = this.handleSaved.bind(this);
        this.handleToggled = this.handleToggled.bind(this);
    }

    /* LIFE CYCLE METHOD */
    componentDidMount() {
        App.navigate = this.navigate;
    }

    componentWillUnmount() {
        App.navigate = (path: string, state: any) => App.history.push(path, state);
    }

    /* HANDLERS */
    handleSaved() {
        this.props.handleSaved();
        this.state.modalFunction();
    }

    handleToggled() {
        this.setState({ toggled: !this.state.toggled })
    }

    /* METHODS */
    navigate(path: string, state: any) {
        const { fields } = this.props;

        if (fields.length > 0) {
            this.setState(
                {
                    toggled: true,
                    modalFunction: () => {
                        App.history.push(path, state);
                    }
                }
            );
        }
        else {
            App.history.push(path, state);
        }
    }

    /* RENDERING */
    render() {
        const { toggled, modalFunction } = this.state;
        const { className, style, children } = this.props;

        return (
            <>
                <div className={className} style={style}>
                    {
                        children
                    }
                </div>

                <PopUpView
                    title={"areYouSureYouWantToLeave"}
                    actionTitle={"saveInformation"}
                    ignoreTitle={"noNeedToSave"}
                    illustration={"question"}
                    onClick={this.handleSaved}
                    onToggle={this.handleToggled}
                    toggled={toggled}
                    onIgnore={modalFunction} theme={"warning"} />
            </>
        );
    }
}