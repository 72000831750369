import * as React from 'react';
import { App } from '../../../../../../app';
import PinViewStyle from './PinViewStyle';
import PinViewState from './PinViewState';
import PinViewProps from './PinViewProps';
import OutsideClickHandler from 'react-outside-click-handler';
import DoctorInfoService from '../../../../../../services/DoctorInfoService';
import DoctorInfoDTO from '../../../../../../models/DoctorInfoDTO';
import BaseComponent from '../../../../../../abstracts/BaseComponent';
import TranslationView from '../../../../../../components/translationView/TranslationView';

export default class PinView extends BaseComponent<PinViewProps, PinViewState>
{
    /* ATTRIBUTES */
    style = new PinViewStyle();
    service = new DoctorInfoService();

    /* CONSTRUCTOR */
    constructor(props: PinViewProps) {
        super(props);

        // Initial state
        this.state = { isSelected: false, isHovered: false, doctorInfoDTO: new DoctorInfoDTO(), isBusy: false };

        // Bindings
        this.renderPin = this.renderPin.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleHover = this.handleHover.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.renderDoctor = this.renderDoctor.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    /* HANDLERS */
    handleClick() {
        const { doctor } = this.props;

        this.props.center(doctor.latitude, doctor.longitude);
        this.setState({ isSelected: true }, async () => {
            this.setState({ doctorInfoDTO: await this.execute(this.service.get(doctor.id)) });
        });
    }

    handleHover() {
        this.setState(previousState => {
            return { isHovered: !previousState.isHovered}
        })
    }

    handleOutsideClick() {
        this.setState({ isSelected: false })
    }

    handleEdit() {
        const { doctor } = this.props;
        App.history.push('/dashboard/doctor/detail', { doctor: doctor })
    }

    /* RENDER */
    render() {
        const { doctor } = this.props;

        return (
            <OutsideClickHandler key={doctor.id} onOutsideClick={this.handleOutsideClick}>

                {/* CARD */}
                {
                    this.state.isSelected && this.renderDoctor()
                }

                {/* PINS */}
                {
                    this.renderPin()
                }

            </OutsideClickHandler>
        );
    }

    renderPin() {
        return (
            <div onMouseEnter={this.handleHover} onMouseLeave={this.handleHover} onClick={this.handleClick} style={this.style.pin}>
                {
                    (this.state.isSelected || this.state.isHovered)
                        ?
                        (
                            <img src="/assets/icon_pin_selected.svg" style={this.style.pin} />
                        )
                        :
                        (
                            <img src="/assets/icon_pin.svg" style={this.style.pin} />
                        )
                }
            </div>
        );
    }

    renderDoctor() {
        const { doctor } = this.props;

        return (
            <div className="card shadow" style={this.style.card}>

                <div className="card-header border-0">

                    <div className="row" style={this.style.titleRow}>

                        {/* DOCTOR NAME */}
                        <div className="col" style={this.style.doctorTitleCol}>
                            {`${doctor.firstName} ${doctor.lastName}`}
                        </div>

                        {/* DOCTOR ACTIONS */}
                        <div className="col" style={this.style.actionTitleCol}>
                            <span onClick={this.handleEdit} style={this.style.actionTitleButton}>
                                <img src='/assets/icon_edit.svg' style={this.style.actionTitleButtonIcon} />
                            </span>
                        </div>

                    </div>

                    {/* DOCTOR INFORMATION AND STATS */}
                    <div className="row" style={this.style.addressRow}>
                        <div className="col">
                            {doctor.officeAddress}
                        </div>
                    </div>

                    {/* DOCTOR STATS */}
                    <div style={this.style.doctorInformationContainer}>
                        <div style={this.style.doctorCardInformationBody}>
                            <div style={this.style.cardContainer}>
                                <div onCopy={this.handleCopied} style={this.style.cardInfo}>
                                    {new Date(this.state.doctorInfoDTO.firstDateUse).toLocaleDateString()}
                                </div>
                                <div style={this.style.cardInfoTitle}>
                                    <TranslationView>
                                        pinFirstDateUse
                                    </TranslationView>
                                </div>
                            </div>
                            <div style={this.style.cardContainer}>
                                <div onCopy={this.handleCopied} style={this.style.cardInfo}>
                                    {this.state.doctorInfoDTO.numberOfChecks}
                                </div>
                                <div style={this.style.cardInfoTitle}>
                                    <TranslationView>
                                        pinNbChecks
                                    </TranslationView>
                                </div>
                            </div>
                            <div style={this.style.cardContainer}>
                                <div onCopy={this.handleCopied} style={this.style.cardInfo}>
                                    {this.state.doctorInfoDTO.efficiency}
                                </div>
                                <div style={this.style.cardInfoTitle}>
                                    <TranslationView>
                                        pinEfficiency
                                    </TranslationView>
                                </div>
                            </div>
                        </div>
                        <div style={this.style.doctorCardInformationBody}>
                            <div style={this.style.cardContainer}>
                                <div onCopy={this.handleCopied} style={this.style.cardInfo}>
                                    {`${parseFloat(this.state.doctorInfoDTO.appUsage) * 100}%`}
                                </div>
                                <div style={this.style.cardInfoTitle}>
                                    <TranslationView>
                                        pinAppUsage
                                    </TranslationView>
                                </div>
                            </div>
                            <div style={this.style.cardContainer}>
                                <div onCopy={this.handleCopied} style={this.style.cardInfo}>
                                    {`${this.state.doctorInfoDTO.statER}%`}
                                </div>
                                <div style={this.style.cardInfoTitle}>
                                    <TranslationView>
                                        pinER
                                    </TranslationView>
                                </div>
                            </div>
                            <div style={this.style.cardContainer}>
                                <div onCopy={this.handleCopied} style={this.style.cardInfo}>
                                    {this.state.doctorInfoDTO.reactivity}
                                </div>
                                <div style={this.style.cardInfoTitle}>
                                    <TranslationView>
                                        pinReactivity
                                    </TranslationView>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}