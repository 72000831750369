import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import HeaderView from '../../components/headerView/HeaderView';
import { useTranslation } from 'src/components/localizationContext/LocalizationContext';
import BoardCard from './components/board-card';
import { useQuery } from 'react-query';
import RequestService from 'src/services/RequestService';
import CheckService from 'src/services/CheckService';
import NetworkHelper from 'src/helpers/NetworkHelper';
import BusyIndicator from '../../components/busyIndicator';
import BoardService from 'src/services/BoardService';
import BoardElement from './components/board-element';
import { InputView } from 'src/components/inputView/InputView';
import CheckBoardService from 'src/services/CheckBoardService';

const DashboardBoardView = () => {

    // Attributes
    const { translate } = useTranslation();
    const [search, setSearch] = useState("");

    // Queries
    const query = useQuery("requests-checks", refreshData, {refetchInterval: 30000});

    // Methods
    async function refreshData() {
        const response = await NetworkHelper.execute(BoardService.get());
        return response.data;
    }

    function filterData() {
        if(query.data)
        {
            const result = {
                requests : query.data.requests.filter(elt => elt.request.worker.firstName.toLowerCase().includes(search.toLowerCase()) || elt.request.worker.lastName.toLowerCase().includes(search.toLowerCase())),
                requestsOnHold : query.data.requestsOnHold.filter(elt => elt.request.worker.firstName.toLowerCase().includes(search.toLowerCase()) || elt.request.worker.lastName.toLowerCase().includes(search.toLowerCase())),
                requestsModificationProblem : query.data.requestsModificationProblem.filter(elt => elt.request.worker.firstName.toLowerCase().includes(search.toLowerCase()) || elt.request.worker.lastName.toLowerCase().includes(search.toLowerCase())),
                checksSendDetails : query.data.checksSendDetails.filter(elt => elt.request.worker.firstName.toLowerCase().includes(search.toLowerCase()) || elt.request.worker.lastName.toLowerCase().includes(search.toLowerCase())),
                checksModificationProblem : query.data.checksModificationProblem.filter(elt => elt.request.worker.firstName.toLowerCase().includes(search.toLowerCase()) || elt.request.worker.lastName.toLowerCase().includes(search.toLowerCase())),
                checksSendPriors : query.data.checksSendPriors.filter(elt => elt.request.worker.firstName.toLowerCase().includes(search.toLowerCase()) || elt.request.worker.lastName.toLowerCase().includes(search.toLowerCase())),
                checksPending : query.data.checksPending.filter(elt => elt.request.worker.firstName.toLowerCase().includes(search.toLowerCase()) || elt.request.worker.lastName.toLowerCase().includes(search.toLowerCase())),
                checksFetchResults : query.data.checksFetchResults.filter(elt => elt.request.worker.firstName.toLowerCase().includes(search.toLowerCase()) || elt.request.worker.lastName.toLowerCase().includes(search.toLowerCase())),
                checksSendResults : query.data.checksSendResults.filter(elt => elt.request.worker.firstName.toLowerCase().includes(search.toLowerCase()) || elt.request.worker.lastName.toLowerCase().includes(search.toLowerCase())),
            };

            return result;
        }

        
        return {
            requests : [],
            requestsOnHold :  [],
            requestsModificationProblem :  [],
            checksSendDetails :  [],
            checksModificationProblem :  [],
            checksSendPriors :  [],
            checksPending :  [],
            checksFetchResults :  [],
            checksSendResults :  [],
        };
    }

    return (
        <div className="col">

            {/* PAGE HEADER */}
            <motion.div initial={{ y: -20 }} animate={{ y: 0 }}>
                <HeaderView title={"Board"} history={[{ name: "Board", path: "/dashboard/board" }]}>
                    <motion.div whileHover={{ width: 350 }} style={{marginLeft:'auto'}}>
                        <InputView placeholder={translate('search', 'en')} type="email" value={search} onChange={(event) => setSearch(event.target.value)}>
                            <img src='/assets/icon_search.svg' style={{marginLeft: 'auto', width: 16.5, height: 16.5, filter:'invert(83%) sepia(10%) saturate(107%) hue-rotate(169deg) brightness(100%) contrast(82%)'}} />
                        </InputView>
                    </motion.div>
                </HeaderView>
                <BusyIndicator query={query}>
                    <motion.div style={{ marginLeft: -30, padding: 30, paddingTop: 0, display: 'flex', flexDirection: 'row', overflow: 'scroll', width: '100%', height: '100%' }} initial={{ marginTop: 60 }} animate={{ marginTop: 30 }}>
                        <BoardCard title={"newRequests"} amount={filterData().requests.length}>
                            {filterData().requests?.map((check) => {
                                return (
                                    <BoardElement check={check} />
                                )
                            })}
                        </BoardCard>
                        <BoardCard title={"onHold"} amount={filterData().requestsOnHold.length}>
                            {filterData().requestsOnHold?.map((check) => {
                                return (
                                    <BoardElement check={check} />
                                )
                            })}
                        </BoardCard>
                        <BoardCard title={"requestModificationOrProblem"} amount={filterData().requestsModificationProblem.length}>
                            {filterData().requestsModificationProblem?.map((check) => {
                                return (
                                    <BoardElement check={check} />
                                )
                            })}
                        </BoardCard>
                        <BoardCard title={"SendDetails"} amount={filterData().checksSendDetails.length}>
                            {filterData().checksSendDetails?.map((check) => {
                                
                                function onAmountClicked(){
                                    check.isSendDetailsConfirmed = true;
                                    check.isSendPriorsConfirmed = false;
                                    CheckBoardService.put(check.id, check);
                                }

                                return (
                                    <BoardElement onAmountClicked={onAmountClicked} check={check} />
                                )
                            })}
                        </BoardCard>
                        <BoardCard title={"CheckModificationOrProblem"} amount={filterData().checksModificationProblem.length}>
                            {filterData().checksModificationProblem?.map((check) => {
                                return (
                                    <BoardElement check={check} />
                                )
                            })}
                        </BoardCard>
                        <BoardCard title={"CheckSendPriors"} amount={filterData().checksSendPriors.length}>
                            {filterData().checksSendPriors?.map((check) => {

                                function onAmountClicked(){
                                    check.IsSendPriorsConfirmed = true;
                                    CheckBoardService.put(check.id, check);
                                }

                                return (
                                    <BoardElement onAmountClicked={onAmountClicked} check={check} />
                                )
                            })}
                        </BoardCard>
                        <BoardCard title={"Pending"} amount={filterData().checksPending.length}>
                            {filterData().checksPending?.map((check) => {
                                return (
                                    <BoardElement check={check} />
                                )
                            })}
                        </BoardCard>
                        <BoardCard title={"FetchResults"} amount={filterData().checksFetchResults.length}>
                            {filterData().checksFetchResults?.map((check) => {
                                return (
                                    <BoardElement check={check} />
                                )
                            })}
                        </BoardCard>
                        <BoardCard title={"SendResults"} amount={filterData().checksSendResults.length}>
                            {filterData().checksSendResults?.map((check) => {
                                return (
                                    <BoardElement check={check} />
                                )
                            })}
                        </BoardCard>
                    </motion.div>
                </BusyIndicator>
            </motion.div>
        </div>
    );
}

export default DashboardBoardView;