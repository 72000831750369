import React from 'react';
import TranslationView from 'src/components/translationView/TranslationView';
import { CheckLaunchType } from 'src/dtos/MyCertificateConfigurationDTO';
import { actions } from '../../../../reducer';
import { GeneralProps } from '../../props';
import MyCertificateInput from '../input';
import './style.css';

const RightGroup = ({ myCertificate, dispatch }: GeneralProps) => {
  return (
    <div className='company-mycertificate-rightgroup-container'>
      <MyCertificateInput
        target='incapacityTypes'
        label='Incapacity type criteria'
        type='multi-select'
        value={myCertificate.company.configuration.incapacityTypes
          .filter((elt) => elt.isCheckable)
          ?.map((elt) => elt.type)}
        handleOnChange={(event) =>
          dispatch({
            type: actions.companyChanged,
            payload: {
              target: 'incapacityTypes',
              value: myCertificate.company.configuration.incapacityTypes?.map(
                (elt) => {
                  if (event.target.value?.includes(elt.type)) {
                    elt.isCheckable = true;
                  } else {
                    elt.isCheckable = false;
                  }

                  return elt;
                }
              ),
            },
          })
        }
      >
        {myCertificate.company.configuration.incapacityTypes
          .filter((elt) => elt.isActive)
          ?.map((option) => {
            return (
              <option value={option.type}>
                <TranslationView>{option.name}</TranslationView>
              </option>
            );
          })}
      </MyCertificateInput>
      <MyCertificateInput
        target='certificateTypes'
        label='Certificate type criteria'
        type='multi-select'
        value={
          myCertificate.company.configuration.checkLaunchType ===
          CheckLaunchType.ABSENCE_DECLARATION
            ? []
            : myCertificate.company.configuration.certificateTypes
                .filter((elt) => elt.isCheckable)
                ?.map((elt) => elt.type)
        }
        disabled={
          myCertificate.company.configuration.checkLaunchType ===
          CheckLaunchType.ABSENCE_DECLARATION
        }
        handleOnChange={(event) =>
          dispatch({
            type: actions.companyChanged,
            payload: {
              target: 'certificateTypes',
              value: myCertificate.company.configuration.certificateTypes?.map(
                (elt) => {
                  if (event.target.value?.includes(elt.type)) {
                    elt.isCheckable = true;
                  } else {
                    elt.isCheckable = false;
                  }

                  return elt;
                }
              ),
            },
          })
        }
      >
        {myCertificate.company.configuration.certificateTypes
          .filter((elt) => elt.isActive)
          ?.map((option) => {
            return (
              <option value={option.type}>
                <TranslationView>{option.name}</TranslationView>
              </option>
            );
          })}
      </MyCertificateInput>
      <div className='company-mycertificate-rightgroup-row'>
        <div style={{ width: '100%', marginRight: 30 }}>
          <MyCertificateInput
            target='isRequestFromSuperiorsAllowed'
            label='Superior Check launch'
            type='select'
            value={
              myCertificate.company.configuration.isRequestFromSuperiorsAllowed
            }
            handleOnChange={(event) =>
              dispatch({
                type: actions.configChanged,
                payload: {
                  target: 'isRequestFromSuperiorsAllowed',
                  value: event.target.value,
                },
              })
            }
          >
            <option value={true as any}>Yes</option>
            <option value={false as any}>No</option>
          </MyCertificateInput>
        </div>
        <div style={{ width: '100%' }}>
          <MyCertificateInput
            target='isRequestFromHRAllowed'
            label='HR Check launch'
            type='select'
            value={myCertificate.company.configuration.isRequestFromHRAllowed}
            handleOnChange={(event) =>
              dispatch({
                type: actions.configChanged,
                payload: {
                  target: 'isRequestFromHRAllowed',
                  value: event.target.value,
                },
              })
            }
          >
            <option value={true as any}>Yes</option>
            <option value={false as any}>No</option>
          </MyCertificateInput>
        </div>
      </div>
    </div>
  );
};

export default RightGroup;
