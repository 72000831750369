import Service from './Service';
import Axios from 'axios';
import CheckDTO from '../models/CheckDTO';

export default class CheckService extends Service {
    get(id?: string) {
        if (id) {
            return this.execute(Axios.get(`${this.url}/checks/${id}`, this.config()));
        }

        return this.execute(Axios.get(`${this.url}/checks`, this.config()));
    }

    post(check: CheckDTO) {
        return this.execute(Axios.post(`${this.url}/checks`, check, this.config()));
    }

    put(check: CheckDTO) {
        return this.execute(Axios.put(`${this.url}/checks`, check, this.config()));
    }

    delete(checkId: string) {
        return this.execute(Axios.delete(`${this.url}/checks/${checkId}`, this.config()));
    }
}
