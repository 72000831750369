import * as React from 'react';
import { App } from '../../../../app';
import HeaderViewProps from './HeaderViewProps';
import HeaderViewStyle from './HeaderViewStyle';

export default class HeaderView extends React.Component<HeaderViewProps>
{
    /* ATTRIBUTES */
    style = new HeaderViewStyle();

    /* CONSTRUCTOR */
    constructor(props: HeaderViewProps) {
        super(props);
    }

    /* LIFE CYCLE EVENTS */
    componentDidMount() { }

    /* HANDLERS */

    /* METHODS */

    /* RENDERING */
    render() {
        return (
            <div>

                {/* TITLE */}
                <div style={this.style.titleContainer}>
                    <span style={this.style.title}>
                        {this.props.title}
                    </span>
                    {this.props.children &&
                        (
                            <span style={this.style.extraNavigation}>
                                {this.props.children}
                            </span>
                        )}
                </div>

                {/* HISTORY */}
                <div style={this.style.historyContainer}>
                    {this.props.history?.map((page: any, index: number) => {
                        if (index == 0) {
                            return (
                                <span key={index} onClick={() => App.navigate(page.path, page.state)} style={this.style.cursor}>
                                    {page.name}
                                </span>
                            );
                        }
                        return (
                            <span key={index}>
                                <span style={this.style.line}>
                                    -
                                    </span>
                                <span>

                                </span>
                                <span onClick={() => App.navigate(page.path, page.state)} style={this.style.pageName}>
                                    {page.name}
                                </span>
                            </span>

                        );
                    })}
                </div>
            </div>
        );
    }
}

