import * as React from 'react';
import { Modal } from 'reactstrap';
import PopUpViewProps from './PopUpViewProps';
import PopUpViewState from './PopUpViewState';
import TranslationView from '../../../../components/translationView/TranslationView';
import PopUpViewStyle from './PopUpViewStyle';
import UserContext from 'src/components/userContext/UserContext';

export default class PopUpView extends React.Component<PopUpViewProps, PopUpViewState> {

    /* ATTRIBUTES */
    style = new PopUpViewStyle();

    /* CONSTRUCTOR */
    constructor(props: PopUpViewProps) {
        super(props);

        // Bindings
        this.handleIgnore = this.handleIgnore.bind(this);
        this.handleClicked = this.handleClicked.bind(this);
        this.getIllustration = this.getIllustration.bind(this);
    }

    /* HANDLERS */
    handleClicked() {
        const { onClick, onToggle } = this.props;
        onToggle();
        onClick();
    }

    handleIgnore() {
        const { onIgnore, onToggle } = this.props;
        onToggle();
        onIgnore();
    }

    /* METHODS */
    getIllustration() {
        const { illustration } = this.props;

        switch (illustration) {
            case 'credits':
                return '/assets/illu_credit_confirmation.png';

            case 'question':
                return '/assets/illu_warning.png';

            case 'warning':
                return '/assets/illu_red_warning.png';
        }
    }

    /* RENDER */
    render() {
        const { theme, title, actionTitle, ignoreTitle, toggled, onToggle } = this.props;

        return (
            <UserContext.Consumer>
                {({ user }) => (
                    <Modal className="modal-dialog-centered" isOpen={toggled} toggle={onToggle} style={this.style.container}>

                        <div style={this.style.modal}>

                            {/* CLOSE BUTTON */}
                            <div onClick={onToggle} style={this.style.closeContainer}>
                                <img style={this.style.close} src="/assets/icon_cross.png" />
                            </div>

                            {/* ILLUSTRATION */}
                            <img className="row" style={this.style.illustration} src={this.getIllustration()} />

                            {/* NAME */}
                            <div className={`row ${theme}`} style={this.style.nameContainer}>
                                <span style={this.style.name}>
                                    <TranslationView>
                                        hey
                                    </TranslationView>
                                    {user?.firstName}
                                </span>
                            </div>

                            {/* VALIDATION */}
                            <div className="row" style={this.style.title}>
                                <TranslationView>
                                    {title}
                                </TranslationView>
                            </div>

                            {/* SAVE BUTTON */}
                            <div className='row'>
                                <button onClick={this.handleClicked} type="button" className={`btn btn-base btn-base-${theme}`} style={this.style.button}>
                                    <span className='roboto' style={this.style.buttonText}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                                            {actionTitle === "deleteCheck" ? <img src="/assets/icon_delete_Check.svg" width={20} height={20} style={{ marginRight: 10 }} /> : ""}
                                            <TranslationView>
                                                {actionTitle}
                                            </TranslationView>
                                        </div>
                                    </span>
                                </button>
                            </div>

                            {/* DON'T SAVE */}
                            <div className="row" style={this.style.ignoreContainer}>
                                <span onClick={this.handleIgnore} style={this.style.ignore}>
                                    <TranslationView>
                                        {ignoreTitle}
                                    </TranslationView>
                                </span>
                            </div>

                        </div>
                    </Modal>
                )}
            </UserContext.Consumer>
        );
    }
}