import { App } from 'src/app';
import { useInactivityTracker } from 'src/functions/useInactivityTracker';

const TIMEOUT_DURATION = 3600000;

const InactivityTracker = () => {
  const userInactive = () => {
    App.history.push('/login', { redirect: true, shouldSignOut: true });
  };

  useInactivityTracker({ timeoutDuration: TIMEOUT_DURATION, onInactive: userInactive });

  return null; 
};

export default InactivityTracker;