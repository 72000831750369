import { CheckStatus } from '../../../../constants/CheckStatus';

export const TagViewStyle =
{
    tag: (status: number) => {

        let color = '';

        switch (status) {
            case CheckStatus.REQUESTED:
                color = '#BED2DE';
                break;
            case CheckStatus.PAUSED:
                color = '#019BC7';
                break;
            case CheckStatus.REQUEST_PROBLEM:
            case CheckStatus.PROBLEM:
                color = '#EC5467';
                break;
            case CheckStatus.SCHEDULED:
                color = '#9FD6E5';
                break;
            case CheckStatus.FINISHED:
                color = '#0A728E';
                break;
            case CheckStatus.UPDATED:
                color = '#FDB56D';
                break;
            case CheckStatus.REQUESTUPDATED:
                color = '#FDB56D';
                break;
            default:
                color = 'black'
                break;
        }

        return {
            backgroundColor: color,
            height: 13,
            width: 13,
            borderRadius: 6.5
        };
    },

    imageTag : {
        height: 13,
        width: 13
    }
}