import * as React from 'react';
import BaseComponent from '../../../../../../abstracts/BaseComponent';
import PhoneLogViewStyle from './PhoneLogViewStyle';

export default class PhoneLogView extends BaseComponent<any, any>
{
    /* ATTRIBUTES */
    style = new PhoneLogViewStyle();

    /* CONSTRUCTOR */
    constructor(props: any)
    {
        super(props);

        // Initial state

        // Bindings
    }

    /* HANDLERS */
    /* RENDER */
    render()
    {
        const {doctor} = this.props;

        return (     
            <div style={this.style.basicLogContainer}>

                <div style={this.style.basicLog}>

                    <div style={{...this.style.commentLogText, backgroundColor:'#85D8C8', borderTopLeftRadius:4, borderTopRightRadius:4, height:38, borderBottomLeftRadius:0, borderBottomRightRadius:0}}>
                        <div style={{float:'left'}}>
                        Ask for an appointment to the doctor by phone
                        </div>
                    </div>

                    {/* LOG RENDERING */}
                    <div style={{...this.style.logText, backgroundColor:'#85D8C8'}}>
                        <div style={{display:'flex', flexDirection:'row'}}>
                            <div style={{...this.style.logTitleText, marginTop:5, width:85}}>
                                GSM :
                            </div>
                            <div style={{...this.style.logBodyText, width:'100%', fontSize:12, padding:6}}>
                                {doctor.gsm ? doctor.gsm : '- - -'}
                            </div>
                        </div>
                        <div style={{display:'flex', flexDirection:'row'}}>
                            <div style={{...this.style.logTitleText, marginTop:5, width:85}}>
                                FIX :
                            </div>
                            <div style={{...this.style.logBodyText, width:'100%', fontSize:12, padding:6}}>
                                {doctor.fixNumber ? doctor.fixNumber : '- - -'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}