import React, { useState } from 'react';
import ToggleViewProps from './ToggleViewProps';
import TranslationView from '../../../../components/translationView/TranslationView';
import ToggleViewStyle from './ToggleViewStyle';

const ToggleView = (props: ToggleViewProps) => {

    const style = new ToggleViewStyle();
    const { isToggled, handleIsToggled } = props;

    if (!isToggled) {
        return (
            <div onClick={() => handleIsToggled(!isToggled)} style={{ cursor: 'pointer', alignItems: 'center', display: 'flex', backgroundColor: 'white', width: 80, height: 40, marginLeft: 10, borderRadius: 20, border: '1px solid #E3E3E3' }}>
                <span style={style.toggled}>

                </span>
                <span style={{ marginLeft: 'auto', marginRight: 10, color: '#E3E3E3', fontWeight: 500, fontSize: 15 }}>
                    <TranslationView>
                        {props.noTitle ? props.noTitle : "no"}
                    </TranslationView>
                </span>
            </div>
        );
    }

    return (
        <div onClick={() => handleIsToggled(!isToggled)} style={{ cursor: 'pointer', alignItems: 'center', display: 'flex', backgroundColor: 'white', width: 80, height: 40, marginLeft: 10, borderRadius: 20, border: '1px solid #009DC8' }}>


            <span style={{ marginRight: 'auto', marginLeft: 10, color: '#009DC8', fontWeight: 500, fontSize: 15 }}>
                <TranslationView>
                    {props.noTitle ? props.yesTitle : "yes"}
                </TranslationView>
            </span>
            <span style={style.unToggled}>

            </span>
        </div>
    )
}

export default ToggleView;