import * as React from "react";
import CompanyDetailViewStyle from "../../CompanyDetailViewStyle";
import { InputView } from "../../../../../../../../components/inputView/InputView";
import TranslationView from "../../../../../../../../components/translationView/TranslationView";
import { LocalizationContext } from "src/components/localizationContext/LocalizationContext";

const style = new CompanyDetailViewStyle();

interface CompanyRecipientProps {
	element: any;
	handleInputChanged: any;
}

const CompanyRecipientView = (props: CompanyRecipientProps) => {
	const { element, handleInputChanged } = props;

	return (
		<LocalizationContext.Consumer>
			{({ translate }) => (
				<div className="card shadow" style={style.card}>
					{/* TITLE */}
					<div className="card-header border-1" style={style.cardHeader}>
						<TranslationView>companyDetailRecipientPreferencesReporting</TranslationView>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col">
								{/* EMAILS FOR RESULTS */}
								<div style={style.inputDouble}>
									<div style={style.inputTitle}>
										<TranslationView>companyDetailEmailsForResults</TranslationView>
									</div>
									<InputView placeholder="amelie@medicheck.io, touni@medicheck.io" type="text" value={element.emailResults} onChange={(event) => handleInputChanged("emailResults", event.target.value)} />
								</div>
							</div>
							<div className="col">
								{/* EMAILS FOR REPORTS */}
								<div style={style.inputDouble}>
									<div style={style.inputTitle}>
										<TranslationView>companyDetailEmailsForHR</TranslationView>
									</div>
									<InputView placeholder="hr@medicheck.io" type="text" value={element.emailHR} onChange={(event) => handleInputChanged("emailHR", event.target.value)} />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</LocalizationContext.Consumer>
	);
};

export default CompanyRecipientView;
