import * as React from 'react';
import { Spring } from "react-spring";
import { InputView } from '../../../../../../../../components/inputView/InputView';
import CommunicationDTO from '../../../../../../../../models/CommunicationDTO';
import ToolBoxElementView from '../toolBoxElementView/ToolBoxElementView';
import ToolBoxViewStyle from './ToolBoxViewStyle';
import TranslationView from '../../../../../../../../components/translationView/TranslationView';
import BaseComponent from '../../../../../../../../abstracts/BaseComponent';
import { motion, useAnimation } from 'framer-motion';
import IntegrationDTO from 'src/models/IntegrationDTO';

const ToolBoxView = (props: any) => {
    // Attributes
    const style = new ToolBoxViewStyle();
    const { communications, handleCommunicationClicked, integrations, handleIntegrationClicked } = props;
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const toolBoxControls = useAnimation();
    const toolBoxContentControls = useAnimation();
    const toolBoxContentControlsFirst = useAnimation();
    const toolBoxContentControlsSecond = useAnimation();

    // Effects
    React.useEffect(() => {
        if (open) {
            sequence();
        }
    }, [open]);

    // Methods
    const sequence = async () => {
        await toolBoxControls.start({
            width: window.innerWidth - 730,
            height: window.innerHeight,
            padding: 50,
            overflowY: 'scroll',
            transition: {
                duration: 0.3
            }
        });
        toolBoxContentControls.start({
            y: 0, opacity: 1, transition: {
                duration: 0.3
            }
        });
        toolBoxContentControlsFirst.start({
            y: 0, opacity: 1, transition: {
                duration: 0.2
            }
        });
        return await toolBoxContentControlsSecond.start({
            y: 0, opacity: 1, transition: {
                duration: 0.2
            }
        });
    }

    function translate(key: string, language: string) {
        try {
            let word: string = "";

            word = BaseComponent.translations[key][language];

            if (word == "") {
                return `[${key}]`;
            }

            return word;
        }
        catch (ex) {
            return `[${key}]`;
        }
    }

    const filterCommunications = (type: number) => {
        const filteredCommunications: any[] = communications.filter((elt: any) => elt.communicationType === type && translate(elt.title, "en").toLocaleLowerCase().includes(search.toLocaleLowerCase()));
        const filteredIntegrations = integrations.filter((elt: IntegrationDTO) => translate(elt.title, "en").toLocaleLowerCase().includes(search.toLocaleLowerCase()));

        let list = filteredCommunications?.map((elt: CommunicationDTO) => <ToolBoxElementView elt={elt} handleOnClick={() => handleCommunicationClicked(elt)} />);
        list = list.concat(filteredIntegrations?.map((elt: IntegrationDTO) => <ToolBoxElementView elt={elt} handleOnClick={() => handleIntegrationClicked(elt)} />));

        list.push(<div style={{ width: 150, height: 0 }} />);
        list.push(<div style={{ width: 150, height: 0 }} />);
        list.push(<div style={{ width: 150, height: 0 }} />);
        list.push(<div style={{ width: 150, height: 0 }} />);
        list.push(<div style={{ width: 150, height: 0 }} />);
        list.push(<div style={{ width: 150, height: 0 }} />);

        return list;
    }

    // Render
    return (
        <>
            {
                // Closed in the right corner
                !open ?
                    (
                        <div onClick={() => setOpen(true)} style={style.toolBoxContainer}>
                            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }} style={style.toolBox}>
                                <img src="/assets/icon_toolbox_closed.svg" style={style.toolBoxIcon} />
                            </motion.div>
                        </div>
                    )

                    :

                    // Open in the right corner
                    (
                        <motion.div initial={{ width: 100, height: 100 }} animate={toolBoxControls} onMouseLeave={() => { setOpen(false); }} style={style.toolBoxContainerWhite}>
                            <motion.div initial={{ y: 20, opacity: 0 }} animate={toolBoxContentControls} style={style.toolBoxBody}>

                                {/* SEARCH CONTAINER */}
                                <motion.div whileHover={{ width: 350 }} style={style.searchContainer}>
                                    <InputView placeholder={"search"} type="email" value={search} onChange={(elt) => setSearch(elt.target.value)}>
                                        <img src='/assets/icon_search.svg' style={style.searchIcon} />
                                    </InputView>
                                </motion.div>

                                {/* COMMUNICATIONS */}
                                <motion.div initial={{ y: 20, opacity: 0 }} animate={toolBoxContentControlsFirst} style={style.communicationContainer}>
                                    <TranslationView>
                                        basicCommunications
                                    </TranslationView>
                                    <div style={style.communicationBody}>
                                        {filterCommunications(0)}
                                    </div>
                                </motion.div>
                                <motion.div initial={{ y: 20, opacity: 0 }} animate={toolBoxContentControlsSecond} style={style.communicationContainer}>
                                    <TranslationView>
                                        specialCommunications
                                    </TranslationView>
                                    <div style={style.communicationBody}>
                                        {filterCommunications(1)}
                                    </div>
                                </motion.div>

                            </motion.div>
                        </motion.div>
                    )
            }
        </>
    );
}

export default ToolBoxView;