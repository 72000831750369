import * as React from 'react';
import { Spring } from 'react-spring';
import LogViewProps from './LogViewProps';
import LogConstants from '../../../../../../constants/LogConstants';
import BaseComponent from '../../../../../../abstracts/BaseComponent';
import UserDTO from '../../../../../../models/UserDTO';
import LogViewStyle from './LogViewStyle';
import EmailLogView from '../EmailLogView/EmailLogView';
import SMSLogView from '../SMSLogView/SMSLogView';

export default class LogView extends BaseComponent<LogViewProps, any>
{
    /* ATTRIBUTES */
    style = new LogViewStyle();

    /* RENDER */
    render() {
        // Destructuring
        const { log, isSchedule } = this.props;
        const { request } = log;
        const { superior } = request;

        // Animations
        // 517 && 80 are numbers taken from the min size of the screen in order to animate correctly.
        const fromAnimation = isSchedule ? { marginLeft: 0, scale: 1 } : { marginLeft: window.innerWidth - 517 - 80, scale: 0 };
        const toAnimation = { marginLeft: 0, scale: 1 };

        if (log.type === LogConstants.SEND_WORKER_SMS || log.type === LogConstants.SEND_COMPANY_SMS || log.type === LogConstants.SEND_DOCTOR_SMS) {
            return (
                <Spring from={fromAnimation} to={toAnimation} delay={this.props.delay}>
                    {(animatedStyle: any) => {
                        return (
                            <div key={log.id} style={{ ...this.style.basicLogContainer, transform: `translate(${animatedStyle.marginLeft}px, ${0}px)` }}>
                                <SMSLogView doctor={this.props.doctor} isModify={this.props.isModify} isDelete={this.props.isDelete} isSchedule={this.props.isSchedule} enabled={log.payLoad === undefined && !this.props.archived} refresh={this.props.refresh} log={this.props.log} superior={superior} request={request} check={this.props.check} />
                            </div>
                        )
                    }
                    }
                </Spring>
            );
        }

        return (
            <Spring from={fromAnimation} to={toAnimation} delay={this.props.delay}>
                {(animatedStyle: any) => {
                    return (
                        <div key={log.id} style={{ ...this.style.basicLogContainer, transform: `translate(${animatedStyle.marginLeft}px, ${0}px)` }}>
                            <EmailLogView doctor={this.props.doctor} isModify={this.props.isModify} isDelete={this.props.isDelete} isSchedule={this.props.isSchedule} enabled={log.payLoad === undefined && !this.props.archived} refresh={this.props.refresh} log={this.props.log} superior={superior} request={request} check={this.props.check} />
                        </div>
                    )
                }
                }
            </Spring>
        );
    }
}